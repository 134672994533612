import React, { useEffect } from 'react';

import styles from './SearchResult.module.scss';

import he from 'he';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import {Infinity, SVGSmallX} from '@assets/icons';
import { IFilterBy, ITabCounts2 } from '@interfaces/index';

import {issues} from "@assets/constants/issues";

interface ISearchResultProps {
  setHasMore: any;
  filterBy: IFilterBy;
  setFilterBy: (filterby: IFilterBy) => void;
  inputValues: Record<string, string | number>;
  setInputValues: (inputValue: Record<string, string | number>) => void;
  lpPlatforms?: any;
  setLpPlatforms?: (platforms: any) => void;
  customMarketplaces: any;
  setCustomMarketplaces: (customMarketplaces: any) => void;
  activePlatforms: string[];
  setActivePlatforms: (activePlatforms: string[]) => void;
  setSearchItem: (searchItem: string) => void;
  handleFetchForSearch: (filterValue: string) => void;
  handleGetColumnSearchDebounced: (
    col: string,
    value: string | number | string[]
  ) => void;
  tabCounts2: ITabCounts2;
  setTabCounts2: (tabCount: any) => void;
  sendReguestColumnSearch: (tab: string, searchPayload: any) => any;
  filterByArr: string[];
  setFilterByArr: (value: string[]) => void;
  myHelpConditionFunc: (response: any, tab: string) => void;
  tab: any;
  hasPro: boolean;
  is_role_allowed: boolean;
  setStartDateCreated?:any;
  setEndDateCreated?:any;
  setStartDateSoldDate?:any;
  setEndDateSoldDate?:any;
  setIssuesValue?:(issuesValue:any) => void;
  issuesChanged?:boolean;
  setIssuesChanged?:(issuesChanged:any) => void;
}

const SearchResult = (props: ISearchResultProps) => {
  const {
    filterBy,
    setFilterBy,
    inputValues,
    setInputValues,
    lpPlatforms,
    setLpPlatforms,
    setActivePlatforms,
    setSearchItem,
    handleFetchForSearch,
    handleGetColumnSearchDebounced,
    tabCounts2,
    setTabCounts2,
    sendReguestColumnSearch,
    filterByArr,
    setFilterByArr,
    activePlatforms,
    setHasMore,
    myHelpConditionFunc,
    customMarketplaces,
    setCustomMarketplaces,
    tab,
    hasPro,
    is_role_allowed,
    setStartDateCreated,
    setEndDateCreated,
    setStartDateSoldDate,
    setEndDateSoldDate,
    setIssuesValue,
   issuesChanged,
   setIssuesChanged
  } = props;
  const finishedToGetCustomMarkets = useSelector((state: any) => state.customMarketplaces.finishedGetCustomMarkets)

  let keys = [
    1,
    4,
    15,
    22,
    82,
    133,
    206,
    209,
    213,
    453,
    505,
    704,
    726,
    842,
    847,
    851,
    795,
    796,
    846,
    'created_at',
    'platforms',
    'not_platforms',
    'custom_marketplaces',
    'not_custom_marketplaces',
    'search_everything',

  ];

  const issueKeys = [
    'issues_status_selected_empty_item_number',
    'issues_status_selected_no_active_listing_found',
    'issues_status_not_selected_active_listing_found',
    'issues_sold_status_active_listing_found',
    'issues_status_selected_no_listing_found'
  ]

  const issueKeysMapping:any = {
    'issues_status_selected_empty_item_number' : 'Empty IDs - Status selected, no ID saved',
    'issues_status_selected_no_active_listing_found' : 'Marketplace status selected, no ACTIVE listing found',
    'issues_status_not_selected_active_listing_found' : 'Marketplace status NOT selected, ACTIVE listing found',
    'issues_sold_status_active_listing_found' : 'Sold status selected, ACTIVE listing found',
    'issues_status_selected_no_listing_found' : 'Unable to check status OR listing not found'
  }


  const platformCodeToName: { [code: string]: string } = {
    '539.1': 'SOLD',
    '539.2': 'eBay',

    '539.3': 'Etsy',

    '539.4': 'Poshmark',
    '539.5': 'Mercari',

    '539.6': 'Tradesy',
    '539.7': 'Amazon',

    '539.8': 'Grailed',

    '539.9': 'Facebook',

    '539.11': 'Depop',

    '539.12': 'Kidizen',

    '539.13': 'Shopify',

    '539.14': 'Instagram',

    '539.15': 'Heroine',

    '539.16': 'Listing Party',

    '539.17': 'Vestiaire',
  };

  // added custom marketplaces platformCode : platformTitle

  const updatedPlatformCodeToName: { [code: string]: string } =
    customMarketplaces?.reduce(
      (acc: any, item: any) => {
        const platformCode = item.platform.code[0];
        const platformTitle = item.platform.title;

        return { ...acc, [platformCode]: platformTitle };
      },
      { ...platformCodeToName }
    );

  const getIntendedForText = (value: string) => {
    try{
      const intendedForOptions: Record<string, string> = {
        "None": "None",
        "Unisex": "Unisex",
        "Mens": "Men",
        "Womens": "Women",
        "Unisex Kids": "Unisex Kids",
        "Girls": "Girls",
        "Boys": "Boys",
        "Babys": "Babies",
        "Maternity": "Maternity",
        "Pets": "Pets",
        "Cats": "Cats",
        "Dogs": "Dogs"
      };

      return value === "None" ? "-" : intendedForOptions[value] || value;
    }
    catch(e){
      console.log(e, 'error')
    }
  };

  useEffect(() => {
    if(finishedToGetCustomMarkets && customMarketplaces) {
      keys = [...keys, ...issueKeys]
      const newFilterByArr = Object.keys(filterBy)
      .filter((key) => keys.includes(Number(key)) || keys.includes(key))
      .map((key) => {
        const hasIssueKey = issueKeys.some((issueKey:any) => issueKey.includes(key))
        if (Array.isArray(filterBy[key])) {
          return filterBy[key].map((item: string) => {
            const platformName = updatedPlatformCodeToName[item];
            if(platformName) {
              return platformName &&
              (key === 'platforms' || key === 'custom_marketplaces')
               ? platformName
               : platformName === 'SOLD'
                ? `Not ${platformName}`
                : `Not on ${platformName}`;
            }
          });
        }
        if(hasIssueKey) {
         return issueKeysMapping[key]
        }

        if(key === '4'){
          return {
            4: {
              text: filterBy[key]
            }
          };
        }

        if(key === '846') {
          return {
            846: {
              ...filterBy[key]
            }
          };
        }
        if(key === 'created_at') {
          return {
            created_at: {
              ...filterBy[key]
            }
          };
        }
        return filterBy[key];
      })
      .flat()
      .sort();
      setFilterByArr(newFilterByArr);
    }
  }, [filterBy, finishedToGetCustomMarkets, customMarketplaces]);

  const handleDeleteFilterTag = async (tag: any) => {
   try{
     const filterKeys = Object.keys(filterBy);
     const hasOnlyIssuesKeys = filterKeys.every(key => issues.includes(key)) && filterKeys.some(key => issues.includes(key));
     const myTag = typeof tag === 'string'
         ? he.decode(tag)
         : (typeof tag === 'object' && tag !== null
             ? tag
             : (tag && tag.toString ? tag.toString() : ''));

     const newFilterBy = { ...filterBy };
     const newInputValues:any = { ...inputValues };

     delete newFilterBy.active;

     if (setLpPlatforms) {
       setActivePlatforms(activePlatforms.filter((item) => item !== tag));

       setLpPlatforms((prevLpPlatforms: any) =>
           prevLpPlatforms.map((item: any) => {
             if (item && item.platform.title === tag) {
               return {
                 ...item,
                 platform: { ...item.platform, isChecked: false },
               };
             } else if (item && item.notOnPlatform.title === tag) {
               return {
                 ...item,
                 notOnPlatform: { ...item.notOnPlatform, isChecked: false },
               };
             }
             return item;
           })
       );
     }

     if (setCustomMarketplaces) {
       setActivePlatforms(activePlatforms.filter((item) => item !== tag));

       setCustomMarketplaces((prevCustomMarketplaces: any) =>
           prevCustomMarketplaces.map((item: any) => {
             if (item && item.platform.title === tag) {
               return {
                 ...item,
                 platform: { ...item.platform, isChecked: false },
               };
             } else if (item && item.notOnPlatform.title === tag) {
               return {
                 ...item,
                 notOnPlatform: { ...item.notOnPlatform, isChecked: false },
               };
             }
             return item;
           })
       );
     }

     const tabs = [
       'view all',
       'active',
       'draft',
       'unlisted',
       'out of stock',
       'sold',
       'issues',
     ];

     let hasDeletedSearchEverything = false;

     for (const key in newFilterBy) {
       if (newFilterBy.hasOwnProperty(key)) {
         const value = newFilterBy[key];

         if(key === '4' && _.has(tag, '4')){
           delete newFilterBy['4'];
         }

         if(key === 'created_at' && _.has(tag, 'created_at')){
           delete newFilterBy['created_at'];
           setStartDateCreated(null);
           setEndDateCreated(null);
         }
         if(key === '846' && _.has(tag, '846')){
           delete newFilterBy['846'];
           setStartDateSoldDate(null);
           setEndDateSoldDate(null);
         }
         if (Array.isArray(value)) {
           if (
               (key === 'platforms' || key === 'custom_marketplaces') && typeof tag === 'string' &&
               !tag.startsWith('Not')
           ) {
             const index = value.findIndex((item: string) => {
               const platformName = updatedPlatformCodeToName[item];
               return platformName === tag;
             });

             if (index > -1) {
               value.splice(index, 1);
             }
           } else if (
               (key === 'not_platforms' || key === 'not_custom_marketplaces') && typeof tag === 'string' &&
               tag.startsWith('Not')
           ) {
             const platformNameWithOutNotOn = tag.includes('SOLD')
                 ? tag.split('Not')[1].trim()
                 : tag.split('Not on')[1].trim();

             const index = value.findIndex((item: string) => {
               const platformName = updatedPlatformCodeToName[item];
               return platformName === platformNameWithOutNotOn;
             });

             if (index > -1) {
               value.splice(index, 1);
             }
           }

           if (value.length === 0) {
             if (key === 'search_everything') {
               hasDeletedSearchEverything = true;
             }

             delete newFilterBy[key];
           }
         } else if (value === tag) {
           if (key === 'search_everything') {
             hasDeletedSearchEverything = true;
           }
           delete newFilterBy[key];
         } else if (issueKeysMapping[key] === tag) {
           delete newFilterBy[key];
           localStorage.removeItem('selectedIssue')
           setIssuesValue &&   setIssuesValue ({
             value: '',
             label: '',
             placeholder: '',
           })

         }
       } else if (newFilterBy[key] === tag) {
         if (key === 'search_everything') {
           hasDeletedSearchEverything = true;
         }

         delete newFilterBy[key];
         setSearchItem('');
       }
     }

     for (const key in newInputValues) {
       const formatDate = (date: any): string | null => {
         return date ? new Date(date).toISOString() : null;
       };

       const isEqual = (date1: string | null, date2: string | null): boolean => {
         return date1 !== null && date1 === date2;
       };

       const formattedTagStart = formatDate(myTag?.start);
       const formattedTagEnd = formatDate(myTag?.end);
       const formattedCreatedStart = formatDate(newInputValues['createdStart']);
       const formattedCreatedEnd = formatDate(newInputValues['createdEnd']);
       const formattedCreatedFullChangedStart = formatDate(newInputValues['createdFullChanged']?.start);
       const formattedCreatedFullChangedEnd = formatDate(newInputValues['createdFullChanged']?.end);

       const formattedTagStartSoldDate = formatDate(myTag?.start);
       const formattedTagEndSoldDate = formatDate(myTag?.end);
       const formattedCreatedStartSoldDate = formatDate(newInputValues['soldDateStart']);
       const formattedCreatedEndSoldDate = formatDate(newInputValues['soldDateEnd']);
       const formattedCreatedFullChangedStartSoldDate = formatDate(newInputValues['soldDateFullChanged']?.start);
       const formattedCreatedFullChangedEndSoldDate = formatDate(newInputValues['soldDateFullChanged']?.end);

       const isMyTagObjectCreated = typeof myTag === 'object' && _.has(myTag, 'created_at') && myTag !== null;
       const isMyTagObjectSoldDate = typeof myTag === 'object' && _.has(myTag, '846') &&  myTag !== null;

       if (
           (isMyTagObjectCreated && (isEqual(formattedTagStart, formattedCreatedStart) || isEqual(formattedTagEnd, formattedCreatedEnd))) ||
           (myTag === newInputValues['createdFullChanged'] ||
               (isEqual(formattedTagStart, formattedCreatedFullChangedStart) && isEqual(formattedTagEnd, formattedCreatedFullChangedEnd)))
       ){
         delete newInputValues['createdStart'];
         delete newInputValues['createdEnd'];
         delete newInputValues['createdFullChanged'];
         setStartDateCreated(null);
         setEndDateCreated(null);
       }
       if (
           (isMyTagObjectSoldDate && (isEqual(formattedTagStartSoldDate, formattedCreatedStartSoldDate) || isEqual(formattedTagEndSoldDate, formattedCreatedEndSoldDate))) ||
           (myTag === newInputValues['soldDateFullChanged'] ||
               (isEqual(formattedTagStartSoldDate, formattedCreatedFullChangedStartSoldDate) && isEqual(formattedTagEndSoldDate, formattedCreatedFullChangedEndSoldDate)))
       ){
         delete newInputValues['soldDateStart'];
         delete newInputValues['soldDateEnd'];
         delete newInputValues['soldDateFullChanged'];
         setStartDateSoldDate(null);
         setEndDateSoldDate(null);
       }

       if (
           newInputValues[key] === myTag ||
           newInputValues[key] === myTag?.toString() || (typeof newInputValues[key] !== 'object' && newInputValues[key]?.toString() === myTag?.toString())
       ) {
         delete newInputValues[key];
         // break;
       }
     }

     if (hasDeletedSearchEverything) {
       setSearchItem('');
     }

     setFilterBy(newFilterBy);
     setInputValues(newInputValues);


     const updatedTabCounts2: any = {};

     for (const key in tabCounts2) {
       if (tabCounts2.hasOwnProperty(key)) {
         updatedTabCounts2[key] = {
           ...tabCounts2[key],
           loader: hasOnlyIssuesKeys ? key === 'issues' : true,
         };
       }
     }

     setTabCounts2(updatedTabCounts2);
     const localTabs = hasOnlyIssuesKeys ? ['issues'] : tabs ;

     const requests = localTabs.map(async (tab) => {
       if (tab === 'issues' && hasPro && !is_role_allowed) return;
       const response = await sendReguestColumnSearch(tab, newFilterBy);

       if (response) {
         const total = myHelpConditionFunc(response, tab);

         setTabCounts2((prevTabCounts2: any) => ({
           ...prevTabCounts2,
           [tab]: {
             count: total,
             loader: false,
             tabName: tab,
           },
         }));
       }

       return response;
     });

     const responses = await Promise.all(requests);
   }
   catch(e){
     console.log(e, 'error')
   }
  };

  const handleClearAll =  () => {
    setHasMore(true);
    setFilterBy({});
    setSearchItem('');
    localStorage.removeItem('selectedIssue')

    setIssuesValue &&   setIssuesValue ({
      value: '',
      label: '',
      placeholder: '',
    })
    if(setIssuesChanged && issuesChanged ){ setIssuesChanged(!issuesChanged) }
    setInputValues({});
    setFilterByArr([]);
    setStartDateCreated(null);
    setEndDateCreated(null);
    setStartDateSoldDate(null);
    setEndDateSoldDate(null);

    if (lpPlatforms && setLpPlatforms) {
      const newLpPlatforms = lpPlatforms.map((platform: any) => {
        if (platform.platform) {
          platform.platform.isChecked = false;
        }
        if (platform.notOnPlatform) {
          platform.notOnPlatform.isChecked = false;
        }
        return platform;
      });
      setLpPlatforms(newLpPlatforms);
    }
    if (customMarketplaces && setCustomMarketplaces) {
      const newCustomMarketplaces = customMarketplaces.map((platform: any) => {
        if (platform.platform && platform.platform.isChecked)  {
          platform.platform.isChecked = false;
        }
        if (platform.notOnPlatform &&  platform.notOnPlatform.isChecked) {
          platform.notOnPlatform.isChecked = false;
        }
        return platform;
      });
      setCustomMarketplaces(newCustomMarketplaces);
    }

    setActivePlatforms([]);

    handleFetchForSearch('');
  };

  const generateRandomKey = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 8; // Adjust the length of the key as needed
    let key = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      key += characters.charAt(randomIndex);
    }

    return key;
  };

  const formatDate = (date:any) => {
    if (!date) return <Infinity />;

    const formattedDate = new Date(date);

    const day = formattedDate.getDate().toString().padStart(2, '0');
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = formattedDate.getFullYear();

    return `${month}/${day}/${year}`;
  };


  return filterByArr?.length > 0 ? (
   <ul className={styles.searchResult}>
     <li className={styles.resultsFor}>Results for:</li>

     {filterByArr?.map((item: any) => (
      <li key={generateRandomKey()} className={styles.searchItem}>
        {/* Render the item based on its type */}
        {typeof item !== 'object' && (
         <>
           <span>{typeof item === 'string' ? he.decode(item) : item}</span>
           <span onClick={() => handleDeleteFilterTag(item)}>
             <SVGSmallX/>
          </span>
         </>
        )}
        {typeof item === 'object' && (
            <>
              {
                _.has(item, 'created_at') ? <>
                  <span className={styles.tagResult} style={{display: 'flex', alignItems:'center', gap:'10px'}}>
                    Created {_.has(item, 'created_at.start') ? formatDate(_.get(item, 'created_at.start')) : <Infinity/>} -
                    {_.has(item, 'created_at.end') ? formatDate(_.get(item, 'created_at.end')) : <Infinity/>}
                  </span>
                  <span onClick={() => handleDeleteFilterTag(item)}>
               <SVGSmallX/>
              </span>
                </> : _.has(item, '846') ? <>
                      <span className={styles.tagResult} style={{display: 'flex', alignItems:'center', gap:'10px'}}>
                        Date Sold {_.has(item, '846.start') ? formatDate(_.get(item, '846.start')) : <Infinity/>} -
                        {_.has(item, '846.end') ? formatDate(_.get(item, '846.end')) : <Infinity/>}
                      </span>
                      <span onClick={() => handleDeleteFilterTag(item)}>
                        <SVGSmallX/>
                      </span>
                    </>
                    : _.has(item, '4') ? <>
                      <span className={styles.tagResult} style={{display: 'flex', alignItems:'center', gap:'10px'}}>
                       {_.has(item, '4.text') ? getIntendedForText(_.get(item, '4.text')) : <></>}
                      </span>
                          <span onClick={() => handleDeleteFilterTag(item)}>
                        <SVGSmallX/>
                      </span>
                        </>
                        :
                    <></>
              }
            </>

        )}
      </li>
     ))}

     <li className={styles.clearAllButton}>
       <button onClick={handleClearAll}>Clear All</button>
     </li>
   </ul>
  ) : null;
};


export default SearchResult;
