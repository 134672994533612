import React, {
	useRef,
	useState,
	useEffect,
	KeyboardEventHandler,
	useLayoutEffect,
	useMemo,
} from 'react';

import styles from '../../Table.module.scss';
import {Popover} from 'react-tiny-popover';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS styles
import moment from 'moment';
import {toast} from "react-toastify";
import _get from "lodash/get";
import _ from 'lodash';

import useBasisFuncs from '../../../../../../hooks/useBasisFuncs';
import {
	AmazonLogo,
	CrossPost2,
	DollarSignSolid,
	Drafted,
	EbayLogo,
	FaImage,
	FaRotateLeft,
	ImportSales,
	MercaryLogo,
	MintCircleCheck,
	PlatformIconShop,
	PoshmarkLogo, ResearchIcon,
	SoldTagSvg,
	SVGNewPencil,
	SVGNewSquares,
	SVGNewTrash,
	ViewTemplatesQuickLink,
	YellowTriangle,
} from '../../../../../../assets/icons';
import PlatformBlockİtem from '../PlatformBlockItem';

import DatePicker from 'react-datepicker';

import {ICatalogColumn, IConfirmModalShow, İNewTableInfo, ITableData,} from '../../../../../../interfaces';
import PlatformsModal from '../../../PlatformsModal';

import {instanceAxios} from '../../../../../../services';

import {IPlatform, platforms} from '../ColumnSearch/data/platforms';
import './TableRow.css';

import {
	depopLogo,
	etsyLogo,
	facebookLogo,
	grailedLogo,
	instagramLogo,
	kidizenLogo,
	listingPartyLogo,
	shopifyLogo,
	tradesyLogo,
	vestiaireLogo,
} from '../../../../../../assets/constants/platformsLogo';
import useAppPrefix from '../../../../../../hooks/UseAppPrefix';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {
	iframeLoaderChanger,
	setCandidantLinkPure,
	setDuplicateListingId,
	setEditAddModalId,
	setEditAddModalShow,
	setIframeLink,
	setIframeName,
	setListingTemplateId,
	setListingViewId,
	setShowEditTemplateModal,
	setShowIframe,
	setShowListingViewModal,
	setShowQuestionModal,
} from '../../../../../../redux/slices/NavigationSlice';
import {TextareaWithCharacterCount} from '../../../../../../components/UI/TextArea';
import ConfirmModal from '../ConfirmModal';

import Tagify from '../Tagify';
import {MoonLoader} from 'react-spinners';
import useRandomNumber from '../../../../../../hooks/UseRandomNumber';
import SendToSalesModal from "../../../SendToSalesModal";
import SuccessModal from '../../../SendToSalesModal/components/SuccessModal'
import {useNavigate} from "react-router-dom";
import useExtensionVersion from "../../../../../../hooks/useExtensionVersion";
import Checkbox, {CheckboxModifier} from "../../../../../../components/UI/Checkbox";
import Button, {ModifierType} from "../../../../../../components/UI/Button";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";
import useRoles from "@hooks/useRoles";
import PlatformsRow from "../../../../../../components/PlatformsRow";
import useShopifyInfo from "@hooks/useShopifyInfo";
import Listings from "@services/Listings";
import Auth from "@services/Auth";


const createOption = (label: string) => ({
	label,
	value: label,
});

interface Option {
	readonly label: string;
	readonly value: string;
}

interface NavigationState {
	iframeLink: string;
}

interface RootState {
	navigation: NavigationState;
}

interface iTableRowProps {
	row: ITableData;
	handleRowSelected: (
	 e: React.MouseEvent<HTMLTableRowElement>,
	 id: string,
	 checked?: any,
	) => void;
	handleChangeCheckbox?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	columns: ICatalogColumn[];
	isBulkEdit: boolean;
	tableData: ITableData[];
	setTableData: (tableData: ITableData[]) => void;
	index: number;
	tab: string;
	resizableElementWidth: number;
	handleBulkDelete: () => void;
	handleBulkRestore: () => void;
	customMarketplaces: any;
	shopifyDinamicLink: null | string;
	syncTime: any;
	setRetryActive: any;
	retryActive: any;
	saveActive: any;
	setSaveActive: any;
	iframeUpdate: any;
	platformsTippy?:any;
	hasBusiness:boolean,
	hasPro:boolean;
	is_role_allowed:boolean;
	hasBetaTeamRole:boolean;
	selectedListings:any;
	selectedListingsData:any;
	setSelectedListingsData:(selectedListingsData:any) => void;
	listingsForDelete?:any;
	hasExtensionInstall?:any;
	setAllTableData:any;
	setTabCounts2:any;
	stickyColumnsList:any;
	getStickyStyle?:any;
	mouseDown?:any;
	activeIndex?:any;
	tippyElementStickyColumnsRef?:any;
	shopifyName?:any;
	shopifyURLFormat?:any;
}

interface Tooltip {
	ebay?: boolean;
	etsy?: boolean;
	poshmark?: boolean;
	mercari?: boolean;
	amazon?: boolean;
	grailed?: boolean;
	facebook?: boolean;
	depop?: boolean;
	kidizen?: boolean;
	shopify?: boolean;
	instagram?: boolean;
	vestiaire?: boolean;
}


const TableRow: React.FC<iTableRowProps> = (props) => {
	const {
		row,
		handleRowSelected,
		handleChangeCheckbox,
		columns,
		isBulkEdit,
		tableData,
		setTableData,
		index,
		tab,
		resizableElementWidth,
		handleBulkDelete,
		handleBulkRestore,
		customMarketplaces,
		shopifyDinamicLink,
		syncTime,
		setRetryActive,
		retryActive,
		saveActive,
		setSaveActive,
		iframeUpdate,
		platformsTippy,
		selectedListings,
		selectedListingsData,
		setSelectedListingsData,
		listingsForDelete,
		hasBusiness,
		hasPro,
		hasBetaTeamRole,
		is_role_allowed,
		hasExtensionInstall,
		setAllTableData,
		setTabCounts2,
		stickyColumnsList,
		getStickyStyle,
		mouseDown,
		activeIndex,
		tippyElementStickyColumnsRef,
		shopifyName,
		shopifyURLFormat
	} = props;
	const {bdecode} = useBasisFuncs();
	const { hasSimple} = useRoles();
	const token: any = Auth.get_token();
	const isSmallScreenLaptop = UseIsSmallLaptop() ;
	const isExtensionSupportedForSingleView =  useExtensionVersion('0.18.5');
	const linkPrefix = useAppPrefix();
	const dispatch = useDispatch();
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [isPlatformModalShow, setIsPlatformModalShow] = useState(false);
	const [tooltipsActive, setTooltipsActive] = useState<Tooltip>({
		ebay: false,
		etsy: false,
		poshmark: false,
		mercari: false,
		amazon: false,
		grailed: false,
		facebook: false,
		depop: false,
		kidizen: false,
		shopify: false,
		instagram: false,
		vestiaire: false,
	});

	const [tooltipsPlatformActive, setTooltipsPlatformActive] = useState<Tooltip>(
	 {
		 ebay: false,
		 etsy: false,
		 poshmark: false,
		 mercari: false,
		 amazon: false,
		 grailed: false,
		 facebook: false,
		 depop: false,
		 kidizen: false,
		 shopify: false,
		 instagram: false,
		 vestiaire: false,
	 }
	);

	const showIframe = useSelector(
	 (state: any) => state.navigation.showIframe
	);

	const [rowCustomMarkets, setRowCustomMarkets] = useState<any>([]);
	const [rowCustomMarketsCached, setRowCustomMarketsCached] =
	 useState<any>(null);
	const iframeLink = useSelector((state: RootState) => state.navigation.iframeLink);
	const [updateCustomRows, setUpdateCustomRows] = useState(false);
	const [dimensions, setDimensions] = useState<string | null>(null);
	const [loaderForImage, setLoaderForImage] = useState(true);
	const [newTableInfo, setNewTableInfo] = useState<İNewTableInfo>({
		newTitle: {
			text: '',
			changed: false,
		},
		newPrice: {
			text: '',
			changed: false,
		},
		newSku: {
			text: '',
			changed: false,
		},
		newQuantity: {
			text: '',
			changed: false,
		},
		newUpdated: 1,
		newNotes: {
			text: '',
			changed: false,
		},
		newPlatform: {
			text: '',
			changed: false,
		},
		newPlatformIssue: {
			text: 'active',
			modalOpen: null,
			removed: [],
			platform: [],
			changed: false,
		},
		newKeywords: {
			text: '',
			changed: false,
		},
		newDescription: {
			text: '',
			changed: false,
		},
		newSold_price: {
			text: '',
			changed: false,
		},
		newShippingCost: {
			text: '',
			changed: false,
		},
		newDateSold_date: {
			text: '',
			changed: false,
		},
		newCogs: {
			text: '',
			changed: false,
		},
		newFees: {
			text: '',
			changed: false,
		},
		newUpc: {
			text: '',
			changed: false,
		},
		newDraft: {
			checked: false,
			changed: false,
		},
		newBrand: {
			text: '',
			changed: false,
		},
		newColor: {
			text: '',
			changed: false,
		},
		newSize: {
			text: '',
			changed: false,
		},
		newMaterial: {
			text: '',
			changed: false,
		},
		newCondition: {
			text: '',
			changed: false,
		},
		newConditionNotes: {
			text: '',
			changed: false,
		},
		newCondition2New: {
			text: '',
			changed: false,
		},
		newCondition2PreOwned: {
			text: '',
			changed: false,
		},
		newIntendedFor: {
			text: '',
			changed: false,
		}
	});
	const [cachedNewTableInfo, setCachedNewTableInfo] = useState<any>(null);
	const isCanceled = useSelector((state: any) => state.catalog.isCancel);
	const isSaved = useSelector((state: any) => state.catalog.isSave);
	const allowListingsDeletion = useSelector((state:any) => state.navigation.allowListingsDeletion);
	const [lpPlatforms, setLpPlatforms] = useState<IPlatform[]>(platforms);
	const [lpPlatformsCached, setLpPlatformsCached] = useState<any>(null);
	const [changedPlatforms, setChangedPatforms] = useState<any>({});
	const [showTooltip, setShowTooltip] = useState(true);
	const [checker, setChecker] = useState(false);
	const [activePlatforms, setActivePlatforms] = useState<string[]>([]);
	const [activePlatformsCached, setActivePlatformsCached] = useState<any>(null);
	const [customMarketplacesTableRow, setCustomMarketplacesTableRow] =
	 useState<any>([]);
	const [customMarketsCached, setCustomMarketsCached] = useState(null);
	const [isVisibleSendToSalesModal, setIsVisibleSendToSalesModal] = useState(false)
	const [isSuccess, setIsSuccess] = useState<boolean>(false)
	const navigate = useNavigate();
	const handleToggleShowSalesModal = () => {
		setIsVisibleSendToSalesModal(!isVisibleSendToSalesModal)
		removeKeysFromModalListings()
	}
	const showEditPageModal = useSelector((state:any) => state.navigation.editAddModalShow);

	function removeKeysFromModalListings() {
		const deletedKeys = [
			'newDateSold',
			'newShippingStatus',
			'newSku',
			'newSoldOn',
			'newShop',
			'newSoldPrice',
			'newSalesTax',
			'newFees',
			'newShippingCostBuyer',
			'newShippingCostSeller',
			'newCogs',
			'newQuantitySold',
			'newQuantityAvailable',
			'newOrderId'
		];

		const updatedTabledata = tableData.map((item: any) => {
			const updatedItem = {...item};

			deletedKeys.forEach((key) => {
				delete updatedItem[key];
			});

			return updatedItem;
		});

		setTableData(updatedTabledata);
	}


	const handleChangeSuccess = () => {
		setIsSuccess(!isSuccess)
		if(isSuccess) {
			const updatedTableData = tableData.map((tbData:any) => {
				if(tbData?.newListingId) {
					delete tbData.newListingId
				}
				return {...tbData, isChecked:false, isSendToSales:false}
			})
			const updatedSelectedListingsData = selectedListingsData.map((item: any) => {
				const updatedItem = {...item};
				const deletedKeys = ['newListingId', 'removedFromSendToSales'];

				deletedKeys.forEach(key => {
					delete updatedItem[key];
				});

				return updatedItem;
			});
			setTableData(updatedTableData)
			setSelectedListingsData(updatedSelectedListingsData);
		}
	}

	const handleShowTooltip = () => {
		setIsPopoverOpen(!isPopoverOpen);
	};

	const randomNumber = useRandomNumber();

	const hoverPopover = () => {
		const crossPost = document.querySelector(
		 '.lp_custom_crosspost_icon_disabled'
		);
		if (crossPost) {
			setShowTooltip(true);
		} else {
			setShowTooltip(false);
		}
	};

	// const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
	// 	const charCode = event.charCode;
	// 	const inputValue = event.currentTarget.value;
	//
	// 	// Check if the pressed key is a dot
	// 	if (charCode === 46) {
	// 		// Check if there is already a dot in the input value
	// 		if (inputValue.includes('.')) {
	// 			event.preventDefault();
	// 		}
	// 	} else if ((charCode < 48 || charCode > 57)) {
	// 		// Allow only digits
	// 		event.preventDefault();
	// 	}
	// };

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const charCode = event.charCode;
		const inputValue = event.currentTarget.value;
		const inputName = event.currentTarget.name; // Access the input's name

		if (inputName === "newPrice") {
			const maxLimit = 1_000_000_000; // 1 billion

			// Check if the new value will exceed the max limit
			const newValue = inputValue + String.fromCharCode(charCode);

			if (Number(newValue) >= maxLimit) {
				event.preventDefault();
				return;
			}
		}

		// Check if the pressed key is a dot
		if (charCode === 46) {
			// Check if there is already a dot in the input value
			if (inputValue.includes('.')) {
				event.preventDefault();
			}
		} else if (charCode < 48 || charCode > 57) {
			// Allow only digits
			event.preventDefault();
		}
	};

	const handleChangeEditInput = (
	 e:
	  | React.ChangeEvent<HTMLInputElement>
	  | React.ChangeEvent<HTMLTextAreaElement>
	  | React.ChangeEvent<HTMLSelectElement>
	) => {
		const {name, value} = e.target;
		if(name === 'newShippingWeightlbkg' && value.length > 3) return
		if(name === 'newShippingWeightozg' && value.length > 2) return
		if(name === 'newZip' && value.length > 20) return
		const updatedItems = [...tableData];
		if (retryActive) {
			setRetryActive(false)
		}
		if (!saveActive) {
			setSaveActive(true)
		}
		if (e.target.nodeName === 'SELECT') {
			if(name === 'condition'){
				updatedItems[index] = {
					...updatedItems[index],
					['newCondition']: value === 'None' ? null : value,
					['newCondition2New']:  null,
					['newCondition2PreOwned']: null,
					changed: true,
				};
				setNewTableInfo({
					...newTableInfo,
					['newCondition']: {text: value, changed: true},
					['newCondition2New']: {text: '', changed: true},
					['newCondition2PreOwned']: {text: '', changed: true},
				});
			}
			else if(name === 'condition2'){
				if (["None New","With Tags", "With Box", "Without Tags", "Without Box", "With Defects"].includes(value)) {
					updatedItems[index] = {
						...updatedItems[index],
						['newCondition2New']: value === "None New" ? null : value,
						['newCondition2PreOwned']: null,
						changed: true,
					};
					setNewTableInfo({
						...newTableInfo,
						['newCondition2New']: {text: value, changed: true},
						['newCondition2PreOwned']: {text: '', changed: true},
					});
				} else if (["None Pre-Owned","Like New", "Good", "Fair", "Poor"].includes(value)) {
					updatedItems[index] = {
						...updatedItems[index],
						['newCondition2PreOwned']: value === 'None Pre-Owned' ? null : value,
						['newCondition2New']: null,
						changed: true,
					};
					setNewTableInfo({
						...newTableInfo,
						['newCondition2PreOwned']: {text: value, changed: true},
						['newCondition2New']: {text: '', changed: true},
					});
				}
			}
			else if (name === 'intended for'){
				updatedItems[index] = {
					...updatedItems[index],
					['newIntendedFor']: value === 'None' ? null : value,
					changed: true,
				};
				setNewTableInfo({
					...newTableInfo,
					['newIntendedFor']: {text: value, changed: true},
				});
			}
		} else if (
		 e.target instanceof HTMLInputElement &&
		 (e.target.type === 'checkbox' || e.target.type === 'radio')
		) {
			const checked = e.target.checked;

			updatedItems[index] = {
				...updatedItems[index],
				[name]: checked ? 'Draft' : null,
				changed: true,
			};
			setNewTableInfo({
				...newTableInfo,
				[name]: {checked: checked, changed: true},
			});
		} else {
			updatedItems[index] = {
				...updatedItems[index],
				[name]: value,
				changed: true,
			};
			setNewTableInfo({
				...newTableInfo,
				[name]: {text: value, changed: true},
			});
		}
		setTableData(updatedItems);
	};

	const handleKeyDown: KeyboardEventHandler = (event) => {
		if (!inputValue) return;
		switch (event.key) {
			case 'Enter':
			case 'Tab':
				const updatedItems = [...tableData];

				updatedItems[index] = {
					...updatedItems[index],
					['newKeywords']: [...value, createOption(inputValue)],

					changed: true,
				};
				setTableData(updatedItems);
				setValue((prev) => [...prev, createOption(inputValue)]);
				setInputValue('');
				event.preventDefault();
		}
	};

	const showModalChanger = async (
		name: string,
		value: null | true | string,
		platformName: string,
		keyNumber?: number
	) => {
		const updatedItems = [...tableData];
		updatedItems[index] = {
			...updatedItems[index],
			[name]: value,
			changed: true,
		};

		if (value === 'cancel') {
			const wordsArray = [...newTableInfo?.newPlatformIssue?.platform];
			const wordToRemove = platformName;
			const removeWordFromArray = (
				array: string[],
				wordToRemove: string
			): string[] => {
				return array.filter((item) => item !== wordToRemove);
			};

			let newArray: string[] = removeWordFromArray(wordsArray, wordToRemove);
			setNewTableInfo({
				...newTableInfo,
				['newPlatformIssue']: {
					text: platformName,
					platform: newArray,
					modalOpen: false,
					removed: newTableInfo?.newPlatformIssue?.removed.filter(
						(item:any) => item !== platformName
					),
					changed: true,
				},
			});
		} else if (value) {
			setNewTableInfo({
				...newTableInfo,
				['newPlatformIssue']: {
					text: platformName,
					platform: [...newTableInfo?.newPlatformIssue?.platform, platformName],
					modalOpen: true,
					removed: [...newTableInfo?.newPlatformIssue?.removed],
					changed: true,
				},
			});
		} else {
			const filteredObject: { [key: string]: unknown } = Object.entries(
				row?.issues || {}
			)
				.filter(([key]) => parseFloat(key) !== keyNumber)
				.reduce((acc, [key, value]) => {
					acc[key] = value;
					return acc;
				}, {} as { [key: string]: unknown });
			const response = await instanceAxios.put('/listings/issues/update', {
				filter_by: {id: row?.id},
				payload: {
					metadata: filteredObject,
				},
			});
			if (response) {
				setNewTableInfo({
					...newTableInfo,
					['newPlatformIssue']: {
						text: platformName,
						platform: [
							...newTableInfo?.newPlatformIssue?.platform,
							platformName,
						],
						removed: [...newTableInfo?.newPlatformIssue?.removed, platformName],
						modalOpen: false,
						changed: true,
					},
				});
			}

			const newObject = {
				...row,
				issues: filteredObject,
			};

			let updatedTableData = tableData.map((obj:any) => {
				if (obj.id == row.id) {
					return {
						...obj,
						...newObject,
					};
				}
				return obj;
			});
			const updateListingPropertiesInAllTabs = (getIdOfThatListing: any, newObject: any, shouldDelete: boolean = false) => {
				setAllTableData((prevData: any) => {
					// Create a copy of the previous state
					const newData = { ...prevData };

					// Iterate over all tabs and update or delete the listing
					Object.keys(newData).forEach((tabName) => {
						newData[tabName].data = shouldDelete
							? newData[tabName].data.filter((listing: any) => listing.id !== getIdOfThatListing)
							: newData[tabName].data.map((listing: any) =>
								listing.id === getIdOfThatListing ? { ...listing, ...newObject } : listing
							);
					});

					return newData;
				});
			};
			if(!(filteredObject && Object.keys(filteredObject).length > 0)) {
				setTabCounts2((prevCounts:any) => ({
					...prevCounts,
					issues: {
						...prevCounts.issues,
						count: Math.max(prevCounts.issues.count - 1, 0), // Ensure the count doesn't go below 0
					},
				}));
				updateListingPropertiesInAllTabs(row.id, newObject, true);
				updatedTableData = tableData.filter((obj: any) => obj.id !== row.id);
			}
			else{
				updateListingPropertiesInAllTabs(row.id, newObject)
			}

			// Update the state with the modified tableData
			setTableData(updatedTableData);
		}
	};


	const rowRef = useRef<HTMLTableRowElement>(null);

  const handleRedirectListedItems = (e: any, id: string) => {
    e.preventDefault();
    if (!iframeLink.length) {
      dispatch(setIframeName('edit'));
      dispatch(setShowIframe());
      dispatch(setIframeName('edit listing'));
      dispatch(iframeLoaderChanger(true));
      dispatch(
       setIframeLink(
        `listperfectly.com/listings/add/?mode=edit&action=edit&edit_id=${id}&is-listing-assistant=true`
       )
      );
    } else {
      dispatch(
       setShowQuestionModal(
        `https://app.listperfectly.com/listings/add/?mode=edit&action=edit&edit_id=${id}&is-listing-assistant=true`
       )
      );
      dispatch(
       setCandidantLinkPure(
        `listperfectly.com/listings/add/?mode=edit&action=edit&edit_id=${id}&is-listing-assistant=true`
       )
      );
    }
    // save for edit listing modal
    //e.preventDefault();
    // dispatch(setEditPageName('edit'));
    // dispatch(setIsCancel(false));
    // dispatch(setEditModalId(id));
    // dispatch(setEditTableData([...tableData]));
    // dispatch(setEditIndex(index));
    // dispatch(setEditModalActive(true));
  };

	const handleTogglePlatformsModalShow = () => {
		setIsPlatformModalShow(!isPlatformModalShow);

		if (isPlatformModalShow) {
			const checkedTitles: string[] = [];

			lpPlatforms?.forEach((item) => {
				if (item.platform.isChecked) {
					checkedTitles.push(item.platform.title);
				}
			});

			customMarketplacesTableRow?.forEach((item: any) => {
				if (item.platform.isChecked) {
					checkedTitles.push(item.platform.title);
				}
			});

			setActivePlatforms(checkedTitles);
		}
	};

	const [confirmModalShow, setConfirmModalShow] = useState<IConfirmModalShow>({
		status: false,
		role: '',
		listingCount: 0,
	});

	const handleToggleConfirmModalShow = () => [
		setConfirmModalShow({
			...confirmModalShow,
			status: !confirmModalShow.status,
		}),
	];

	const handleFuncDeleteOrRestore = (role: string) => {
		if (role === 'delete') {
			handleBulkDelete();
		} else if (role === 'restore') {
			handleBulkRestore();
		}
		handleToggleConfirmModalShow();
	};

	const handleChangeStatusToListing = async () => {
		const previousTableData = [...tableData];


		function checkIfExists(listingsForDelete:any, idToCheck:any) {
			return listingsForDelete.some((id:any) => id === idToCheck);
		}

		const exists = checkIfExists(listingsForDelete, row?.id);

		const remainingSelectedListings = selectedListings.filter((id:any) => !listingsForDelete.some((item:any) => item === id));

		const foundItem:any = tableData.find(item => item.id === row?.id);
		const isChecked = foundItem?.isChecked;


		if (selectedListings?.length > 0 && !exists && isChecked) {
			setConfirmModalShow({
				...confirmModalShow,
				status: true,
				role: 'delete',
				listingCount: remainingSelectedListings?.length,
			});
		} else if (selectedListings?.length > 0 && exists && isChecked) {
			setConfirmModalShow({
				...confirmModalShow,
				status: true,
				role: 'restore',
				listingCount: listingsForDelete?.length,
			});
		} else {
			try {
				const newStatus = row.status === 'active' ? 'trash' : 'active';
				const tempTableData = tableData.map((item) => {
					if (item.id === row.id) {
						return {...item, status: newStatus};
					} else {
						return item;
					}
				});
				setTableData(tempTableData);
		        await Listings.update({id: row.id}, {status: newStatus}, token);

			} catch (error) {

				setTableData(previousTableData)
				toast.error(`Oops! We encountered an error while attempting to ${row.status === 'active' ? 'delete' : 'restore'} your listing. Please try again later.`, {
					position: 'bottom-right',
				});
				console.error(error);
			}
		}
	};

	const handleTooltipClick = (tooltipName: keyof typeof tooltipsActive | null) => {
		setTooltipsActive((prevState) => ({
			ebay: tooltipName === 'ebay' ? !prevState.ebay : false,
			etsy: tooltipName === 'etsy' ? !prevState.etsy : false,
			poshmark: tooltipName === 'poshmark' ? !prevState.poshmark : false,
			mercari: tooltipName === 'mercari' ? !prevState.mercari : false,
			amazon: tooltipName === 'amazon' ? !prevState.amazon : false,
			grailed: tooltipName === 'grailed' ? !prevState.grailed : false,
			facebook: tooltipName === 'facebook' ? !prevState.facebook : false,
			instagram: tooltipName === 'instagram' ? !prevState.instagram : false,
			depop: tooltipName === 'depop' ? !prevState.depop : false,
			kidizen: tooltipName === 'kidizen' ? !prevState.kidizen : false,
			shopify: tooltipName === 'shopify' ? !prevState.shopify : false,
			vestiaire: tooltipName === 'vestiaire' ? !prevState.vestiaire : false,
		}));
	};

	const handleTooltipClickPlatforms = (tooltipName: any) => {
		setTooltipsPlatformActive((prevState) => ({
			ebay: tooltipName === 'eBay' ? !prevState.ebay : false,
			etsy: tooltipName === 'Etsy' ? !prevState.etsy : false,
			poshmark: tooltipName === 'Poshmark' ? !prevState.poshmark : false,
			mercari: tooltipName === 'Mercari' ? !prevState.mercari : false,
			amazon: tooltipName === 'Amazon' ? !prevState.amazon : false,
			grailed: tooltipName === 'Grailed' ? !prevState.grailed : false,
			facebook: tooltipName === 'Facebook' ? !prevState.facebook : false,
			instagram: tooltipName === 'Instagram' ? !prevState.instagram : false,
			depop: tooltipName === 'Depop' ? !prevState.depop : false,
			kidizen: tooltipName === 'Kidizen' ? !prevState.kidizen : false,
			shopify: tooltipName === 'Shopify' ? !prevState.shopify : false,
			vestiaire: tooltipName === 'Vestiaire' ? !prevState.vestiaire : false,
		}));
	};

	const handleClick = (platformName: string) => {
		let activePlatformName: string | null = null;

		let isActive = false;
		let isNotOnPlatform = false;

		const updatedPlatforms = lpPlatforms?.map((item:any) => {
			if (item.platform.code[0] === platformName) {
				isActive = !item.platform.isChecked;
				activePlatformName = item.platform.title;

				return {
					...item,
					platform: {
						...item.platform,
						isChecked: isActive,
					},
					notOnPlatform: {
						...item.notOnPlatform,
						isChecked: false,
					},
				};
			} else if (item.notOnPlatform.code[0] === platformName) {
				isNotOnPlatform = !item.notOnPlatform.isChecked;
				activePlatformName = item.platform.title;
				return {
					...item,
					platform: {
						...item.platform,
						isChecked: false,
					},
					notOnPlatform: {
						...item.notOnPlatform,
						isChecked: isNotOnPlatform,
					},
				};
			}
			return item;
		});

		setLpPlatforms(updatedPlatforms);

		const updatedCustomMarketplaces = customMarketplacesTableRow?.map(
		 (item: any) => {
			 if (item.platform.code[0] === platformName) {
				 isActive = !item.platform.isChecked;
				 activePlatformName = `Custom${item.platform.title}`;

				 return {
					 ...item,
					 platform: {
						 ...item.platform,
						 isChecked: isActive,
					 },
					 notOnPlatform: {
						 ...item.notOnPlatform,
						 isChecked: false,
					 },
				 };
			 } else if (item.notOnPlatform.code[0] === platformName) {
				 isNotOnPlatform = !item.notOnPlatform.isChecked;
				 activePlatformName = `Custom${item.platform.title}`;
				 return {
					 ...item,
					 platform: {
						 ...item.platform,
						 isChecked: false,
					 },
					 notOnPlatform: {
						 ...item.notOnPlatform,
						 isChecked: isNotOnPlatform,
					 },
				 };
			 }
			 return item;
		 }
		);

		setCustomMarketplacesTableRow(updatedCustomMarketplaces);

		if (activePlatformName) {
			if (activePlatformName === 'Listing Party') {
				activePlatformName = 'Listing_party';
			}

			const updatedTableData = tableData.map((item: ITableData) => {
				if (item.id === row.id) {
					const key = `new${activePlatformName}`;
					return {
						...item,
						changed: true,

						[key]: isNotOnPlatform
						 ? null
						 : isActive
						  ? activePlatformName === 'Listing_party'
						   ? 'Listing Party'
						   : activePlatformName
						  : null,
					};
				}
				return item;
			});

			setTableData(updatedTableData);
		}
	};

	useEffect(() => {
		setCustomMarketplacesTableRow(customMarketplaces)
	},[customMarketplaces])

	useEffect(() => {
		const allChecked = tableData.some((item) => item.isChecked === true);
		if (allChecked) {
			setChecker(true);
		} else {
			setChecker(false);
		}
	}, [isBulkEdit]);

	useEffect(() => {
		if ((newTableInfo && !cachedNewTableInfo) || isSaved || iframeUpdate) {
			setCachedNewTableInfo(newTableInfo);
		}
	}, [newTableInfo, cachedNewTableInfo, isSaved]);

	useEffect(() => {
		if (isCanceled && cachedNewTableInfo) {
			setNewTableInfo(cachedNewTableInfo);
			setCachedNewTableInfo(null);
		}
	}, [isCanceled]);

	useEffect(() => {
		if ((lpPlatforms && !lpPlatformsCached) || isSaved || iframeUpdate) {
			setLpPlatformsCached(lpPlatforms);
		}
	}, [lpPlatforms, lpPlatformsCached, isSaved]);

	useEffect(() => {
		if ((activePlatforms?.length > 0 && !activePlatformsCached) || isSaved || iframeUpdate) {
			setActivePlatformsCached(activePlatforms);
		}
	}, [activePlatforms, activePlatformsCached, isSaved]);

	useEffect(() => {
		if ((customMarketplacesTableRow && !customMarketsCached) || isSaved || iframeUpdate) {
			setCustomMarketsCached(customMarketplacesTableRow);
		}
	}, [customMarketplacesTableRow, customMarketsCached, isSaved]);

	useEffect(() => {
		if (
		 (rowCustomMarkets?.length > 0 && !rowCustomMarketsCached) ||
		 isSaved ||
		 !isCanceled || iframeUpdate
		) {
			setRowCustomMarketsCached(rowCustomMarkets);
		}
	}, [rowCustomMarkets, rowCustomMarketsCached, isSaved, isCanceled]);

	useEffect(() => {
		const handleScroll = () => {
			if (handleTooltipClick) {
				handleTooltipClick(null);
			}
		};

		const scrollTable:any = document.getElementById('catalogPageTable')

		scrollTable?.addEventListener('scroll', handleScroll);

		return () => {
			scrollTable?.removeEventListener('scroll', handleScroll);
		};
	}, [handleTooltipClick]);



	const unescapedRowMetadata = useMemo(() => {
		const result: any = {};
		for (const key in row?.metadata) {
			if (row.metadata.hasOwnProperty(key)) {
				result[key] = _.get(row.metadata, key);
			}
		}
		return result;
	}, [row]);

	const unescapedRowMetadataIssues = useMemo(() => {
		const result: any = {};
		for (const key in row?.issues) {
			if (row?.issues?.hasOwnProperty(key)) {
				result[key] = _.get(row.issues, key);
			}
		}
		return result;
	}, [row?.issues]);

	const spanKeywords = row!.newKeywords || unescapedRowMetadata[453]?.split(', ');
	const spanKeywordsWithLabels = spanKeywords?.map((item: any) =>
	 createOption(item)
	);
	// keyword text area state
	const [inputValue, setInputValue] = useState('');
	const [value, setValue] = useState<readonly Option[]>(
	 spanKeywordsWithLabels || []
	);
	//

	const initialDate = moment(unescapedRowMetadata[846]).toDate(); // Convert moment to Date

	const [selectedDate, setSelectedDate] = useState(initialDate);

	const handleChange = (date: any, name: string) => {
		if (retryActive) {
			setRetryActive(false)
		}
		if (!saveActive) {
			setSaveActive(true)
		}
		const updatedItems = [...tableData];
		const formattedDate = moment(date).format('YYYY-MM-DD');

		setSelectedDate(date);
		updatedItems[index] = {
			...updatedItems[index],
			['newDateSold_date']: formattedDate,
			changed: true,
		};
		setNewTableInfo({
			...newTableInfo,
			['newDateSold_date']: {text: formattedDate, changed: true},
		});
		setTableData(updatedItems);
	};

	useEffect(() => {
		const rowMetaData = row.metadata;

		const getSold = _get(row, 'newSOLD')
		const getEbay = _get(row, 'neweBay')
		const getEtsy = _get(row, 'newEtsy')
		const getPoshmark = _get(row, 'newPoshmark')
		const getMercari = _get(row, 'newMercari')
		const getAmazon = _get(row, 'newAmazon')
		const getGrailed = _get(row, 'newGrailed')
		const getFacebook = _get(row, 'newFacebook')
		const getDepop = _get(row, 'newDepop')
		const getKitidzen = _get(row, 'newKidizen')
		const getShopify = _get(row, 'newShopify')
		const getListingParty = _get(row, 'newListing_party')
		const getInstagram = _get(row, 'newInstagram')
		const getVestiaire = _get(row, 'newVestiaire')

		const getEbayId = _get(row, 'newPlatformIdByeBay')
		const getEtsyId = _get(row, 'newPlatformIdByEtsy')
		const getPoshmarkId = _get(row, 'newPlatformIdByPoshmark')
		const getMercariId = _get(row, 'newPlatformIdByMercari')
		const getAmazonId = _get(row, 'newPlatformIdByAmazon')
		const getGrailedId = _get(row, 'newPlatformIdByGrailed')
		const getFacebookId = _get(row, 'newPlatformIdByFacebook')
		const getDepopId = _get(row, 'newPlatformIdByDepop')
		const getKitidzenId = _get(row, 'newPlatformIdByKidizen')
		const getShopifyId = _get(row, 'newPlatformIdByShopify')
		const getInstagramId = _get(row, 'newPlatformIdByInstagram')
		const getVestiaireId = _get(row, 'newPlatformIdByVestiaire')

		const getCustomMarket1 = _get(row, `newCustom${customMarketplacesTableRow[0]?.platform?.title}`)
		const getCustomMarket2 = _get(row, `newCustom${customMarketplacesTableRow[1]?.platform?.title}`)
		const getCustomMarket3 = _get(row, `newCustom${customMarketplacesTableRow[2]?.platform?.title}`)
		const getCustomMarket4 = _get(row, `newCustom${customMarketplacesTableRow[3]?.platform?.title}`)
		const getCustomMarket5 = _get(row, `newCustom${customMarketplacesTableRow[4]?.platform?.title}`)
		const getCustomMarket6 = _get(row, `newCustom${customMarketplacesTableRow[5]?.platform?.title}`)
		const getCustomMarket7 = _get(row, `newCustom${customMarketplacesTableRow[6]?.platform?.title}`)
		const getCustomMarket8 = _get(row, `newCustom${customMarketplacesTableRow[7]?.platform?.title}`)
		const getCustomMarket9 = _get(row, `newCustom${customMarketplacesTableRow[8]?.platform?.title}`)
		const getCustomMarket10 = _get(row, `newCustom${customMarketplacesTableRow[9]?.platform?.title}`)

		const getCustomMarketId1 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[0]?.platform?.title}`)
		const getCustomMarketId2 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[1]?.platform?.title}`)
		const getCustomMarketId3 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[2]?.platform?.title}`)
		const getCustomMarketId4 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[3]?.platform?.title}`)
		const getCustomMarketId5 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[4]?.platform?.title}`)
		const getCustomMarketId6 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[5]?.platform?.title}`)
		const getCustomMarketId7 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[6]?.platform?.title}`)
		const getCustomMarketId8 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[7]?.platform?.title}`)
		const getCustomMarketId9 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[8]?.platform?.title}`)
		const getCustomMarketId10 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[9]?.platform?.title}`)

		const objectPlatforms: any = {
			...(getSold !== undefined ? {'SOLD': getSold ? true : false} : {}),
			...(getEbay !== undefined ? {'eBay': getEbay ? true : false} : {}),
			...(getEtsy !== undefined ? {'Etsy': getEtsy ? true : false} : {}),
			...(getPoshmark !== undefined ? {'Poshmark': getPoshmark ? true : false} : {}),
			...(getMercari !== undefined ? {'Mercari': getMercari ? true : false} : {}),
			...(getAmazon !== undefined ? {'Amazon': getAmazon ? true : false} : {}),
			...(getGrailed !== undefined ? {'Grailed': getGrailed ? true : false} : {}),
			...(getFacebook !== undefined ? {'Facebook': getFacebook ? true : false} : {}),
			...(getDepop !== undefined ? {'Depop': getDepop ? true : false} : {}),
			...(getKitidzen !== undefined ? {'Kidizen': getKitidzen ? true : false} : {}),
			...(getShopify !== undefined ? {'Shopify': getShopify ? true : false} : {}),
			...(getInstagram !== undefined ? {'Instagram': getInstagram ? true : false} : {}),
			...(getListingParty !== undefined ? {'Listing Party': getListingParty ? true : false} : {}),
			...(getVestiaire !== undefined ? {'Vestiaire': getVestiaire ? true : false} : {}),
		};

		const objectCustomPlatforms: any = {
			...(getCustomMarket1 !== undefined ? {'892': getCustomMarket1 ? true : false} : {}),
			...(getCustomMarket2 !== undefined ? {'893': getCustomMarket2 ? true : false} : {}),
			...(getCustomMarket3 !== undefined ? {'894': getCustomMarket3 ? true : false} : {}),
			...(getCustomMarket4 !== undefined ? {'895': getCustomMarket4 ? true : false} : {}),
			...(getCustomMarket5 !== undefined ? {'896': getCustomMarket5 ? true : false} : {}),
			...(getCustomMarket6 !== undefined ? {'897': getCustomMarket6 ? true : false} : {}),
			...(getCustomMarket7 !== undefined ? {'898': getCustomMarket7 ? true : false} : {}),
			...(getCustomMarket8 !== undefined ? {'899': getCustomMarket8 ? true : false} : {}),
			...(getCustomMarket9 !== undefined ? {'900': getCustomMarket9 ? true : false} : {}),
			...(getCustomMarket10 !== undefined ? {'901': getCustomMarket10 ? true : false} : {}),
		};

		const objectPlatformsIds: any = {
			...(getEbayId !== undefined ? {'eBay': getEbayId ? getEbayId : ''} : {}),
			...(getEtsyId !== undefined ? {'Etsy': getEtsyId ? getEtsyId : ''} : {}),
			...(getPoshmarkId !== undefined ? {'Poshmark': getPoshmarkId ? getPoshmarkId : ''} : {}),
			...(getMercariId !== undefined ? {'Mercari': getMercariId ? getMercariId : ''} : {}),
			...(getAmazonId !== undefined ? {'Amazon': getAmazonId ? getAmazonId : ''} : {}),
			...(getFacebookId !== undefined ? {'Facebook': getFacebookId ? getFacebookId : ''} : {}),
			...(getGrailedId !== undefined ? {'Grailed': getGrailedId ? getGrailedId : ''} : {}),
			...(getDepopId !== undefined ? {'Depop': getDepopId ? getDepopId : ''} : {}),
			...(getKitidzenId !== undefined ? {'Kidizen': getKitidzenId ? getKitidzenId : ''} : {}),
			...(getShopifyId !== undefined ? {'Shopify': getShopifyId ? getShopifyId : ''} : {}),
			...(getInstagramId !== undefined ? {'Instagram': getInstagramId ? getInstagramId : ''} : {}),
			...(getVestiaireId !== undefined ? {'Vestiaire': getVestiaireId ? getVestiaireId : ''} : {}),
		};

		const objectPlatformsCustomIds: any = {
			...(getCustomMarketId1 !== undefined ? {[`${customMarketplacesTableRow[0]?.platform?.title}`]: getCustomMarketId1} : {}),
			...(getCustomMarketId2 !== undefined ? {[`${customMarketplacesTableRow[1]?.platform?.title}`]: getCustomMarketId2} : {}),
			...(getCustomMarketId3 !== undefined ? {[`${customMarketplacesTableRow[2]?.platform?.title}`]: getCustomMarketId3} : {}),
			...(getCustomMarketId4 !== undefined ? {[`${customMarketplacesTableRow[3]?.platform?.title}`]: getCustomMarketId4} : {}),
			...(getCustomMarketId5 !== undefined ? {[`${customMarketplacesTableRow[4]?.platform?.title}`]: getCustomMarketId5} : {}),
			...(getCustomMarketId6 !== undefined ? {[`${customMarketplacesTableRow[5]?.platform?.title}`]: getCustomMarketId6} : {}),
			...(getCustomMarketId7 !== undefined ? {[`${customMarketplacesTableRow[6]?.platform?.title}`]: getCustomMarketId7} : {}),
			...(getCustomMarketId8 !== undefined ? {[`${customMarketplacesTableRow[7]?.platform?.title}`]: getCustomMarketId8} : {}),
			...(getCustomMarketId9 !== undefined ? {[`${customMarketplacesTableRow[8]?.platform?.title}`]: getCustomMarketId9} : {}),
			...(getCustomMarketId10 !== undefined ? {[`${customMarketplacesTableRow[9]?.platform?.title}`]: getCustomMarketId10} : {})
		};

		if (rowMetaData !== null && typeof rowMetaData === 'object') {
			const platformsCode = Object.keys(row.metadata);
			const tempPlatforms = (lpPlatforms || []).map((item) => {
				if (
				 item.platform.code &&
				 item.platform.code.every((code) => {
					 const valueToCheck = row.metadata[code];
					 return (
					  (platformsCode.includes(code) ||
					   platformsCode.includes(code.toLowerCase())) &&
					  valueToCheck !== null
					 );
				 })
				) {
					return {
						...item,
						platform: {
							...item.platform,
							isChecked: true,
						},
					};
				} else {
					return item;
				}
			});

			const updatedPlatforms = tempPlatforms?.map(platformItem => {
				const platformTitle = platformItem.platform.title;

				if (objectPlatforms?.hasOwnProperty(platformTitle)) {
					// @ts-ignore
					return {
						...platformItem,
						platform: {
							...platformItem.platform,
							isChecked: objectPlatforms[platformTitle],
						},
					};
				}
				return platformItem;
			});

			const updatesPlatformId = updatedPlatforms?.map(platformItem => {
				const platformTitle = platformItem.platform.title;

				if (objectPlatformsIds?.hasOwnProperty(platformTitle)) {
					// @ts-ignore
					return {
						...platformItem,
						platform: {
							...platformItem.platform,
							platformIdFromIframe: objectPlatformsIds[platformTitle],
						},
					};
				}
				return platformItem;
			});

			const tempCustomMarketplaces = (customMarketplaces || []).map((item: any) => {
				if (
				 item.platform.code &&
				 item.platform.code.every((code: any) => {
					 const valueToCheck = row.metadata[code];
					 return (
					  (platformsCode.includes(code) ||
					   platformsCode.includes(code.toLowerCase())) &&
					  valueToCheck !== null
					 );
				 })
				) {
					return {
						...item,
						platform: {
							...item.platform,
							isChecked: true,
						},
					};
				} else {
					return item;
				}
			});

			const updatedCustomPlatforms = tempCustomMarketplaces?.map((platformItem: any) => {
				const platformTitle = platformItem.platform.platformId;

				if (objectCustomPlatforms?.hasOwnProperty(platformTitle)) {
					// @ts-ignore
					return {
						...platformItem,
						platform: {
							...platformItem.platform,
							isChecked: objectCustomPlatforms[platformTitle],
						},
					};
				}
				return platformItem;
			});

			const updatedCustomPlatformsIds = updatedCustomPlatforms?.map((platformItem: any) => {
				const platformTitle = platformItem.platform.title;
				if (objectPlatformsCustomIds?.hasOwnProperty(platformTitle)) {
					// @ts-ignore
					return {
						...platformItem,
						platform: {
							...platformItem.platform,
							platformIdFromIframe: objectPlatformsCustomIds[platformTitle],
						},
					};
				}
				return platformItem;
			});

			const tempPlatformsActive = updatedPlatforms.filter((item) => item.platform.isChecked).map((item) => item.platform.title);
			const tempCustomMarketplacesActive = updatedCustomPlatformsIds.filter((item: any) => item.platform.isChecked && item.platform.title);
			const customMarketplacePlatforms = tempCustomMarketplacesActive.map((item: any) => item.platform.title);

			const allPlatforms = [...tempPlatformsActive, ...customMarketplacePlatforms];
			setActivePlatforms(allPlatforms);
			setLpPlatforms(updatesPlatformId);

			if (tempCustomMarketplaces.length > 0) {
				setCustomMarketplacesTableRow(updatedCustomPlatformsIds);
				setRowCustomMarkets(tempCustomMarketplacesActive.map((item: any) => ({
					title: item.platform.title,
					code: item.platform?.code[0],
					color: item?.color,
					platformId: item.platform.platformId,
				})));
			}

      if (isCanceled) {
        if (lpPlatformsCached) {
          setLpPlatforms(lpPlatformsCached);
          setLpPlatformsCached(null);
        }
        if (activePlatformsCached) {
          setActivePlatforms(activePlatformsCached);
          setActivePlatformsCached(null);
        }
        if (customMarketsCached) {
          setCustomMarketplacesTableRow(customMarketsCached);
          setCustomMarketsCached(null);
        }
        if (rowCustomMarketsCached) {
          setRowCustomMarkets(rowCustomMarketsCached);
          setRowCustomMarketsCached(null);
        }
      }
    }
  }, [isBulkEdit, showIframe, showEditPageModal]);
    // when edit page will be needed add here showEditPageModal dep

	useEffect(() => {

		if(customMarketplaces.length && !updateCustomRows) {
			setUpdateCustomRows(true);
			const rowMetaData = row.metadata;

			const getCustomMarket1 = _get(row, `newCustom${customMarketplacesTableRow[0]?.platform?.title}`)
			const getCustomMarket2 = _get(row, `newCustom${customMarketplacesTableRow[1]?.platform?.title}`)
			const getCustomMarket3 = _get(row, `newCustom${customMarketplacesTableRow[2]?.platform?.title}`)
			const getCustomMarket4 = _get(row, `newCustom${customMarketplacesTableRow[3]?.platform?.title}`)
			const getCustomMarket5 = _get(row, `newCustom${customMarketplacesTableRow[4]?.platform?.title}`)
			const getCustomMarket6 = _get(row, `newCustom${customMarketplacesTableRow[5]?.platform?.title}`)
			const getCustomMarket7 = _get(row, `newCustom${customMarketplacesTableRow[6]?.platform?.title}`)
			const getCustomMarket8 = _get(row, `newCustom${customMarketplacesTableRow[7]?.platform?.title}`)
			const getCustomMarket9 = _get(row, `newCustom${customMarketplacesTableRow[8]?.platform?.title}`)
			const getCustomMarket10 = _get(row, `newCustom${customMarketplacesTableRow[9]?.platform?.title}`)

			const getCustomMarketId1 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[0]?.platform?.title}`)
			const getCustomMarketId2 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[1]?.platform?.title}`)
			const getCustomMarketId3 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[2]?.platform?.title}`)
			const getCustomMarketId4 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[3]?.platform?.title}`)
			const getCustomMarketId5 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[4]?.platform?.title}`)
			const getCustomMarketId6 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[5]?.platform?.title}`)
			const getCustomMarketId7 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[6]?.platform?.title}`)
			const getCustomMarketId8 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[7]?.platform?.title}`)
			const getCustomMarketId9 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[8]?.platform?.title}`)
			const getCustomMarketId10 = _get(row, `newPlatformIdByCustom${customMarketplacesTableRow[9]?.platform?.title}`)

			const objectCustomPlatforms: any = {
				...(getCustomMarket1 !== undefined ? {'892': getCustomMarket1 ? true : false} : {}),
				...(getCustomMarket2 !== undefined ? {'893': getCustomMarket2 ? true : false} : {}),
				...(getCustomMarket3 !== undefined ? {'894': getCustomMarket3 ? true : false} : {}),
				...(getCustomMarket4 !== undefined ? {'895': getCustomMarket4 ? true : false} : {}),
				...(getCustomMarket5 !== undefined ? {'896': getCustomMarket5 ? true : false} : {}),
				...(getCustomMarket6 !== undefined ? {'897': getCustomMarket6 ? true : false} : {}),
				...(getCustomMarket7 !== undefined ? {'898': getCustomMarket7 ? true : false} : {}),
				...(getCustomMarket8 !== undefined ? {'899': getCustomMarket8 ? true : false} : {}),
				...(getCustomMarket9 !== undefined ? {'900': getCustomMarket9 ? true : false} : {}),
				...(getCustomMarket10 !== undefined ? {'901': getCustomMarket10 ? true : false} : {}),
			};

			const objectPlatformsCustomIds: any = {
				...(getCustomMarketId1 !== undefined ? {[`${customMarketplacesTableRow[0]?.platform?.title}`]: getCustomMarketId1} : {}),
				...(getCustomMarketId2 !== undefined ? {[`${customMarketplacesTableRow[1]?.platform?.title}`]: getCustomMarketId2} : {}),
				...(getCustomMarketId3 !== undefined ? {[`${customMarketplacesTableRow[2]?.platform?.title}`]: getCustomMarketId3} : {}),
				...(getCustomMarketId4 !== undefined ? {[`${customMarketplacesTableRow[3]?.platform?.title}`]: getCustomMarketId4} : {}),
				...(getCustomMarketId5 !== undefined ? {[`${customMarketplacesTableRow[4]?.platform?.title}`]: getCustomMarketId5} : {}),
				...(getCustomMarketId6 !== undefined ? {[`${customMarketplacesTableRow[5]?.platform?.title}`]: getCustomMarketId6} : {}),
				...(getCustomMarketId7 !== undefined ? {[`${customMarketplacesTableRow[6]?.platform?.title}`]: getCustomMarketId7} : {}),
				...(getCustomMarketId8 !== undefined ? {[`${customMarketplacesTableRow[7]?.platform?.title}`]: getCustomMarketId8} : {}),
				...(getCustomMarketId9 !== undefined ? {[`${customMarketplacesTableRow[8]?.platform?.title}`]: getCustomMarketId9} : {}),
				...(getCustomMarketId10 !== undefined ? {[`${customMarketplacesTableRow[9]?.platform?.title}`]: getCustomMarketId10} : {})
			};

			if (rowMetaData !== null && typeof rowMetaData === 'object') {
				const platformsCode = Object.keys(row.metadata);

				const tempCustomMarketplaces = (customMarketplaces || []).map((item: any) => {
					if (
					 item.platform.code &&
					 item.platform.code.every((code: any) => {
						 const valueToCheck = row.metadata[code];
						 return (
						  (platformsCode.includes(code) ||
						   platformsCode.includes(code.toLowerCase())) &&
						  valueToCheck !== null
						 );
					 })
					) {
						return {
							...item,
							platform: {
								...item.platform,
								isChecked: true,
							},
						};
					} else {
						return item;
					}
				});

				const updatedCustomPlatforms = tempCustomMarketplaces?.map((platformItem: any) => {
					const platformTitle = platformItem.platform.platformId;

					if (objectCustomPlatforms?.hasOwnProperty(platformTitle)) {
						// @ts-ignore
						return {
							...platformItem,
							platform: {
								...platformItem.platform,
								isChecked: objectCustomPlatforms[platformTitle],
							},
						};
					}
					return platformItem;
				});

				const updatedCustomPlatformsIds = updatedCustomPlatforms?.map((platformItem: any) => {
					const platformTitle = platformItem.platform.title;
					if (objectPlatformsCustomIds?.hasOwnProperty(platformTitle)) {
						// @ts-ignore
						return {
							...platformItem,
							platform: {
								...platformItem.platform,
								platformIdFromIframe: objectPlatformsCustomIds[platformTitle],
							},
						};
					}
					return platformItem;
				});

				const tempCustomMarketplacesActive = updatedCustomPlatformsIds.filter((item: any) => item.platform.isChecked && item.platform.title);

				if (tempCustomMarketplaces.length > 0) {
					setRowCustomMarkets(tempCustomMarketplacesActive.map((item: any) => ({
						title: item.platform.title,
						code: item.platform?.code[0],
						color: item?.color,
						platformId: item.platform.platformId,
					})));
				}
			}
		}

	}, [customMarketplaces]);

	const activeRowClass = row?.isChecked && !isBulkEdit ? styles.activeRow : '';
	const rowNotActiveClass =
	 !row?.isChecked && isBulkEdit && checker ? styles.rowNotActive : '';
	const combinedClassName = `${activeRowClass} ${rowNotActiveClass}`;
	const link = row?.newImage !== undefined ? row?.newImage : `${row?.thumbnail}?rnd=${randomNumber}`

	useLayoutEffect(() => {
		const getImageDimensions = (src: string | undefined) => {
			try {
				if (src) {
					const img = new Image();
					img.src = src;

					img.onload = () => {
						if (img.width >= img.height) {
							setDimensions('width');
						} else {
							setDimensions('height');
						}
						setLoaderForImage(false);
					};
				}
			} catch (e) {
				console.log('e');
				setLoaderForImage(false);
			}
		};
		getImageDimensions(link);
	}, [tab, row?.newImage, link]);

	const triggerColumnResize = (index: number) => {
		// Set the active column index
		mouseDown(index);
		// Simulate a mousemove event after setting activeIndex
		const simulatedMouseEvent = new MouseEvent('mousemove', {
			clientX: columns[index]?.ref?.current?.getBoundingClientRect().right + 30, // Adjust '30' for width change
		});

		window.dispatchEvent(simulatedMouseEvent);

		// Simulate mouseup to release the resize action
		const simulatedMouseUpEvent = new MouseEvent('mouseup');
		window.dispatchEvent(simulatedMouseUpEvent);
	};


	const decodedKeywordTags = row!.newKeywords || unescapedRowMetadata[453]
	?.split(',')
	.map((item: string) => bdecode(item))
	.join(',');

	const shopifyLinkLocal = shopifyName && shopifyURLFormat === 2 ?
		`https://admin.shopify.com/store/${shopifyName}/products/${unescapedRowMetadata[855]}`
		: shopifyName && shopifyURLFormat === 1 ? `https://${shopifyName}.myshopify.com/admin/products/${unescapedRowMetadata[855]}`
		: `https://.myshopify.com/admin/products/${unescapedRowMetadata[855]}`;

	const orderIds = unescapedRowMetadata[902] || [];
	const data = {order_ids: orderIds, ...(unescapedRowMetadata[133]) && {listingTitle:unescapedRowMetadata[133]} };
	const encodedData = encodeURIComponent(JSON.stringify(data));


	const handlePaste = (event:any) => {
		const pasteData = event?.clipboardData?.getData('Text');
		if (isNaN(pasteData)) {
			event.preventDefault();
		}
	};


	const titleLink = !hasExtensionInstall ? `https://${window.location.hostname}/listings/view/${row.id}` : isExtensionSupportedForSingleView
	 ? `https://${window.location.hostname}/listings/view/${row.id}`
	 : `https://${linkPrefix.prefix}.listperfectly.com/listings/single/view/${row.id}`;

	const intendedForOptions:any = {
		"None": "None",
		"Unisex": "Unisex",
		"Mens": "Men",
		"Womens": "Women",
		"Unisex Kids": "Unisex Kids",
		"Girls": "Girls",
		"Boys": "Boys",
		"Babys": "Babies",
		"Maternity": "Maternity",
		"Pets": "Pets",
		"Cats": "Cats",
		"Dogs": "Dogs"
	};

    // Determine the value to use
	const selectedValue = row?.newIntendedFor !== undefined ? row!.newIntendedFor : unescapedRowMetadata[4] || "-";

    // Convert value to display text
	const displayText = selectedValue === "None" ? "-" : intendedForOptions[selectedValue] || selectedValue;

	// Checkers for pricing tool
	const hasNewTitle = newTableInfo?.newTitle?.changed && newTableInfo?.newTitle?.text?.length > 0;
	const hasMetadataTitle = !newTableInfo?.newTitle?.changed && unescapedRowMetadata[133];
	const isEnabledPriceTool = hasNewTitle || hasMetadataTitle;

	return (
	 <>
		 {confirmModalShow.status && (
		  <ConfirmModal
		   handleToggleConfirmModalShow={handleToggleConfirmModalShow}
		   confirmModalShow={confirmModalShow}
		   selectedListings={selectedListings}
		   handleFuncDeleteOrRestore={handleFuncDeleteOrRestore}
		   type={'listings'}
		  />
		 )}
		 <tr ref={rowRef} className={combinedClassName} id="tableRowId">
			 <td
			  onClick={(e: any) => {
				  if (!hasSimple) {
					  handleRowSelected(e, row?.id, row?.isChecked);
				  }
			  }}
			  className={styles.stickyCheckboxColumn}
			 >
				 <Checkbox
					 name={row?.id}
					 checked={row?.isChecked && !isBulkEdit ? row.isChecked : undefined}
					 onChange={handleChangeCheckbox}
					 modifier={CheckboxModifier.Primary}
					 disabled={hasSimple || isBulkEdit}
					 visibility={!hasSimple}
				 />
			 </td>

			 {columns?.map((columnItem, i) =>
			  columnItem.value === 'image' ? (
				  <td
					  key={columnItem.value}
					  className={`${styles.thumbnailTd} ${styles.catalogImage} 
				      ${stickyColumnsList.some(
						  (obj: any) =>
							  obj.value?.toLowerCase() === 'image' &&
							  obj.stickyColumn
					  ) ? styles.stickyColumnActive : ''}
				     `}
					  style={{
						  ...getStickyStyle(columnItem.value), // Dynamic styles
					  }}
				  >
					  <div className={styles.thumbnail}>
						  {(row?.thumbnail || row?.newImage) && row?.newImage !== null ? (
							  loaderForImage ? (
								  <MoonLoader color={'#1e3166'} loading={true} size={18}/>
							  ) : (
								  <img
									  src={link}
									  alt=""
									  style={{
										  width: dimensions === 'width' ? (!isSmallScreenLaptop ? '100px' : '80px') : 'auto',
										  height: dimensions === 'height' ? (!isSmallScreenLaptop ? '100px' : '80px') : 'auto',
									  }}
								  />
							  )
						  ) : (
							  <FaImage/>
						  )}
					  </div>
					  {
						  stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'image' && obj.activeColumn) &&
						  <div
							  style={{
								  height: '100%',
								  position: 'absolute',
								  zIndex: 534534,
							  }}
							  onMouseDown={(e) => {
								  e.preventDefault(); // Prevent any default browser behavior
								  if (typeof mouseDown === "function") {
									  mouseDown(i); // Pass the index of the column you want to resize
								  }
								  // Add global mouseup fallback to ensure cleanup
								  const stopResize = () => {
									  const mouseUpEvent = new MouseEvent("mouseup");
									  window.dispatchEvent(mouseUpEvent);
									  window.removeEventListener("mouseup", stopResize); // Clean up
								  };
								  window.addEventListener("mouseup", stopResize);
							  }}
							  className={`resize-handle ${
								  activeIndex === i ? 'active' : 'idle'
							  } 
						 
						  `}
						  />
					  }

				  </td>
			  ) : columnItem.value === 'title' ? (
				  <td key={columnItem.value}
					  className={`${styles.titleTd}
				   ${stickyColumnsList.some(
						  (obj: any) =>
							  obj.value?.toLowerCase() === 'title' &&
							  obj.stickyColumn
					  ) ? styles.stickyColumnActive : ''}
				   `}
					  style={{
						  ...getStickyStyle(columnItem.value), // Dynamic styles
					  }}
				  >
					  {!isBulkEdit ? (
						  <a
							  style={{cursor: 'pointer', color: '#191D88'}}
							  className={styles.catalogNewTitle}
							  onClick={(e: any) => {
								  e.preventDefault();
								  const location = window.location;
								  const searchParams = new URLSearchParams(location.search);
								  searchParams.set('modal-listing-id', `${row.id}`);
								  searchParams.set('modal', 'listing-view');
							      navigate(`?${searchParams.toString()}`);
							   if(!hasExtensionInstall){
								   dispatch(setListingViewId(row.id));
								   dispatch(setShowListingViewModal(true));
							   }
							   else {
								   if (isExtensionSupportedForSingleView) {
									   dispatch(setListingViewId(row.id));
									   dispatch(setShowListingViewModal(true));
								   }
								   else {
									   if (!iframeLink.length) {
										   dispatch(setIframeName('title'));
										   dispatch(setShowIframe());
										   dispatch(iframeLoaderChanger(true));
										   dispatch(
											setIframeLink(
											 `listperfectly.com/listings/single/view/${row.id}`
											)
										   );
									   }
									   else {
										   dispatch(
											setShowQuestionModal(
											 `https://app.listperfectly.com/listings/single/view/${row.id}`
											)
										   );
										   dispatch(
											setCandidantLinkPure(
											 `listperfectly.com/listings/single/view/${row.id}`
											)
										   );
									   }
								   }
							   }
						   }
						   }
						   target="_blank"
						   rel="noreferrer"
						   href={titleLink}
					   >
						   {row?.newTitle !== undefined ? bdecode(row!.newTitle) : bdecode(unescapedRowMetadata[133])}
					   </a>
				   ) : (
					   <TextareaWithCharacterCount
						   name="newTitle"
						   value={
							   row?.newTitle !== undefined
								   ? bdecode(row!.newTitle)
								   : bdecode(unescapedRowMetadata[133])
						   }
						   onChange={handleChangeEditInput}
					   />
				   )}
					  {
						  stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'title' && obj.activeColumn) &&
						  <div
							  style={{
								  height: '100%',
								  position: 'absolute',
								  zIndex: 534534,
							  }}
							  onMouseDown={(e) => {
								  e.preventDefault(); // Prevent any default browser behavior
								  if (typeof mouseDown === "function") {
									  mouseDown(i); // Pass the index of the column you want to resize
								  }
								  // Add global mouseup fallback to ensure cleanup
								  const stopResize = () => {
									  const mouseUpEvent = new MouseEvent("mouseup");
									  window.dispatchEvent(mouseUpEvent);
									  window.removeEventListener("mouseup", stopResize); // Clean up
								  };
								  window.addEventListener("mouseup", stopResize);
							  }}
							  className={`resize-handle ${
								  activeIndex === i ? 'active' : 'idle'
							  } 
						 
						  `}
						  />
					  }
			   </td>
			  ) : columnItem.value === 'SKU' ? (
				  <td key={columnItem.value}
					  className={`${styles.skuTd}
				      ${stickyColumnsList.some(
						  (obj: any) =>
							  obj.value?.toLowerCase() === 'sku' &&
							  obj.stickyColumn
					  ) ? styles.stickyColumnActive : ''}
					  `}
					  style={{
						  ...getStickyStyle(columnItem.value), // Dynamic styles
					  }}
					  >
					  {!isBulkEdit ? (
						  <div style={{cursor: 'text'}}>
							  {row?.newSku !== undefined
								  ? bdecode(row.newSku)
						 : bdecode(unescapedRowMetadata[1]) || '-'}
					</div>
				   ) : (
					<textarea
					 className="myInputTextArea"
					 name="newSku"
					 value={
						 row?.newSku !== undefined
						  ? bdecode(row.newSku)
						  : bdecode(unescapedRowMetadata[1])
					 }
					 onChange={handleChangeEditInput}
					/>
				   )}
					  {
						  stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'sku' && obj.activeColumn) &&
						  <div
							  style={{
								  height: '100%',
								  position: 'absolute',
								  zIndex: 534534,
							  }}
							  onMouseDown={(e) => {
								  e.preventDefault(); // Prevent any default browser behavior
								  if (typeof mouseDown === "function") {
									  mouseDown(i); // Pass the index of the column you want to resize
								  }
								  // Add global mouseup fallback to ensure cleanup
								  const stopResize = () => {
									  const mouseUpEvent = new MouseEvent("mouseup");
									  window.dispatchEvent(mouseUpEvent);
									  window.removeEventListener("mouseup", stopResize); // Clean up
								  };
								  window.addEventListener("mouseup", stopResize);
							  }}
							  className={`resize-handle ${
								  activeIndex === i ? 'active' : 'idle'
							  } 
						 
						  `}
						  />
					  }
			   </td>
			  ) : columnItem.value === 'price' ? (
			   <td key={columnItem.value}
				   className={`${styles.priceTd}
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'price' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			    >
				   {!isBulkEdit ? (
					   <div style={{ cursor: 'text' }}>
						   {row.newPrice !== undefined
							   ? `$${Number(row.newPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
							   : (unescapedRowMetadata[22] !== undefined
								   ? `$${Number(unescapedRowMetadata[22]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
								   : '-')}
					   </div>
				   ) : (
					   <>
						   <input
							   className="myInput  no-spinner"
							   type="text"
							   onKeyPress={handleKeyPress}
							   name="newPrice"
							   value={
								   row?.newPrice !== undefined
									   ? row!.newPrice
									   : bdecode(unescapedRowMetadata[22])
							   }
							   onChange={handleChangeEditInput}
							   onPaste={handlePaste}
						   />
						   {
							   (hasPro || (hasPro && is_role_allowed)) &&
							   <div className={styles.researchLensContainer}>
								   {
									   isEnabledPriceTool ? (
										   <Button
											   modifier={ModifierType.Primary2}
											   disabled={!hasNewTitle && !hasMetadataTitle}

											   handleClick={() => {
												   const screenWidth = window.screen.width;
												   const screenHeight = window.screen.height;

												   // Set popup size dynamically as a percentage of screen size
												   const popupWidth = Math.min(0.9 * screenWidth); // 90% of screen width
												   const popupHeight = Math.min(0.75 * screenHeight); // 75% of screen height

												   const left = (screenWidth - popupWidth) / 2;
												   const top = (screenHeight - popupHeight) / 2;
												   window.open(
													   `https://www.ebay.com/sh/research?marketplace=EBAY-US&keywords=${hasNewTitle ? newTableInfo?.newTitle?.text : unescapedRowMetadata[133]}&dayRange=365&endDate=1729187113959&startDate=1697651113959&categoryId=0&offset=0&limit=50&sorting=-avgsalesprice&tabName=SOLD&tz=Asia%2FTbilisi`,
													   '_blank',
													   `width=${popupWidth},height=${popupHeight},left=${left},top=${top},resizable=yes,scrollbars=yes`
												   );
											   }}
										   >
											   <ResearchIcon />
										   </Button>
									   ) : (
										   <Tippy
											   interactive={true}
											   arrow={false}
											   zIndex={9999}
											   trigger="click"
											   appendTo={tippyElementStickyColumnsRef.current || document.body}
											   content={
												   <div>
													   <p>Add listing title to use research button</p>
												   </div>
											   }
										   >
											   <div>
												   <Button modifier={ModifierType.Primary2} disabled={true}>
													   <ResearchIcon />
												   </Button>
											   </div>
										   </Tippy>
									   )
								   }
							   </div>
						   }
					   </>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'price' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'platforms' ? (
			   <td key={columnItem.value}
				   className={`${styles.platforms}
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'platforms' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
					   ...{
						   overflow: 'visible', justifyContent:'flex-start'
					   },
				   }}
				   >
				   {isBulkEdit ? (
					<div
					 style={{
						 height: activePlatforms?.length === 0 ? '50px' : '120px',
						 overflow: 'auto',
					 }}
					 className={styles.platformsInput}
					 onClick={handleTogglePlatformsModalShow}
					>
						<div className={styles.platformsInputWrapper}>
							{activePlatforms?.length > 0 ? (
							 activePlatforms?.map((item) => (
							  <div key={item} className={styles.platformItem}>
								  <label htmlFor={item}>{item}</label>
							  </div>
							 ))
							) : (
							 <span className={styles.platformsinputTitle}>
                        Platforms
                      </span>
							)}
						</div>
					</div>
				   ) : (
					   <PlatformsRow
						   itemInfo={row}
						   metadata={unescapedRowMetadata}
						   source="catalog"
						   newTableInfo={newTableInfo}
						   tooltipsPlatformActive={tooltipsPlatformActive}
						   handleTooltipClickPlatforms={handleTooltipClickPlatforms}
						   showModalChanger={showModalChanger}
						   unescapedRowMetadataIssues={unescapedRowMetadataIssues}
						   isSmallScreenLaptop={isSmallScreenLaptop}
						   hasTitle={true}
						   shopifyLinkLocal={shopifyLinkLocal}
					   />
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'platforms' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'issues' ? (
			   <td key={columnItem.value}
					className={`${styles.platforms}
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'issues' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
					`}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}

			   >
				   <div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						gap: !isSmallScreenLaptop ? 10 : 8,
						flexWrap: 'wrap',
					}}
				   >
					   {((!unescapedRowMetadata[618] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.2] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('eBay') && (
						 <Tippy
						  visible={tooltipsActive.ebay}
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.2] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('ebay');
							  }
						  }}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						  zIndex={9999}
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',

							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.2] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'ebay')
								  }
								  else {
									  handleTooltipClick('ebay');
								  }

							  }}
							 >
								 <YellowTriangle
								  className={`${styles.yellowTriangle} idError`}/>

								 <PlatformBlockİtem
								  platformName={'eBay'}
								  sync={row?.sync}
								  href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
								  platformIcon={EbayLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.2] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.2] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.2] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.2] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.2] ===
					   'status_selected_no_listing_found' ||
						((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.2] === 'status_selected_empty_item_number' )  ?
					    (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.2] === 'status_selected_empty_item_number' ) ? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes('ebay') ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'ebay'
							 ) ? null : (
								 !((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.2] === 'status_selected_empty_item_number' ) &&
								 <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger('newPlatformIssue', true, 'ebay')
							   }
							  >
								  <YellowTriangle
								  className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'eBay'}
								   sync={row?.sync}
								   href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
								   platformIcon={EbayLogo}
								  />
							  </div>
							 )
							) : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.2] === 'status_selected_empty_item_number' ) &&
							 <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger('newPlatformIssue', true, 'ebay')
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}

								 />
								 <PlatformBlockİtem
								  platformName={'eBay'}
								  sync={row?.sync}
								  href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
								  platformIcon={EbayLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'ebay' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <div>
									 {unescapedRowMetadataIssues[539.2] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform not selected for eBay, active listing
                                found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
											   >
                                  eBay
                                </a>
                              </span>
									   </div>
									  )}
									 {
										 // @ts-ignore
										 (!(!row?.['618'] && row?.['618'] !== '' && unescapedRowMetadata[618] && unescapedRowMetadata[539.2] === 'eBay') && is_role_allowed && hasPro && unescapedRowMetadataIssues[539.2] === 'status_selected_empty_item_number') &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 {' '}
											 <span style={{textAlign: 'center'}}>
                                             A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                                            </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.2] ===
										 'status_selected_no_active_listing_found' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected eBay, no active listing found
                                on{' '}
											   <a
												   target="_blank"
												   style={{color: 'blue', display: 'inline'}}
												   href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
											   >
                                  eBay
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.2] ===
									  'status_selected_out_of_stock' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected as eBay, listed as "out of
                                stock" on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
											   >
                                  eBay
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.2] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Sold status, active listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
											   >
                                  eBay
                                </a>
                              </span>
									   </div>
									  )}

									 {unescapedRowMetadataIssues[539.2] ===
									  'status_selected_no_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Unable to check status OR listing not found{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
											   >
                                  eBay
                                </a>
                              </span>
									   </div>
									  )}
								 </div>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[618]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>
								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'ebay',
								   539.2
								  )}>Remove</Button>
							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}
					   {((!unescapedRowMetadata[619] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.3] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('Etsy') && (
						 <Tippy
						  visible={tooltipsActive.etsy}
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.3] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('etsy');
							  }
						  }}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						  zIndex={9999}
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',

							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.3] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'etsy')
								  }
								  else {
									  handleTooltipClick('etsy');
								  }

							  }}
							 >
								 <YellowTriangle

								  className={`${styles.yellowTriangle} idError`}/>
								 <PlatformBlockİtem
								  platformName={'Etsy'}
								  sync={row?.sync}
								  href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
								  platformIcon={etsyLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.3] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.3] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.3] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.3] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.3] ===
					   'status_selected_no_listing_found' ||
					   ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.3] === 'status_selected_empty_item_number' )  ? (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.3] === 'status_selected_empty_item_number' )? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes('etsy') ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'etsy'
							 ) ? null : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.3] === 'status_selected_empty_item_number' ) &&  <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger('newPlatformIssue', true, 'etsy')
							   }
							  >
								  <YellowTriangle
								   className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'Etsy'}
								   sync={row?.sync}
								   href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
								   platformIcon={etsyLogo}
								  />
							  </div>
							 )
							) : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.3] === 'status_selected_empty_item_number' ) && <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger('newPlatformIssue', true, 'etsy')
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}

								 />
								 <PlatformBlockİtem
								  platformName={'Etsy'}
								  sync={row?.sync}
								  href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
								  platformIcon={etsyLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'etsy' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'etsy'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <div>
									 {unescapedRowMetadataIssues[539.3] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform not selected for Etsy, active listing
                                found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
											   >
                                  Etsy
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.3] ===
									  'status_selected_no_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected Etsy, no active listing found
                                on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
											   >
                                  Etsy
                                </a>
                              </span>
									   </div>
									  )}
									 {
										 // @ts-ignore
										 (!(!row?.['619'] && row?.['619'] !== '' && unescapedRowMetadata[619] && unescapedRowMetadata[539.3] === 'Etsy') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.3] === 'status_selected_empty_item_number')  &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 <span style={{textAlign: 'center'}}>
                                             A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                                            </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.3] ===
										 'status_selected_out_of_stock' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
												 {' '}
												 <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
													 Platform selected as Etsy, listed as "out of
                                stock" on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
											   >
                                  Etsy
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.3] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Sold status, active listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
											   >
                                  Etsy
                                </a>
                              </span>
									   </div>
									  )}

									 {unescapedRowMetadataIssues[539.3] ===
									  'status_selected_no_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Unable to check status OR listing not found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
											   >
                                  Etsy
                                </a>
                              </span>
									   </div>
									  )}
								 </div>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[619]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>

								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'etsy',
								   539.3
								  )}>Remove</Button>
							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}

					   {
						   ((!unescapedRowMetadata[620] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.4] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('Poshmark') && (
						 <Tippy
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.4] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('poshmark');
							  }
						  }}
						  visible={tooltipsActive.poshmark}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						  zIndex={9999}
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',
							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.4] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'poshmark')
								  }
								  else {
									  handleTooltipClick('poshmark');
								  }

							  }}
							 >
								 <YellowTriangle
								  className={`${styles.yellowTriangle} idError`}/>
								 <PlatformBlockİtem
								  platformName={'Poshmark'}
								  sync={row?.sync}
								  href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
								  platformIcon={PoshmarkLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.4] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.4] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.4] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.4] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.4] ===
					   'status_selected_no_listing_found' ||
					   ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.4] === 'status_selected_empty_item_number' )   ? (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.4] === 'status_selected_empty_item_number' )? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes(
							 'poshmark'
							) ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'poshmark'
							 ) ? null : (
								 !((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.4] === 'status_selected_empty_item_number' ) &&   <div
							   style={{

								   backgroundColor: '#FFF0C3',

							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger(
								 'newPlatformIssue',
								 true,
								 'poshmark'
								)
							   }
							  >
								  <YellowTriangle
								  className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'Poshmark'}
								   sync={row?.sync}
								   href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
								   platformIcon={PoshmarkLogo}
								  />
							  </div>
							 )
							) : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.4] === 'status_selected_empty_item_number' ) &&  <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger('newPlatformIssue', true, 'poshmark')
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}

								 />
								 <PlatformBlockİtem
								  platformName={'Poshmark'}
								  sync={row?.sync}
								  href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
								  platformIcon={PoshmarkLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'poshmark' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'poshmark'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <div>
									 {unescapedRowMetadataIssues[539.4] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform not selected for Poshmark, active
                                listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
											   >
                                  Poshmark
                                </a>
                              </span>
									   </div>
									  )}
									 {
										 // @ts-ignore
										 (!(!row?.['620'] && row?.['620'] !== '' && unescapedRowMetadata[620] && unescapedRowMetadata[539.4] === 'Poshmark') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.4] === 'status_selected_empty_item_number') &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 {' '}
											 <span style={{textAlign: 'center'}}>
                                             A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                                            </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.4] ===
										 'status_selected_no_active_listing_found' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
												 {' '}
												 <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
													 Platform selected Poshmark, no active listing
                                found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
											   >
                                  Poshmark
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.4] ===
									  'status_selected_out_of_stock' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected as Poshmark, listed as "out of
                                stock" on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
											   >
                                  Poshmark
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.4] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Sold status, active listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
											   >
                                  Poshmark
                                </a>
                              </span>
									   </div>
									  )}

									 {unescapedRowMetadataIssues[539.4] ===
									  'status_selected_no_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Unable to check status OR listing not found{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
											   >
                                  Poshmark
                                </a>
                              </span>
									   </div>
									  )}
								 </div>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[620]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>
								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'poshmark',
								   539.4
								  )}>Remove</Button>

							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}

					   {
						   ((!unescapedRowMetadata[621] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.5] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('Mercari') &&
						!changedPlatforms?.newPlatformIdByMercari?.length && (
						 <Tippy
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.5] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('mercari');
							  }
						  }}
						  visible={tooltipsActive.mercari}
						  zIndex={9999}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',
							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.5] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'mercari')
								  }
								  else {
									  handleTooltipClick('mercari');
								  }

							  }}
							 >
								 <YellowTriangle
								  className={`${styles.yellowTriangle} idError`}/>
								 <PlatformBlockİtem
								  platformName={'Mercari'}
								  sync={row?.sync}
								  href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
								  platformIcon={MercaryLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.5] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.5] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.5] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.5] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.5] ===
					   'status_selected_no_listing_found' ||
					   ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.5] === 'status_selected_empty_item_number' ) ? (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.5] === 'status_selected_empty_item_number' )? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes(
							 'mercari'
							) ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'mercari'
							 ) ? null : (
								 !((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.5] === 'status_selected_empty_item_number' ) &&   <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger(
								 'newPlatformIssue',
								 true,
								 'mercari'
								)
							   }
							  >
								  <YellowTriangle
								  className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'Mercari'}
								   sync={row?.sync}
								   href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
								   platformIcon={MercaryLogo}
								  />
							  </div>
							 )
							) : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.5] === 'status_selected_empty_item_number' ) && 	 <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger('newPlatformIssue', true, 'mercari')
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}

								 />
								 <PlatformBlockİtem
								  platformName={'Mercari'}
								  sync={row?.sync}
								  href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
								  platformIcon={MercaryLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'mercari' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'mercari'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <div>
									 {unescapedRowMetadataIssues[539.5] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform not selected for Mercari, active
                                listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
											   >
                                  Mercari
                                </a>
                              </span>
									   </div>
									  )}
									 {
										 // @ts-ignore
										 (!(!row?.['621'] && row?.['621'] !== '' && unescapedRowMetadata[621] && unescapedRowMetadata[539.5] === 'Mercari') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.5] === 'status_selected_empty_item_number') &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 {' '}
											 <span style={{textAlign: 'center'}}>A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales" </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.5] ===
										 'status_selected_no_active_listing_found' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
												 {' '}
												 <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
													 Platform selected Mercari, no active listing
                                found on{' '}
													 <a
														 target="_blank"
														 style={{color: 'blue', display: 'inline'}}
														 href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
											   >
                                  Mercari
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.5] ===
									  'status_selected_out_of_stock' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected as Mercari, listed as "out of
                                stock" on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
											   >
                                  Mercari
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.5] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Sold status, active listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
											   >
                                  Mercari
                                </a>
                              </span>
									   </div>
									  )}

									 {unescapedRowMetadataIssues[539.5] ===
									  'status_selected_no_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Unable to check status OR listing not found{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
											   >
                                  Mercari
                                </a>
                              </span>
									   </div>
									  )}
								 </div>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[621]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>
								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'mercari',
								   539.5
								  )}>Remove</Button>
							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}
					   {unescapedRowMetadataIssues[539.6] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.6] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.6] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.6] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.6] ===
					   'status_selected_no_listing_found' ? (
						<div>
							{newTableInfo.newPlatformIssue.platform.includes(
							 'tradesy'
							) ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'tradesy'
							 ) ? null : (
							  <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger(
								 'newPlatformIssue',
								 true,
								 'tradesy'
								)
							   }
							  >
								  <YellowTriangle
								   className={styles.yellowTriangle}

								  />
								  <PlatformBlockİtem
								   platformName={'Tradesy'}
								   sync={row?.sync}
								   href={`https://www.vestiairecollective.com/?utm_source=tradesy&utm_medium=referral&utm_campaign=US_EN_Tradesy_Tradesy_Buyer_All_All_Web`}
								   platformIcon={tradesyLogo}
								  />
							  </div>
							 )
							) : (
							 <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger('newPlatformIssue', true, 'tradesy')
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}

								 />
								 <PlatformBlockİtem
								  platformName={'Tradesy'}
								  sync={row?.sync}
								  href={`https://www.vestiairecollective.com/?utm_source=tradesy&utm_medium=referral&utm_campaign=US_EN_Tradesy_Tradesy_Buyer_All_All_Web`}
								  platformIcon={tradesyLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'tradesy' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'tradesy'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <p>
									 {unescapedRowMetadataIssues[539.6] ===
									  'status_not_selected_active_listing_found' &&
									  ' Platform not selected for Tradesy, active listing found on Tradesy'}
									 {unescapedRowMetadataIssues[539.6] ===
									  'status_selected_no_active_listing_found' &&
									  'Platform selected Tradesy, no active listing found on Tradesy'}
									 {unescapedRowMetadataIssues[539.6] ===
									  'status_selected_out_of_stock' &&
									  'Platform selected as Tradesy, listed as "out of stock" on Tradesy'}
									 {unescapedRowMetadataIssues[539.6] ===
									  'sold_status_active_listing_found' &&
									  'Sold status, active listing found on Tradesy'}
									 {unescapedRowMetadataIssues[539.6] ===
									  'status_selected_no_listing_found' &&
									  'Unable to check status OR listing not found Tradesy'}
								 </p>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[836]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>
								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'tradesy',
								   539.6
								  )}>Remove</Button>
							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}
					   {
						   ((!unescapedRowMetadata[836] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.7] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('Amazon') && (
						 <Tippy
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.7] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('amazon');
							  }
						  }}
						  visible={tooltipsActive.amazon}
						  zIndex={9999}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',
							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.7] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'amazon')
								  }
								  else {
									  handleTooltipClick('amazon');
								  }

							  }}
							 >
								 <YellowTriangle className={`${styles.yellowTriangle} idError`}/>
								 <PlatformBlockİtem
								  platformName={'Amazon'}
								  sync={row?.sync}
								  href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
								  platformIcon={AmazonLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.7] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.7] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.7] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.7] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.7] ===
					   'status_selected_no_listing_found' ||
					   ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.7] === 'status_selected_empty_item_number' )? (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.7] === 'status_selected_empty_item_number' )? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes(
							 'amazon'
							) ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'amazon'
							 ) ? null : (
								 !((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.7] === 'status_selected_empty_item_number' ) && 	  <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger('newPlatformIssue', true, 'amazon')
							   }
							  >
								  <YellowTriangle
								  className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'Amazon'}
								   sync={row?.sync}
								   href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
								   platformIcon={AmazonLogo}
								  />
							  </div>
							 )
							) : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.7] === 'status_selected_empty_item_number' ) && 	 <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger('newPlatformIssue', true, 'amazon')
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}
								 />
								 <PlatformBlockİtem
								  platformName={'Amazon'}
								  sync={row?.sync}
								  href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
								  platformIcon={AmazonLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'amazon' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'amazon'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <div>
									 {unescapedRowMetadataIssues[539.7] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform not selected for Amazon, active listing
                                found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
											   >
                                  Amazon
                                </a>
                              </span>
									   </div>
									  )}
									 {
										 // @ts-ignore
										 !((!row?.['836'] && row?.['836'] !== '' && unescapedRowMetadata[836] && unescapedRowMetadata[539.7] === 'Amazon') && is_role_allowed && hasPro && unescapedRowMetadataIssues[539.7] === 'status_selected_empty_item_number') &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 {' '}
											 <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.7] ===
										 'status_selected_no_active_listing_found' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
												 {' '}
												 <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
													 Platform selected Amazon, no active listing
                                found on{' '}
													 <a
														 target="_blank"
														 style={{color: 'blue', display: 'inline'}}
														 href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
											   >
                                  Amazon
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.7] ===
									  'status_selected_out_of_stock' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected as Amazon, listed as "out of
                                stock" on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
											   >
                                  Amazon
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.7] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Sold status, active listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
											   >
                                  Amazon
                                </a>
                              </span>
									   </div>
									  )}

									 {unescapedRowMetadataIssues[539.7] ===
									  'status_selected_no_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Unable to check status OR listing not found{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
											   >
                                  Amazon
                                </a>
                              </span>
									   </div>
									  )}
								 </div>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[636]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>
								 <button
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									null,
									'amazon',
									539.7
								   )
								  }
								 >
									 Remove
								 </button>
							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}
					   {((!unescapedRowMetadata[839] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.8] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('Grailed') && (
						 <Tippy
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.8] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('grailed');
							  }
						  }}
						  visible={tooltipsActive.grailed}
						  zIndex={9999}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',
							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.8] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'grailed')
								  }
								  else {
									  handleTooltipClick('grailed');
								  }

							  }}
							 >
								 <YellowTriangle className={`${styles.yellowTriangle} idError`}/>
								 <PlatformBlockİtem
								  platformName={'Grailed'}
								  sync={row?.sync}
								  href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
								  platformIcon={grailedLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.8] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.8] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.8] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.8] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.8] ===
					   'status_selected_no_listing_found' ||
					   ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.8] === 'status_selected_empty_item_number' )   ? (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.8] === 'status_selected_empty_item_number' )? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes(
							 'grailed'
							) ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'grailed'
							 ) ? null : (
								 !((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.8] === 'status_selected_empty_item_number' ) &&   <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger(
								 'newPlatformIssue',
								 true,
								 'grailed'
								)
							   }
							  >
								  <YellowTriangle
								  className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'Grailed'}
								   sync={row?.sync}
								   href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
								   platformIcon={grailedLogo}
								  />
							  </div>
							 )
							) : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.8] === 'status_selected_empty_item_number' ) && 	 <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger('newPlatformIssue', true, 'grailed')
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}
								 />
								 <PlatformBlockİtem
								  platformName={'Grailed'}
								  sync={row?.sync}
								  href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
								  platformIcon={grailedLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'grailed' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'grailed'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <div>
									 {unescapedRowMetadataIssues[539.8] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform not selected for Grailed, active
                                listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
											   >
                                  Grailed
                                </a>
                              </span>
									   </div>
									  )}
									 {
										 // @ts-ignore
										 (!(!row?.['839'] && row?.['839'] !== '' && unescapedRowMetadata[839] && unescapedRowMetadata[539.8] === 'Grailed') && is_role_allowed && hasPro && unescapedRowMetadataIssues[539.8] === 'status_selected_empty_item_number') &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 {' '}
											 <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.8] ===
										 'status_selected_no_active_listing_found' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
												 {' '}
												 <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
													 Platform selected Grailed, no active listing
                                found on{' '}
													 <a
														 target="_blank"
														 style={{color: 'blue', display: 'inline'}}
														 href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
											   >
                                  Grailed
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.8] ===
									  'status_selected_out_of_stock' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected as Grailed, listed as "out of
                                stock" on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
											   >
                                  Grailed
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.8] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Sold status, active listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
											   >
                                  Grailed
                                </a>
                              </span>
									   </div>
									  )}

									 {unescapedRowMetadataIssues[539.8] ===
									  'status_selected_no_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Unable to check status OR listing not found{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
											   >
                                  Grailed
                                </a>
                              </span>
									   </div>
									  )}
								 </div>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[839]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>

								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'grailed',
								   539.8
								  )}>Remove</Button>

							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}
					   {
						   ((!unescapedRowMetadata[840] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.9] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('Facebook') && (
						 <Tippy
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.9] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('facebook');
							  }
						  }}
						  visible={tooltipsActive.facebook}
						  zIndex={9999}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',
							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.9] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'facebook')
								  }
								  else {
									  handleTooltipClick('facebook');
								  }

							  }}
							 >
								 <YellowTriangle className={`${styles.yellowTriangle} idError`}/>
								 <PlatformBlockİtem
								  platformName={'Facebook'}
								  sync={row?.sync}
								  href={`https://www.facebook.com/marketplace/item/${unescapedRowMetadata[840]}/`}
								  platformIcon={facebookLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.9] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.9] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.9] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.9] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.9] ===
					   'status_selected_no_listing_found' ||
					   ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.9] === 'status_selected_empty_item_number' ) ? (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.9] === 'status_selected_empty_item_number' )? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes(
							 'facebook'
							) ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'facebook'
							 ) ? null : (
								 !((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.9] === 'status_selected_empty_item_number' ) &&	  <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger(
								 'newPlatformIssue',
								 true,
								 'facebook'
								)
							   }
							  >
								  <YellowTriangle
								  className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'Facebook'}
								   sync={row?.sync}
								   href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}/`}
								   platformIcon={facebookLogo}
								  />
							  </div>
							 )
							) : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.9] === 'status_selected_empty_item_number' ) &&	 <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger('newPlatformIssue', true, 'facebook')
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}

								 />
								 <PlatformBlockİtem
								  platformName={'Facebook'}
								  sync={row?.sync}
								  href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}/`}
								  platformIcon={facebookLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'facebook' ? (
							 <div className="issueModalOverlay"
									onClick={() =>
										showModalChanger(
										'newPlatformIssue',
										'cancel',
										'ebay'
										 )
										   }>

							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'facebook'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <div>
									 {unescapedRowMetadataIssues[539.9] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform not selected for Facebook, active
                                listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}`}
											   >
                                  Facebook
                                </a>
                              </span>
									   </div>
									  )}
									 {// @ts-ignore
										 (!(!row?.['840'] && row?.['840'] !== '' && unescapedRowMetadata[840] && unescapedRowMetadata[539.9] === 'Facebook') && is_role_allowed && hasPro && unescapedRowMetadataIssues[539.9] === 'status_selected_empty_item_number') &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 {' '}
											 <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.9] ===
										 'status_selected_no_active_listing_found' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
												 {' '}
												 <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
													 Platform selected Facebook, no active listing
                                found on{' '}
													 <a
														 target="_blank"
														 style={{color: 'blue', display: 'inline'}}
														 href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}`}
											   >
                                  Facebook
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.9] ===
									  'status_selected_out_of_stock' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected as Facebook, listed as "out of
                                stock" on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}`}
											   >
                                  Facebook
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.9] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Sold status, active listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}`}
											   >
                                  Facebook
                                </a>
                              </span>
									   </div>
									  )}

									 {unescapedRowMetadataIssues[539.9] ===
									  'status_selected_no_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Unable to check status OR listing not found{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}`}
											   >
                                  Facebook
                                </a>
                              </span>
									   </div>
									  )}
								 </div>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[840]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>

								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'facebook',
								   539.9
								  )}>Remove</Button>
							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}
					   {
						   ((!unescapedRowMetadata[841] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.11] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('Depop') && (
						 <Tippy
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.11] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('depop');
							  }
						  }}
						  visible={tooltipsActive.depop}
						  zIndex={9999}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',
							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.11] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'depop')
								  }
								  else {
									  handleTooltipClick('depop');
								  }

							  }}
							 >
								 <YellowTriangle className={`${styles.yellowTriangle} idError`}/>
								 <PlatformBlockİtem
								  platformName={'Depop'}
								  sync={row?.sync}
								  href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
								  platformIcon={depopLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.11] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.11] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.11] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.11] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.11] ===
					   'status_selected_no_listing_found' ||
					   ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.11] === 'status_selected_empty_item_number' ) ? (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.11] === 'status_selected_empty_item_number' )? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes(
							 'depop'
							) ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'depop'
							 ) ? null : (
								 !((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.11] === 'status_selected_empty_item_number' ) &&   <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger('newPlatformIssue', true, 'depop')
							   }
							  >
								  <YellowTriangle
								  className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'Depop'}
								   sync={row?.sync}
								   href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
								   platformIcon={depopLogo}
								  />
							  </div>
							 )
							) : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.11] === 'status_selected_empty_item_number' ) &&  <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger('newPlatformIssue', true, 'depop')
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}

								 />
								 <PlatformBlockİtem
								  platformName={'Depop'}
								  sync={row?.sync}
								  href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
								  platformIcon={depopLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'depop' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'depop'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <div>
									 {unescapedRowMetadataIssues[539.11] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform not selected for Depop, active listing
                                found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
											   >
                                  Depop
                                </a>
                              </span>
									   </div>
									  )}
									 {
										 // @ts-ignore
										 (!(!row?.['841'] && row?.['841'] !== '' && unescapedRowMetadata[841] && unescapedRowMetadata[539.11] === 'Depop') && hasPro && is_role_allowed  && unescapedRowMetadataIssues[539.11] === 'status_selected_empty_item_number') &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 {' '}
											 <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.11] ===
										 'status_selected_no_active_listing_found' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
												 {' '}
												 <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
													 Platform selected Depop, no active listing found
                                on{' '}
													 <a
														 target="_blank"
														 style={{color: 'blue', display: 'inline'}}
														 href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
											   >
                                  Depop
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.11] ===
									  'status_selected_out_of_stock' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected as Depop, listed as "out of
                                stock" on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
											   >
                                  Depop
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.11] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Sold status, active listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
											   >
                                  Depop
                                </a>
                              </span>
									   </div>
									  )}

									 {unescapedRowMetadataIssues[539.11] ===
									  'status_selected_no_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Unable to check status OR listing not found{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
											   >
                                  Depop
                                </a>
                              </span>
									   </div>
									  )}
								 </div>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[841]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>

								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'depop',
								   539.11
								  )}>Remove</Button>

							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}
					   {
						   ((!unescapedRowMetadata[853] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.12] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('Kidizen') && (
						 <Tippy
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.12] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('kidizen');
							  }
						  }}
						  visible={tooltipsActive.kidizen}
						  zIndex={9999}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',
							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.12] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'kidizen')
								  }
								  else {
									  handleTooltipClick('kidizen');
								  }

							  }}

							 >
								 <YellowTriangle className={`${styles.yellowTriangle} idError`}/>
								 <PlatformBlockİtem
								  platformName={'Kidizen'}
								  sync={row?.sync}
								  href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
								  platformIcon={kidizenLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.12] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.12] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.12] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.12] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.12] ===
					   'status_selected_no_listing_found' ||
					   ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.12] === 'status_selected_empty_item_number' )? (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.12] === 'status_selected_empty_item_number' )? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes(
							 'kidizen'
							) ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'kidizen'
							 ) ? null : (
								 !((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.12] === 'status_selected_empty_item_number' ) && 	  <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger(
								 'newPlatformIssue',
								 true,
								 'kidizen'
								)
							   }
							  >
								  <YellowTriangle
								  className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'Kidizen'}
								   sync={row?.sync}
								   href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
								   platformIcon={kidizenLogo}
								  />
							  </div>
							 )
							) : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.12] === 'status_selected_empty_item_number' ) && 	 <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger('newPlatformIssue', true, 'kidizen')
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}

								 />
								 <PlatformBlockİtem
								  platformName={'Kidizen'}
								  sync={row?.sync}
								  href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
								  platformIcon={kidizenLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'kidizen' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'kidizen'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <div>
									 {unescapedRowMetadataIssues[539.12] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform not selected for Kidizen, active
                                listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
											   >
                                  Kidizen
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.12] ===
									  'status_selected_no_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected Kidizen, no active listing
                                found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
											   >
                                  Kidizen
                                </a>
                              </span>
									   </div>
									  )}
									 {
										 // @ts-ignore
										 (!(!row?.['853'] && row?.['853'] !== '' && unescapedRowMetadata[853] && unescapedRowMetadata[539.12] === 'Kidizen') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.12] === 'status_selected_empty_item_number') &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 {' '}
											 <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.12] ===
										 'status_selected_out_of_stock' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
												 {' '}
												 <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
													 Platform selected as Kidizen, listed as "out of
                                stock" on{' '}
													 <a
														 target="_blank"
														 style={{color: 'blue', display: 'inline'}}
														 href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
											   >
                                  Kidizen
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.12] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Sold status, active listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
											   >
                                  Kidizen
                                </a>
                              </span>
									   </div>
									  )}

									 {unescapedRowMetadataIssues[539.12] ===
									  'status_selected_no_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Unable to check status OR listing not found{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
											   >
                                  Kidizen
                                </a>
                              </span>
									   </div>
									  )}
								 </div>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[853]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>

								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'kidizen',
								   539.12
								  )}>Remove</Button>
							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}
					   {
						   ((!unescapedRowMetadata[855] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.13] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('Shopify') && (
						 <Tippy
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.13] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('shopify');
							  }
						  }}
						  visible={tooltipsActive.shopify}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						  zIndex={9999}
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',
							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.13] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'shopify')
								  }
								  else {
									  handleTooltipClick('shopify');
								  }

							  }}
							 >
								 <YellowTriangle className={`${styles.yellowTriangle} idError`}/>
								 <PlatformBlockİtem
								  platformName={'Shopify'}
								  sync={row?.sync}
								  href={`https://accounts.shopify.com/lookup?rid=70f0f0e2-3f19-4767-849c-f8e779c0005a`}
								  platformIcon={shopifyLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.13] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.13] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.13] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.13] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.13] ===
					   'status_selected_no_listing_found' ||
					   ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.13] === 'status_selected_empty_item_number' )? (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.13] === 'status_selected_empty_item_number' )? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes(
							 'shopify'
							) ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'shopify'
							 ) ? null : (
								 !((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.13] === 'status_selected_empty_item_number' ) && 	  <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger(
								 'newPlatformIssue',
								 true,
								 'shopify'
								)
							   }
							  >
								  <YellowTriangle
								  className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'Shopify'}
								   sync={row?.sync}
								   href={`https://accounts.shopify.com/lookup?rid=70f0f0e2-3f19-4767-849c-f8e779c0005a`}
								   platformIcon={shopifyLogo}
								   fromPlatform={false}
								  />
							  </div>
							 )
							) : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.13] === 'status_selected_empty_item_number' ) && 	 <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger('newPlatformIssue', true, 'shopify')
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}

								 />
								 <PlatformBlockİtem
								  platformName={'Shopify'}
								  sync={row?.sync}
								  href={`https://accounts.shopify.com/lookup?rid=70f0f0e2-3f19-4767-849c-f8e779c0005a`}
								  platformIcon={shopifyLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'shopify' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'shopify'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <p>
									 {unescapedRowMetadataIssues[539.13] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
                              <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
								  Platform not selected for Shopify, active
                                listing found on
                                <a
								 target="_blank"
								 style={{color: 'blue', display: 'inline'}}
								 href={`https://admin.shopify.com/store/listperfectly/products/${unescapedRowMetadata[855]}`}
								>
                                  Shopify
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.13] ===
									  'status_selected_no_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
                              <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
								  Platform selected Shopify, no active listing
                                found on
                                <a
								 target="_blank"
								 style={{color: 'blue', display: 'inline'}}
								 href={`https://admin.shopify.com/store/listperfectly/products/${unescapedRowMetadata[855]}`}
								>
                                  Shopify
                                </a>
                              </span>
									   </div>
									  )}
									 {
										 // @ts-ignore
										 (!(!row?.['855'] && row?.['855'] !== '' && unescapedRowMetadata[855] && unescapedRowMetadata[539.13] === 'Shopify') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.13] === 'status_selected_empty_item_number') &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 {' '}
											 <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.13] ===
										 'status_selected_out_of_stock' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
                              <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
								  Platform selected as Shopify, listed as "out of
                                stock" on
                                <a
									target="_blank"
									style={{color: 'blue', display: 'inline'}}
									href={`https://admin.shopify.com/store/listperfectly/products/${unescapedRowMetadata[855]}`}
								>
                                  Shopify
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.13] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
                              <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
								  Sold status, active listing found on
                                <a
								 target="_blank"
								 style={{color: 'blue', display: 'inline'}}
								 href={`https://admin.shopify.com/store/listperfectly/products/${unescapedRowMetadata[855]}`}
								>
                                  Shopify
                                </a>
                              </span>
									   </div>
									  )}

									 {unescapedRowMetadataIssues[539.13] ===
									  'status_selected_no_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
                              <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
								  Unable to check status OR listing not found{' '}
								  <a
								   target="_blank"
								   style={{color: 'blue', display: 'inline'}}
								   href={`https://admin.shopify.com/store/listperfectly/products/${unescapedRowMetadata[855]}`}
								  >
                                  Shopify
                                </a>
                              </span>
									   </div>
									  )}
								 </p>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[855]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>

								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'shopify',
								   539.13
								  )}>Remove</Button>
							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}
					   {
						   ((!unescapedRowMetadata[856] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.14] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('Instagram') && (
						 <Tippy
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.14] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('instagram');
							  }
						  }}

						  visible={tooltipsActive.instagram}
						  zIndex={9999}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',
							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.14] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'instagram')
								  }
								  else {
									  handleTooltipClick('instagram');
								  }

							  }}
							 >
								 <YellowTriangle className={`${styles.yellowTriangle} idError`}/>
								 <PlatformBlockİtem
								  platformName={'Instagram'}
								  sync={row?.sync}
								  href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}/`}
								  platformIcon={instagramLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.14] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.14] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.14] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.14] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.14] ===
					   'status_selected_no_listing_found' ||
					   ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.14] === 'status_selected_empty_item_number' )? (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.14] === 'status_selected_empty_item_number' )? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes(
							 'instagram'
							) ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'instagram'
							 ) ? null : (
								 !((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.14] === 'status_selected_empty_item_number' ) &&
								 <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger(
								 'newPlatformIssue',
								 true,
								 'instagram',
								 539.14
								)
							   }
							  >
								  <YellowTriangle
								  className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'Instagram'}
								   sync={row?.sync}
								   href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}/`}
								   platformIcon={instagramLogo}
								  />
							  </div>
							 )
							) : (
								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.14] === 'status_selected_empty_item_number' ) && 	 <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger(
								'newPlatformIssue',
								true,
								'instagram'
							   )
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}
								 />
								 <PlatformBlockİtem
								  platformName={'Instagram'}
								  sync={row?.sync}
								  href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}/`}
								  platformIcon={instagramLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'instagram' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'instagram'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <div>
									 {unescapedRowMetadataIssues[539.14] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform not selected for Instagram, active
                                listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}`}
											   >
                                  Instagram
                                </a>
                              </span>
									   </div>
									  )}
									 {
										 // @ts-ignore
										 (!(!row?.['856'] && row?.['856'] !== '' && unescapedRowMetadata[856] && unescapedRowMetadata[539.14] === 'Instagram') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.14] === 'status_selected_empty_item_number') &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 {' '}
											 <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.14] ===
										 'status_selected_no_active_listing_found' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
												 {' '}
												 <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
													 Platform selected Instagram, no active listing
                                found on{' '}
													 <a
														 target="_blank"
														 style={{color: 'blue', display: 'inline'}}
														 href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}`}
											   >
                                  Instagram
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.14] ===
									  'status_selected_out_of_stock' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected as Instagram, listed as "out
                                of stock" on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}`}
											   >
                                  Instagram
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.14] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Sold status, active listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}`}
											   >
                                  Instagram
                                </a>
                              </span>
									   </div>
									  )}

									 {unescapedRowMetadataIssues[539.14] ===
									  'status_selected_no_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Unable to check status OR listing not found{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}`}
											   >
                                  Instagram
                                </a>
                              </span>
									   </div>
									  )}
								 </div>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[856]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>

								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'instagram',
								   539.14
								  )}>Remove</Button>
							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}
					   {((!unescapedRowMetadata[885] && !(hasPro && is_role_allowed)) || ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.17] === 'status_selected_empty_item_number')) &&
						unescapedRowMetadata[539.1] !== 'SOLD' &&
						activePlatforms.includes('Vestiaire') && (
						 <Tippy
						  interactive={true}
						  onClickOutside={() => {
							  if(!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.17] === 'status_selected_empty_item_number')) {
								  handleTooltipClick('vestiaire');
							  }
						  }}
						  visible={tooltipsActive.vestiaire}
						  content={
							  <span>
                          A platform status is selected, but no platform ID is
                          saved to the corresponding item number field in
                          "inventory and sales"
                        </span>
						  }
						  zIndex={9999}
						 >
							 <div
							  style={{
								  backgroundColor: '#FFDADA',
							  }}
							  className={styles.issues}
							  onClick={() => {
								  if(((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.17] === 'status_selected_empty_item_number'))  {
									  showModalChanger('newPlatformIssue', true, 'vestiaire')
								  }
								  else {
									  handleTooltipClick('vestiaire');
								  }

							  }}
							 >
								 <YellowTriangle className={`${styles.yellowTriangle} idError`}/>
								 <PlatformBlockİtem
								  platformName={'Vestiaire'}
								  sync={row?.sync}
								  href=""
								  platformIcon={vestiaireLogo}
								  fromPlatform={false}
								 />
							 </div>
						 </Tippy>
						)}
					   {unescapedRowMetadataIssues[539.17] ===
					   'status_not_selected_active_listing_found' ||
					   unescapedRowMetadataIssues[539.17] ===
					   'status_selected_no_active_listing_found' ||
					   unescapedRowMetadataIssues[539.17] ===
					   'status_selected_out_of_stock' ||
					   unescapedRowMetadataIssues[539.17] ===
					   'sold_status_active_listing_found' ||
					   unescapedRowMetadataIssues[539.17] ===
					   'status_selected_no_listing_found' ||
					   ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.17] === 'status_selected_empty_item_number' )? (
						<div style={{position: ((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.17] === 'status_selected_empty_item_number' )? 'absolute' : 'relative'}}>
							{newTableInfo.newPlatformIssue.platform.includes(
							 'vestiaire'
							) ? (
							 newTableInfo.newPlatformIssue.removed.includes(
							  'vestiaire'
							 ) ? null : (

								 !((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.17] === 'status_selected_empty_item_number' ) && 	  <div
							   style={{
								   backgroundColor: '#FFF0C3',
							   }}
							   className={styles.issues}
							   onClick={() =>
								showModalChanger(
								 'newPlatformIssue',
								 true,
								 'vestiaire'
								)
							   }
							  >
								  <YellowTriangle
								   className={styles.yellowTriangle}
								  />
								  <PlatformBlockİtem
								   platformName={'Vestiaire'}
								   sync={row?.sync}
								   href=""
								   platformIcon={vestiaireLogo}
								  />
							  </div>
							 )
							) : (

								!((hasPro && is_role_allowed) && unescapedRowMetadataIssues[539.17] === 'status_selected_empty_item_number' ) && 		 <div
							  style={{
								  backgroundColor: '#FFF0C3',
							  }}
							  className={styles.issues}
							  onClick={() =>
							   showModalChanger(
								'newPlatformIssue',
								true,
								'vestiaire'
							   )
							  }
							 >
								 <YellowTriangle
								  className={styles.yellowTriangle}

								  />
								 <PlatformBlockİtem
								  platformName={'Vestiaire'}
								  sync={row?.sync}
								  href=""
								  platformIcon={vestiaireLogo}
								 />
							 </div>
							)}

							{newTableInfo.newPlatformIssue.modalOpen &&
							newTableInfo.newPlatformIssue.text === 'vestiaire' ? (
							 <div className="issueModalOverlay"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'ebay'
								   )
								  }>
							 <div className="issueModal">
								 <div
								  className="closeIcon"
								  onClick={() =>
								   showModalChanger(
									'newPlatformIssue',
									'cancel',
									'vestiaire'
								   )
								  }
								 >
									 &#10799;
								 </div>
								 <div>
									 {unescapedRowMetadataIssues[539.17] ===
									  'status_not_selected_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform not selected for Vestiare, active
                                listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://vestiairecollective.com/'${unescapedRowMetadata[885]}.shtml`}
											   >
                                  Vestiare
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.17] ===
									  'status_selected_no_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected Vestiare, no active listing
                                found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://vestiairecollective.com/'${unescapedRowMetadata[885]}.shtml`}
											   >
                                  Vestiare
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.17] ===
									  'status_selected_out_of_stock' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Platform selected as Vestiare, listed as "out of
                                stock" on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://vestiairecollective.com/'${unescapedRowMetadata[885]}.shtml`}
											   >
                                  Vestiare
                                </a>
                              </span>
									   </div>
									  )}
									 {unescapedRowMetadataIssues[539.17] ===
									  'sold_status_active_listing_found' && (
									   <div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									   >
										   {' '}
										   <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
											   Sold status, active listing found on{' '}
											   <a
												target="_blank"
												style={{color: 'blue', display: 'inline'}}
												href={`https://vestiairecollective.com/'${unescapedRowMetadata[885]}.shtml`}
											   >
                                  Vestiare
                                </a>
                              </span>
									   </div>
									  )}
									 {
										 // @ts-ignore
										 (!(!row?.['885'] && row?.['885'] !== '' && unescapedRowMetadata[885] && unescapedRowMetadata[539.17] === 'Vestiaire') && is_role_allowed && hasPro && unescapedRowMetadataIssues[539.17] === 'status_selected_empty_item_number') &&
										 <div
											 style={{
												 display: 'flex',
												 justifyContent: 'center',
												 alignItems: 'center',
												 flexWrap: 'wrap',
												 gap: '5px',
											 }}
										 >
											 {' '}
											 <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
										 </div>
									 }
									 {unescapedRowMetadataIssues[539.17] ===
										 'status_selected_no_listing_found' && (
											 <div
												 style={{
													 display: 'flex',
													 justifyContent: 'center',
													 alignItems: 'center',
													 flexWrap: 'wrap',
													 gap: '5px',
												 }}
											 >
												 {' '}
												 <span>
                                {syncTime && <p>Last sync {syncTime}</p>}
													 Unable to check status OR listing not found{' '}
													 <a
														 target="_blank"
														 style={{color: 'blue', display: 'inline'}}
														 href={`https://vestiairecollective.com/'${unescapedRowMetadata[885]}.shtml`}
													 >
                                  Vestiare
                                </a>
                              </span>
									   </div>
									  )}
								 </div>
								 <a
								  target="_blank"
								  href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[885]}&catalog_listing_id=${row.id}`}
								  style={{padding: '5px', color: 'blue'}}
								 >
									 Report incorrect status
								 </a>

								 <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20}} modifier={ModifierType.Transparent} handleClick={() =>
								  showModalChanger(
								   'newPlatformIssue',
								   null,
								   'vestiaire',
								   539.17
								  )}>Remove</Button>
							 </div>
							 </div>
							) : null}
						</div>
					   ) : null}
				   </div>
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'issues' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'quantity' ? (
			   <td key={columnItem.value}
				   className={`${styles.quantityTd}
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'quantity' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{row?.newQuantity !== undefined ? row!.newQuantity : unescapedRowMetadata[851] || '-'}
					</div>
				   ) : (
					<input
					 className="myInput  no-spinner"
					 type="text"
					 onKeyPress={handleKeyPress}
					 name="newQuantity"
					 value={
						 row?.newQuantity !== undefined ? row!.newQuantity : unescapedRowMetadata[851]
					 }
					 onChange={handleChangeEditInput}
					 onPaste={handlePaste}
					/>
				   )}
				   {
				   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'quantity' && obj.activeColumn) &&
				   <div
					   style={{
						   height: '100%',
						   position: 'absolute',
						   zIndex: 534534,
					   }}
					   onMouseDown={(e) => {
						   e.preventDefault(); // Prevent any default browser behavior
						   if (typeof mouseDown === "function") {
							   mouseDown(i); // Pass the index of the column you want to resize
						   }
						   // Add global mouseup fallback to ensure cleanup
						   const stopResize = () => {
							   const mouseUpEvent = new MouseEvent("mouseup");
							   window.dispatchEvent(mouseUpEvent);
							   window.removeEventListener("mouseup", stopResize); // Clean up
						   };
						   window.addEventListener("mouseup", stopResize);
					   }}
					   className={`resize-handle ${
						   activeIndex === i ? 'active' : 'idle'
					   } 
						 
						  `}
				   />
			   }
			   </td>
			  ) :  columnItem.value === 'sales' ? (
				  <td key={columnItem.value}
					  className={`${styles.salesTd}
				      ${stickyColumnsList.some(
						  (obj: any) =>
							  obj.value?.toLowerCase() === 'sales' &&
							  obj.stickyColumn
					  ) ? styles.stickyColumnActive : ''}
					  `}
					  style={{
						  ...getStickyStyle(columnItem.value), // Dynamic styles
					  }}
					 >

				   {unescapedRowMetadata[902] ? <a
					href={`/sales?filter_by=${encodedData}`}
					target="_blank"
					title={`/sales?filter_by=${encodedData}`}
					className={styles.salesWrapper}>
					   <div style={{border: '1px solid #4DB89E'}} className={styles.dollarSignSolid}>
						   <DollarSignSolid/>
						   <MintCircleCheck className={styles.circleCheck}/>
						   <div className={styles.connectCount}>
							   {unescapedRowMetadata[902]?.length}
						   </div>
					   </div>
				   </a> : <div>-</div>}
					  {
						  stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'sales' && obj.activeColumn) &&
						  <div
							  style={{
								  height: '100%',
								  position: 'absolute',
								  zIndex: 534534,
							  }}
							  onMouseDown={(e) => {
								  e.preventDefault(); // Prevent any default browser behavior
								  if (typeof mouseDown === "function") {
									  mouseDown(i); // Pass the index of the column you want to resize
								  }
								  // Add global mouseup fallback to ensure cleanup
								  const stopResize = () => {
									  const mouseUpEvent = new MouseEvent("mouseup");
									  window.dispatchEvent(mouseUpEvent);
									  window.removeEventListener("mouseup", stopResize); // Clean up
								  };
								  window.addEventListener("mouseup", stopResize);
							  }}
							  className={`resize-handle ${
								  activeIndex === i ? 'active' : 'idle'
							  } 
						 
						  `}
						  />
					  }
			   </td>)
			   :
			   columnItem.value === 'updated' ? (
			   <td key={columnItem.value}
				   className={`${styles.updatedTd}
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'updated' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}

				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   <div style={{cursor: 'text'}}>
					   {moment(row?.updated_at).format('MM/DD/YYYY')}
				   </div>
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'updated' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'created' ? (
			   <td key={columnItem.value}
				   className={`${styles.createdTd}
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'created' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}>
				   <div style={{cursor: 'text'}}>
					   {moment(row?.created_at).format('MM/DD/YYYY')}
				   </div>
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'created' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'notes' ? (
			   <td key={columnItem.value}
				   className={`${styles.nocheckedListingCountd}
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'notes' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}>
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}} className={styles.catalogNotes}>
						{row!.newNotes !== undefined
						 ? bdecode(row!.newNotes)
						 : bdecode(unescapedRowMetadata[726]) || '-'}
					</div>
				   ) : (
					<textarea
					 className="myInputTextArea"
					 name="newNotes"
					 value={
						 row!.newNotes !== undefined
						  ? bdecode(row!.newNotes)
						  : bdecode(unescapedRowMetadata[726])
					 }
					 onChange={handleChangeEditInput}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'notes' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'keywords' ? (
			   <td key={columnItem.value}
					  className={`${styles.keywordsTd}
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'keywords' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				 
					  `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
				  >
				   <div
					style={{
						maxHeight: '200px',
						overflowY: 'auto',
						overflowX: 'hidden',
					}}
					id="keywords_tagify"
				   >
					   {!isBulkEdit ? (
						<div style={{cursor: 'text'}}>
							{row!.newKeywords ? (
							 <ul className={styles.keywordsWrapper}>
								 {row!.newKeywords?.map((item: any, index: number) => (
								  <li key={index}>
									  <span>{item?.value?.length ? item?.value : item}</span>
								  </li>
								 ))}
							 </ul>
							) : (
							 (
							  <ul className={styles.keywordsWrapper}>
								  {spanKeywords?.map((item: any, index: number) => (
								   <li key={index}>
									   <span>{bdecode(item)}</span>
								   </li>
								  ))}
							  </ul>
							 ) || '-'
							)}
						</div>
					   ) : (
						<Tagify saveActive={saveActive} setSaveActive={setSaveActive} setRetryActive={setRetryActive}
								retryActive={retryActive}
								keywordTags={decodedKeywordTags}
								index={index}
								tableData={tableData}
								setTableData={setTableData}
						/>
					   )}
				   </div>
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'keywords' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'description' ? (
			   <td key={columnItem.value}
					   className={`${styles.descriptionTd}
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'description' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
				   >
				   {!isBulkEdit ? (
					newTableInfo.newDescription.changed ? (
					 row!.newDescription?.length ? (
					  row!.newDescription
					 ) : (
					  '-'
					 )
					) : (
					 unescapedRowMetadata[505] || '-'
					)
				   ) : (
					<textarea
					 className="myInputTextArea"
					 name="newDescription"
					 value={
						 newTableInfo.newDescription.changed
						  ? newTableInfo.newDescription.text
						  : newTableInfo.newDescription.text ||
						  unescapedRowMetadata[505]
					 }
					 onChange={handleChangeEditInput}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'description' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'assignee' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'assignee' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >{row?.assignee ? row.assignee : '-'}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'assignee' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'sold price' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'sold price' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{row?.newSold_price !== undefined
						 ? `$${Number(row.newSold_price).toFixed(2)}`
						 : (unescapedRowMetadata[842] !== undefined
						  ? `$${Number(unescapedRowMetadata[842]).toFixed(2)}`
						  : '-')}
					</div>
				   ) : (
					<input
					 className="myInput  no-spinner"
					 type="text"
					 onKeyPress={handleKeyPress}
					 name="newSold_price"
					 value={
						 row?.newSold_price !== undefined
						  ? row!.newSold_price
						  : unescapedRowMetadata[842]
					 }
					 onChange={handleChangeEditInput}
					 onPaste={handlePaste}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'sold price' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'profit' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'profit' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				   
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {(() => {
					   const soldPrice = row!.newSold_price?.length
						? Number(row!.newSold_price)
						: Number(unescapedRowMetadata[842] || 0);
					   const shippingCost = row!.newShippingCost?.length
						? Number(row!.newShippingCost)
						: Number(unescapedRowMetadata[852] || 0);
					   const cogs = row!.newCogs?.length
						? Number(row!.newCogs)
						: Number(unescapedRowMetadata[847] || 0);
					   const fees = row!.newFees?.length
						? Number(row!.newFees)
						: Number(unescapedRowMetadata[844] || 0);

					   const profit = soldPrice - cogs - shippingCost - fees;

					   return `$${profit.toFixed(2)}`;
				   })()}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'profit' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'shipping cost' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'shipping cost' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{row?.newShippingCost !== undefined
						 ? `$${Number(row.newShippingCost).toFixed(2)}`
						 : (unescapedRowMetadata[852] !== undefined
						  ? `$${Number(unescapedRowMetadata[852]).toFixed(2)}`
						  : '-')}
					</div>
				   ) : (
					<input
					 className="myInput  no-spinner"
					 type="text"
					 onKeyPress={handleKeyPress}
					 name="newShippingCost"
					 value={
						 row?.newShippingCost !== undefined
						  ? row!.newShippingCost
						  : unescapedRowMetadata[852]
					 }
					 onChange={handleChangeEditInput}
					 onPaste={handlePaste}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'shipping cost' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'date sold' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'date sold' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				 
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{unescapedRowMetadata[846]
						 ? moment(unescapedRowMetadata[846]).format('MM/DD/YYYY')
						 : '-'}
					</div>
				   ) : (
					<DatePicker
					 selected={selectedDate}
					 onChange={handleChange}
					 name="newDateSold_date"
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'date sold' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'cogs' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'cogs' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				 
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{row?.newCogs !== undefined
						 ? `$${Number(row.newCogs).toFixed(2)}`
						 : (unescapedRowMetadata[847] !== undefined
						  ? `$${Number(unescapedRowMetadata[847]).toFixed(2)}`
						  : '-')}
					</div>
				   ) : (
					<input
					 className="myInput  no-spinner"
					 type="text"
					 onKeyPress={handleKeyPress}
					 name="newCogs"
					 value={
						 row?.newCogs !== undefined
						  ? row!.newCogs
						  : unescapedRowMetadata[847]
					 }
					 onChange={handleChangeEditInput}
					 onPaste={handlePaste}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'cogs' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'fees' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'fees' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{row?.newFees !== undefined
						 ? `$${Number(row.newFees).toFixed(2)}`
						 : (unescapedRowMetadata[844] !== undefined
						  ? `$${Number(unescapedRowMetadata[844]).toFixed(2)}`
						  : '-')}
					</div>
				   ) : (
					<input
					 className="myInput  no-spinner"
					 type="text"
					 onKeyPress={handleKeyPress}
					 name="newFees"
					 value={
						 row?.newFees !== undefined
						  ? row!.newFees
						  : unescapedRowMetadata[844]
					 }
					 onChange={handleChangeEditInput}
					 onPaste={handlePaste}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'fees' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'upc' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'upc' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				   
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{row?.newUpc !== undefined
						 ? bdecode(row!.newUpc)
						 : bdecode(unescapedRowMetadata[704]) || '-'}
					</div>
				   ) : (
					<textarea
					 className="myInputTextArea"
					 name="newUpc"
					 value={
						 row?.newUpc !== undefined
						  ? bdecode(row!.newUpc)
						  : bdecode(unescapedRowMetadata[704])
					 }
					 onChange={handleChangeEditInput}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'upc' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'drafts' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'drafts' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {!isBulkEdit ? (
					row?.newDraft !== undefined ? row?.newDraft ? <Drafted/> : '-' : unescapedRowMetadata[859.1] ?
					 <Drafted
						className={styles.draftIcon}
					 /> : '-'
				   ) : (
					<input
					 className="draftCheckbox"
					 type="checkbox"
					 name="newDraft"
					 style={{
						 cursor: 'pointer',
						 width: '30px',
					 }}
					 checked={
						 row?.newDraft !== undefined ? row?.newDraft ? true : false : unescapedRowMetadata[859.1] ? true : false
					 }
					 onChange={handleChangeEditInput}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'drafts' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'brand' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'brand' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				   
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{row?.newBrand !== undefined
						 ? bdecode(row!.newBrand)
						 : unescapedRowMetadata[206]?.replace(/&amp;/g, '') || '-'}
					</div>
				   ) : (
					<textarea
					 className="myInputTextArea"
					 name="newBrand"
					 value={
						 row?.newBrand !== undefined
						  ? row!.newBrand
						  : unescapedRowMetadata[206]?.replace(/&amp;/g, '')
					 }
					 onChange={handleChangeEditInput}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'brand' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'color' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'color' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				 `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
					 
				   }}
			   >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{row?.newColor !== undefined
						 ? bdecode(row!.newColor)
						 : bdecode(unescapedRowMetadata[209]) || '-'}
					</div>
				   ) : (
					<textarea
					 className="myInputTextArea"
					 name="newColor"
					 value={
						 row?.newColor !== undefined
						  ? bdecode(row!.newColor)
						  : bdecode(unescapedRowMetadata[209])
					 }
					 onChange={handleChangeEditInput}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'color' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'size' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'size' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{row?.newSize !== undefined
						 ? bdecode(row!.newSize)
						 : bdecode(unescapedRowMetadata[213]) || '-'}
					</div>
				   ) : (
					<textarea
					 className="myInputTextArea"
					 name="newSize"
					 value={
						 row?.newSize !== undefined
						  ? bdecode(row!.newSize)
						  : bdecode(unescapedRowMetadata[213])
					 }
					 onChange={handleChangeEditInput}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'size' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'material' ? (
			   <td key={columnItem.value}
				   className={`
				      ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'material' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
			   >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{row?.newMaterial !== undefined
						 ? bdecode(row!.newMaterial)
						 : bdecode(unescapedRowMetadata[82]) || '-'}
					</div>
				   ) : (
					<textarea
					 className="myInputTextArea"
					 name="newMaterial"
					 value={
						 row?.newMaterial !== undefined
						  ? bdecode(row!.newMaterial)
						  : bdecode(unescapedRowMetadata[82])
					 }
					 onChange={handleChangeEditInput}
					/>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'material' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  ) : columnItem.value === 'condition' ? (
			   <td key={columnItem.value}
					className={`${styles.conditionTd}
				    ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'condition' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				   
					`}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
					   
				   }}
				  >
				   {!isBulkEdit ? (
					<div style={{cursor: 'text'}}>
						{row?.newCondition !== undefined
						 ? row!.newCondition === 'None'
						  ? '-'
						  : row!.newCondition
						 : unescapedRowMetadata[15] || '-'}
					</div>
				   ) : (
					<select
					 style={{
						 fontSize:!isSmallScreenLaptop ? '16px' : '13px'
					 }}
					 name="condition"
					 id="dropdown"
					 value={
						 row?.newCondition !== undefined ? row!.newCondition : unescapedRowMetadata[15] || '-'
					 }
					 onChange={handleChangeEditInput}
					>
						<option
						 value="None">None</option>
						<option value="Pre-Owned">Pre-Owned</option>
						<option value="New">New</option>
					</select>
				   )}
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'condition' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			  )
				   : columnItem.value === 'condition 2' ? (
					   <td key={columnItem.value}
						   className={`${styles.conditionTd}
				    ${stickyColumnsList.some(
							   (obj: any) =>
								   obj.value?.toLowerCase() === 'condition 2' &&
								   obj.stickyColumn
						   ) ? styles.stickyColumnActive : ''}
				   
					`}
						   style={{
							   ...getStickyStyle(columnItem.value), // Dynamic styles

						   }}
					   >
						   {!isBulkEdit ? (
							   <div style={{cursor: 'text'}}>
								   {
									   row?.newCondition2New !== undefined || row?.newCondition2PreOwned !== undefined
										   ? row!.newCondition2New === 'None' && row!.newCondition2PreOwned === 'None'
											   ? '-'
											   : row!.newCondition2New || row!.newCondition2PreOwned
										   : unescapedRowMetadata[15] === 'New' ? unescapedRowMetadata[795] : unescapedRowMetadata[15] === 'Pre-Owned' ? unescapedRowMetadata[796] :  '-'
								   }
							   </div>
						   ) : (
							   <select
								   style={{
									   fontSize:!isSmallScreenLaptop ? '16px' : '13px'
								   }}
								   name="condition2"
								   id="dropdown"
								   value={
									   row?.newCondition2New !== undefined || row?.newCondition2PreOwned !== undefined
										   ? newTableInfo?.newCondition2New?.text ||  newTableInfo?.newCondition2PreOwned?.text
										   : unescapedRowMetadata[15] === 'New' ? unescapedRowMetadata[795] : unescapedRowMetadata[15] === 'Pre-Owned' ? unescapedRowMetadata[796] :  '-'
								   }
								   onChange={handleChangeEditInput}
							   >
								   {
									  newTableInfo?.newCondition?.text === 'New' || (unescapedRowMetadata[15] === 'New' && !newTableInfo?.newCondition?.changed) ?
										   <>
											   <option value="None New">None</option>
											   <option value="With Tags">With Tags</option>
											   <option value="With Box">With Box</option>
											   <option value="Without Tags">Without Tags</option>
											   <option value="Without Box">Without Box</option>
											   <option value="With Defects">With Defects</option>
										   </>
										   : newTableInfo?.newCondition?.text === 'Pre-Owned' || (unescapedRowMetadata[15] === 'Pre-Owned' && !newTableInfo?.newCondition?.changed) ?
											   <>
												   <option value="None Pre-Owned">None</option>
												   <option value="Like New">Like New</option>
												   <option value="Good">Good</option>
												   <option value="Fair">Fair</option>
												   <option value="Poor">Poor</option>

											   </>
											   :
											   <>
												   <option
													   value="None">None
												   </option>
											   </>
								   }
							   </select>
						   )}
						   {
							   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'condition 2' && obj.activeColumn) &&
							   <div
								   style={{
									   height: '100%',
									   position: 'absolute',
									   zIndex: 534534,
								   }}
								   onMouseDown={(e) => {
									   e.preventDefault(); // Prevent any default browser behavior
									   if (typeof mouseDown === "function") {
										   mouseDown(i); // Pass the index of the column you want to resize
									   }
									   // Add global mouseup fallback to ensure cleanup
									   const stopResize = () => {
										   const mouseUpEvent = new MouseEvent("mouseup");
										   window.dispatchEvent(mouseUpEvent);
										   window.removeEventListener("mouseup", stopResize); // Clean up
									   };
									   window.addEventListener("mouseup", stopResize);
								   }}
								   className={`resize-handle ${
									   activeIndex === i ? 'active' : 'idle'
								   } 
						 
						  `}
							   />
						   }
					   </td>
				   )
					   : columnItem.value === 'condition notes' ? (
				   <td key={columnItem.value}
					   className={`${styles.nocheckedListingCountd}
				      ${stickyColumnsList.some(
						   (obj: any) =>
							   obj.value?.toLowerCase() === 'condition notes' &&
							   obj.stickyColumn
					   ) ? styles.stickyColumnActive : ''}
				  
				   `}
					   style={{
						   ...getStickyStyle(columnItem.value), // Dynamic styles
					   }}>
					   {!isBulkEdit ? (
						   <div style={{cursor: 'text'}} className={styles.catalogNotes}>
							   {row!.newConditionNotes!== undefined
								   ? bdecode(row!.newConditionNotes)
								   : bdecode(unescapedRowMetadata[17]) || '-'}
						   </div>
					   ) : (
						   <textarea
							   className="myInputTextArea"
							   name="newConditionNotes"
							   value={
								   row!.newConditionNotes !== undefined
									   ? bdecode(row!.newConditionNotes)
									   : bdecode(unescapedRowMetadata[17])
							   }
							   onChange={handleChangeEditInput}
						   />
					   )}
					   {
						   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'condition notes' && obj.activeColumn) &&
						   <div
							   style={{
								   height: '100%',
								   position: 'absolute',
								   zIndex: 534534,
							   }}
							   onMouseDown={(e) => {
								   e.preventDefault(); // Prevent any default browser behavior
								   if (typeof mouseDown === "function") {
									   mouseDown(i); // Pass the index of the column you want to resize
								   }
								   // Add global mouseup fallback to ensure cleanup
								   const stopResize = () => {
									   const mouseUpEvent = new MouseEvent("mouseup");
									   window.dispatchEvent(mouseUpEvent);
									   window.removeEventListener("mouseup", stopResize); // Clean up
								   };
								   window.addEventListener("mouseup", stopResize);
							   }}
							   className={`resize-handle ${
								   activeIndex === i ? 'active' : 'idle'
							   } 
						 
						  `}
						   />
					   }
				   </td>
			   )
						   : columnItem.value === 'intended for' ? (
								   <td key={columnItem.value}
									   className={`${styles.conditionTd}
				                       ${stickyColumnsList.some(
										   (obj: any) =>
											   obj.value?.toLowerCase() === 'intended for' &&
											   obj.stickyColumn
									   ) ? styles.stickyColumnActive : ''}
				   
					                  `}
									   style={{
										   ...getStickyStyle(columnItem.value), // Dynamic styles

									   }}
								   >
									   {!isBulkEdit ? (
										   <div style={{cursor: 'text'}}>
											   {displayText}
										   </div>
									   ) : (
										   <select
											   style={{
												   fontSize: !isSmallScreenLaptop ? '16px' : '13px'
											   }}
											   name="intended for"
											   id="dropdown"
											   value={
												   row?.newIntendedFor !== undefined ? row!.newIntendedFor : unescapedRowMetadata[4] || '-'
											   }
											   onChange={handleChangeEditInput}
										   >
											   <option value="None">None</option>
											   <option value="Unisex">Unisex</option>
											   <option value="Mens">Men</option>
											   <option value="Womens">Women</option>
											   <option value="Unisex Kids">Unisex Kids</option>
											   <option value="Girls">Girls</option>
											   <option value="Boys">Boys</option>
											   <option value="Babys">Babies</option>
											   <option value="Maternity">Maternity</option>
											   <option value="Pets">Pets</option>
											   <option value="Cats">Cats</option>
											   <option value="Dogs">Dogs</option>
										   </select>
									   )}
									   {
										   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'intended for' && obj.activeColumn) &&
										   <div
											   style={{
												   height: '100%',
												   position: 'absolute',
												   zIndex: 534534,
											   }}
											   onMouseDown={(e) => {
												   e.preventDefault(); // Prevent any default browser behavior
												   if (typeof mouseDown === "function") {
													   mouseDown(i); // Pass the index of the column you want to resize
												   }
												   // Add global mouseup fallback to ensure cleanup
												   const stopResize = () => {
													   const mouseUpEvent = new MouseEvent("mouseup");
													   window.dispatchEvent(mouseUpEvent);
													   window.removeEventListener("mouseup", stopResize); // Clean up
												   };
												   window.addEventListener("mouseup", stopResize);
											   }}
											   className={`resize-handle ${
												   activeIndex === i ? 'active' : 'idle'
											   } 
						 
						  `}
										   />
									   }
								   </td>
							   )
						   : columnItem.value === 'shipping weight lb/kg' ? (
			   <td
				key={columnItem.value}
				className={`
				    ${stickyColumnsList.some(
					(obj: any) =>
						obj.value?.toLowerCase() === 'shipping weight lb/kg' &&
						obj.stickyColumn
				) ? styles.stickyColumnActive : ''}
				
				`}
				style={{
					...getStickyStyle(columnItem.value), // Dynamic styles
				}}
			   >
				   {
					   !isBulkEdit ? <div>{row?.['newShippingWeightlbkg'] || unescapedRowMetadata[2] || '-'}</div> : <input
						className="myInput  no-spinner"
						type={"text"}
						onKeyPress={handleKeyPress}
						name="newShippingWeightlbkg"
						value={
							row?.['newShippingWeightlbkg'] !== undefined
							 ? row!['newShippingWeightlbkg']
							 : unescapedRowMetadata[2]
						}
						onChange={handleChangeEditInput}
						onPaste={handlePaste}
					   />
				   }
				   {
					   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'shipping weight lb/kg' && obj.activeColumn) &&
					   <div
						   style={{
							   height: '100%',
							   position: 'absolute',
							   zIndex: 534534,
						   }}
						   onMouseDown={(e) => {
							   e.preventDefault(); // Prevent any default browser behavior
							   if (typeof mouseDown === "function") {
								   mouseDown(i); // Pass the index of the column you want to resize
							   }
							   // Add global mouseup fallback to ensure cleanup
							   const stopResize = () => {
								   const mouseUpEvent = new MouseEvent("mouseup");
								   window.dispatchEvent(mouseUpEvent);
								   window.removeEventListener("mouseup", stopResize); // Clean up
							   };
							   window.addEventListener("mouseup", stopResize);
						   }}
						   className={`resize-handle ${
							   activeIndex === i ? 'active' : 'idle'
						   } 
						 
						  `}
					   />
				   }
			   </td>
			   ) : columnItem.value === 'shipping weight oz/g' ? (
				  <td
				   key={columnItem.value}
				   className={`
				    ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'shipping weight oz/g' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				  
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
				  >
					  <div>{
						  !isBulkEdit ? <div>{row?.['newShippingWeightozg'] || unescapedRowMetadata[3] || '-'}</div> : <input
						   className="myInput  no-spinner"
						   type={"text"}
						   onKeyPress={handleKeyPress}
						   name="newShippingWeightozg"
						   value={
							   row?.['newShippingWeightozg'] !== undefined
								? row!['newShippingWeightozg']
								: unescapedRowMetadata[3]
						   }
						   onChange={handleChangeEditInput}
						   onPaste={handlePaste}
						  />
					  }</div>
					  {
						  stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'shipping weight oz/g' && obj.activeColumn) &&
						  <div
							  style={{
								  height: '100%',
								  position: 'absolute',
								  zIndex: 534534,
							  }}
							  onMouseDown={(e) => {
								  e.preventDefault(); // Prevent any default browser behavior
								  if (typeof mouseDown === "function") {
									  mouseDown(i); // Pass the index of the column you want to resize
								  }
								  // Add global mouseup fallback to ensure cleanup
								  const stopResize = () => {
									  const mouseUpEvent = new MouseEvent("mouseup");
									  window.dispatchEvent(mouseUpEvent);
									  window.removeEventListener("mouseup", stopResize); // Clean up
								  };
								  window.addEventListener("mouseup", stopResize);
							  }}
							  className={`resize-handle ${
								  activeIndex === i ? 'active' : 'idle'
							  } 
						 
						  `}
						  />
					  }
				  </td>
				 )
				: columnItem.value === 'shipping package length' ? (
				<td
				 key={columnItem.value}
				 className={`
				    ${stickyColumnsList.some(
					 (obj: any) =>
						 obj.value?.toLowerCase() === 'shipping package length' &&
						 obj.stickyColumn
				    ) ? styles.stickyColumnActive : ''}
				   
				 `}
				 style={{
					 ...getStickyStyle(columnItem.value), // Dynamic styles
				 }}
				>
					{
						!isBulkEdit ? <div>{row?.['newShippingPackageLength'] || unescapedRowMetadata[531] || '-'}</div> : <input
						 className="myInput  no-spinner"
						 type={"text"}
						 onKeyPress={handleKeyPress}
						 name="newShippingPackageLength"
						 value={
							 row?.['newShippingPackageLength'] !== undefined
							  ? row!['newShippingPackageLength']
							  : unescapedRowMetadata[531]
						 }
						 onChange={handleChangeEditInput}
						 onPaste={handlePaste}
						/>
					}
					{
						stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'shipping package length' && obj.activeColumn) &&
						<div
							style={{
								height: '100%',
								position: 'absolute',
								zIndex: 534534,
							}}
							onMouseDown={(e) => {
								e.preventDefault(); // Prevent any default browser behavior
								if (typeof mouseDown === "function") {
									mouseDown(i); // Pass the index of the column you want to resize
								}
								// Add global mouseup fallback to ensure cleanup
								const stopResize = () => {
									const mouseUpEvent = new MouseEvent("mouseup");
									window.dispatchEvent(mouseUpEvent);
									window.removeEventListener("mouseup", stopResize); // Clean up
								};
								window.addEventListener("mouseup", stopResize);
							}}
							className={`resize-handle ${
								activeIndex === i ? 'active' : 'idle'
							} 
						 
						  `}
						/>
					}
				</td>
				) : columnItem.value === 'shipping package width' ? (
				  <td
				   key={columnItem.value}
				   className={`
				    ${stickyColumnsList.some(
					   (obj: any) =>
						   obj.value?.toLowerCase() === 'shipping package width' &&
						   obj.stickyColumn
				   ) ? styles.stickyColumnActive : ''}
				   
				   `}
				   style={{
					   ...getStickyStyle(columnItem.value), // Dynamic styles
				   }}
				  >
					  {
						  !isBulkEdit ? <div>{row?.['newShippingPackageWidth'] || unescapedRowMetadata[532] || '-'}</div> :
						   <input
							className="myInput  no-spinner"
							type={"text"}
							onKeyPress={handleKeyPress}
							name="newShippingPackageWidth"
							value={
								row?.['newShippingPackageWidth'] !== undefined
								 ? row!['newShippingPackageWidth']
								 : unescapedRowMetadata[532]
							}
							onChange={handleChangeEditInput}
							onPaste={handlePaste}
						   />
					   }
					  {
						  stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'shipping package width' && obj.activeColumn) &&
						  <div
							  style={{
								  height: '100%',
								  position: 'absolute',
								  zIndex: 534534,
							  }}
							  onMouseDown={(e) => {
								  e.preventDefault(); // Prevent any default browser behavior
								  if (typeof mouseDown === "function") {
									  mouseDown(i); // Pass the index of the column you want to resize
								  }
								  // Add global mouseup fallback to ensure cleanup
								  const stopResize = () => {
									  const mouseUpEvent = new MouseEvent("mouseup");
									  window.dispatchEvent(mouseUpEvent);
									  window.removeEventListener("mouseup", stopResize); // Clean up
								  };
								  window.addEventListener("mouseup", stopResize);
							  }}
							  className={`resize-handle ${
								  activeIndex === i ? 'active' : 'idle'
							  } 
						 
						  `}
						  />
					  }
				   </td>
				 )
				 : columnItem.value === 'shipping package height' ? (
				   <td
					key={columnItem.value}
					style={{
						...getStickyStyle(columnItem.value), // Dynamic styles
					}}
					className={`
				    ${stickyColumnsList.some(
						(obj: any) =>
							obj.value?.toLowerCase() === 'shipping package height' &&
							obj.stickyColumn
					) ? styles.stickyColumnActive : ''}
					
					`}>
					   {
						   !isBulkEdit ? <div>{row?.['newShippingPackageHeight'] || unescapedRowMetadata[533] || '-'}</div> : <input
							className="myInput  no-spinner"
							type={"text"}
							onKeyPress={handleKeyPress}
							name="newShippingPackageHeight"
							value={
								row?.['newShippingPackageHeight'] !== undefined
								 ? row!['newShippingPackageHeight']
								 : unescapedRowMetadata[533]
							}
							onChange={handleChangeEditInput}
							onPaste={handlePaste}
						   />
					   }
					   {
						   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'shipping package height' && obj.activeColumn) &&
						   <div
							   style={{
								   height: '100%',
								   position: 'absolute',
								   zIndex: 534534,
							   }}
							   onMouseDown={(e) => {
								   e.preventDefault(); // Prevent any default browser behavior
								   if (typeof mouseDown === "function") {
									   mouseDown(i); // Pass the index of the column you want to resize
								   }
								   // Add global mouseup fallback to ensure cleanup
								   const stopResize = () => {
									   const mouseUpEvent = new MouseEvent("mouseup");
									   window.dispatchEvent(mouseUpEvent);
									   window.removeEventListener("mouseup", stopResize); // Clean up
								   };
								   window.addEventListener("mouseup", stopResize);
							   }}
							   className={`resize-handle ${
								   activeIndex === i ? 'active' : 'idle'
							   } 
						 
						  `}
						   />
					   }
				   </td>
				  )
				  : columnItem.value === 'zip' ? (
				   <td key={columnItem.value}
					   className={`
				       ${stickyColumnsList.some(
						   (obj: any) =>
							   obj.value?.toLowerCase() === 'zip' &&
							   obj.stickyColumn
					   ) ? styles.stickyColumnActive : ''}
					   
					   `}
					   style={{
						   ...getStickyStyle(columnItem.value), // Dynamic styles
					   }}>
					   {
						   !isBulkEdit ? <div>{row?.['newZip'] || unescapedRowMetadata[837]}</div> : <input
							className="myInput  no-spinner"
							type={"text"}
							onKeyPress={handleKeyPress}
							name="newZip"
							value={
								row?.['newZip'] !== undefined
								 ? row!['newZip']
							  : unescapedRowMetadata[837]
						 }
						 onChange={handleChangeEditInput}
						 onPaste={handlePaste}
						/>
					}
					   {
						   stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'zip' && obj.activeColumn) &&
						   <div
							   style={{
								   height: '100%',
								   position: 'absolute',
								   zIndex: 534534,
							   }}
							   onMouseDown={(e) => {
								   e.preventDefault(); // Prevent any default browser behavior
								   if (typeof mouseDown === "function") {
									   mouseDown(i); // Pass the index of the column you want to resize
								   }
								   // Add global mouseup fallback to ensure cleanup
								   const stopResize = () => {
									   const mouseUpEvent = new MouseEvent("mouseup");
									   window.dispatchEvent(mouseUpEvent);
									   window.removeEventListener("mouseup", stopResize); // Clean up
								   };
								   window.addEventListener("mouseup", stopResize);
							   }}
							   className={`resize-handle ${
								   activeIndex === i ? 'active' : 'idle'
							   } 
						 
						  `}
						   />
					   }
				</td>
				  ) : (
				   columnItem.value === 'actions' && (
					<td key={columnItem.value}
						style={{
							...getStickyStyle(columnItem.value), // Dynamic styles
						}}
						className={`
					    ${styles.actionsTd}
				        ${stickyColumnsList.some(
							(obj: any) =>
								obj.value?.toLowerCase() === 'actions' &&
								obj.stickyColumn
						) ? styles.stickyColumnActive : ''}
					
						`}
						>
						<div className={styles.actions}>
							{tab !== 'deleted' && (
								<Popover
									isOpen={isPopoverOpen}
									positions={['bottom']}
									reposition={true} // preferred positions by priority
									content={
										showTooltip ? (
											<div className="popover">
												<p>
													Crossposting is not enabled.{' '}
													<a
														style={{width: '100%', color: 'blue'}}
														target="_blank"
														href="https://help.listperfectly.com/en/articles/8913965"
													>
														Install
													</a>{' '}
													or activate extension to enable.{' '}
												</p>
											</div>
										) : (
											<div></div>
										)
									}
								>
									<CrossPost2
										onMouseEnter={hoverPopover}
										style={{
											height: !isSmallScreenLaptop ? '35px' : '28px',
											width: !isSmallScreenLaptop ? '35px' : '28px'
										}}
										onClick={handleShowTooltip}
										className="lp_custom_crosspost_icon lp_custom_crosspost_icon_disabled"
									/>
								</Popover>
							)}
							{tab !== 'deleted' && (
								<a
									className={styles.editSpan}
									onClick={(e) => {
										e.preventDefault();
										dispatch(setEditAddModalShow(true));
										dispatch(setEditAddModalId(row.id));
										const location = window.location;
										const searchParams = new URLSearchParams(location.search);
										searchParams.set('modal-listing-id', `${row.id}`);
										searchParams.set('modal', 'listing-edit-view');
										navigate(`?${searchParams.toString()}`);
									}}
									title="Edit"
									style={{borderColor: '#4db89e'}}
									target="_blank"
									href={`/listings/edit/${row.id}`}
								>
									<SVGNewPencil/>
								</a>
							)}
							{tab !== 'deleted' && (
								<a
									className={styles.pageSpan}
									target="_blank"
									onClick={() => {
										dispatch(setDuplicateListingId(row?.id));
										navigate('/listings/add');
									}}
									rel="noreferrer"
									title="Duplicate"
									style={{borderColor: '#1E3166'}}
								>
									<SVGNewSquares/>
								</a>
							)}

					  {/*{tab !== 'deleted' && (*/}
					  {/* <a*/}
						{/*className={styles.listSpan}*/}
						{/*target="_blank"*/}
						{/*onClick={() => {*/}
						{/*	if (!iframeLink.length) {*/}
						{/*		dispatch(setIframeName('template actions'));*/}
						{/*		dispatch(setShowIframe());*/}
						{/*		dispatch(iframeLoaderChanger(true));*/}
						{/*		dispatch(*/}
						{/*		 setIframeLink(*/}
						{/*		  `listperfectly.com/listings/templates/add/?entry_id=${row.id}`*/}
						{/*		 )*/}
						{/*		);*/}
						{/*	} else {*/}
						{/*		dispatch(*/}
						{/*		 setShowQuestionModal(*/}
						{/*		  `https://app.listperfectly.com/listings/templates/add/?entry_id=${row.id}`*/}
						{/*		 )*/}
						{/*		);*/}
						{/*		dispatch(*/}
						{/*		 setCandidantLinkPure(*/}
						{/*		  `listperfectly.com/listings/templates/add/?entry_id=${row.id}`*/}
						{/*		 )*/}
						{/*		);*/}
						{/*	}*/}
						{/*}}*/}
						{/*// href={`https://app.listperfectly.com/listings/templates/add/?entry_id=${row.id}`}*/}
						{/*rel="noreferrer"*/}
						{/*title="Template"*/}
						{/*style={{borderColor: '#1E3166'}}*/}
					  {/* >*/}
						{/*   <SVGNewTemplate/>*/}
					  {/* </a>*/}
					  {/*)}*/}
							<a
							 className={styles.templateListing}
							 href={`/templates/add?source=catalog&listing_id=${row.id}`}
							 onClick={(e) => {
								 e.preventDefault();
								 dispatch(setShowEditTemplateModal(true))
								 dispatch(setListingTemplateId(row.id))
							 }}
							 title="Add template"
							 style={{borderColor: '#1E3166'}}
							>
								<ViewTemplatesQuickLink/>
							</a>
					  {(hasBusiness || hasPro) && <a
					   title="Send to Sales & Analytics"
					   style={{borderColor: '#1E3166'}}
					   onClick={handleToggleShowSalesModal}
					  >
						  <ImportSales/>
					  </a>}
							{
								allowListingsDeletion === false ?
									<Tippy
										interactive={true}
										zIndex={9993}
										trigger="click"
										arrow={false}
										appendTo={tippyElementStickyColumnsRef.current || document.body}
										placement="bottom"
										content={
											<div>
												<p>
													Request access from primary account
												</p>
											</div>
										}>
										<a
											style={{borderColor: '#B6B6B6', cursor: "not-allowed"}}
										>
											<SVGNewTrash className="deleteIconDisabled"/>
										</a>

									</Tippy>
									: <>
										{row.status === 'active' ? (
											<a
												className={styles.trashSpan}
												onClick={handleChangeStatusToListing}
												title="Delete"
												style={{borderColor: '#CA1010'}}
											>
												<SVGNewTrash/>
											</a>
										) : (
											<a
												className={styles.recoverySpan}
												onClick={handleChangeStatusToListing}
												title="Restore"
											>
												<FaRotateLeft/>
											</a>
										)}
									</>
							}
				  </div>
						{
							stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === 'actions' && obj.activeColumn) &&
							<div
								style={{
									height: '100%',
									position: 'absolute',
									zIndex: 534534,
								}}
								onMouseDown={(e) => {
									e.preventDefault(); // Prevent any default browser behavior
									if (typeof mouseDown === "function") {
										mouseDown(i); // Pass the index of the column you want to resize
									}
									// Add global mouseup fallback to ensure cleanup
									const stopResize = () => {
										const mouseUpEvent = new MouseEvent("mouseup");
										window.dispatchEvent(mouseUpEvent);
										window.removeEventListener("mouseup", stopResize); // Clean up
									};
									window.addEventListener("mouseup", stopResize);
								}}
								className={`resize-handle ${
									activeIndex === i ? 'active' : 'idle'
								} 
						 
						  `}
							/>
						}
			  </td>
			 )
				 )
			 )}
		 </tr>
		 {isPlatformModalShow && (
			 <PlatformsModal saveActive={saveActive} setSaveActive={setSaveActive} setRetryActive={setRetryActive}
							 retryActive={retryActive}
							 setChangedPatforms={setChangedPatforms}
							 handleTogglePlatformsModalShow={handleTogglePlatformsModalShow}
							 lpPlatforms={lpPlatforms}
							 customMarketplaces={customMarketplacesTableRow}
						  setLpPlatforms={setLpPlatforms}
						  handleClick={handleClick}
						  main={false}
						  unescapedRowMetadata={unescapedRowMetadata}
						  tableData={tableData}
						  setTableData={setTableData}
						  index={index}
		  />
		 )}
		 {isVisibleSendToSalesModal &&
		  <SendToSalesModal
		   handleToggleShowSalesModal={handleToggleShowSalesModal}
		   tableData={tableData}
		   setTableData={setTableData}
		   listingItem={row}
		   handleChangeSuccess={handleChangeSuccess}
		   selectedListingsData={selectedListingsData}
		   setSelectedListingsData={setSelectedListingsData}
		  />}
		 {isSuccess && <SuccessModal
		  tableData={tableData}
		  handleChangeSuccess={handleChangeSuccess}
		  selectedListingsData={selectedListingsData}
		 />}
	 </>
	);
};

export default TableRow;
