import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import _ from "lodash";

import styles from './Stats.module.scss';
import StatsItem from './components/StatsItem';
import TwoLevelPieChart from '../../components/TwoLevelPieChart';

// Import services
import AuthService from "@services/Auth";
import OrdersService from "@services/Orders";
import useIsMobile from "@hooks/UseIsMobile";
import MobileSlider from "./components/MobileSlider";


const Stats = (props: any) => {
	const globalCustomMarketplaces = useSelector((state: any) => state.customMarketplaces.customMarketplaces);


	const get_color_by_marketplace = (marketplace : string) => {

		const marketplace_colors: any = {
			tradesy: '#000000',
			ebay: '#86B817',
			etsy: '#eb6d20',
			poshmark: '#b63a4d',
			mercari: '#5e6df2',
			amazon: '#ff9900',
			grailed: '#000000',
			depop: '#ff2300',
			kidizen: '#7ed0e0',
			shopify: '#96bf48',
			instagram: '#30618a',
			heroine: '#000000',
			vestiaire: '#ff9900',
			facebook: '#0165e1'
		};

		let marketplace_color = _.get(marketplace_colors, `${marketplace}`);
		if(!marketplace_color && marketplace.includes('891')){
			// TODO refactor later:
			const custom_marketplace = globalCustomMarketplaces.find((customMarket: any) => {
				return customMarket.platform.code[0] === marketplace;
			});
			marketplace_color = _.get(custom_marketplace, 'color');
		}
		if(!marketplace_color) marketplace_color = '#000000';
		return marketplace_color;

	};
	const get_name_by_marketplace = (marketplace : string) => {
		if(marketplace === 'ebay') return 'eBay';

		// TODO refactor later:
		if(marketplace.includes('891')){
			// TODO refactor later:
			const custom_marketplace = globalCustomMarketplaces.find((customMarket: any) => {
				return customMarket.platform.code[0] === marketplace;
			});
			return _.get(custom_marketplace, 'platform.title');
		}
		else return _.capitalize(marketplace);
	};


	const { filter_by } = props;


	// TODO temporarily modifying filter here
	const filter_by_modified = { ..._.pick(filter_by, ['date_sold_range']) };

	const [activeStatIndex,setActiveStatIndex] = useState(0)
	const [graphData, setGraphData] = useState<any>([]);
	const [dataForLegend, setDataForLegend] = useState<any>([]);

	const [stats, set_stats] : any = useState([]);

	const isMobile = useIsMobile()


	// If filter_by changes this use effect is fired
	// The useEffect hook will run once on component mount if filter_by is passed as a dependency, even if it hasn't changed yet. React executes useEffect initially to handle any side effects related to filter_by.
	useEffect(() => {

		const launch = async () => {

			const [
				total_sales_response,
				total_sales_taxes_response,
				total_fees_response,
				total_cogs_response,
				total_shipping_costs_response
			] = await Promise.all([
				OrdersService.metrics_total_price_of_orders_per_marketplace_list(filter_by_modified, AuthService.get_token()),
				OrdersService.metrics_total_sales_tax_of_orders_per_marketplace_list(filter_by_modified, AuthService.get_token()),
				OrdersService.metrics_total_fees_of_orders_per_marketplace_list(filter_by_modified, AuthService.get_token()),
				OrdersService.metrics_total_cogs_of_order_listings_per_marketplace_list(filter_by_modified, AuthService.get_token()),
				OrdersService.metrics_total_shipping_costs_of_orders_per_marketplace_list(filter_by_modified, AuthService.get_token())
			]);


			const is_entry_empty = (entry: any) => {
				const value = _.toFinite(_.get(entry, 'value'));
				return !!value;
			}

			let total_sales_response_data = _.get(total_sales_response, 'data') || [];
			total_sales_response_data = total_sales_response_data.filter((entry : any) => (is_entry_empty(entry)));
			const total_sales_response_data_sum_of_values = total_sales_response_data.reduce((sum: any, item: any) => sum + item.value, 0);

			let total_sales_taxes_response_data = _.get(total_sales_taxes_response, 'data') || [];
			total_sales_taxes_response_data = total_sales_taxes_response_data.filter((entry : any) => (is_entry_empty(entry)));
			const total_sales_taxes_response_data_sum_of_values = total_sales_taxes_response_data.reduce((sum: any, item: any) => sum + item.value, 0);

			let total_fees_response_data = _.get(total_fees_response, 'data') || [];
			total_fees_response_data = total_fees_response_data.filter((entry : any) => (is_entry_empty(entry)));
			const total_fees_response_data_sum_of_values = total_fees_response_data.reduce((sum: any, item: any) => sum + item.value, 0);

			let total_cogs_response_data = _.get(total_cogs_response, 'data') || [];
			total_cogs_response_data = total_cogs_response_data.filter((entry : any) => (is_entry_empty(entry)));
			const total_cogs_response_data_sum_of_values = total_cogs_response_data.reduce((sum: any, item: any) => sum + item.value, 0);

			let total_shipping_costs_response_data = _.get(total_shipping_costs_response, 'data') || [];
			total_shipping_costs_response_data = total_shipping_costs_response_data.filter((entry : any) => (is_entry_empty(entry)));
			const total_shipping_costs_response_data_sum_of_values = total_shipping_costs_response_data.reduce((sum: any, item: any) => sum + item.value, 0);



			const combined_expenses = _.chain([...total_cogs_response_data, ...total_shipping_costs_response_data,  ...total_fees_response_data, ...total_sales_taxes_response_data])
				.groupBy('marketplace')
				.map((items: any, marketplace: any) => ({
					marketplace,
					value: _.sumBy(items, 'value')
				}))
				.value();


			// Subtract the values
			const net_profit_data = total_sales_response_data.map((item: any) => {
				// Find the matching object in combined_expenses
				const combined_expenses_profit_item = _.find(combined_expenses, { marketplace: item.marketplace });

				// If a matching marketplace is found, subtract the values
				return { ...item, value: combined_expenses_profit_item ? item.value - combined_expenses_profit_item.value : item.value };
			});

			const net_profit_sum_values = total_sales_response_data_sum_of_values - total_cogs_response_data_sum_of_values - total_shipping_costs_response_data_sum_of_values - total_fees_response_data_sum_of_values - total_sales_taxes_response_data_sum_of_values;

			set_stats([
				{
					id: 1,
					title: 'Total Sales',
					price: total_sales_response_data_sum_of_values,
					data: total_sales_response_data,
					value:'all_sales'
				},
				{
					id: 2,
					title: 'Cost of Goods',
					price: total_cogs_response_data_sum_of_values,
					data: total_cogs_response_data,
					value:'cost_of_goods'
				},
				{
					id: 3,
					title: 'Shipping Cost Seller',
					price: total_shipping_costs_response_data_sum_of_values,
					data: total_shipping_costs_response_data,
					value:'shipping_costs'
				},
				{
					id: 4,
					title: 'Fees',
					price: total_fees_response_data_sum_of_values,
					data: total_fees_response_data,
					value:'fees'
				},
				{
					id: 5,
					title: 'Sales Tax',
					price: total_sales_taxes_response_data_sum_of_values,
					data: total_sales_taxes_response_data,
					value:'sales_taxes'
				},
				{
					id: 6,
					title: 'Net Profit',
					price: net_profit_sum_values,
					data: net_profit_data,
					value:'net_profit'
				}
			]);

		}
		launch();

	}, [filter_by]);

	// TODO temporary workaround to select value on the first launch
	useEffect(() => {
		if(stats.length) handleStatsClick({...stats[0], index: 0});
	}, [stats]);


	// TODO rename later.
	const handleStatsClick = (stat:any) => {
		const pre_data = _.find(stats, { value: stat.value });
		let data = _.get(pre_data, 'data');

		// Transform data
		let transformed_data = data
			.map((item: any) => ({
				name: get_name_by_marketplace(_.get(item, "marketplace")),
				color: get_color_by_marketplace(_.get(item, "marketplace")),
				value: _.get(item, "value"),
			}))
			// Sort by value descending
			.sort((item_1: any, item_2: any) => (_.get(item_2, 'value') - _.get(item_1, 'value')));

		// Sets list next to circle
		setDataForLegend(transformed_data) // setDataForLegend([{name: 'All Sales', value: 13}])

		// Sets circle data
		setGraphData(transformed_data); // setGraphData([{name: 'test', color: '#000', value: 13 }]);

		// Sets active tile
		setActiveStatIndex(stat.index);
	}

	return (
	 <div className={styles.statsWrapper}>
		 {isMobile ? (<MobileSlider stats={stats} handleStatsClick={handleStatsClick}/>) : null}

		 <TwoLevelPieChart
			 graphData={graphData}
			 dataForLegend={dataForLegend}
		 />

		 {!isMobile ? (<div className={styles.stats}>
				 <div>
					 <div className={styles.statsHeader}>
						 <h1>Stats</h1>
						 <span>Click to view chart details</span>
					 </div>
					 <div className={styles.statsColumnsWrapper}>
						 {stats.map((item: any, index: number) => (
						  <StatsItem
						   key={item.id}
						   title={item.title}
						   price={item.price}
						   data={item.data}
						   isActive={index === activeStatIndex}
						   handleStatsClick={handleStatsClick}
						   value={item.value}
						   index={index}
						  />
						 ))}
					 </div>
				 </div>
			 </div>) : null}
	 </div>
	);
};

export default Stats;
