import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';

import styles from "../../../../Table.module.scss";

import _ from "lodash";
import {MoonLoader} from "react-spinners";
import {useDispatch, useSelector} from "react-redux";

// Import config
import config from "@config";

// Import hooks
import useRandomNumber from "@hooks/UseRandomNumber";
import useBasicFuncs from "@hooks/useBasisFuncs";

// Import icons
import {
	FaImage,
	LinkedSVG,
	NotLinkedSvg,
	LPIconQuickLinkSVG,
	MarkSold,
	ThreeDots,
	NewDeleteIcon,
	FaRotateLeft
} from "@assets/icons";

// Import interfaces
import {INestedTableRowProps} from "@interfaces/index";

// Something related to redux
import {
	setListingViewId,
	setShowListingViewModal,
} from "../../../../../../../../redux/slices/NavigationSlice";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";
import {useNavigate} from "react-router-dom";
import PlatformsRow from "../../../../../../../../components/PlatformsRow";
import Tooltip from "../../../../../../../../components/UI/Tooltip";
import UseDetailedExtensionVersion from "@hooks/UseDetailedExtensionVersion";
import Tippy from "@tippyjs/react";
import UseExtensionVersion from "@hooks/useExtensionVersion";

const NestedTableRow = (props: INestedTableRowProps) => {

	const {
		itemTabledata,
		columns,
		trClass,
		handleToogleShowCatalogModalShow,
		buttonRef,
		isBulkEdit,
		newTableInfo,
		setNewTableInfo,
		tableData,
		setTableData,
		index,
		nestedIndex,
		setSelectedListing,
		retryActive,
		setRetryActive,
		saveActive,
		setSaveActive,
	 	hasBusiness,
		hasPro,
	 	is_role_allowed_master,
		stickyColumnsListSales,
		getStickyStyle,
		activeIndex,
	    mouseDown,
		shopifyName,
		shopifyURLFormat,
		hasExtensionInstall,
		handleListingUnlinking
	} = props ;
	const isSmallScreenLaptop = UseIsSmallLaptop() ;

	const dispatch = useDispatch()
    const navigate = useNavigate();
	const [dimensions, setDimensions] = useState<string | null>(null);
	const [loaderForImage, setLoaderForImage] = useState(true);
	const [isOpenMoreDropdown,setIsOpenMoreDropdown] = useState(false) ;


	const randomNumber = useRandomNumber()
	const { bdecode } = useBasicFuncs()

	const handleSendClickToMarkButton = () => {
		if (buttonRef?.current) {
			buttonRef?.current.click();
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const charCode = event.charCode;

		if ((charCode < 48 || charCode > 57) && charCode !== 46) {
			event.preventDefault();
		}
	};

	const handleToggleMoreDropdown = () => {
		setIsOpenMoreDropdown(!isOpenMoreDropdown)
	}

	useLayoutEffect(() => {
		const getImageDimensions = (src: string | undefined) => {
			try {
				if (src) {
					const img = new Image();
					img.src = `${config.orders_image_prefix}/${src}`;

					img.onload = () => {
						if (img.width >= img.height) {
							setDimensions('width');
						} else {
							setDimensions('height');
						}
						setLoaderForImage(false);
					};
				}
			} catch (e) {
				console.log('e');
				setLoaderForImage(false);
			}
		};
		getImageDimensions(_.get(itemTabledata,'thumbnail'));
	}, []);

	// TODO. This one looks like a copy of the similar function in parent component.
	const handleChangeEditInputOrdersListings = (e: | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>, listingIndex: number) => {
		const {name, value} = e.target;
		const updatedItems = [...tableData];
		if (retryActive) {
			setRetryActive(false)
		}
		if (!saveActive) {
			setSaveActive(true)
		}
		const updatedItem = {
			...updatedItems[index],
			listings: updatedItems[index].listings.map((listing: any, i: number) => {
				if (i === listingIndex) {
					return {
						...listing,
						[name]: value,
						multiItem: true, // Устанавливаем multiItem в true только при изменении данных в textarea внутри listings
					};
				}
				return listing;
			}),
		};

		const updatedTableData = updatedItems.map((item, idx) => {
			if (idx === index) {
				return updatedItem;
			}
			return item;
		});
		setNewTableInfo({
			...newTableInfo,
			[name]: {text: value, changed: true},
		});

		setTableData(updatedTableData);
	};

	const unescapedRowMetadataInnerListingFunc = (itemTableData: any) => {
		const innerListings: Record<string, any> = {};
		if (itemTableData?.catalog_metadata) {
			for (const key in itemTableData.catalog_metadata) {
				if (itemTableData.catalog_metadata.hasOwnProperty(key)) {
					innerListings[key] = _.get(itemTableData.catalog_metadata, key);
				}
			}
		}
		return innerListings;
	};

	const shopifyLinkLocal =
		shopifyName && shopifyURLFormat === 2
			? `https://admin.shopify.com/store/${shopifyName}/products/${_.get(
				unescapedRowMetadataInnerListingFunc(
					itemTabledata
				),
				'855'
			)}`
			: shopifyName && shopifyURLFormat === 1 ?
				`https://${shopifyName}.myshopify.com/admin/products/${_.get(
					unescapedRowMetadataInnerListingFunc(
						itemTabledata
					),
					'855'
				)}`
				: `https://.myshopify.com/admin/products/${_.get(
					unescapedRowMetadataInnerListingFunc(
						itemTabledata
					),
					'855'
				)}`;

	// TODO. Temporary solution for rendering table cells(tds). Start
	const render_table_td = (column_item: any) => {


		// Init
		let TD_Element_Content;
		let TD_Element_Props : any = {};
		const order_listing_property_code = column_item.value;
		// const new_order_listing_property_code = `new__${order_listing_property_code}`;

		// If bulk edit is NOT ACTIVATED.
		if(!isBulkEdit){

			if(order_listing_property_code === 'cogs'){
				TD_Element_Content = (itemTabledata[`newMultiItemCogs${itemTabledata.id}`]
					?.length ? (
					`$${Number(
						itemTabledata[`newMultiItemCogs${itemTabledata.id}`]
					).toFixed(2)}`
				) : itemTabledata[order_listing_property_code] ? (
					`$${Number(itemTabledata[order_listing_property_code])}`
				) : (
					'-'
				));
			}

			if(order_listing_property_code === 'quantity_sold'){
				TD_Element_Content = (
					itemTabledata[
						`newMultiItemQuantitySold${itemTabledata.id}`
						]?.length ? (
						itemTabledata[
							`newMultiItemQuantitySold${itemTabledata.id}`
							]
					) : itemTabledata[order_listing_property_code] ? (
						itemTabledata[order_listing_property_code]
					) : (
						'-'
					)
				);
			}


			if(order_listing_property_code === 'sku'){
				TD_Element_Content = (
					itemTabledata[`newMultiItemSku${itemTabledata.id}`]
						?.length >= 0 ? (
						itemTabledata[`newMultiItemSku${itemTabledata.id}`]
					) : itemTabledata[order_listing_property_code] ? (
						itemTabledata[order_listing_property_code]
					) : (
						'-'
					)
				);
			}

		}

		// If bulk edit is ACTIVATED.
		if(isBulkEdit){

			if(order_listing_property_code === 'cogs'){
				TD_Element_Content = (
					<input
						className="myInput  no-spinner"
						type="text"
						name={`newMultiItemCogs${itemTabledata.id}`}
						value={
							newTableInfo[`newMultiItemCogs${itemTabledata.id}`]
								?.changed
								? newTableInfo[
									`newMultiItemCogs${itemTabledata.id}`
									]?.text
								: newTableInfo[
								`newMultiItemCogs${itemTabledata.id}`
								]?.text || itemTabledata[order_listing_property_code]
						}
						onChange={(e) =>
							handleChangeEditInputOrdersListings(e, nestedIndex)
						}
					/>
				);

			}

			if(order_listing_property_code === 'quantity_sold'){
				TD_Element_Content = (

					<input
						className="myInput  no-spinner"
						type="text"
						name={`newMultiItemQuantitySold${itemTabledata.id}`}
						onKeyPress={handleKeyPress}
						value={
							newTableInfo[
								`newMultiItemQuantitySold${itemTabledata.id}`
								]?.changed
								? newTableInfo[
									`newMultiItemQuantitySold${itemTabledata.id}`
									]?.text
								: newTableInfo[
								`newMultiItemQuantitySold${itemTabledata.id}`
								]?.text || itemTabledata[order_listing_property_code]
						}
						onChange={(e) =>
							handleChangeEditInputOrdersListings(e, nestedIndex)
						}
					/>

				);
			}

			if(order_listing_property_code === 'sku'){
				TD_Element_Content = (
					<textarea
						className="myInputTextArea"
						name={`newMultiItemSku${itemTabledata.id}`}
						value={
							newTableInfo[`newMultiItemSku${itemTabledata.id}`]
								?.changed
								? newTableInfo[
									`newMultiItemSku${itemTabledata.id}`
									]?.text
								: newTableInfo[
								`newMultiItemSku${itemTabledata.id}`
								]?.text || itemTabledata[order_listing_property_code]
						}
						onChange={(e) =>
							handleChangeEditInputOrdersListings(e, nestedIndex)
						}
					/>
				);
			}

		}

		// Exceptions:
		if(order_listing_property_code === 'title'){
			TD_Element_Content = (<div>{bdecode(itemTabledata[order_listing_property_code])}</div>);
		}
		if(order_listing_property_code === 'quantity_available'){

			let quantity_available = _.get(itemTabledata, order_listing_property_code);
			if(quantity_available) quantity_available = _.toNumber(quantity_available);
			quantity_available = _.isFinite(quantity_available) ? quantity_available : '-';
			TD_Element_Content = (<div style={{cursor: 'text'}}>{quantity_available}</div>);

		}



		// Return generated cell(td)
		return (<td
			key={order_listing_property_code} {...TD_Element_Props}
			className={`
				    ${stickyColumnsListSales.some(
				(obj: any) =>
					obj.value?.toLowerCase() === order_listing_property_code &&
					obj.stickyColumn
			) ? 'stickyColumnActive' : ''}
				   `}
			style={{
				...getStickyStyle(order_listing_property_code), // Dynamic styles
			}}
		>
			{TD_Element_Content}
			{
				stickyColumnsListSales.some((obj: any) => obj.value?.toLowerCase() === order_listing_property_code.toLowerCase() && obj.activeColumn) &&
				<div
					style={{
						height: '100%',
						position: 'absolute',
						zIndex: 534534,
					}}
					onMouseDown={(e) => {
						e.preventDefault(); // Prevent any default browser behavior
						if (typeof mouseDown === "function") {
							mouseDown(columns.findIndex(option => option.value === order_listing_property_code)); // Pass the index of the column you want to resize
						}
						// Add global mouseup fallback to ensure cleanup
						const stopResize = () => {
							const mouseUpEvent = new MouseEvent("mouseup");
							window.dispatchEvent(mouseUpEvent);
							window.removeEventListener("mouseup", stopResize); // Clean up
						};
						window.addEventListener("mouseup", stopResize);
					}}
					className={`resize-handle ${
						activeIndex === columns.findIndex(option => option.value === order_listing_property_code) ? 'active' : 'idle'
					} 
						 
						  `}
				/>
			}
		</td>);

	};
	// TODO. Temporary solution for rendering table cells(tds). End

	const orderListingsCatalogIdForLpCustomExtension = itemTabledata ? itemTabledata.catalog_id : "" ;

	const isVisibleMarkSoldButton = UseExtensionVersion("0.71.0") ;


	useEffect(() => {
		const tableElement = document.getElementById("salesPageTable");

		if (isOpenMoreDropdown && tableElement) {
			const handleScroll = () => {
				handleToggleMoreDropdown()
			};

			tableElement.addEventListener("scroll", handleScroll);
			window.addEventListener("scroll", handleScroll, { passive: true });

			return () => {
				tableElement.removeEventListener("scroll", handleScroll);
				window.removeEventListener("scroll", handleScroll);
			};
		}
	}, [isOpenMoreDropdown]);
	return (
	 <tr
	  className={trClass}
	 >
		 <td className='stickyCheckboxColumn'></td>
		 {columns?.map((nestedColumnItem: any) => (
			 // TODO. that's just temporary workaround to keep code readable, later it needs to replaced:
			 (nestedColumnItem.value === 'title' ? (render_table_td(nestedColumnItem)) : null) ||
			 (nestedColumnItem.value === 'cogs' ? (render_table_td(nestedColumnItem)) : null) ||
			 (nestedColumnItem.value === 'quantity_sold' ? (render_table_td(nestedColumnItem)) : null) ||
			 (nestedColumnItem.value === 'quantity_available' ? (render_table_td(nestedColumnItem)) : null) ||
			 (nestedColumnItem.value === 'sku' ? (render_table_td(nestedColumnItem)) : null) ||
			 (<td key={nestedColumnItem.value}
				  className={`
				    ${stickyColumnsListSales.some(
					  (obj: any) =>
						  obj.value?.toLowerCase() === nestedColumnItem.value &&
						  obj.stickyColumn
				  ) ? 'stickyColumnActive' : ''}
				   `}
				  style={{
					  ...getStickyStyle(nestedColumnItem.value), // Dynamic styles
				  }}
			 >
				 {nestedColumnItem.value === 'actions' ? (
				  <div className={styles.actionButtons}>
					  {itemTabledata.catalog_id ? (
					   <Tooltip trigger={"mouseenter"}
								content={<div>Manage Linking</div>}
								placement={"bottom"}
								appendTo={document.getElementById("actionColumnBtnTippyBlock") as Element}
					   >
						  <div
						   className={`${styles.actionColumnSvg} ${styles.linkBtn}`}
						   onClick={() => {
							   setSelectedListing(itemTabledata);
							   handleToogleShowCatalogModalShow();
						   }}
						  >
							  <LinkedSVG/>
						  </div>
					  </Tooltip>
					  ) :
					   <Tooltip trigger={"mouseenter"}
								content={<div>Manage Linking</div>}
								placement={"bottom"}
								appendTo={document.getElementById("actionColumnBtnTippyBlock") as Element}
					   >
						   <div
							className={`${styles.actionColumnSvg} ${styles.unlinkBtn}`}
							onClick={() => {
								setSelectedListing(itemTabledata);
								handleToogleShowCatalogModalShow();
							}}
						   >
							   <NotLinkedSvg/>
						   </div>
					   </Tooltip>
					  }
					  {isVisibleMarkSoldButton && (
						_.get(unescapedRowMetadataInnerListingFunc(itemTabledata), '539.1') !== 'SOLD' &&
						_.get(itemTabledata, 'catalog_id') && (
						 hasExtensionInstall ?
						  <Tooltip
						   trigger={"mouseenter"}
						   content={<div>Mark Sold</div>}
						   placement={"bottom"}
						   appendTo={document.getElementById("actionColumnBtnTippyBlock") as Element}
						  >
						    <div
						     data-lp-custom-extension-order-listing-catalog_id={orderListingsCatalogIdForLpCustomExtension}
						     className={`${
						  	styles.actionColumnSvg
						     } lp_custom_extension_sales_page_mark_sold_button markSoldButton ${
						  	!_.isNil(_.get(itemTabledata, 'catalog_id')) && ((hasPro && is_role_allowed_master) || hasPro || hasBusiness)
						  	 ? 'linked' : ''}`}
						     onClick={handleSendClickToMarkButton}
						    >
						  	  <MarkSold/>
						    </div>
						  </Tooltip>
						  :
						  <Tooltip
						   trigger={"focus click"}
						   content={
						    <p>
						  	  Mark sold is not enabled. <a target="_blank"
						  								   href={'https://help.listperfectly.com/en/articles/8913965'}>Install</a> or
						  	  active
						  	  activate extension to enable.
						    </p>
						  }
						   placement={"bottom"}
						   appendTo={document.getElementById("actionColumnBtnTippyBlock") as Element}
						  >
						  <div
						   className={`${
							styles.actionColumnSvg
						   }  markSoldButtonDisabled`}
						   onClick={handleSendClickToMarkButton}
						  >
							  <MarkSold/>
						  </div>
						 </Tooltip>
						)
					  )
					  }
					  {!_.isNil(_.get(itemTabledata, 'catalog_id')) && (
					   <button
						ref={buttonRef}
						style={{
							visibility: 'hidden',
							height: 0,
							width: 0,
							padding: 0,
							margin: 0,
							border: 0,
							marginRight: -10,
						}}
						className="markSoldIcon"
					   >
					   </button>
					  )}

					  {itemTabledata.catalog_id && (
					   <Tooltip
						content={<div>More Actions</div>}
						placement="bottom"
						trigger="mouseenter"
						appendTo={document.getElementById('actionColumnBtnTippyBlock') as Element}
					   >
						   <Tippy
							arrow={false}
							popperOptions={{
								modifiers: [
									{
										name: 'offset',
										options: {
											offset: !isSmallScreenLaptop ? [-36, 10] : [-28, 8],
										},
									},
								],
							}}
							content={
								<>
									<div
									 className={"moreDropdownItem"}
									 onClick={() => {
										 handleListingUnlinking(itemTabledata.id);
										 handleToggleMoreDropdown();
									 }}
									>
										<NotLinkedSvg />
										<span>Unlink</span>
									</div>
								</>
							}
							interactive={true}
							visible={isOpenMoreDropdown}
							placement="bottom"
							onClickOutside={handleToggleMoreDropdown}
							appendTo={document.getElementById('actionColumnMoreDropdownTippyBlock') as Element}
						   >
							   <div className={`${styles.actionColumnSvg} ${styles.moreBtn}`} onClick={handleToggleMoreDropdown}>
								   <ThreeDots />
							   </div>
						   </Tippy>
					   </Tooltip>
					  )}

				  </div>
				 ) : nestedColumnItem.value === 'image' ? (
					 <div
						 key={nestedColumnItem.value}
						 className={styles.thumbnail}
						 style={{
							 display: 'flex',
							 alignItems: 'center',
							 justifyContent: 'center',
						 }}
					 >
						 {_.get(itemTabledata,'thumbnail') ? (
							 loaderForImage ? (
								 <MoonLoader
									 color={'#1e3166'}
									 loading={true}
									 size={18}
								 />
							 ) : (
								 <img
									 src={`${config.orders_image_prefix}/${_.get(itemTabledata,'thumbnail')}?rnd=${randomNumber}`}
									 alt={dimensions ? dimensions : ""}
									 style={{
										 width: dimensions === 'width' ? (!isSmallScreenLaptop ? '100px' : '80px') : 'auto',
										 height:
											 dimensions === 'height' ? (!isSmallScreenLaptop ? '100px' : '80px'): 'auto',
									 }}
								 />
							 )
						 ) : (
							 <FaImage/>
						 )}
					 </div>
				 ) : nestedColumnItem.value === 'linked' ? (
					 <div style={{cursor: 'text'}}>

						 {itemTabledata.catalog_id ? (
						  <Tooltip
						   trigger={"mouseenter"}
						   content={<div>View Linked Listing</div>}
						   placement={"bottom"}
						   appendTo={document.getElementById("actionColumnBtnTippyBlock") as Element}
						  >
							  <div
							   className={styles.linkedView}
							   onClick={(e: any) => {
								   e.preventDefault();
								   if (itemTabledata.catalog_id) {
									   const location = window.location;
									   const searchParams = new URLSearchParams(location.search);
									   searchParams.set('modal-listing-id', `${itemTabledata.catalog_id}`);
									   searchParams.set('modal', 'listing-view');
									   navigate(`?${searchParams.toString()}`);
									   dispatch(setShowListingViewModal(true));
									   dispatch(setListingViewId(Number(itemTabledata.catalog_id)));
								   }
							   }}
							  >
								  <LPIconQuickLinkSVG/>
								  <span>View</span>
							  </div>
						  </Tooltip>
						 ) : (
						  '-'
						 )}
					 </div>
				 ) : nestedColumnItem.value === 'platforms' ? (
				  <PlatformsRow
				   itemInfo={itemTabledata}
				   metadata={unescapedRowMetadataInnerListingFunc(itemTabledata)}
				   source="nestedRow"
				   shopifyLinkLocal={shopifyLinkLocal}
				   hasTitle={true}
				  />
				 ) : (
				  <div>{itemTabledata[nestedColumnItem.value]}</div>
				 )}
				 {
				  stickyColumnsListSales.some((obj: any) => obj.value?.toLowerCase() === nestedColumnItem.value.toLowerCase() && obj.activeColumn) &&
				  <div
				   style={{
					   height: '100%',
					   position: 'absolute',
					   zIndex: 534534,
				   }}
				   onMouseDown={(e) => {
							 e.preventDefault(); // Prevent any default browser behavior
							 if (typeof mouseDown === "function") {
								 mouseDown(columns.findIndex(option => option.value === nestedColumnItem.value)); // Pass the index of the column you want to resize
							 }
							 // Add global mouseup fallback to ensure cleanup
							 const stopResize = () => {
								 const mouseUpEvent = new MouseEvent("mouseup");
								 window.dispatchEvent(mouseUpEvent);
								 window.removeEventListener("mouseup", stopResize); // Clean up
							 };
							 window.addEventListener("mouseup", stopResize);
						 }}
						 className={`resize-handle ${
							 activeIndex === columns.findIndex(option => option.value === nestedColumnItem.value) ? 'active' : 'idle'
						 } 
						 
						  `}
					 />
				 }
			 </td>)


		 ))}
	 </tr>
	)
}

export default NestedTableRow
