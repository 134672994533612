import React from 'react';

import styles from './DownloadCSV.module.scss';
import bdecode from "../../../../utils/bdecode";

import {toast} from "react-toastify";
import ListingsService from "@services/Listings";
import Papa from "papaparse";
import {saveAs} from "file-saver";
import moment from "moment/moment";
import _ from "lodash";
import {CsvNavigationSVG} from "@assets/icons";




const DownloadCSV = (props: any) => {

    const {listingCount, filter_by, options={}, sort_by, token,download_csv_percentage,set_download_csv_percentage } = props;


    const handleDownloadCSVButtonClick = async () => {
        if(listingCount < 1){
            toast.warning(`No results found to download. Please adjust your filters and try again.`, {
                position: 'bottom-right',
                autoClose: 3000
            });
        }
        if(download_csv_percentage || listingCount < 1) return;

        toast.warning(`The CSV file is being prepared for download. Please do not refresh the page.`, {
            position: 'bottom-right',
            autoClose: 3000
        });

        try {


            // Fetch all orders
            const listings_list_all_response : any = await ListingsService.list_all({ parallel_threads: 3 }, filter_by, { offset: 0, limit: 100 }, { include_images: true }, sort_by, token, (progress: number) => {

                if(progress === 100){
                    toast.success(`The CSV file has been prepared and is now ready for download.`, {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                }
                // TODO add error handlers for failures
                if(progress === 100) progress = 0;
                set_download_csv_percentage(progress);

            });
            // console.log('listings_list_all_response', listings_list_all_response);


            // Init records[]
            let records : any = [];

            // Loop through all listings
            records = listings_list_all_response.map((listing : any) => {

                // Handle custom description field
                let field_854 = _.get(listing, 'metadata.854') || '';
                const tmp_el = document.createElement('pre');
                tmp_el.innerHTML = field_854;
                tmp_el.style.position = 'absolute';
                tmp_el.style.left = '-9999px';
                document.body.appendChild(tmp_el);

                // Normalize text content to NFC (Canonical Composition)
                field_854 = (tmp_el.innerText || tmp_el.textContent || '').normalize('NFC');

                document.body.removeChild(tmp_el);


                // Convert some properties to numbers
                let field_842 = _.get(listing, 'metadata.842'); // Sold price
                if(field_842) field_842 = _.toNumber(field_842);
                field_842 = _.isFinite(field_842) ? field_842 : 0;

                let field_852 = _.get(listing, 'metadata.852'); // Shipping
                if(field_852) field_852 = _.toNumber(field_852);
                field_852 = _.isFinite(field_852) ? field_852 : 0;

                let field_847 = _.get(listing, 'metadata.847'); // COGS
                if(field_847) field_847 = _.toNumber(field_847);
                field_847 = _.isFinite(field_847) ? field_847 : 0;

                let field_844 = _.get(listing, 'metadata.844'); // Fees
                if(field_844) field_844 = _.toNumber(field_844);
                field_844 = _.isFinite(field_844) ? field_844 : 0;

                // Calculate profit
                let field_profit = field_842 - field_852 - field_847 - field_844;

                // Handle images
                let thumbnail_url = '';
                let gallery_urls : any = [];
                const images = _.get(listing, 'images') || [];
                images.forEach((image : any) => {
                    const image_type = _.get(image, 'type');
                    let image_link = _.get(image, 'link');

                    // Add CDN
                    if(image_link.includes('https://s3.amazonaws.com/app1.listperfectly')){
                        image_link = image_link.replace('https://s3.amazonaws.com/app1.listperfectly', 'https://media.listperfectly.com')
                    }
                    if(image_type === 'thumbnail'){
                        thumbnail_url = image_link;
                        return;
                    }
                    gallery_urls.push(image_link);
                });

                // Handle date created
                const created_at = moment(_.get(listing, 'created_at')).format('YYYY-MM-DD HH:mm:ss');

                return {
                    'Date': created_at,
                    'Title': bdecode(_.get(listing, 'metadata.133') || ''),
                    'Main Image': thumbnail_url,
                    'Image Gallery': gallery_urls.join('\n'),
                    'UPC': _.get(listing, 'metadata.704') || '',
                    'Brand': bdecode(_.get(listing, 'metadata.206') || ''),
                    'Color': _.get(listing, 'metadata.209') || '',
                    'Size': _.get(listing, 'metadata.213') || '',
                    'Material': _.get(listing, 'metadata.82') || '',
                    'SKU': _.get(listing, 'metadata.1') || '',
                    'Price': _.get(listing, 'metadata.22'),
                    'Notes': bdecode(_.get(listing, 'metadata.726') || ''),
                    'Quantity': _.get(listing, 'metadata.851'),
                    'Description': bdecode(field_854),
                    'eBay': _.get(listing, 'metadata["539.2"]') || '',
                    'Etsy': _.get(listing, 'metadata["539.3"]') || '',
                    'Poshmark': _.get(listing, 'metadata["539.4"]') || '',
                    'Mercari': _.get(listing, 'metadata["539.5"]') || '',
                    'Tradesy': _.get(listing, 'metadata["539.6"]') || '',
                    'Amazon': _.get(listing, 'metadata["539.7"]') || '',
                    'Grailed': _.get(listing, 'metadata["539.8"]') || '',
                    'Facebook': _.get(listing, 'metadata["539.9"]') || '',
                    'Depop': _.get(listing, 'metadata["539.11"]') || '',
                    'Kidizen': _.get(listing, 'metadata["539.12"]') || '',
                    'Shopify': _.get(listing, 'metadata["539.13"]') || '',
                    'Vestiaire': _.get(listing, 'metadata["539.17"]') || '',
                    'SOLD': _.get(listing, 'metadata["539.1"]') || '',
                    'Cost of Goods': field_847,
                    'Sold Price': field_842,
                    'Shipping Cost': field_852,
                    'Other Costs': field_844,
                    'Sold Date': _.get(listing, 'metadata.846'),
                    'Profit': field_profit
                }


                // // Get order listings and type
                // const order_listings = _.get(order, 'listings') || [];
                // const order_type = order_listings.length > 1 ? 'multi' : 'single';
                //
                // // Handle certain properties
                // const shipping_status = _.get(order, 'shipping_status') || '';
                // const sales_tax = _.get(order, 'sales_tax') || 0;
                // const total_price = _.round( (_.get(order, 'total_price') || 0), 2);
                // const order_fees = _.get(order, 'fees') || 0;
                // const order_date_sold = moment(_.get(order, 'date_sold')).format('MM/DD/YYYY') || '';
                // const order_shipping_cost_seller = _.get(order, 'shipping_cost_seller') || 0;
                // const order_shipping_cost_buyer = _.get(order, 'shipping_cost_buyer') || 0;
                // const order_shop_id = _.get(order, 'shop_id') || '';
                //


            });

            // console.log(records, 'listings')



            const file_name = `ListPerfectly__Listings__${moment().format('ddd_MMM_DD_YYYY__HH_mm_ss')}.csv`;

            const csv = Papa.unparse(records, {
                quotes: true, // Wrap all fields in quotes
                quoteChar: '"', // Use double quotes
                delimiter: ",", // Use a comma as the delimiter
                newline: "\n", // Ensure consistent newlines
                escapeChar: '"', // Escape character
            });

            const blob = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, file_name);


        } catch (error) {
            console.error(error);
        }

    };


    const DownloadCSVButton = () => {
        return (
            <div className={`${styles.csvBtn} ${download_csv_percentage || listingCount < 1 ? styles.csvBtnDisabled : null}`} onClick={handleDownloadCSVButtonClick}>
                <CsvNavigationSVG className="settingsIcon"/>
            </div>
        );
    };

    return (
        <DownloadCSVButton/>
    );
};

export default DownloadCSV;
