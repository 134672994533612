import Select from 'react-select';
import styles from './ColumnSearch.module.scss';

import { useState } from 'react';
import { useLocation } from 'react-router';
import { joinArrayValues } from '../../../../../../utils/joinArrayValues';
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";
import {shippingStatusOptions} from "@assets/constants/reactSelectOptions";

interface columnName {
  name: string;
  handleGetColumnSearchDebounced: any;
  handleTogglePlatformsModalShow: any;
  activePlatforms: any;
}


const titleIcon =
  'https://cdn.listperfectly.com/apptest.listperfectly.com/globals/icons/shop_regular.svg';

const downUpIcon = `https://cdn.listperfectly.com/apptest.listperfectly.com/globals/icons/chevron_down_light.svg`;

function findOptionsByValues(value: string) {
  const option = shippingStatusOptions.find((option) => option.value === value);
  return option || { value: '', label: '-' }; // Default option if not found
}

const ColumnSearch = ({
  name,
  handleGetColumnSearchDebounced,
  handleTogglePlatformsModalShow,
  activePlatforms,
}: columnName) => {
  const isSmallScreenLaptop =UseIsSmallLaptop() ;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filterByParam = queryParams.get('filter_by');

  let decodedFilterBy;

  if (filterByParam) {
    try {
      decodedFilterBy = JSON.parse(decodeURIComponent(filterByParam));
      if (decodedFilterBy.hasOwnProperty('shipping_status')) {
        const shippingStatus = decodedFilterBy.shipping_status;
        const result = findOptionsByValues(shippingStatus);
        decodedFilterBy = result;
      } else {
        decodedFilterBy = {};
      }
    } catch (error) {
      console.log(error, 'error');
    }
  }

  const [selectedOption, setSelectedOption] = useState(
    JSON.stringify(decodedFilterBy) !== '{}'
      ? decodedFilterBy
      : {
          value: '',
          label: '-',
        }
  );

  const dropDownChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    handleGetColumnSearchDebounced('shipping_status', selectedOption.value);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#fff',
      color: '#1E3166',
      padding: 1,
      borderRadius: !isSmallScreenLaptop ? '4px' : '3px',
      cursor: 'pointer',
      zIndex: 1,
      minHeight: !isSmallScreenLaptop ? '39px' : '32px',
      height: !isSmallScreenLaptop ? '39px' : '32px',
      borderColor:'#D5D5D5',
      boxShadow: 'none',
      "&:hover": {
        borderColor: '#D5D5D5',
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: !isSmallScreenLaptop ? '39px' : '32px',
      padding: !isSmallScreenLaptop ? '0 6px' : '0 5px'
    }),
    input: (provided: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state:any) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: !isSmallScreenLaptop ? '39px' : '32px',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      minWidth: !isSmallScreenLaptop ? '200px' : '160px',
      border: '1px solid #1E3166',
      borderRadius: !isSmallScreenLaptop ? '8px' : '6px',
      paddingTop: !isSmallScreenLaptop ? '10px' : '8px',
      marginTop: !isSmallScreenLaptop ? '18px' : '14px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      fontWeight: 600,
      fontSize: !isSmallScreenLaptop ? '16px' : '13px',
    }),
  };



  return name === 'shipping_status' ? (
    <div style={{ width: '100%', padding: '0 10px' }}>
      <Select
        styles={customStyles}
        value={selectedOption}
        onChange={dropDownChange}
        options={shippingStatusOptions}
        isSearchable={false}
        placeholder={null}
        menuPlacement={'bottom'}
        menuPosition={'fixed'}
      />
    </div>
  ) : name === 'sold_on' ? (
    <div
      className={styles.platformsInput}
      onClick={() => handleTogglePlatformsModalShow('sold_on')}
    >
      <div className={styles.platformsContainer}>
        <div className={styles.titleContainer}>
          <div
            className={styles.titleIcon}
            style={{
              backgroundImage: `url(${titleIcon})`,
            }}
          ></div>
          <div
           className={styles.title}>
            {activePlatforms?.length
              ? joinArrayValues(activePlatforms)
              : 'Platforms'}
          </div>
          <div
            className={styles.downUpIcon}
            style={{
              backgroundImage: `url(${downUpIcon})`,
            }}
          ></div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default ColumnSearch;
