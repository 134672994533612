
import React, {ReactElement} from 'react' ;
import "./tooltip.scss"
import Tippy, {TippyProps} from "@tippyjs/react";

interface ITooltipProps {
	children: ReactElement;
	trigger: string;
	content: React.ReactNode;
	placement?:TippyProps["placement"];
	appendTo?:any
}

const Tooltip = (props:ITooltipProps) => {
	const {children,trigger,content, placement,appendTo} = props;
	return (
	 <div className={"local__tooltip"}>
		 <Tippy
		  interactive={true}
		  zIndex={9999}
		  arrow={true}
		  placement={placement ||  "top"}
		  appendTo={appendTo || "parent"}
		  trigger={trigger}
		  content={content}
		  onMount={(instance) => {
			  const handleScroll = () => instance.hide();

			  (instance as any)._scrollListener = handleScroll;
			  window.addEventListener('scroll', handleScroll);
		  }}

		  onHide={(instance) => {
			  if ((instance as any)._scrollListener) {
				  window.removeEventListener('scroll', (instance as any)._scrollListener);
				  delete (instance as any)._scrollListener;
			  }
		  }}
		  onClickOutside={(instance, event) => {
			  instance.hide();
		  }}
		 >
			 {children}
		 </Tippy>
	 </div>
	)
}

export default Tooltip
