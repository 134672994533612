import axios from 'axios';

let token: string = '';
const tokenFromLocalStorage = localStorage.getItem('lp_authorization_token');
if (tokenFromLocalStorage) {
  token = tokenFromLocalStorage;
} else {
  const tokenFromCookies = document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith('lp_authorization_token='));

  if (tokenFromCookies) {
    const tokenPrefix = 'lp_authorization_token=';
    token = tokenFromCookies.substring(tokenPrefix.length);
  } else {
    window.location.href = 'https://app.listperfectly.com/';
  }
}

export const instanceAxios = axios.create({
  baseURL: 'https://api.listperfectly.com/v1',
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

export const XinstanceAxios = axios.create({
  baseURL: 'https://xapi.listperfectly.com/v1',
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

export const instanceAxios2 = axios.create({
  baseURL: 'https://api.listperfectly.com',
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
});