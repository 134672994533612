import styles from "./MultiItemOrderMobileCard.module.scss";

import Select from "react-select";
import moment from "moment";

import OrderListingCard from "./components/OrderListingCard";
import {
	AmazonLogo, DeleteIcon, DepopLogo, EbayLogo, EtsyLogo, FacebookLogo,
	GrailedLogo, InstagramLogo, KidizenLogo, ListingPartyLogo, MercaryLogo, NotPlatformIcon, PoshmarkLogo,
	ShopifyLogo, VestiaireLogo
} from "@assets/icons";
import useMultiItemOrderMobileCardLogic from "./useMultiItemOrderMobileCardLogic";
import {shippingStatusDropdownOptions} from "../SingleItemOrderMobileCard/useSingleItemOrderMobileCardLogic";
import accounting from 'accounting';

const customStyles = {
	control: (provided: any) => ({
		...provided,
		backgroundColor: '#fff',
		color: '#1E3166',
		padding: 1,
		borderRadius: 8,
		cursor: 'pointer',
		zIndex: 1,
		minHeight: 39,
		height:39,
		borderColor:'#DDD',
		boxShadow: 'none',
		width:"100%"
	}),
	menuPortal: (provided: any) => ({
		...provided,
		zIndex:9999

	}),
	valueContainer: (provided: any) => ({
		...provided,
		height:39,
	}),

	input: (provided: any) => ({
		...provided,
		margin: '0px',
	}),
	indicatorSeparator: (state:any) => ({
		display: 'none',
	}),
	indicatorsContainer: (provided: any) => ({
		...provided,
		height:39,
	}),

	menu: (provided: any) => ({
		...provided,
		width: '100%',
		border: '1px solid #1E3166',
		borderRadius: 8,
		zIndex:9
	}),
	option: (provided: any, state: any) => ({
		...provided,
		cursor: 'pointer',
		fontWeight: 600,
		fontSize: 14,
	}),
};

interface IMultiItemOrderMobileCardProps {
	order:any;
	order_listings: any;
	tableData:any;
	setTableData:(tableData:any) => void;
	filter_by_url_json:any;
	metadataForInfiniteScroll:any;
}

const MultiItemOrderMobileCard = (props: IMultiItemOrderMobileCardProps) => {
	const { order,order_listings,tableData,filter_by_url_json,metadataForInfiniteScroll,setTableData } = props;
	const {shop_id,date_sold,total_price} = order;

	const { selectedOption, shippingStatusDropdownChange,handleDeleteForOneSale } = useMultiItemOrderMobileCardLogic({
		order,
		filter_by_url_json,
		metadataForInfiniteScroll,
		tableData,
		setTableData
	});

	const getMarketplaceLogo = (marketplace: string) => {
		const marketplaceLogos: Record<string, JSX.Element> = {
			ebay: <EbayLogo />,
			poshmark: <PoshmarkLogo />,
			mercari: <MercaryLogo />,
			etsy: <EtsyLogo />,
			facebook: <FacebookLogo />,
			vestiaire: <VestiaireLogo />,
			depop: <DepopLogo />,
			grailed: <GrailedLogo />,
			kidizen: <KidizenLogo />,
			instagram: <InstagramLogo />,
			amazon: <AmazonLogo />,
			shopify: <ShopifyLogo />,
			listing_party: <ListingPartyLogo />,
		};

		return marketplaceLogos[marketplace] ||  <div className={styles['multiItemOrderMobileCard__header-info-notPlatform']}><NotPlatformIcon/></div>;
	};

	return (
	 <>
		 <div className={styles['multiItemOrderMobileCard']}>
			 <div className={styles['multiItemOrderMobileCard__header']}>
				 <div className={styles['multiItemOrderMobileCard__header-title']}>
					 <div>Multi Item Order</div>
					 <div className={styles['multiItemOrderMobileCard__header-badge']}>{order_listings.length}</div>
				 </div>
				 <div className={styles['multiItemOrderMobileCard__header-info']}>
					 <div className={styles['multiItemOrderMobileCard__header-info-platform']}>
						 {getMarketplaceLogo(order.marketplace)}
					 </div>
					 <div className={styles['multiItemOrderMobileCard__header-info-details']}>
						 <div className={styles['multiItemOrderMobileCard__detail-item']}>
							 <div className={styles['multiItemOrderMobileCard__detail-label']}>
								 Shop
							 </div>
							 <div className={styles['multiItemOrderMobileCard__detail-value']}>
								 {shop_id || "-"}
							 </div>
						 </div>
						 <div className={styles['multiItemOrderMobileCard__detail-item']}>
							 <div className={styles['multiItemOrderMobileCard__detail-label']}>
								 Sold Price
							 </div>
							 <div
							  className={`${styles['multiItemOrderMobileCard__detail-value']} ${styles['multiItemOrderMobileCard__detail-value--green']}`}>
								 {total_price ? accounting.formatMoney(total_price, '$', 2)
								  : "-"}
							 </div>
						 </div>
						 <div className={styles['multiItemOrderMobileCard__detail-item']}>
							 <div className={styles['multiItemOrderMobileCard__detail-label']}>
								 Date Sold
							 </div>
							 <div className={styles['multiItemOrderMobileCard__detail-value']}>
								 {moment(date_sold).format('MM/DD/YYYY') || "-"}
							 </div>
						 </div>
					 </div>
				 </div>
				 <div className={styles['multiItemOrderMobileCard__header-selectBlock']}>
					 <div className={styles['multiItemOrderMobileCard__header-select']}>
						 <Select
						  styles={customStyles}
						  value={selectedOption}
						  options={shippingStatusDropdownOptions}
						  onChange={shippingStatusDropdownChange}
						  isSearchable={false}
						 />
					 </div>
					 <div className={styles['multiItemOrderMobileCard__header-delete']}
					 onClick={handleDeleteForOneSale}
					 >
						<DeleteIcon/>
					 </div>
				 </div>
			 </div>
			 <div className={styles['multiItemOrderMobileCard__order-listings']}>
				 {order_listings?.map((orderListing: any) => (
				  <OrderListingCard key={orderListing.id} orderListing={orderListing} total_price={total_price} order={order} filter_by_url_json={filter_by_url_json} metadataForInfiniteScroll={metadataForInfiniteScroll} tableData={tableData} setTableData={setTableData}
				  />
				 ))}
			 </div>
		 </div>

	 </>
	);
};

export default MultiItemOrderMobileCard;
