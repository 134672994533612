import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {FaSearch, LockIcon, SVGClearSort, SVGNewAsc, SVGNewDesc} from "../../../../assets/icons";
import _ from "lodash";
import {useLocation} from "react-router-dom";
import styles from "../../../CatalogPage/components/Table/Table.module.scss";
import {Popover} from "react-tiny-popover";
import Tippy from "@tippyjs/react";

interface ISortData {
    id: number;
    title: string;
}

const sortData: ISortData[] = [
    {
        id: Date.now(),
        title: 'ascending',
    },
    {
        id: Date.now(),
        title: 'descending',
    },
    {
        id: Date.now(),
        title: 'clear Sort',
    },
];


const TableResizeColumn = ({searchResult, directions, handleChangeDirection, key, col, i, mouseDown, id, innerRef, activeIndex, handleSearch, stickyColumnsListTemplates, setStickyColumnsListTemplates, getStickyStyle, tippyElementStickyColumnsRefTemplates}:any) => {
    const [isFocused, setIsFocused] = useState(false);
    const [cacher, setCacher] = useState(true);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sortRef = useRef<HTMLDivElement | null>(null);
    const [sortTollTipShow, setSortToolTipShow] = useState<boolean>(false);
    const direction = directions[id] || 'none';
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleSearchChange = (
     e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setCacher(false);
        handleSearch(e.target.name, e.target.value);
    };

    const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFocused(false);
        if (!event.target.value) {
            setIsFocused(false);
        }
    };

    const handleToggleSortToolTipShow = () => {
        setSortToolTipShow(!sortTollTipShow);
    };

    let decodedFilterBy: any;
    const functionGetValueFromUrl = (columnValue: string) => {
        const filterByParam = queryParams.get('filter_by');
        if (filterByParam) {
            try {
                let safeParam = filterByParam.replace(/%(?![0-9A-Fa-f]{2})/g, "%25");
                let decodedValue :any = decodeURIComponent(safeParam);
                const tempElement = document.createElement("textarea");
                tempElement.innerHTML = decodedValue;
                decodedValue = tempElement.value;
                decodedFilterBy = JSON.parse(decodedValue) || {};
            }
            catch (e){
                console.log(e, 'error')
            }
        }
        if (columnValue === 'title') {
            return _.get(decodedFilterBy, '133');
        } else if (columnValue === 'SKU') {
            return _.get(decodedFilterBy, '1');
        } else if (columnValue === 'price') {
            return _.get(decodedFilterBy, '22');
        } else if (columnValue === 'quantity') {
            return _.get(decodedFilterBy, '851');
        } else if (columnValue === 'notes') {
            return _.get(decodedFilterBy, '726');
        } else if (columnValue === 'platforms') {
            return _.get(decodedFilterBy, 'platforms');
        } else {
            return '';
        }
    };

    useEffect(() => {
        try{
            const savedName = localStorage.getItem("activeColumnNameTemplates");

            if (savedName) {
                // Update the state based on the saved name
                setStickyColumnsListTemplates((prevList: any) => {
                    // Find the index of the active column
                    const activeIndex = prevList.findIndex(
                        (item: any) => item.value?.toLowerCase() === savedName.toLowerCase()
                    );

                    // Map over the list to update activeColumn and stickyColumn
                    return prevList.map((item: any, index: number) => ({
                        ...item,
                        activeColumn: index === activeIndex,
                        stickyColumn: index <= activeIndex, // Make all previous and active columns sticky
                    }));
                });
            }
        }catch(e){
            console.log(e, 'error');
        }
    }, []);

    function updateActiveColumn(name: any) {
        try {
            // Retrieve the currently active column name from localStorage
            const currentActiveColumn = localStorage.getItem("activeColumnNameTemplates");

            // Determine if the clicked item is already active
            const isCurrentlyActive = currentActiveColumn?.toLowerCase() === name?.toLowerCase();

            if (isCurrentlyActive) {
                // If the clicked item is already active, deactivate it
                localStorage.removeItem("activeColumnNameTemplates");
                setStickyColumnsListTemplates((prevList: any) =>
                    prevList.map((item: any) => ({
                        ...item,
                        activeColumn: false,
                        stickyColumn: false,
                    }))
                );
            } else {
                // If the clicked item is not active, activate it
                localStorage.setItem("activeColumnNameTemplates", name);

                setStickyColumnsListTemplates((prevList: any) => {
                    // Find the index of the clicked item
                    const activeIndex = prevList.findIndex(
                        (item: any) => item.value?.toLowerCase() === name?.toLowerCase()
                    );

                    // Update the stickyColumn and activeColumn properties
                    return prevList.map((item: any, index: number) => ({
                        ...item,
                        activeColumn: index === activeIndex,
                        stickyColumn: index <= activeIndex, // Set stickyColumn true for active and preceding columns
                    }));
                });
            }
        }catch(e){
            console.log(e, 'error')
        }
    }

    useEffect(() => {
        functionGetValueFromUrl(col.value);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (sortRef.current && !sortRef.current?.contains(event.target as Node)) {
                setSortToolTipShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sortRef, setSortToolTipShow]);


  return (
    <>
        <th
            ref={innerRef}
            className="sticky-element"
            id={`${stickyColumnsListTemplates.some(
                (obj: any) =>
                    obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                    obj.stickyColumn
            ) ? 'sticky-image-col' : ''}`}
            style={{
                ...getStickyStyle(col.value), // Dynamic styles
                ...{
                    position: 'sticky',
                    top: '0',
                    background: '#F4F7FF',
                },
            }}
        >
            <div style={{display: 'flex', alignItems:"flex-start", gap:'10px'}}>
                {
                    (col.value === 'title' || col.value === 'updated') ?
                        <>
                            {
                                stickyColumnsListTemplates.some((obj: any) => obj.value?.toLowerCase() === col.value?.toLowerCase()) ?
                                    <div className="th-inner-container">
                                        <div
                                            className={`lock-icon ${
                                                stickyColumnsListTemplates.some(
                                                    (obj: any) =>
                                                        obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                                        obj.activeColumn
                                                )
                                                    ? 'sticky-column-active-template' // Replace 'sticky-class' with your desired class name
                                                    : ''
                                            }`}
                                            onClick={()=> updateActiveColumn(col.value) }>
                                            {
                                                !stickyColumnsListTemplates.some(
                                                    (obj: any) =>
                                                        obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                                        obj.activeColumn
                                                ) ?
                                                    <Tippy
                                                        interactive={true}
                                                        zIndex={9993}
                                                        trigger="mouseenter"
                                                        arrow={false}
                                                        appendTo={tippyElementStickyColumnsRefTemplates.current || document.body}
                                                        placement="bottom"
                                                        content={
                                                            <div>
                                                                Pin this column and all columns to its left to keep them in view while
                                                                scrolling.
                                                            </div>
                                                        }
                                                    >
                                                        <LockIcon/>
                                                    </Tippy> :
                                                    <Tippy
                                                        interactive={true}
                                                        zIndex={9993}
                                                        trigger="mouseenter"
                                                        arrow={false}
                                                        appendTo={tippyElementStickyColumnsRefTemplates.current || document.body}
                                                        placement="bottom"
                                                        content={
                                                            <div>
                                                                Click to unpin all pinned columns.
                                                            </div>
                                                        }
                                                    >
                                                        <LockIcon/>
                                                    </Tippy>
                                            }
                                        </div>
                                        <Popover
                                            isOpen={sortTollTipShow}
                                            positions={['bottom']}
                                            reposition={true}
                                            padding={15}
                                            containerStyle={{zIndex: '999'}}
                                            content={
                                                sortTollTipShow ? (
                                                    <div className="popover popoverCatalog" ref={sortRef}>
                                                        {sortData.map((item) => (
                                                            <div className={styles.popOverWrapper}>
                                                                <div
                                                                    className={styles.popoverSortItem}
                                                                    key={item.id}
                                                                    onClick={() => {
                                                                        handleChangeDirection(
                                                                            col.value,
                                                                            item.title === 'clear Sort'
                                                                                ? 'none'
                                                                                : item.title === 'ascending'
                                                                                    ? 'top'
                                                                                    : 'bottom'
                                                                        );

                                                                        handleToggleSortToolTipShow();
                                                                    }}
                                                                >
                                                                    <div
                                                                        className={styles['popoverSortItemIcon']}
                                                                    >
                                                                        {item.title === 'clear Sort' ? (
                                                                            <SVGClearSort/>
                                                                        ) : item.title === 'descending' ? (
                                                                            <SVGNewDesc/>
                                                                        ) : (
                                                                            <SVGNewAsc/>
                                                                        )}
                                                                    </div>
                                                                    <div>{item.title}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )
                                            }
                                        >
                <span
                    onClick={handleToggleSortToolTipShow}
                    style={{display: 'flex', alignItems: "flex-start", gap: '10px', cursor: 'pointer'}}
                >
                  <p>{col.heading}</p>

                    {(col.value === 'title' || col.value === 'updated') && direction !== 'none' && (
                        <div className={styles.selectedSortView}>
                            {direction === 'bottom' ? (
                                <div className={styles.caretUp}>
                                    <SVGNewDesc/>
                                </div>
                            ) : (
                                <SVGNewAsc/>
                            )}
                        </div>
                    )}
                </span>
                                        </Popover>
                                    </div> :
                                    <Popover
                                        isOpen={sortTollTipShow}
                                        positions={['bottom']}
                                        reposition={true}
                                        padding={15}
                                        containerStyle={{zIndex: '999'}}
                                        content={
                                            sortTollTipShow ? (
                                                <div className="popover popoverCatalog" ref={sortRef}>
                                                    {sortData.map((item) => (
                                                        <div className={styles.popOverWrapper}>
                                                            <div
                                                                className={styles.popoverSortItem}
                                                                key={item.id}
                                                                onClick={() => {
                                                                    handleChangeDirection(
                                                                        col.value,
                                                                        item.title === 'clear Sort'
                                                                            ? 'none'
                                                                            : item.title === 'ascending'
                                                                                ? 'top'
                                                                                : 'bottom'
                                                                    );

                                                                    handleToggleSortToolTipShow();
                                                                }}
                                                            >
                                                                <div
                                                                    className={styles['popoverSortItemIcon']}
                                                                >
                                                                    {item.title === 'clear Sort' ? (
                                                                        <SVGClearSort/>
                                                                    ) : item.title === 'descending' ? (
                                                                        <SVGNewDesc/>
                                                                    ) : (
                                                                        <SVGNewAsc/>
                                                                    )}
                                                                </div>
                                                                <div>{item.title}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div></div>
                                            )
                                        }
                                    >
                <span
                    onClick={handleToggleSortToolTipShow}
                    style={{display: 'flex', alignItems: "flex-start", gap: '10px', cursor: 'pointer'}}
                >
                  <p>{col.heading}</p>

                    {(col.value === 'title' || col.value === 'updated') && direction !== 'none' && (
                        <div className={styles.selectedSortView}>
                            {direction === 'bottom' ? (
                                <div className={styles.caretUp}>
                                    <SVGNewDesc/>
                                </div>
                            ) : (
                                <SVGNewAsc/>
                            )}
                        </div>
                    )}
                </span>
                                    </Popover>
                            }
                        </>
                     :
                      <>
                          {
                              stickyColumnsListTemplates.some((obj: any) => obj.value?.toLowerCase() === col.value?.toLowerCase()) ?
                                  <div className="th-inner-container">
                                      <div
                                          className={`lock-icon ${
                                              stickyColumnsListTemplates.some(
                                                  (obj: any) =>
                                                      obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                                      obj.activeColumn
                                              )
                                                  ? 'sticky-column-active-template' // Replace 'sticky-class' with your desired class name
                                                  : ''
                                          }`}
                                          onClick={()=> updateActiveColumn(col.value) }
                                      >
                                          {
                                              !stickyColumnsListTemplates.some(
                                                  (obj: any) =>
                                                      obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                                      obj.activeColumn
                                              ) ?
                                                  <Tippy
                                                      interactive={true}
                                                      zIndex={9993}
                                                      trigger="mouseenter"
                                                      arrow={false}
                                                      appendTo={tippyElementStickyColumnsRefTemplates.current || document.body}
                                                      placement="bottom"
                                                      content={
                                                          <div>
                                                              Pin this column and all columns to its left to keep them in view while
                                                              scrolling.
                                                          </div>
                                                      }
                                                  >
                                                      <LockIcon/>
                                                  </Tippy> :
                                                  <Tippy
                                                      interactive={true}
                                                      zIndex={9993}
                                                      trigger="mouseenter"
                                                      arrow={false}
                                                      appendTo={tippyElementStickyColumnsRefTemplates.current || document.body}
                                                      placement="bottom"
                                                      content={
                                                          <div>
                                                              Click to unpin all pinned columns.
                                                          </div>
                                                      }
                                                  >
                                                      <LockIcon/>
                                                  </Tippy>
                                          }
                                      </div>
                                      <p> {col.heading} </p>
                                  </div> :
                                  <p> {col.heading} </p>
                          }
                      </>
                }
            </div>
            <div
                style={{
                    height: '100%',
                    position: 'absolute',
                    zIndex: 534534,
                }}
                onMouseDown={() => mouseDown(i)}
                className={`resize-handle ${
                    activeIndex === i ? 'active' : 'idle'
                }`}
            />
            {
             col.value === 'title' &&
             <div style={{position: 'relative', width:'100%'}}>
                 <input className="myInputCatalog"
                        onChange={handleSearchChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        name={col.value}
                        value={
                         (searchResult?.find((item:any) => item?.name?.toLowerCase() === 'title')?.value) ||
                         (cacher && functionGetValueFromUrl(col.value)) ||
                         ''
                        }
                        maxLength={140}

                        />
                 {!isFocused &&
                  !(searchResult?.find((item:any) => item?.name?.toLowerCase() === 'title')?.value) &&
                  !functionGetValueFromUrl(col.value)  && (
                   <div className="myInputSearchIcon">
                       <FaSearch/>
                   </div>
                  )}
             </div>
            }
        </th>
    </>
  )
}

export default TableResizeColumn;