export interface IPlatform {
  id: number;
  platform: {
    title: string;
    isChecked: boolean;

    code?: string[];
    platformId?: string;
  };
  notOnPlatform: {
    title: string;
    isChecked: boolean;
    code?: string[];
  };
}

export const platforms: IPlatform[] = [
  {
    id: 1,
    platform: {
      title: 'eBay',
      isChecked: false,
      code: ['539.2'],
      platformId: '618',
    },
    notOnPlatform: {
      title: 'Not on eBay',
      isChecked: false,
      code: ['539.2not'],
    },
  },
  {
    id: 2,
    platform: {
      title: 'Etsy',
      isChecked: false,
      code: ['539.3'],
      platformId: '619',
    },
    notOnPlatform: {
      title: 'Not on Etsy',
      isChecked: false,
      code: ['539.3not'],
    },
  },
  {
    id: 3,
    platform: {
      title: 'Poshmark',
      isChecked: false,
      code: ['539.4'],
      platformId: '620',
    },
    notOnPlatform: {
      title: 'Not on Poshmark',
      isChecked: false,
      code: ['539.4not'],
    },
  },
  {
    id: 4,
    platform: {
      title: 'Mercari',
      isChecked: false,
      code: ['539.5'],
      platformId: '621',
    },
    notOnPlatform: {
      title: 'Not on Mercari',
      isChecked: false,
      code: ['539.5not'],
    },
  },
  // {
  //   id: Date.now(),
  //   platform: {
  //     title: 'Tradesy',
  //     isChecked: false,
  //     code: ['539.6'],
  //     platformId: '833',
  //   },
  //   notOnPlatform: {
  //     title: 'Not on Tradesy',
  //     isChecked: false,
  //   },
  // },
  {
    id: 5,
    platform: {
      title: 'Amazon',
      isChecked: false,
      code: ['539.7'],
      platformId: '836',
    },
    notOnPlatform: {
      title: 'Not on Amazon',
      isChecked: false,
      code: ['539.7not'],
    },
  },
  {
    id: 6,
    platform: {
      title: 'Grailed',
      isChecked: false,
      code: ['539.8'],
      platformId: '839',
    },
    notOnPlatform: {
      title: 'Not on Grailed',
      isChecked: false,
      code: ['539.8not'],
    },
  },
  {
    id: 7,
    platform: {
      title: 'Facebook',
      isChecked: false,
      code: ['539.9'],
      platformId: '840',
    },
    notOnPlatform: {
      title: 'Not on Facebook',
      isChecked: false,
      code: ['539.9not'],
    },
  },
  {
    id: 8,
    platform: {
      title: 'Depop',
      isChecked: false,
      code: ['539.11'],
      platformId: '841',
    },
    notOnPlatform: {
      title: 'Not on Depop',
      isChecked: false,
      code: ['539.11not'],
    },
  },
  {
    id:9,
    platform: {
      title: 'Kidizen',
      isChecked: false,
      code: ['539.12'],
      platformId: '853',
    },
    notOnPlatform: {
      title: 'Not on Kidizen',
      isChecked: false,
      code: ['539.12not'],
    },
  },
  {
    id: 10,
    platform: {
      title: 'Shopify',
      isChecked: false,
      code: ['539.13'],
      platformId: '855',
    },
    notOnPlatform: {
      title: 'Not on Shopify',
      isChecked: false,
      code: ['539.13not'],
    },
  },
  {
    id: 11,
    platform: {
      title: 'Instagram',
      isChecked: false,
      code: ['539.14'],
      platformId: '856',
    },
    notOnPlatform: {
      title: 'Not on Instagram',
      isChecked: false,
      code: ['539.14not'],
    },
  },
  // {
  //   id: Date.now(),
  //   platform: {
  //     title: 'Heroine',
  //     code: ['539.15'],
  //     isChecked: false,
  //   },
  //   notOnPlatform: {
  //     title: 'Not on Heroine',
  //     isChecked: false,
  //   },
  // },

  {
    id: 12,
    platform: {
      title: 'Vestiaire',
      isChecked: false,
      code: ['539.17'],
      platformId: '885',
    },
    notOnPlatform: {
      title: 'Not on Vestiaire',
      isChecked: false,
      code: ['539.17not'],
    },
  },
];
