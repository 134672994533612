import _ from 'lodash';
import async from 'async';

// Import config
import config from "../config";

// Import API clients
import LP_API_Client from './LP_API_Client';
import Non_LP_API_Client from './Non_LP_API_Client';


class Listings {

    add(token : string, payload: any){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url_x}/v1/listings/add`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(payload) _.set(params, 'data.payload', payload);


            // Return result
            LP_API_Client(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    tools_barcode_scanner_add(token : any, payload:any, options: any) {
        return new Promise(async (resolve, reject) => {
            // Init root params
            const params: any = {
                method: 'POST',
                url: `${config.base_url_x}/v1/listings/tools/barcode-scanner/add`,
                data: {
                    data: payload,
                    options
                },
                headers: {Authorization: `Bearer ${token}`}
            };

            // Return result
            LP_API_Client(params)
                .then((res: any) => {
                    const data: any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err: any) => {
                    return reject(err);
                });
        });
    }
    tools_generator_add(token : any, payload:any, options: any) {
        return new Promise(async (resolve, reject) => {
            // Init root params
            const params: any = {
                method: 'POST',
                url: `${config.base_url_x}/v1/listings/tools/generator/add`,
                data: {
                    data: payload,
                    options
                },
                headers: {Authorization: `Bearer ${token}`}
            };

            // Return result
            LP_API_Client(params)
            .then((res: any) => {
                const data: any = _.get(res, 'data');
                return resolve(data);
            })
            .catch((err: any) => {
                return reject(err);
            });
        });
    }
    tools_quotas_list(token:any, filter_by : any){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v1/listings/tools/quotas/list`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);

            // Return result
            LP_API_Client(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }
    metadata_update(filter_by: any, payload:any, token: any) {
        return new Promise(async (resolve, reject) => {
            // Init root params
            const params: any = {
                method: 'PUT',
                url: `${config.base_url}/v1/listings/metadata/update`,
                data: {
                    filter_by,
                    payload,
                },
                headers: {Authorization: `Bearer ${token}`}
            };

            // Return result
            LP_API_Client(params)
            .then((res: any) => {
                const data: any = _.get(res, 'data');
                return resolve(data);
            })
            .catch((err: any) => {
                return reject(err);
            });
        });
    }
    update(filter_by: any, payload:any, token: any) {
        return new Promise(async (resolve, reject) => {
            // Init root params
            const params: any = {
                method: 'PUT',
                url: `${config.base_url}/v1/listings/update`,
                data: {
                    filter_by,
                    payload,
                },
                headers: {Authorization: `Bearer ${token}`}
            };

            // Return result
            LP_API_Client(params)
                .then((res: any) => {
                    const data: any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err: any) => {
                    return reject(err);
                });
        });
    }

    list(filter_by : any, metadata : any, options: any, sort_by: any, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url_x}/v1/listings/list`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);
            if(metadata) _.set(params, 'data.metadata', metadata);
            if(options) _.set(params, 'data.options', options);
            if(sort_by) _.set(params, 'data.sort_by', sort_by);

            // Return result
            LP_API_Client(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }
    list_all(params: any, filter_by : any, metadata : any, options: any, sort_by: any, token : string, callback: any){

        const self = this;
        return new Promise(async (resolve, reject) => {

            try {

                // Function which calculates percentage
                const calculate_fetched_percentage = (total : number, completed : number) => {
                    return _.round((completed / total) * 100, 2);
                };

                // Init array to store all records
                let records : any = [];

                // Get list_all method params
                const parallel_threads =  _.get(params, 'parallel_threads');

                // Get the metadata limit
                const limit = _.get(metadata, 'limit');

                // Send a request to fetch the first set of records and get metadata
                const first_response = await self.list(filter_by, metadata, options, sort_by, token);
                const first_response_total = _.get(first_response, 'metadata.total');
                const first_response_records = _.get(first_response, 'data') || [];

                // Return empty response if there is no data to fetch
                if(!first_response_total) return resolve([]);


                // Exclude already fetched records from total
                const remaining_total = first_response_total - first_response_records.length;

                // Add the first set of records to records[]
                records = [...records, ...first_response_records];

                // Report back the fetched percentage.
                callback(calculate_fetched_percentage(first_response_total, records.length));


                // Creating an array with list of pages to loop through
                const pages = new Array( Math.ceil(remaining_total / limit) ).fill(undefined);

                // Loop through pages and fetch the remaining records
                async.eachOfLimit(pages, parallel_threads, async (a : any, i : any) => {

                    const offset = limit * (i + 1); // Add 1 to exclude the already fetched first page
                    const new_metadata = { ...metadata, offset }; // Set new offset to metadata

                    // Sending request to list records and return result
                    const response = await self.list(filter_by, new_metadata, options, sort_by, token);
                    const response_records = _.get(response, 'data') || [];
                    records = [...records, ...response_records];

                    // Report back the fetched percentage.
                    callback(calculate_fetched_percentage(first_response_total, records.length));


                }, (err : any) => {
                    if (err) return reject(err);
                    return resolve(records);
                });


            } catch (e){
                return reject(e);
            }

        });

    }

    generate_sku_for_listing (token : any, payload:any) {
        return new Promise(async (resolve, reject) => {
            // Init root params
            const params: any = {
                method: 'POST',
                url: `${config.base_url_app}/wp-json/lpcustom/v2/sku-id/add`,
                data: payload,
                headers: {Authorization: `Bearer ${token}`}
            };

            // Return result
            Non_LP_API_Client(params)
            .then((res: any) => {
                const data: any = _.get(res, 'data');
                return resolve(data);
            })
            .catch((err: any) => {
                return reject(err);
            });
        });
    }
}

export default new Listings();
