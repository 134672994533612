import { useState, useEffect, ChangeEvent } from 'react';
import Select from 'react-select';
import styles from './MobileSearch.module.scss';
import {CloseRanges, PlatformIconShop, PlusSolid, RangesCalendar, RangesCalendarGreen} from '../../../../assets/icons';
import { useLocation } from 'react-router';
import _ from 'lodash';
import Tippy from "@tippyjs/react";
import DatePicker from "react-datepicker";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";
import useRoles from "@hooks/useRoles";

const MobileSearch = ({
  handleInputChange,
  inputValues,
  handleTogglePlatformsModalShow,
  filterBy,
  setFilterBy,
  handleFetchForSearch,
  searchItem,
  setSearchItem,
  isMobile,
  handleToggleDateRangeModalShow,
  startDateCreated,
  endDateCreated,
  setStartChangedCreated,
  setEndChangedCreated,
  setStartDateCreated,
  setEndDateCreated,
  createdTippy,
  setCreatedCloseClicked,
  dateRangeModalShow,
  handleToggleDateRangeModalShowSoldDate,
  startDateSoldDate,
  endDateSoldDate,
  setStartChangedSoldDate,
  setEndChangedSoldDate,
  setStartDateSoldDate,
  setEndDateSoldDate,
  dateRangeModalShowSoldDate,
  setSoldDateCloseClicked,
  selectedCondition,
  dropDownChangeCondition,
  optionsCondition,
  optionsIntendedFor,
  dropDownChangeIntendedFor,
  selectedIntendedFor
                      }: any) => {
  const isSmallScreenLaptop = UseIsSmallLaptop();
  const [search, setSearch] = useState(() => {
    const savedSearch = localStorage.getItem('search');
    if (savedSearch) {
      return JSON.parse(savedSearch);
    } else {
      // Set your default value here if nothing is saved in localStorage
      return [{ value: 'Default Title', label: 'default-title', id: 1 }];
    }
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {hasBusiness, hasSimple } = useRoles();

  const [firstStateValue, setFirstSelectValue] = useState('');
  const [secondStateValue, setSecondSelectValue] = useState('');
  const [cacher, setCacher] = useState(true);
  const [disabledFirstInput, setDisableFirstInput] = useState(false);
  const [disabledSecondInput, setDisabledSecondInput] = useState(false);
  const storedOption = localStorage.getItem('selectedOption');
  const initialOption = storedOption
    ? JSON.parse(storedOption)
    : { value: 'title', label: 'Title' };

  const storedOptionSecond = localStorage.getItem('selectedOptionSecond');
  const initialOptionSecond = storedOptionSecond
    ? JSON.parse(storedOptionSecond)
    : { value: 'title', label: 'Title' };

  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [selectedOptionSecond, setSelectedOptionSecond] =
    useState(initialOptionSecond);

  const bussinessSimpleState = [
    { value: 'title', label: 'Title' },
    { value: 'platforms', label: 'Platforms' },
    { value: 'search_everything', label: 'Search Everything' },
    ...(hasBusiness
        ? [
          { value: 'brand', label: 'Brand' },
          { value: 'keywords', label: 'Keywords' },
        ]
        : []),
  ];
  const propProPlusState = [
    { value: 'title', label: 'Title' },
    { value: 'SKU', label: 'SKU' },
    { value: 'quantity', label: 'Quantity' },
    { value: 'platforms', label: 'Platforms' },
    { value: 'search_everything', label: 'Search Everything' },
    { value: 'created', label: 'Created' },
    { value: 'date sold', label: 'Date Sold' },
    { value: 'brand', label: 'Brand' },
    { value: 'condition', label: 'Condition' },
    { value: 'intended for', label: 'Intended For' },
    { value: 'keywords', label: 'Keywords' },
  ];
  const options =
    hasSimple || hasBusiness ? bussinessSimpleState : propProPlusState;

  const optionsUpdated = search.length === 2 ? options.filter(option => option.value !== selectedOptionSecond.value) : options;


  const bussinessSimpleState2 = [
    { value: 'title', label: 'Title' },
    { value: 'platforms', label: 'Platforms' },
    { value: 'search_everything', label: 'Search Everything' },
    ...(hasBusiness
        ? [
          { value: 'brand', label: 'Brand' },
          { value: 'keywords', label: 'Keywords' },
        ]
        : []),
  ];

  const propProPlusState2 = [
    { value: 'title', label: 'Title' },
    { value: 'SKU', label: 'SKU' },
    { value: 'quantity', label: 'Quantity' },
    { value: 'platforms', label: 'Platforms' },
    { value: 'search_everything', label: 'Search Everything' },
    { value: 'created', label: 'Created' },
    { value: 'date sold', label: 'Date Sold' },
    { value: 'brand', label: 'Brand' },
    { value: 'condition', label: 'Condition' },
    { value: 'intended for', label: 'Intended For' },
    { value: 'keywords', label: 'Keywords' },
  ];

  const filterByParam = queryParams.get('filter_by');
  let decodedFilterBy2;
  if (filterByParam) {
    try {
      let safeParam = filterByParam.replace(/%(?![0-9A-Fa-f]{2})/g, "%25");
      let decodedValue :any = decodeURIComponent(safeParam);
      const tempElement = document.createElement("textarea");
      tempElement.innerHTML = decodedValue;
      decodedValue = tempElement.value;
      decodedFilterBy2 = JSON.parse(decodedValue) || {};
    }
    catch (e) {console.log(e, 'error')}
  }

  const searchEverythingValue = _.get(decodedFilterBy2, 'search_everything');

  const options2 =
    hasSimple || hasBusiness ? bussinessSimpleState2 : propProPlusState2;

  const optionsUpdated2 = search.length === 2 ? options2.filter(option => option.value !== selectedOption.value) : options2;

  const customStyles = {
    // Style the input element
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#fff',
      color: '#1E3166',
      borderColor: '#D5D5D5',
      padding: 0,
      borderRadius: !isSmallScreenLaptop || isMobile ? '0 4px 4px 0' : '0 3px 3px 0',
      minWidth: '100px',
      maxWidth: '100%',
      width: '100%',
      cursor: 'pointer',
      minHeight: !isSmallScreenLaptop || isMobile ? '38px' : '32px',
      height: !isSmallScreenLaptop || isMobile ? '38px' : '32px',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      minWidth: isMobile ? '100px' : '160px',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: !isSmallScreenLaptop || isMobile ? '38px' : '32px',
      padding: !isSmallScreenLaptop || isMobile ? '0 6px' : '0 5px'
    }),

    input: (provided: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: !isSmallScreenLaptop || isMobile ? '38px' : '32px',
    }),
    container: (provided: any) => ({
      ...provided,
      maxWidth: !isSmallScreenLaptop || isMobile ? '100%' : '80%',
      width: !isSmallScreenLaptop || isMobile ? '100%' : '80%',
      flex:!isSmallScreenLaptop|| isMobile ? '1 1 0%' : '0.8 0.8 0% !important'
    }),
  };

  const customStylesCondition = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#fff',
      color: '#1E3166',
      borderColor: '#D5D5D5',
      padding: 1,
      borderRadius: !isSmallScreenLaptop ? '4px' : '3px',
      cursor: 'pointer',
      zIndex: 1,
      minHeight: !isSmallScreenLaptop ? '39px' : '32px',
      height: !isSmallScreenLaptop ? '39px' : '32px',
      width: "100%", // Ensure full width
      minWidth: "100px", // Optional: Prevent collapsing too much
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: !isSmallScreenLaptop ? '39px' : '32px',
      padding: !isSmallScreenLaptop ? '0 6px' : '0 5px'
    }),
    input: (provided: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state:any) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: !isSmallScreenLaptop ? '39px' : '32px',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      minWidth: !isSmallScreenLaptop ? '200px' : '160px',
      border: '1px solid #1E3166',
      borderRadius: !isSmallScreenLaptop ? '8px' : '6px',
      paddingTop: !isSmallScreenLaptop ? '10px' : '8px',
      marginTop: !isSmallScreenLaptop ? '18px' : '14px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      fontWeight: 600,
      fontSize: !isSmallScreenLaptop ? '16px' : '13px',
      textAlign: 'center',
    }),
  };

  useEffect(() => {
    const storedOption = localStorage.getItem('selectedOption');
    const storedOptionSecond = localStorage.getItem('selectedOptionSecond');
    if (storedOption) {
      const parsedOption = JSON.parse(storedOption);
      if (parsedOption.value === 'platforms') {
        setDisableFirstInput(true);
      }
    }
    if (storedOptionSecond) {
      const parsedOption = JSON.parse(storedOptionSecond);
      if (parsedOption.value === 'platforms') {
        setDisabledSecondInput(true);
      }
    }
  }, []);
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setCacher(false);
    setSearchItem(e.target.value);
    if (e.target.value.trim().length >= 2) {
      setFilterBy({ ...filterBy, search_everything: e.target.value });
      handleFetchForSearch(e.target.value);
    } else if (!e.target.value.length) {
      setFilterBy(_.omit(filterBy, ['search_everything']));
      handleFetchForSearch('');
    }
  };

  const handleSelectChange = (selectedOpt: any) => {
    propProPlusState2.filter(option => option.value !== selectedOpt.value);
    if (selectedOption.value !== 'platforms' && firstStateValue) {
      handleInputChange(selectedOption.value, '');
    }
    if (selectedOpt.value === 'platforms') {
      setDisableFirstInput(true);
      handleTogglePlatformsModalShow();
    }
    else if(selectedOpt.value === 'created'){
      handleToggleDateRangeModalShow();
    }
    else if(selectedOpt.value === 'date sold'){
      handleToggleDateRangeModalShowSoldDate();
    }
    else {
      setDisableFirstInput(false);
    }
    setSelectedOption(selectedOpt);
  };

  const handleSelectChangeSecond = (selectedOptSec: any) => {
    propProPlusState.filter(option => option.value !== selectedOptSec.value);
    if (selectedOptionSecond.value !== 'platforms' && secondStateValue) {
      handleInputChange(selectedOption.value, '');
    }
    if (selectedOptSec.value === 'platforms') {
      setDisabledSecondInput(true);
      handleTogglePlatformsModalShow();
    }
    else if(selectedOptSec.value === 'created'){
      handleToggleDateRangeModalShow();
    }
    else if(selectedOptSec.value === 'date sold'){
      handleToggleDateRangeModalShowSoldDate();
    }
    else {
      setDisabledSecondInput(false);
    }
    setSelectedOptionSecond(selectedOptSec);
  };

  const handleAddSearch = () => {
    if (search.length < 2) {
      const newSearch = [...search, { value: 'title', label: 'Title', id: 2 }];
      setSearch(newSearch);
    }
  };

  useEffect(() => {
    localStorage.setItem('selectedOption', JSON.stringify(selectedOption));
    localStorage.setItem(
      'selectedOptionSecond',
      JSON.stringify(selectedOptionSecond)
    );
  }, [selectedOption, selectedOptionSecond]);

  useEffect(() => {
    localStorage.setItem('search', JSON.stringify(search));
  }, [search]);

  const handleDeleteSearch = (id: number) => {
    // Filter out the search object with the specified id
    if (selectedOptionSecond.value === 'search_everything') {
      setSearchItem('');
      setFilterBy(_.omit(filterBy, ['search_everything']));
      handleFetchForSearch('');
    }
    const updatedSearch = search.filter((item: any) => item.id !== id);
    setSearch(updatedSearch);
    setSecondSelectValue('');
    if (secondStateValue.length) {
      handleInputChange(selectedOptionSecond.value, '');
    }
  };

  const handleChangeValue = (id: number, newValue: string) => {
    setCacher(false);
    if (id === 1) {
      setFirstSelectValue(newValue);
      handleInputChange(selectedOption.value, newValue);
    } else {
      setSecondSelectValue(newValue);
      handleInputChange(selectedOptionSecond.value, newValue);
    }
  };

  let decodedFilterBy: any;
  const functionGetValueFromUrl = (columnValue: string) => {
    const filterByParam = queryParams.get('filter_by');
    if (filterByParam) {
      try {
        let safeParam = filterByParam.replace(/%(?![0-9A-Fa-f]{2})/g, "%25");
        let decodedValue :any = decodeURIComponent(safeParam);
        const tempElement = document.createElement("textarea");
        tempElement.innerHTML = decodedValue;
        decodedValue = tempElement.value;
        decodedFilterBy = JSON.parse(decodedValue) || {};
      }
      catch (e){console.log(e, 'error')}
    }
    if (columnValue === 'title') {
      return _.get(decodedFilterBy, '133');
    } else if (columnValue === 'SKU') {
      return _.get(decodedFilterBy, '1');
    } else if (columnValue === 'price') {
      return _.get(decodedFilterBy, '22');
    } else if (columnValue === 'quantity') {
      return _.get(decodedFilterBy, '851');
    } else if (columnValue === 'notes') {
      return _.get(decodedFilterBy, '726');
    } else if (columnValue === 'search_everything') {
      return _.get(decodedFilterBy, 'search_everything');
    } else if (columnValue === 'platforms') {
      return _.get(decodedFilterBy, 'platforms');
    } else if (columnValue === 'brand') {
      return _.get(decodedFilterBy, '206');
    } else if (columnValue === 'condition') {
      return _.get(decodedFilterBy, '15');
    }else if (columnValue === 'intended for') {
      return _.get(decodedFilterBy, '4');
    }else if (columnValue === 'keywords') {
      return _.get(decodedFilterBy, '453');
    }
    else {
      return '';
    }
  };
  const isActive = startDateCreated || endDateCreated;
  const handleChange = ([newStartDate, newEndDate]: any) => {
    if (newStartDate !== startDateCreated ) {
      setStartDateCreated(newStartDate);
      setStartChangedCreated(true);
    }
    if (newEndDate !== endDateCreated) {
      setEndDateCreated(newEndDate);
      setEndChangedCreated(true);
    }
  };

  const isActiveSoldDate = startDateSoldDate || endDateSoldDate;
  const handleChangeSoldDate = ([newStartDate, newEndDate]: any) => {
    if (newStartDate !== startDateSoldDate ) {
      setStartDateSoldDate(newStartDate);
      setStartChangedSoldDate(true);
    }
    if (newEndDate !== endDateSoldDate) {
      setEndDateSoldDate(newEndDate);
      setEndChangedSoldDate(true);
    }
  };

  useEffect(() => {
    if(dateRangeModalShow) {
      setStartChangedCreated(false);
      setEndChangedCreated(false);
    }
  }, [dateRangeModalShow]);

  useEffect(() => {
    if(dateRangeModalShowSoldDate) {
      setStartChangedSoldDate(false);
      setEndChangedSoldDate(false);
    }
  }, [dateRangeModalShowSoldDate]);

  return (
    <div
      style={{
        display: isMobile ? 'block' : 'flex',
        gap: isMobile ? '0' : (!isSmallScreenLaptop ? "10px" : "8px"),
        flexWrap: isMobile ? 'nowrap' : 'wrap',
      }}
    >
      <div
        className={styles.searchContainer}
        style={{ padding: isMobile ? '5px 20px' : 0 }}
      >
        {selectedOption.value === 'search_everything' ? (
          <input
            placeholder="Search"
            className={styles.inputStyles}
            disabled={disabledFirstInput}
            value={searchItem || (cacher && searchEverythingValue) || ''}
            onChange={handleChangeInput}
          />
        ) : (
         <>
           {
            selectedOption.value === 'platforms' ? (
             <div className={styles.platformContainerIcon} onClick={handleTogglePlatformsModalShow}>
                  <PlatformIconShop/>
             </div>
            ) : selectedOption.value === 'created' ? (
              <div className={"createdRangesPicker"}>
                <Tippy
                 appendTo={createdTippy ? createdTippy.current : document.body}
                 visible={dateRangeModalShow}
                 interactive={true}
                 zIndex={9991}
                 arrow={false}
                 trigger="focus click"
                 placement="bottom-end"
                 content={
                   <div
                    className={styles.rangesModal}
                    onClick={(e) => e.stopPropagation()}
                   >
                     <div className={styles.rangesheader}>
                       <p>Set Date Created Range</p>
                       <div onClick={() => {
                         handleToggleDateRangeModalShow();
                         setCreatedCloseClicked(true);
                       }}
                            className={styles.closeRangesModal}>
                         <CloseRanges/>
                       </div>
                     </div>

                     <div className={'createdRange'}>
                       <DatePicker
                        selected={startDateCreated}
                        onChange={handleChange}
                        selectsRange
                        startDate={startDateCreated}
                        endDate={endDateCreated}
                        dateFormat="MM/dd/yyyy"
                        isClearable={true}
                        open={true}
                        placeholderText="Start Date - End Date"
                        showDaysMonthYearPicker
                        showYearDropdown
                        showMonthDropdown
                       />
                       <div className={styles.rangesCalendar}>
                         <RangesCalendarGreen/>
                       </div>
                     </div>
                   </div>
                 }
                >
                  <div className={`${styles.calendarIcon} ${isActive ? styles.calendarIconActive : ''}`}
                       onClick={() => {
                         if (dateRangeModalShow) {
                           setCreatedCloseClicked(true);
                         } else {
                           setCreatedCloseClicked(false);
                         }
                         handleToggleDateRangeModalShow();
                       }}>
                    <div className={`${styles.platformContainerIcon} ${styles.calendarIcon} ${isActive ? styles.calendarIconActive : ''}`} onClick={handleToggleDateRangeModalShow}>
                      <RangesCalendar/>
                    </div>
                  </div>
                </Tippy>
              </div>
            )
                : selectedOption.value === 'date sold' ? (
                    <div className={"soldDateRangesPicker"}>
                      <Tippy
                          appendTo={createdTippy ? createdTippy.current : document.body}
                          visible={dateRangeModalShowSoldDate}
                          interactive={true}
                          zIndex={9991}
                          arrow={false}
                          trigger="focus click"
                          placement="bottom-end"
                          content={
                            <div
                                className={styles.rangesModal}
                                onClick={(e) => e.stopPropagation()}
                            >
                              <div className={styles.rangesheader}>
                                <p>Set Date Created Range</p>
                                <div onClick={() => {
                                  handleToggleDateRangeModalShowSoldDate();
                                  setSoldDateCloseClicked(true);
                                }}
                                     className={styles.closeRangesModal}>
                                  <CloseRanges/>
                                </div>
                              </div>

                              <div className={'createdRange'}>
                                <DatePicker
                                    selected={startDateSoldDate}
                                    onChange={handleChangeSoldDate}
                                    selectsRange
                                    startDate={startDateSoldDate}
                                    endDate={endDateSoldDate}
                                    dateFormat="MM/dd/yyyy"
                                    isClearable={true}
                                    open={true}
                                    placeholderText="Start Date - End Date"
                                    showDaysMonthYearPicker
                                    showYearDropdown
                                    showMonthDropdown
                                />
                                <div className={styles.rangesCalendar}>
                                  <RangesCalendarGreen/>
                                </div>
                              </div>
                            </div>
                          }
                      >
                        <div className={`${styles.calendarIcon} ${isActiveSoldDate ? styles.calendarIconActive : ''}`}
                             onClick={() => {
                               if (dateRangeModalShow) {
                                 setSoldDateCloseClicked(true);
                               } else {
                                 setSoldDateCloseClicked(false);
                               }
                               handleToggleDateRangeModalShowSoldDate();
                             }}>
                          <div className={`${styles.platformContainerIcon} ${styles.calendarIcon} ${isActiveSoldDate ? styles.calendarIconActive : ''}`} onClick={handleToggleDateRangeModalShowSoldDate}>
                            <RangesCalendar/>
                          </div>
                        </div>
                      </Tippy>
                    </div>
                )
                    : selectedOption.value === 'condition' ? (
                        <div className={"conditionsSelect"}>
                          <Select
                              styles={customStylesCondition}
                              value={selectedCondition}
                              onChange={dropDownChangeCondition}
                              options={optionsCondition}
                              isSearchable={false}
                              placeholder={null}
                              menuPlacement={'bottom'}
                              menuPosition={'fixed'}
                          />
                         </div>
                        )

                        : selectedOption.value === 'intended for' ? (
                                <div className={"intendedForSelect"}>
                                  <Select
                                      styles={customStylesCondition}
                                      value={selectedIntendedFor}
                                      onChange={dropDownChangeIntendedFor}
                                      options={optionsIntendedFor}
                                      isSearchable={false}
                                      placeholder={null}
                                      menuPlacement={'bottom'}
                                      menuPosition={'fixed'}
                                  />
                                </div>
                            )

                            : (
             <input
              className={styles.inputStyles}
              placeholder="Search"
           disabled={disabledFirstInput}
           value={
            inputValues[selectedOption.value] ||
            (cacher && functionGetValueFromUrl(selectedOption.value)) ||
            ''
           }
           onChange={(e) => handleChangeValue(1, e.target.value)}
           {...(selectedOption.value === 'title' ? { maxLength: 140 } : {})}
         />
        )
        }
      </>
      )}

      <Select
       value={selectedOption}
       onChange={handleSelectChange}
       options={optionsUpdated}
       placeholder="Actions"
       menuPlacement="auto"
       styles={customStyles}
       isSearchable={false}

      />
      {search.length < 2 && (
       <div
        className={styles.PlusSolid}
        onClick={handleAddSearch}
        style={{
          cursor: 'pointer',
          width: '40px',
          height: '40px',
          minWidth: '40px',
        }}
       >
         <PlusSolid/>
       </div>
      )}
    </div>
{
  search.length === 2 && (
   <div
    className={styles.searchContainer}
    style={{padding: isMobile ? '5px 20px' : 0}}
   >
     {selectedOptionSecond.value === 'search_everything' ? (
      <input
       placeholder="Search"
       className={styles.inputStyles}
       disabled={disabledFirstInput}
       value={searchItem || (cacher && searchEverythingValue) || ''}
       onChange={handleChangeInput}
      />
          ) : (
      <>
        {
          selectedOptionSecond.value === 'platforms' ? (
           <div className={styles.platformContainerIcon} onClick={handleTogglePlatformsModalShow}>
             <PlatformIconShop/>
           </div>
          ) : selectedOptionSecond.value === 'condition' ? (
                  <div className={"conditionsSelect"}>
                    <Select
                        styles={customStylesCondition}
                        value={selectedCondition}
                        onChange={dropDownChangeCondition}
                        options={optionsCondition}
                        isSearchable={false}
                        placeholder={null}
                        menuPlacement={'bottom'}
                        menuPosition={'fixed'}
                    />
                  </div>
              )

              : selectedOptionSecond.value === 'intended for' ? (
                      <div className={"intendedForSelect"}>
                        <Select
                            styles={customStylesCondition}
                            value={selectedIntendedFor}
                            onChange={dropDownChangeIntendedFor}
                            options={optionsIntendedFor}
                            isSearchable={false}
                            placeholder={null}
                            menuPlacement={'bottom'}
                            menuPosition={'fixed'}
                        />
                      </div>
                  )

                  : selectedOptionSecond.value === 'created' ? (
           <div className={"createdRangesPicker"}>
             <Tippy
              appendTo={createdTippy ? createdTippy.current : document.body}
              visible={dateRangeModalShow}
              interactive={true}
              zIndex={9991}
              arrow={false}
              trigger="focus click"
              placement="bottom"
              content={
                <div
                 className={styles.rangesModal}
                 onClick={(e) => e.stopPropagation()}
                >
                  <div className={styles.rangesheader}>
                    <p>Set Date Created Range</p>
                    <div onClick={() => {
                      handleToggleDateRangeModalShow();
                      setCreatedCloseClicked(true);
                    }}
                         className={styles.closeRangesModal}>
                      <CloseRanges/>
                    </div>
                  </div>

                  <div className={'createdRange'}>
                    <DatePicker
                     selected={startDateCreated}
                     onChange={handleChange}
                     selectsRange
                     startDate={startDateCreated}
                     endDate={endDateCreated}
                     dateFormat="MM/dd/yyyy"
                     isClearable={true}
                     open={true}
                     placeholderText="Start Date - End Date"
                     showDaysMonthYearPicker
                     showYearDropdown
                     showMonthDropdown
                    />
                    <div className={styles.rangesCalendar}>
                      <RangesCalendarGreen/>
                    </div>
                  </div>
                </div>
              }
             >
               <div className={`${styles.calendarIcon} ${isActive ? styles.calendarIconActive : ''}`}
                    onClick={() => {
                      if (dateRangeModalShow) {
                        setCreatedCloseClicked(true);
                      } else {
                        setCreatedCloseClicked(false);
                      }
                      handleToggleDateRangeModalShow();
                    }}>
                 <div
                  className={`${styles.platformContainerIcon} ${styles.calendarIcon} ${isActive ? styles.calendarIconActive : ''}`}
                  onClick={handleToggleDateRangeModalShow}>
                   <RangesCalendar/>
                 </div>
               </div>
             </Tippy>
           </div>
          ) : selectedOptionSecond.value === 'date sold' ? (
              <div className={"soldDateRangesPicker"}>
                <Tippy
                    appendTo={createdTippy ? createdTippy.current : document.body}
                    visible={dateRangeModalShowSoldDate}
                    interactive={true}
                    zIndex={9991}
                    arrow={false}
                    trigger="focus click"
                    placement="bottom"
                    content={
                      <div
                          className={styles.rangesModal}
                          onClick={(e) => e.stopPropagation()}
                      >
                        <div className={styles.rangesheader}>
                          <p>Set Date Created Range</p>
                          <div onClick={() => {
                            handleToggleDateRangeModalShowSoldDate();
                            setSoldDateCloseClicked(true);
                          }}
                               className={styles.closeRangesModal}>
                            <CloseRanges/>
                          </div>
                        </div>

                        <div className={'createdRange'}>
                          <DatePicker
                              selected={startDateSoldDate}
                              onChange={handleChangeSoldDate}
                              selectsRange
                              startDate={startDateSoldDate}
                              endDate={endDateSoldDate}
                              dateFormat="MM/dd/yyyy"
                              isClearable={true}
                              open={true}
                              placeholderText="Start Date - End Date"
                              showDaysMonthYearPicker
                              showYearDropdown
                              showMonthDropdown
                          />
                          <div className={styles.rangesCalendar}>
                            <RangesCalendarGreen/>
                          </div>
                        </div>
                      </div>
                    }
                >
                  <div className={`${styles.calendarIcon} ${isActiveSoldDate ? styles.calendarIconActive : ''}`}
                       onClick={() => {
                         if (dateRangeModalShowSoldDate) {
                           setSoldDateCloseClicked(true);
                         } else {
                           setSoldDateCloseClicked(false);
                         }
                         handleToggleDateRangeModalShowSoldDate();
                       }}>
                    <div
                        className={`${styles.platformContainerIcon} ${styles.calendarIcon} ${isActiveSoldDate ? styles.calendarIconActive : ''}`}
                        onClick={handleToggleDateRangeModalShowSoldDate}>
                      <RangesCalendar/>
                    </div>
                  </div>
                </Tippy>
              </div>
          ) : (
           <input
            disabled={disabledSecondInput}
            className={styles.inputStyles}
            placeholder="Search"
            value={
             inputValues[selectedOptionSecond.value] ||
             (cacher &&
              functionGetValueFromUrl(selectedOptionSecond.value)) ||
             ''
            }
            onChange={(e) => handleChangeValue(2, e.target.value)}
            {...(selectedOptionSecond.value === 'title' ? { maxLength: 140 } : {})}
           />
          )
        }
      </>

     )}

     <Select
      value={selectedOptionSecond}
      onChange={handleSelectChangeSecond}
      options={optionsUpdated2}
      placeholder="Actions"
      menuPlacement="auto"
      styles={customStyles}
      isSearchable={false}
     />
     <div
      className={styles.PlusSolid}
      style={{
        cursor: 'pointer',
              width: '40px',
              height: '40px',
              minWidth: '40px',
            }}
            onClick={() => handleDeleteSearch(2)}
          >
            <div className={styles.minus}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileSearch;
