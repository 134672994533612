import {useEffect, useState} from "react" ;
import {platforms} from "../Table/data/platformsData";
import {shippingStatusOptions, soldDateRangeOptions} from "@assets/constants/reactSelectOptions";
import _ from "lodash";

function findOptionsByValues(options:any[],value: string) {
	const option = options.find((option:any) => option.value === value);
	return option || { value: '', label: '-' }; // Default option if not found
}

const defaultDateSoldRangeFilter = {
	value:"last-30-days",
	label:"Last 30 Days"
} ;


interface IUseMobileFiltersLogicProps {
	setSelectedOption:(selectedOption:any) => void;
	handleToggleIsMobileFiltersShow:() => void;
	filter_by_url_json:any;
	set_filter_by_url_json:(filter_by_url_json:any) => void;
	dateRangeTableDateFilter:any;
	set_filter_by_for_stats:(filter_by_for_stats:any) => void;
	assemble_filter_by_from_filter_by_url_json:(filter_by_url_json : any) => void;

}
export const useMobileFiltersLogic = (props:IUseMobileFiltersLogicProps) => {

	const {setSelectedOption,handleToggleIsMobileFiltersShow,filter_by_url_json,set_filter_by_url_json,dateRangeTableDateFilter,set_filter_by_for_stats,assemble_filter_by_from_filter_by_url_json} = props ;

	const [platformsModalShow, setPlatformsModalShow] = useState(false);
	const [lpPlatforms,setLpPlatforms] = useState<any>(platforms) ;
	const [activePlatforms,setActivePlatforms] = useState<any>(null) ;

	const [mobileFilters,setMobileFilters]  = useState<any>(null) ;

	const [shippingStatusSelectedOption, setShippingStatusSelectedOption ] = useState(
	 findOptionsByValues(shippingStatusOptions,filter_by_url_json?.shipping_status))

	const [dateSoldRangeSelectedOption,setDateSoldRangeSelectedOption] = useState(
	 (filter_by_url_json?.date_sold_range?.start || filter_by_url_json?.date_sold_range?.end
	 ) ? {value:"custom", label:'Custom'} : findOptionsByValues(soldDateRangeOptions,filter_by_url_json?.date_sold_range)
	)

	const handleTogglePlatformsModalShow = () => {
		setPlatformsModalShow(!platformsModalShow);
	};

	const updateActivePlatforms = (platformsArray:any) => {
		const selectedPlatforms = platformsArray
		.filter((platform:any) => platform.platform.isChecked || platform.notOnPlatform.isChecked)
		.map((platform:any) => ({
			 platformCode: platform.platform.isChecked ? platform.platform.code[0] : platform.notOnPlatform.code[0],
			 title: platform.platform.isChecked ? platform.platform.title : platform.notOnPlatform.title
		 })
		);

		setActivePlatforms(selectedPlatforms);
	};

	const handleClick = (platformCode: string) => {
		const updatedPlatforms = lpPlatforms?.map((item: any) => {

			if (item.platform.code[0] === platformCode) {
				return {
					...item,
					platform: {
						...item.platform,

						isChecked: !item.platform.isChecked,
					},
					notOnPlatform: {
						...item.notOnPlatform,
						isChecked: false,
					},
				};
			} else if (item.notOnPlatform.code[0] === platformCode) {
				return {
					...item,
					platform: {
						...item.platform,
						isChecked: false,
					},
					notOnPlatform: {
						...item.notOnPlatform,
						isChecked: !item.notOnPlatform.isChecked,
					},
				};
			}
			return item;
		});

		updateActivePlatforms(updatedPlatforms) ;
		setLpPlatforms(updatedPlatforms);
	};

	const handleDateRangeFilter = (selected:any) => {
		// Get selected value

		const selected_value = _.get(selected, 'value');

		const updatedMobileFilters:any = {...mobileFilters,  date_sold_range: selected_value };

		setDateSoldRangeSelectedOption(selected);

		setMobileFilters(updatedMobileFilters);
	}

	const handleShippingStatusFilter = (selected:any) => {

		// Get selected value
		const selected_value = _.get(selected, 'value');

		const updatedMobileFilters:any = { ...mobileFilters,shipping_status: selected_value };

		setShippingStatusSelectedOption(selected)
		setMobileFilters(updatedMobileFilters);

	}

	const handleClearAllPlatforms = () => {
		setMobileFilters({
			marketplaces:[],
			not_marketplaces:[]
		})
		setLpPlatforms(platforms) ;
		setActivePlatforms(null) ;
	}

	const handleClearAllFilters = () => {
		const localFilterByUrlJson:any = {...filter_by_url_json} ;

		const hasRelevantFilters =
		 "date_sold_range" in localFilterByUrlJson ||
		 "shipping_status" in localFilterByUrlJson ||
		 "marketplaces" in localFilterByUrlJson ||
		 "not_marketplaces" in localFilterByUrlJson;

		if(hasRelevantFilters) {
			localFilterByUrlJson.date_sold_range =defaultDateSoldRangeFilter.value ;
			delete localFilterByUrlJson.shipping_status ;
			delete localFilterByUrlJson.marketplaces ;
			delete localFilterByUrlJson.not_marketplaces ;

			const assembledFilter:any = assemble_filter_by_from_filter_by_url_json(localFilterByUrlJson);
			if (assembledFilter && typeof assembledFilter === 'object') {
				set_filter_by_for_stats({ ...assembledFilter });
			}

			set_filter_by_url_json(localFilterByUrlJson) ;
			setMobileFilters(null) ;
			setSelectedOption(defaultDateSoldRangeFilter)
		}

		handleToggleIsMobileFiltersShow();
	}

	const handleApplyFilters = () => {
		const new__filter_by_url_json:any = { ...filter_by_url_json, ...mobileFilters };

		const assembledFilter:any = assemble_filter_by_from_filter_by_url_json(new__filter_by_url_json);
		if (assembledFilter && typeof assembledFilter === 'object' && mobileFilters?.date_sold_range) {
			set_filter_by_for_stats({ ...assembledFilter });
		}

		set_filter_by_url_json(new__filter_by_url_json);
		handleToggleIsMobileFiltersShow();
		setSelectedOption(dateSoldRangeSelectedOption)
	}

	useEffect(() => {
		document.documentElement.style.overflow = 'hidden';

		return () => {
			document.documentElement.style.overflow = 'visible';
		};
	}, []);

	useEffect(() => {
		if(dateSoldRangeSelectedOption.value !== 'custom') return ;
		let date_sold_range = { ...dateRangeTableDateFilter };
		const date_sold_range_start = _.get(date_sold_range, 'start');
		if(!date_sold_range_start) date_sold_range = _.omit(date_sold_range, ['start']);
		const date_sold_range_end = _.get(date_sold_range, 'end');
		if(!date_sold_range_end) date_sold_range = _.omit(date_sold_range, ['end']);

		const updatedMobilefilters = { ...mobileFilters, date_sold_range };
		if(_.isEmpty(date_sold_range)) _.set(updatedMobilefilters, 'date_sold_range', 'last-30-days');
		setMobileFilters(updatedMobilefilters)
	}, [dateRangeTableDateFilter]);

	useEffect(() => {
		const marketplaces = lpPlatforms
		.filter((lpPlatform:any) => lpPlatform.platform.isChecked)
		.map((mLpPlatform:any) => mLpPlatform.platform.title.toLowerCase());

		const not_marketplaces = lpPlatforms
		.filter((lpPlatform:any) => lpPlatform.notOnPlatform.isChecked)
		.map((mLpPlatform:any) => mLpPlatform.platform.title.toLowerCase());


		const updatedMobileFiltes = {...mobileFilters,marketplaces,not_marketplaces}

		setMobileFilters(updatedMobileFiltes);

	}, [lpPlatforms]);

	useEffect(() => {
		try {
			const updatedPlatforms = lpPlatforms.map((platform:any) => {
				if (
				 filter_by_url_json?.marketplaces?.includes(
				  platform.platform.title.toLowerCase()
				 )
				) {
					return {
						...platform,
						platform: {
							...platform.platform,
							isChecked: true,
							code:platform.platform.code
						},
					};
				} else if (
				 filter_by_url_json?.not_marketplaces?.includes(
				  platform.platform.title.toLowerCase()
				 )
				) {
					return {
						...platform,
						notOnPlatform: {
							title: `Not on ${platform.platform.title}`,
							isChecked: true,
							code:platform.notOnPlatform.code

						},
					};
				} else {
					return platform;
				}
			});
			updateActivePlatforms(updatedPlatforms)
			setLpPlatforms(updatedPlatforms);
		} catch (e: any) {
			console.log(e, 'e');
		}
	}, []);

	return  {
		mobileFilters,platformsModalShow,handleTogglePlatformsModalShow,handleClick,lpPlatforms,dateSoldRangeSelectedOption,handleDateRangeFilter,shippingStatusSelectedOption,handleShippingStatusFilter,activePlatforms,handleClearAllPlatforms,handleClearAllFilters,handleApplyFilters
	}
}