import React, {useEffect, useState} from 'react' ;

import styles from "./MobileSlider.module.scss" ;
import './slickStyles.scss' ;

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {ArrowLeftSlick, ArrowRightSlick} from '@assets/icons';
import accounting from "accounting";


interface IMobileSliderProps {
	stats:any;
	handleStatsClick:(stat:any) => void;
}
const slidesToShow = 3;

const  MobileSlider = (props:IMobileSliderProps) => {
	const {stats,handleStatsClick} = props ;
	let sliderRef: any = React.createRef();
	const [currentSlide, setCurrentSlide] = useState(0);
	const [isNextDisabled, setIsNextDisabled] = useState(false);
	const [isPrevDisabled, setIsPrevDisabled] = useState(true);

	const [activeStatItemValue,setActiveStatItemValue] = useState("all_sales")


	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow,
		slidesToScroll: 2,
		arrows: false, // Enable navigation arrows
		beforeChange: (current: number, next: number) => {
			setCurrentSlide(next);
		}
	};

	const handlePrevClick = () => {
		if (!isPrevDisabled && sliderRef.current) {
			sliderRef.current.slickPrev();
		}
	};

	const handleNextClick = () => {
		if (!isNextDisabled && sliderRef.current) {
			sliderRef.current.slickNext();
		}
	};

	const updateArrowDisabledStates = () => {
		if (sliderRef.current) {
			const slideCount = React.Children.count(sliderRef.current.innerSlider.props.children);
			setIsPrevDisabled(currentSlide === 0);
			setIsNextDisabled(currentSlide >= slideCount - slidesToShow);
		}
	};

	useEffect(() => {
		updateArrowDisabledStates();
	}, [currentSlide, stats]);


	return (
	 <div className={styles['mobileSliderContainer']}>
		 <div className={`slider-container slider-custom-container ${styles.sliderContainer}`}>
			 <Slider {...settings} ref={sliderRef}>
					 {stats?.map((statsItem:any,index:number) => {
						 const {id,title,price,value,data} = statsItem ;
						 return  <div key={id}
									  onClick={() => {
										  setActiveStatItemValue(value);
										  handleStatsClick({ title, price, value, index, data })
									  }}
									  className={`${styles['statsItem']} ${activeStatItemValue === value ? styles['statsItem--active'] : ""}`}>
							 <div className={styles['statsItem__title']}>{title}</div>
							 <div className={styles['statsItem__price']}>{accounting.formatMoney(price, '$', 2)}</div>
						 </div>
					 })}
			 </Slider>
		 </div>
		 <div className={styles.customArrows}>
			 <div className={`${styles.leftArrow} ${isPrevDisabled ? styles.disabled : ''}`} onClick={handlePrevClick}>
				 <ArrowLeftSlick/>
			 </div>
			 <div className={`${styles.rightArrow} ${isNextDisabled ? styles.disabled : ''}`} onClick={handleNextClick}>
				 <ArrowRightSlick/>
			 </div>
		 </div>
	 </div>
	)
}

export default MobileSlider;