export const PlatformsList = [
    {
        key: "sold",
        name: "SOLD",
        icon: "SoldTagSvg",
        url: () => "",
        metadataKey: "539.1",
        isLink: false,
        linkKey: ''
    },
    {
        key: "listing_party",
        name: "Listing Party",
        icon: "listingPartyLogo",
        url: (id: string) => `https://listingparty.com/listings?id=${id}`,
        metadataKey: "539.16",
        linkKey: ''
    },
    {
        key: "ebay",
        name: "eBay",
        icon: "EbayLogo",
        url: (id: string) => `https://ebay.com/itm/${id}`,
        metadataKey: "539.2",
        linkKey: '618'
    },
    {
        key: "etsy",
        name: "Etsy",
        icon: "etsyLogo",
        url: (id: string) => `https://www.etsy.com/listing/${id}`,
        metadataKey: "539.3",
        linkKey: '619'
    },
    {
        key: "poshmark",
        name: "Poshmark",
        icon: "PoshmarkLogo",
        url: (id: string) => `https://poshmark.com/listing/${id}`,
        metadataKey: "539.4",
        linkKey: '620'
    },
    {
        key: "mercari",
        name: "Mercari",
        icon: "MercaryLogo",
        url: (id: string) => `https://www.mercari.com/us/item/${id}`,
        metadataKey: "539.5",
        linkKey: '621'
    },
    {
        key: "amazon",
        name: "Amazon",
        icon: "AmazonLogo",
        url: (id: string) => `https://www.amazon.com/${id}`,
        metadataKey: "539.7",
        linkKey: '836'
    },
    {
        key: "grailed",
        name: "Grailed",
        icon: "grailedLogo",
        url: (id: string) => `https://www.grailed.com/listings/${id}`,
        metadataKey: "539.8",
        linkKey: '839'
    },
    {
        key: "facebook",
        name: "Facebook",
        icon: "facebookLogo",
        url: (id: string) => `https://www.facebook.com/marketplace/item/${id}`,
        metadataKey: "539.9",
        linkKey: '840'
    },
    {
        key: "depop",
        name: "Depop",
        icon: "depopLogo",
        url: (id: string) => `https://www.depop.com/products/${id}`,
        metadataKey: "539.11",
        linkKey: '841'
    },
    {
        key: "kidizen",
        name: "Kidizen",
        icon: "kidizenLogo",
        url: (id: string) => `https://www.kidizen.com/items/${id}`,
        metadataKey: "539.12",
        linkKey: '853'
    },
    {
        key: "shopify",
        name: "Shopify",
        icon: "shopifyLogo",
        url: () => '',
        metadataKey: "539.13",
        linkKey: "855"
    },
    {
        key: "instagram",
        name: "Instagram",
        icon: "instagramLogo",
        url: (id: string) => `https://www.instagram.com/p/${id}/`,
        metadataKey: "539.14",
        linkKey: "856"
    },
    {
        key: "vestiaire",
        name: "Vestiaire",
        icon: "vestiaireLogo",
        url: (id: string) => `https://vestiairecollective.com/${id}.shtml`,
        metadataKey: "539.17",
        linkKey: "885"
    },
];
