import React, { useCallback, useState } from 'react';
import styles from './TwoLevelPieChart.module.scss';
import { PieChart, Pie, Cell, Sector, Legend, ResponsiveContainer } from 'recharts';
import accounting from 'accounting';
import useIsMobile from '@hooks/UseIsMobile';

const renderActiveShape = (props: any) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
		value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	const isMobile = window.innerWidth <= 768 ;

	return (
	 <g>
		 <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
			 {payload.name}
		 </text>
		 <Sector
		  className={styles.cursorPointer}
		  cx={cx}
		  cy={cy}
		  innerRadius={innerRadius}
		  outerRadius={outerRadius}
		  startAngle={startAngle}
		  endAngle={endAngle}
		  fill={fill}
		 />
		 <Sector
		  cx={cx}
		  cy={cy}
		  startAngle={startAngle}
		  endAngle={endAngle}
		  innerRadius={outerRadius + 6}
		  outerRadius={outerRadius + 10}
		  fill={fill}
		 />
		 {
			 !isMobile ? (
			  <>
				  <path
				   d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				   stroke={fill}
				   fill="none"
				  />
				  <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
				  <text
				   x={ex + (cos >= 0 ? 1 : -1) * 12}
				   y={ey}
				   textAnchor={textAnchor}
				   fill="#333"
				  >
					  {accounting.formatMoney(value, '$', 2)}
				  </text>
				  <text
				   x={ex + (cos >= 0 ? 1 : -1) * 12}
				   y={ey}
				   dy={18}
				   textAnchor={textAnchor}
				   fill="#999"
				  >
					  {`(${(percent * 100).toFixed(2)}%) of sales`}
				  </text>
			  </>
			 ) : null
		 }
	 </g>
	);
};

const CustomLegend = ({payload, activeIndex, setActiveIndex}: any) => {
	return (
	 <ul className={styles.legendList}>
		 {payload.map((entry: any, index: any) => (
		  <li
		   onMouseEnter={() => setActiveIndex(index)}
		   key={`legend-${index}`}
		   className={styles.legendItem}
		  >
			  <svg width={20} height={20} className={styles.legendSvg}>
				  <rect width={20} height={20} fill={entry.color}/>
			  </svg>
			  <span className={styles.legendSpan}>
            <p>{entry.name}</p>
            <p className={styles.legendValue}>
              {accounting.formatMoney(entry?.value, '$', 2)}
            </p>
          </span>
		  </li>
		 ))}
	 </ul>
	);
};

interface ITwoLevelPieChart {
	graphData: any;
	dataForLegend: any;
}

const TwoLevelPieChart = (props: ITwoLevelPieChart) => {
	const { graphData, dataForLegend } = props;
	const [activeIndex, setActiveIndex] = useState(0);
	const isMobile = useIsMobile();

	const onPieEnter = useCallback(
	 (_: any, index: any) => {
		 setActiveIndex(index);
	 },
	 [setActiveIndex]
	);

	const innerRadius = isMobile ? 80 : 100;
	const outerRadius = isMobile ? 120 : 160;

	return (
	 <div className={styles.twoLevelPieChart}>
		 <div className={isMobile ? styles.chartContainerMobile : styles.chartContainer}>
			 {graphData?.length ? (
			  <ResponsiveContainer width="100%" height="100%">
				  <PieChart>
					  <Pie
					   activeIndex={activeIndex}
					   activeShape={renderActiveShape}
					   data={graphData}
					   cx="50%"
					   cy="50%"
					   innerRadius={innerRadius}
					   outerRadius={outerRadius}
					   fill="#8884d8"
					   dataKey="value"
					   onMouseEnter={onPieEnter}
					  >
						  {graphData.map((entry: any, index: any) => (
						   <Cell key={`cell-${index}`} fill={entry.color} />
						  ))}
					  </Pie>
						  <Legend
						   layout={isMobile ? 'horizontal' : 'vertical'}
						   align={isMobile ? 'center' : 'right'}
						   verticalAlign={isMobile ? 'bottom' : 'top'}
						   payload={dataForLegend.map((entry: any) => ({
							   id: entry.name,
							   type: 'rect',
							   name: entry.name,
							   value: `$${entry.value}`,
							   color: entry.color,
						   }))}
						   wrapperStyle={{ overflow: 'visible', position:'absolute' }}
						   content={
							   <CustomLegend
								activeIndex={activeIndex}
								setActiveIndex={setActiveIndex}
							   />
						   }
						  />
				  </PieChart>
			  </ResponsiveContainer>
			 ) : (
			  <div className={isMobile ? styles.noResultsMobile : styles.noResults}>
				  No results
			  </div>
			 )}
		 </div>
	 </div>
	);
};

export default TwoLevelPieChart;
