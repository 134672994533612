import styles from "./MobileViewFilters.module.scss";

import {CloseModal, SelectPlatformsIcon, SVGSmallX} from "@assets/icons";
import Select from "react-select";
import Button, {ModifierType} from "../../../../components/UI/Button";
import PlatformsModal from "../Table/components/PlatformsModal";
import ReactDatePicker from "../ReactDatePicker";
import {shippingStatusOptions, soldDateRangeOptions} from "@assets/constants/reactSelectOptions";
import {useMobileFiltersLogic} from "./useMobileFiltersLogic";

const customStyles = {
	control: (provided: any) => ({
		...provided,
		backgroundColor: '#fff',
		color: '#1E3166',
		borderColor: '#D5D5D5',
		padding: 1,
		borderRadius: 8,
		cursor: 'pointer',
		width: "100%",
		minHeight: 39,
		height: 39,
		zIndex: 99,
	}),
	valueContainer: (provided: any) => ({
		...provided,
		height: 39,
		padding:'0 6px',
	}),

	input: (provided: any) => ({
		...provided,
		margin: '0px',
	}),
	indicatorSeparator: (state:any) => ({
		display: 'none',
	}),
	indicatorsContainer: (provided: any) => ({
		...provided,
		height: 39,
	}),

	menu: (provided: any, state: any) => ({
		...provided,
		width: '100%',
		minWidth: 200,
		border: '1px solid #1E3166',
		borderRadius: 8,
		paddingTop: 10,
		zIndex: 999,
	}),
	option: (provided: any, state: any) => ({
		...provided,
		cursor: 'pointer',
		fontWeight: 600,
		fontSize: 16,
		zIndex: 99,
		padding:'6px 10px'
	}),
};

interface IMobileViewFiltersProps {
	setSelectedOption:(selectedOption:any) => void;
	handleToggleIsMobileFiltersShow:() => void;
	filter_by_url_json:any;
	set_filter_by_url_json:(filter_by_url_json:any) => void;
	dateRangeTableDateFilter:any;
	setDateRangeTableDateFilter:(dateRangeTableDateFilter:any) => void;
	set_filter_by_for_stats:(filter_by_for_stats:any) => void;
	assemble_filter_by_from_filter_by_url_json:(filter_by_url_json : any) => void;
}

const MobileViewFilters = (props:IMobileViewFiltersProps) => {
	const {setSelectedOption,handleToggleIsMobileFiltersShow,filter_by_url_json,set_filter_by_url_json,dateRangeTableDateFilter,setDateRangeTableDateFilter,set_filter_by_for_stats,assemble_filter_by_from_filter_by_url_json} = props ;

	const {mobileFilters,platformsModalShow,handleTogglePlatformsModalShow, handleClick,lpPlatforms,dateSoldRangeSelectedOption,handleDateRangeFilter,shippingStatusSelectedOption,handleShippingStatusFilter,activePlatforms,handleClearAllPlatforms,handleClearAllFilters,handleApplyFilters} = useMobileFiltersLogic({
		setSelectedOption,
		handleToggleIsMobileFiltersShow,
		filter_by_url_json,
		set_filter_by_url_json,
		dateRangeTableDateFilter,
		set_filter_by_for_stats,
		assemble_filter_by_from_filter_by_url_json
	})


	return (
		 <div className={styles['mobileViewFilters']}>
			 <div className={styles['mobileViewFilters__modal']}>
				 <div className={styles['mobileViewFilters__modal-titleBlock']}>
					 <div className={styles['mobileViewFilters__modal-title']}>Filter your Sales</div>
					 <CloseModal onClick={handleToggleIsMobileFiltersShow}/>
				 </div>
				 <div className={styles['mobileViewFilters__modal-line']}></div>
				 <div className={styles["mobileViewFilters__modal-selectBlock"]}>
					 <div className={styles['mobileViewFilters__modal-filterTitle']}>
						 Sold Date Range
					 </div>
					 <Select
					  value={dateSoldRangeSelectedOption}
					  onChange={handleDateRangeFilter}
					  options={soldDateRangeOptions}
					  styles={customStyles}
					  placeholder=""
					  isSearchable={false}
					 />
					 {dateSoldRangeSelectedOption.value === 'custom' && (
					  <div className={styles['mobileViewFilters__modal-reactDatePickerBlock']}>
						  <ReactDatePicker
						   isLabel={false}
						   dateRangeTableDateFilter={dateRangeTableDateFilter}
						   setDateRangeTableDateFilter={setDateRangeTableDateFilter}
						   filter_by_url_json={filter_by_url_json}
						   set_filter_by_url_json={set_filter_by_url_json}
						  />
					  </div>
					 )}
				 </div>
				 <div className={styles['mobileViewFilters__modal-line']}></div>
				 <div className={styles["mobileViewFilters__modal-selectBlock"]}>
					 <div className={styles['mobileViewFilters__modal-filterTitle']}>
						 Shipping Status
					 </div>
					 <Select
					  value={shippingStatusSelectedOption}
					  onChange={handleShippingStatusFilter}
					  options={shippingStatusOptions}
					  styles={customStyles}
					  placeholder=""
					  isSearchable={false}
					 />
				 </div>
				 <div className={styles['mobileViewFilters__modal-line']}></div>
				 <div className={styles["mobileViewFilters__modal-selectBlock"]}>
					 <div className={styles['mobileViewFilters__modal-filterTitle']}>
						 Sold On
					 </div>
					 <Button style={{justifyContent: "normal", width: "fit-content"}}
							 modifier={ModifierType.Primary} handleClick={handleTogglePlatformsModalShow}>
						 <SelectPlatformsIcon/>
						 <span>Select Platforms</span>
					 </Button>
				 </div>
				 <div className={styles['mobileViewFilters__modal-activePlatformsBlock']}>
					 {(mobileFilters?.marketplaces || mobileFilters?.not_marketplaces) && activePlatforms?.map((activePlatform: any, index: number) => {
						 return (
						  <div key={index} className={styles['mobileViewFilters__modal-activePlatformItem']}>
							  <span>{activePlatform.title}</span>
							  <SVGSmallX onClick={() => handleClick(activePlatform.platformCode)}/>
						  </div>
						 )
					 })}
					 {activePlatforms && activePlatforms?.length > 0 ? (<div
					  className={styles['mobileViewFilters__modal-clearAllBtn']}
					  onClick={handleClearAllPlatforms}
					 >Clear All</div>) : null}
				 </div>

				 <div className={styles['mobileViewFilters__modal-buttonsBlock']}>
					 <div className={styles['mobileViewFilters__modal-line']}></div>
					 <div className={styles['mobileViewFilters__modal-buttons']}>
						 <Button style={{background: "#EFEFEF", color: "#767676"}} modifier={ModifierType.Transparent}
								 handleClick={handleClearAllFilters}>Clear
							 All</Button>
						 <Button modifier={ModifierType.Primary} handleClick={handleApplyFilters}>Apply Filters</Button>
					 </div>
				 </div>
			 </div>
			 {platformsModalShow && (
			  <PlatformsModal
			   platformModalMessage={""}
		   handleTogglePlatformsModalShow={handleTogglePlatformsModalShow}
		   handleClick={handleClick}
		   lpPlatforms={lpPlatforms}
		   main={true}
		  />
		 )}
	 </div>
	)
}

export default MobileViewFilters;