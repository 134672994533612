import styles from './SingleItemOrderMobileCard.module.scss';

import Select from 'react-select';
import {MoonLoader} from "react-spinners";
import moment from "moment/moment";
import accounting from "accounting";
import {useNavigate} from "react-router-dom";

import {
	AmazonLogo,
	DeleteIcon,
	DepopLogo,
	DropdownArrow,
	EbayLogo,
	EtsyLogo,
	FacebookLogo,
	FaImage,
	GrailedLogo,
	InstagramLogo,
	KidizenLogo,
	LinkedSVG,
	ListingPartyLogo,
	MercaryLogo,
	MobileViewIcon,
	NotLinkedSvg,
	NotPlatformIcon,
	PoshmarkLogo,
	ShopifyLogo,
	VestiaireLogo
} from '@assets/icons';
import useBasicFuncs from '@hooks/useBasisFuncs';
import useSingleItemOrderMobileCardLogic, {shippingStatusDropdownOptions} from "./useSingleItemOrderMobileCardLogic";
import CatalogInModal from "../../../CatalogInModal";
import _ from "lodash";
import {setListingViewId, setShowListingViewModal} from "../../../../../../redux/slices/NavigationSlice";
import {useDispatch} from "react-redux";


const customStyles = {
	control: (provided: any) => ({
		...provided,
		backgroundColor: '#fff',
		color: '#1E3166',
		padding: 1,
		borderRadius: 8,
		cursor: 'pointer',
		zIndex: 1,
		minHeight: 32,
		height:32,
		borderColor:'#DDD',
		boxShadow: 'none',
		// "&:hover": {
		// 	borderColor: '#D5D5D5',
		// },
	}),
	menuPortal: (provided: any) => ({
		...provided,
		zIndex:9999

	}),
	valueContainer: (provided: any) => ({
		...provided,
		height:32,
		// padding: !isSmallScreenLaptop ? '0 6px' : '0 5px'
	}),

	input: (provided: any) => ({
		...provided,
		margin: '0px',
	}),
	indicatorSeparator: (state:any) => ({
		display: 'none',
	}),
	indicatorsContainer: (provided: any) => ({
		...provided,
		height:32,
	}),

	menu: (provided: any) => ({
		...provided,
		width: '100%',
		border: '1px solid #1E3166',
		borderRadius: 8,
		zIndex:9
	}),
	option: (provided: any, state: any) => ({
		...provided,
		cursor: 'pointer',
		fontWeight: 600,
		fontSize: 14,
	}),
};

interface ISingleItemOrderMobileCardProps {
	order:any;
	tableData:any;
	setTableData:(tableData:any) => void;
	filter_by_url_json:any;
	metadataForInfiniteScroll:any;
 }

export default function SingleItemOrderMobileCard(props:ISingleItemOrderMobileCardProps) {
	const {order,tableData,setTableData,filter_by_url_json,metadataForInfiniteScroll} = props;
	const {thumbnail,title,shop_id,date_sold,total_price,sku} = order;

	const {bdecode} = useBasicFuncs()
	const dispatch = useDispatch();
	const navigate = useNavigate() ;

	const { selectedOption, shippingStatusDropdownChange,isOpenActionsDropdown, handleToggleActionsDropdown,loadingDimensions,dimensions,actionsDropdownRef,actionsBtnRef,is_linked,handleDeleteForOneSale, handleListingUnlinking,isCatalogModalShow,handleToogleShowCatalogModalShow,selectedListing
	} = useSingleItemOrderMobileCardLogic({
		order,
		tableData,
		setTableData,
		filter_by_url_json,
		metadataForInfiniteScroll
	});

	function renderImageContent() {
		if (thumbnail && !thumbnail.includes('null')) {
			if (loadingDimensions) {
				return (
				 <>
					 <MoonLoader color="#1e3166" loading={true} size={158} />
				 </>
				);
			} else {
				return (
					 <img
					  src={thumbnail}
					  alt=""
					  style={{
						  width: dimensions === 'width' ? '120px' : 'auto',
						  height: dimensions === 'height' ? '120px' : 'auto',
					  }}
					 />
				);
			}
		} else {
			return (
				 <FaImage/>
			);
		}
	}

	const getMarketplaceLogo = (marketplace: string) => {
		const marketplaceLogos: Record<string, JSX.Element> = {
			ebay: <EbayLogo />,
			poshmark: <PoshmarkLogo />,
			mercari: <MercaryLogo />,
			etsy: <EtsyLogo />,
			facebook: <FacebookLogo />,
			vestiaire: <VestiaireLogo />,
			depop: <DepopLogo />,
			grailed: <GrailedLogo />,
			kidizen: <KidizenLogo />,
			instagram: <InstagramLogo />,
			amazon: <AmazonLogo />,
			shopify: <ShopifyLogo />,
			listing_party: <ListingPartyLogo />,
		};

		return marketplaceLogos[marketplace] || <div className={styles['info__notPlatform']}><NotPlatformIcon/></div>;
	};

	return (
	 <>
		 <div className={styles.tableMobileCard}>
			 <div className={styles['tableMobileCard__imageWithTitle']}>
				 <div className={styles['tableMobileCard__image']}>
					 {renderImageContent()}
				 </div>
				 <div className={styles['tableMobileCard__title']}>{bdecode(title.long) || ""}</div>
			 </div>
			 <div className={styles['tableMobileCard__info']}>
				 <div className={styles['info__platform']}>
					 {getMarketplaceLogo(order.marketplace)}
				 </div>
				 <div className={styles['tableMobileCard__info-otherDetails']}>
					 <div className={styles['tableMobileCard__info-otherDetail']}>
						 <div className={styles['tableMobileCard__info-otherDetailLabel']}>Shop</div>
						 <div className={styles['tableMobileCard__info-otherDetailValue']}>{shop_id || "-"}</div>
					 </div>
					 <div className={styles['tableMobileCard__info-otherDetail']}>
					 <div className={styles['tableMobileCard__info-otherDetail']}>
						 <div className={styles['tableMobileCard__info-otherDetailLabel']}>Date Sold</div>
						 <div className={styles['tableMobileCard__info-otherDetailValue']}>{moment(date_sold).format('MM/DD/YYYY') || "-"}</div>
					 </div>
						 <div className={styles['tableMobileCard__info-otherDetailLabel']}>Sold Price</div>
						 <div className={`${styles['tableMobileCard__info-otherDetailValue']} ${total_price && styles['tableMobileCard__info-otherDetailValue--green']}`}>{total_price ? accounting.formatMoney(total_price, '$', 2)
						  : "-"}</div>
					 </div>
					 <div className={styles['tableMobileCard__info-otherDetail']}>
						 <div className={styles['tableMobileCard__info-otherDetailLabel']}>SKU</div>
						 <div className={styles['tableMobileCard__info-otherDetailValue']}>{sku || "-"}</div>
					 </div>
				 </div>
			 </div>

			 <div className={styles['tableMobileCard__select']}>
				 <Select
				  isSearchable={false}
				  value={selectedOption}
				  options={shippingStatusDropdownOptions}
				  onChange={shippingStatusDropdownChange}
				  styles={customStyles}
				 />
			 </div>
			 <div className={styles['tableMobileCard__actionsBtnWrapper']}>
				 <div ref={actionsBtnRef} className={styles['tableMobileCard__actionsBtn']}
					  onClick={handleToggleActionsDropdown}>
					 <span>Actions</span>
					 <DropdownArrow className={isOpenActionsDropdown ? styles['dropdownArrowRotate'] : ""}/>
				 </div>
				 {isOpenActionsDropdown ?
				  <div ref={actionsDropdownRef} className={styles['tableMobileCard__actionsDropdown']}>
					  {is_linked ? <div
						   className={`${styles['tableMobileCard__actionsDropdownItem']} ${styles['tableMobileCard__actionsDropdownItem--view']}`}
						   onClick={(e: any) => {
							   e.preventDefault();
							   const id = `${_.get(order, 'listings[0].catalog_id')}`;
							   if (id) {
								   const location = window.location;
								   const searchParams = new URLSearchParams(location.search);
								   searchParams.set('modal-listing-id', `${id}`);
								   searchParams.set('modal', 'listing-view');
								   navigate(`?${searchParams.toString()}`);
								   dispatch(setListingViewId(Number(id)));
								   dispatch(setShowListingViewModal(true));
							   }
						   }}
						  >
							  <MobileViewIcon/>
							  <span>View</span>
						  </div> :null}
					  {(!is_linked ?
					   (<div
						className={`${styles['tableMobileCard__actionsDropdownItem']} ${styles['tableMobileCard__actionsDropdownItem--link']}`}
						onClick={handleToogleShowCatalogModalShow}>
						   <LinkedSVG/>
						   <span>Link</span>
					   </div>) :
					   (<div
						className={`${styles['tableMobileCard__actionsDropdownItem']} ${styles['tableMobileCard__actionsDropdownItem--unlink']}`}
						onClick={handleListingUnlinking}
					   >
						   <NotLinkedSvg/>
						   <span>Unlink</span>
					   </div>))
					  }
					  <div
					   className={`${styles['tableMobileCard__actionsDropdownItem']} ${styles['tableMobileCard__actionsDropdownItem--delete']}`}
					   onClick={handleDeleteForOneSale}>
						  <DeleteIcon/>
						  <span>Delete</span>
					  </div>
				  </div> : null}
			 </div>
		 </div>
		 {
			 isCatalogModalShow ? <CatalogInModal
			  handleToogleShowCatalogModalShow={handleToogleShowCatalogModalShow}
			  handleListingUnlinking={handleListingUnlinking}
			  selectedListing={selectedListing}
			  orderId={order.id}
			 /> : null
		 }
	 </>
	);
}

