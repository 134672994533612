import React from "react";
import Tippy from "@tippyjs/react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    setDuplicateListingId,
    setEditAddModalId,
    setEditAddModalShow,
    setShowListingViewModal
} from "../../../../../redux/slices/NavigationSlice";
import Button, {ModifierType} from "../../../Button";
import styles from "./HeaderComponent.module.scss";
import {
    AddListingSVG,
    CrosspostSingleView,
    EditPencil,
    ExitButton,
    PopoverIcon,
    Print,
    SVGNewSquares
} from "@assets/icons";

const HeaderComponent = ({popoverContainerRef, crossPostRef,visibleCrossPost,listingViewModalShow,listViewId,hasPro, is_role_allowed, handleCloseListingModal, hasBetaRole, openPdfModal}:any) =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return(
            <div className={`${styles.fixedHeader} ${listingViewModalShow ? styles.fixedHeaderOnModal : ''}`}>
                <div className={styles.titleRightTop}>
                    <p
                        className={`${styles.mobileHeaderTitle} ${listingViewModalShow ? styles.mobileHeaderTitleModal : ''}`}>Listing
                        Preview
                    </p>
                    <div className={styles.headerButtonsContainer}>
                        <button
                            className={`${styles.headerButton} ${styles.buttonCrossPost} lp_custom_crosspost_icon lp_custom_crosspost_icon_disabled lp_custom_chrome_extension_single_view_page_crosspost_icon`}
                            ref={crossPostRef}>
                            {
                                visibleCrossPost ?
                                    <Tippy
                                        interactive={true}
                                        trigger={'click'}
                                        placement={'bottom'}
                                        appendTo={popoverContainerRef.current}
                                        zIndex={9995}
                                        content={
                                            visibleCrossPost ?
                                                <div>
                                                    <p>
                                                        Crossposting is not enabled.{' '}
                                                        <a rel="noreferrer"
                                                           style={{width: '100%', color: 'blue'}}
                                                           target="_blank"
                                                           href="https://help.listperfectly.com/en/articles/8913965"
                                                        >
                                                            Install
                                                        </a>{' '}
                                                        or activate extension to enable.{' '}
                                                    </p>
                                                </div> : null
                                        }
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px',
                                                padding: '5px',
                                            }}
                                        >
                                            <CrosspostSingleView/>
                                            <span>Crosslist</span>
                                        </div>
                                    </Tippy>
                                    : <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px',
                                            padding: '5px',
                                        }}
                                    >
                                        <CrosspostSingleView/>
                                        <span>Crosslist</span>
                                    </div>
                            }
                        </button>

                        <Button handleClick={() => {
                            if (listingViewModalShow) {
                                dispatch(setEditAddModalShow(true));
                                dispatch(setEditAddModalId(listViewId));
                                dispatch(setShowListingViewModal(false));
                                const location = window.location;
                                const searchParams = new URLSearchParams(location.search);
                                searchParams.set('modal-listing-id', `${listViewId}`);
                                searchParams.set('modal', 'listing-edit-view');
                                navigate(`?${searchParams.toString()}`);
                            } else {
                                navigate(`/listings/edit/${listViewId}`);
                            }
                        }}
                                modifier={ModifierType.Secondary}
                        >
                            <EditPencil/>
                            <span>Edit</span>
                        </Button>
                        {
                            (hasPro && is_role_allowed) &&
                            <div className={styles.inventoryLabelContainer}>
                                {
                                    hasBetaRole ?
                                        <Button handleClick={() => openPdfModal()}
                                                modifier={ModifierType.Primary2}
                                        >
                                            <Print/>
                                            Inventory Label
                                        </Button> :
                                        <Button handleClick={() => window.open(`${origin}/print-qr-page/${listViewId}`)}
                                                modifier={ModifierType.Primary2}
                                        >
                                            <Print/>
                                            Inventory Label
                                        </Button>
                                }
                                <Tippy
                                    interactive={true}
                                    zIndex={9995}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div className={`${styles.popoverContent}`}>
                                            <>
                                                <p>Easily manage your inventory with labels that include the item title,
                                                    SKU, and a QR code. Scan the code to instantly view the listing in
                                                    your
                                                    List Perfectly catalog. Ideal for quick item location and efficient
                                                    inventory management. <a rel="noreferrer" target='_blank'
                                                                             href={"https://help.listperfectly.com/en/articles/8912348 "}>Learn
                                                        more.</a></p>
                                            </>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </div>
                        }

                        <Button
                            handleClick={() => {
                                dispatch(setDuplicateListingId(listViewId));
                                navigate('/listings/add');
                                dispatch(setShowListingViewModal(false));
                            }}
                            modifier={ModifierType.Primary2}
                        >
                            <SVGNewSquares/>
                            <span>Duplicate</span>
                        </Button>
                        <Button handleClick={() => {
                            navigate('/listings/add');
                            dispatch(setShowListingViewModal(false));
                        }}
                                modifier={ModifierType.Primary2}
                        >
                            <AddListingSVG/>
                            <span>New</span>
                        </Button>
                    </div>
                    {
                        listingViewModalShow &&
                        <Button handleClick={handleCloseListingModal}
                                modifier={ModifierType.Primary2}
                                style={{
                                    backgroundColor: '#FFDADA',
                                    color: '#FF4D4D',
                                }}>
                            <ExitButton className={styles.closeSvgRed}/> Close
                        </Button>
                    }

                </div>
            </div>
    )
}

export default HeaderComponent;