import React from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import { PlatformsList } from "./PlatformsList";
import PlatformBlockItem from "../../pages/CatalogPage/components/Table/components/PlatformBlockItem";
import styles from "./PlatformsRow.module.scss";
import {PlatformIconShop} from "@assets/icons";


interface PlatformBlockItemWrapperProps {
    itemInfo?:any; // Item Info
    metadata?: any; // Metadata reference
    source: "catalog" | "sales" | "nestedRow"; // Identifies where this is used
    newTableInfo?: any; // Used in catalog for `removed` and `syncIssue`
    tooltipsPlatformActive?: Record<string, any>; // Used in catalog for showing issue modal
    handleTooltipClickPlatforms?: any; // Used in catalog, function for issue modal
    showModalChanger?: any; // Used in catalog to toggle sync issue modal
    shopifyLinkLocal?:any; // Generated shopify link depending extension
    unescapedRowMetadataIssues?:any; // Used in catalog, Issues Data
    isSmallScreenLaptop?:any; // Checker for screen size
    hasTitle?:any; // Checker if platform has title
    sync?:any; // Sync property from item data
}

const PlatformsRow: React.FC<PlatformBlockItemWrapperProps>  = (
    {
        itemInfo,
        metadata,
        source,
        newTableInfo,
        tooltipsPlatformActive,
        handleTooltipClickPlatforms,
        showModalChanger,
        shopifyLinkLocal,
        unescapedRowMetadataIssues,
        isSmallScreenLaptop,
        hasTitle,
        sync
    }) => {
    const customMarketplaces:any = useSelector((state: any) => state.customMarketplaces.customMarketplaces)
    const determineIsLink = (
        source: string,
        platform: { key: string; linkKey: string; metadataKey: string; name: string },
        row: Record<string, any>,
        unescapedRowMetadata: Record<string, any>
    ) => {
        try{
                if (platform.key === 'listing_party'){
                    return true
                }
                else if (platform.key === 'sold'){
                    return false
                }
                else{
                    if ((source === 'catalog' || source === "sales")) {
                        return (
                            Boolean((row?.[platform.linkKey] !== undefined && row?.[platform.linkKey] !== '') ||
                                (!row?.[platform.linkKey] && row?.[platform.linkKey] !== '' &&
                                    unescapedRowMetadata[platform.linkKey] &&
                                    unescapedRowMetadata[platform.metadataKey] === platform.name))
                        );
                    }
                    else if (source === 'nestedRow'){
                        const linkValue = _.get(unescapedRowMetadata, platform.linkKey);
                        const metadataValue = _.get(unescapedRowMetadata, platform.metadataKey);
                        const result = linkValue !== undefined && metadataValue !== undefined ? { linkValue, metadataValue } : undefined;
                        return Boolean(result)
                    }
                    else {
                        return false;
                    }
                }
        }
        catch(e){
            console.log(e, 'error')
        }
    };

    return (
        <div className={`platforms_container ${styles.platformsRowWrapper} ${!hasTitle ? styles.platformsWithNoTitle : styles.platformsWithTitle}`}>
            <>
             {PlatformsList.map((platform) => {
                // Extract metadata key for this platform
                const platformMetadata = source === "nestedRow"
                    ? _.get(metadata, platform.metadataKey)
                    : metadata?.[platform.metadataKey];

                const hrefValue = platform.key !== 'sold' && platform.key !== 'listing_party' ? source === "nestedRow"
                    ? _.get(metadata, platform.linkKey)
                    : source === 'catalog' ? ( _.get(itemInfo, platform.linkKey) || metadata?.[platform.linkKey]) : metadata?.[platform.linkKey] : '';

                // Check if the platform should be displayed
                const platformExists = (itemInfo?.[`new${platform.name}`] === platform.name || platformMetadata === platform.name) && itemInfo?.[`new${platform.name}`] !== null;
                if (!platformExists) return null;

                return (
                    <PlatformBlockItem
                        key={platform.key}
                        platformName={platform.name === 'Listing Party' ? 'listing_party' : platform.name}
                        href={platform.name === 'Shopify' ? shopifyLinkLocal : platform.name === 'Listing Party' ? platform.url(itemInfo?.id) : platform.url(hrefValue)}
                        platformIcon={platform.icon}
                        removed={source === "catalog" ? newTableInfo?.newPlatformIssue?.removed?.includes(platform.key) : undefined}
                        issueExist={source === "catalog" ? unescapedRowMetadataIssues[platform.metadataKey]  === "status_selected_empty_item_number" : undefined}
                        syncIssue={
                            source === "catalog"
                                ? newTableInfo?.newPlatformIssue?.removed?.includes(platform.key)
                                    ? null
                                    : unescapedRowMetadataIssues[platform.metadataKey] || null
                                : undefined
                        }
                        syncIssueClick={source === "catalog" ? () => showModalChanger?.("newPlatformIssue", true, platform.key) : undefined}
                        isLink={
                            determineIsLink(source, platform, itemInfo, metadata)
                        }
                        fromPlatform={true}
                        type={source === "sales" || source === "nestedRow" ? "sales" : undefined}
                        visible={source === "catalog" ? tooltipsPlatformActive?.[platform.key] : undefined}
                        handleTooltipClickPlatforms={source === "catalog" ? handleTooltipClickPlatforms : undefined}
                        IsSold={source === "catalog" ? metadata?.["539.1"] : undefined}
                        hasTitle={!!hasTitle}
                        sync={sync}
                    />
                );
            })}
                {
                    customMarketplaces.map((item: any) => {
                        const itemInfoMetadata =
                            source === 'catalog'
                                ? itemInfo?.metadata ?? {}
                                : itemInfo?.catalog_metadata ?? {};

                        if (!item.platform?.code || !Array.isArray(item.platform.code)) {
                            return null; // Ensure item.platform.code is an array
                        }

                        const platformsCode = Object.keys(itemInfoMetadata);

                        const isValidPlatform = item.platform.code.every((code: any) => {
                            if (!code || typeof code !== 'string') return false; // Ensure `code` is a string
                            const valueToCheck = itemInfoMetadata[code];
                            return (
                                (platformsCode.includes(code) ||
                                    platformsCode.includes(code.toLowerCase())) &&
                                valueToCheck !== null &&
                                valueToCheck !== undefined
                            );
                        });

                        if (isValidPlatform) {
                            return (
                                <div
                                    key={item?.platform?.title}
                                >
                                    {metadata[item?.platform?.platformId]?.length > 0 ? (
                                        <a
                                            href={metadata[item?.platform?.platformId]}
                                            target="_blank"
                                            className={styles.customMarketWithPlatformId}
                                        >
                                            <div
                                                style={{border: `1px solid ${item?.color ? item.color : '#000'}`}}
                                                className={styles.customMarketIcon}
                                             >
                                                    <PlatformIconShop
                                                        width={isSmallScreenLaptop ? "16px" : "20px"}
                                                        height={isSmallScreenLaptop ? "16px" : "20px"}
                                                        fill={item?.color ? item.color : '#000'}
                                                    />
                                                </div>
                                            <div
                                                className={styles.customMarketName}
                                            >
                                                {item?.platform?.title}
                                            </div>
                                        </a>
                                    ) : (
                                        <div
                                            className={styles.customMarket}
                                        >
                                            <div>
                                                <div
                                                    style={{
                                                        border: `1px solid ${item?.color ? item.color : '#000'}`,
                                                    }}
                                                    className={styles.customMarketIcon}
                                                >
                                                    <PlatformIconShop
                                                        width={isSmallScreenLaptop ? "16px" : "20px"}
                                                        height={isSmallScreenLaptop ? "16px" : "20px"}
                                                        fill={item?.color ? item.color : '#000'}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={styles.customMarketName}
                                                style={{
                                                    fontSize: isSmallScreenLaptop ? (hasTitle ? 13 : 12) : (hasTitle ? 16 : 15),
                                                    color: hasTitle ? "#2B2B2B" : "#393838",
                                                }}
                                            >
                                                {item?.platform?.title}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        }
                        return null;
                    })
                }

            </>
        </div>
    );
};

export default PlatformsRow;
