import {CopyIcon, PopoverIcon, ResearchIcon} from "../../../../assets/icons";
import Tippy from "@tippyjs/react";
import styles from './KeywordComponent.module.scss';

import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import {TextAreaTagify} from "../../TextAreaTagify";
import {InputCount} from "../../InputCount";
import React, {useState} from "react";
import _ from "lodash";
import Listings from "@services/Listings";
import _get from "lodash/get";
import {toast} from "react-toastify";
import Button, {ModifierType} from "../../../UI/Button";
import Auth from "@services/Auth";

export const KeywordsComponent = ({is_role_allowed, hasPro, savingProgress, enabledButtons, editIndex, itemData, skuKeys,config,SKUInputValue,activeItemSKUValue,editId,SKUprefix,SKUformat,skuGeneratorUniqueIdFormat, setFormInfo, setItemData, handleSKUitemClick, activeItemSKU,handleKeyPress, handleInputChangeSKU, setSKUloader, SKUloader, hasSimple, formInfo, unescapedRowMetadata, handleTagifiesChange, handleStandartInputChange, enableToShowSkuGenerator }:any) => {
    const { bdecode } = useBasisFuncs();
    const [copied, setCopied] = useState(false);

    const [hideSkuGenerator, setHideSkuGenerator] = useState(false);
    const token: any = Auth.get_token();
    const hasValidSkuLocationValue = () => {
        return skuKeys?.some((key:any) => config[key] !== undefined && config[key] !== '');
    }
    const hasLocationNumberInCustomSkuFormat = config?.sku_generator_format?.includes("location_number") ;
    const hasLocation = config?.sku_generator_format?.includes('location_type')


    const handleGenerateSKU = async() => {
        const defaultSkuFormat = ['sku_prefix',' ', 'location_type', 'location_number',' ', 'unique_id',' ','weight'];
        try {
            const payload:any = {
                location_number: SKUInputValue?.trim() === '' ? null :  Number(SKUInputValue),
                location_type: activeItemSKUValue,
                entry_id: editId,
                sku_prefix: SKUprefix,
                sku_format: SKUformat.length ? SKUformat : defaultSkuFormat,
                weight: (() => {
                    const lbkg = formInfo?.newShippingWeightlbkg?.text;
                    const ozg = formInfo?.newShippingWeightozg?.text;

                    if (lbkg && ozg) {
                        return `${lbkg}-${ozg}`;
                    } else if (ozg) {
                        return `0-${ozg}`;
                    } else if (lbkg) {
                        return `${lbkg}-0`;
                    } else {
                        return "";
                    }
                })(),
                unique_id_format: skuGeneratorUniqueIdFormat,
            };

            const response : any = await Listings.generate_sku_for_listing(token,  payload);
            if (response) {
                const data = response.data;
                const skuId = _get(data, 'sku_id');
                if (skuId) {
                    // If sku_id exists, update the newSku text in expressList
                    setFormInfo({
                        ...formInfo,
                        ['newSku']: { text: skuId, changed: true },
                    });
                    const updatedItem = [...itemData];

                    updatedItem[editIndex] = {
                        ...updatedItem[editIndex],
                        ['newSku']: skuId,
                        changed: true,
                    };
                    setItemData(updatedItem)
                };
                setHideSkuGenerator(true);
            }
            toast.success('SKU generated !', {
                autoClose: 2000, // Set the duration for auto close
                transitionDuration: 500, // Set the transition duration for this specific notification
                position: 'bottom-right'
            } as any); // Type cast the options object
        }
        catch (e) {
            toast.error('Something went wrong while Generating SKU please try again', {
                autoClose: 3000, // Set the duration for auto close
                transitionDuration: 500, // Set the transition duration for this specific notification
                position: 'bottom-right'
            } as any); // Type cast the options object
        }

    };

    const handleSKUGeneratorClick = async() => {
        if(SKUloader || !enabledButtons || savingProgress || !editId) return
        setSKUloader(true);
        try {
            await handleGenerateSKU();
        }
        catch (error) {
            toast.error('An error occurred while creating the listing and generating the SKU. Please try again.', {
                position: 'bottom-right',
            });
        }
        finally {
            setSKUloader(false);
        }
    }

    const handleCopyClick = () => {
       try{
           const value = formInfo.newKeywords.changed
               ? bdecode(formInfo.newKeywords.text)
               : unescapedRowMetadata[453]
                   ? bdecode(unescapedRowMetadata[453])
                   : '';

           let textToCopy = '';

           try {
               // Step 1: Check if the value is a stringified JSON array
               const parsedValue = typeof value === 'string' ? JSON.parse(value) : value;

               // Step 2: If it's an array, extract the 'value' property and join with commas
               if (Array.isArray(parsedValue)) {
                   textToCopy = parsedValue.map(item => item.value).join(', ');
               } else {
                   // If it's not an array, use the original value as a string
                   textToCopy = value;
               }
           } catch (error) {
               // If JSON parsing fails, treat it as a regular string
               textToCopy = value;
           }

           // Copy the text to clipboard
           if (textToCopy) {
               navigator.clipboard.writeText(textToCopy);
               setCopied(true);
               setTimeout(()=>{
                   setCopied(false)
               }, 2000)

           }
       }
       catch(e){
           console.log(e, 'error')
       }
    };

    const hasNewTitle = formInfo?.newTitle?.changed && formInfo?.newTitle?.text?.length > 0;
    const hasMetadataTitle = !formInfo?.newTitle?.changed && unescapedRowMetadata[133];
    const isEnabledPriceTool = hasNewTitle || hasMetadataTitle;

    return(
        <div>
            <div className={styles.accordionContent}>
                <div className={styles.accordionContent2}>
                    <div className={styles.upperContainer}>
                        {
                            !hasSimple && <div className={styles.leftContainer}>
                                <div className={styles.headerContainer}>
                                    <p className={styles.heading}>
                                        Keywords or Tags
                                        <Tippy
                                            interactive={true}
                                            zIndex={5}
                                            trigger="click"
                                            arrow={false}
                                            content={
                                                <div
                                                    className={`popover ${styles.popoverContent}`}
                                                >
                                                    <ul>
                                                        <li>
                                                            Enter your keywords and separate them
                                                            with commas.
                                                        </li>
                                                        <li>
                                                            Example: summer, vintage, classic,
                                                            retro shirt
                                                            <ul>
                                                                <li>
                                                                    Example: summer, vintage, classic,
                                                                    retro shirt
                                                                </li>
                                                            </ul>
                                                        </li>

                                                        <div className={styles.line}></div>
                                                        <p>
                                                            ★ Crossposts to supported 'keywords
                                                            and tags' fields.
                                                        </p>
                                                    </ul>
                                                </div>
                                            }
                                        >
                                            <PopoverIcon
                                                className={styles.popoverIcon}
                                                color={'#fff'}
                                            ></PopoverIcon>
                                        </Tippy>
                                       <div className={styles.copyKeywordsDesc}>
                                           <Button modifier={ModifierType.Secondary} handleClick={handleCopyClick}>
                                               <CopyIcon/>
                                               <span>Copy</span>
                                           </Button>
                                           {
                                               copied && <p className={styles.copiedText}>
                                                   Copied!
                                               </p>
                                           }
                                       </div>
                                    </p>
                                       <div className={styles.copyKeywordsMobile}>
                                           <Button modifier={ModifierType.Secondary} handleClick={handleCopyClick}>
                                               <CopyIcon/>
                                               <span>Copy</span>
                                           </Button>
                                            {
                                                copied && <p className={styles.copiedText}>
                                                    Copied!
                                                </p>
                                            }
                                        </div>
                                    {/*<div className={styles.suggestBtn}>*/}
                                    {/*  Suggest Keywords*/}
                                    {/*</div>*/}
                                </div>
                                <TextAreaTagify
                                    placeholder="Keywords or Tags"
                                    name="input_453"
                                    isTagify={true}
                                    value={

                                        formInfo.newKeywords.changed
                                            ? bdecode(formInfo.newKeywords.text)
                                            : unescapedRowMetadata[453]
                                                ? bdecode(unescapedRowMetadata[453])
                                                : ''
                                    }
                                    handleTagifyChange={handleTagifiesChange}
                                    limit={700}
                                    height={200}
                                />
                                {
                                    ((hasPro && is_role_allowed) && enableToShowSkuGenerator) &&
                                    <div
                                        className={`${styles.inputContainerDefault} ${styles.inputContainerDefaultSKU}`}>
                                        <div className={`${styles.inpRightItem} ${styles.skuGeneratorInput}`}>
                                            <p className={styles.heading}>
                                                SKU
                                                <Tippy
                                                    interactive={true}
                                                    zIndex={5}
                                                    trigger="click"
                                                    arrow={false}
                                                    content={
                                                        <div
                                                            className={`popover ${styles.popoverContent}`}
                                                        >
                                                            <ul>
                                                                <li>'Stock Keeping Unit'</li>
                                                                <li>
                                                                    Used to track inventory movement and
                                                                    locate inventory.
                                                                </li>
                                                                <li>
                                                                    Example: G-Box32 = Located in
                                                                    garage, in box #32.
                                                                </li>
                                                                <div className={styles.line}></div>
                                                                <p>
                                                                    ★ Crossposts to supported 'SKU'
                                                                    fields.
                                                                </p>
                                                            </ul>
                                                        </div>
                                                    }
                                                >
                                                    <PopoverIcon
                                                        className={styles.popoverIcon}
                                                        color={'#fff'}
                                                    ></PopoverIcon>
                                                </Tippy>
                                            </p>
                                            <InputCount
                                                placeholder="SKU"
                                                name="input_1"
                                                value={
                                                    formInfo.newSku.changed
                                                        ? bdecode(formInfo.newSku.text)
                                                        : unescapedRowMetadata[1]
                                                            ? bdecode(unescapedRowMetadata[1])
                                                            : ''
                                                }
                                                handleChange={handleStandartInputChange}
                                                limit={250}
                                                height={40}
                                                hideCount={true}
                                            />
                                        </div>
                                        {
                                            (!hideSkuGenerator) && <div className={styles.skuContainer}>
                                                <div className={styles.title}>
                                                    SKU Generator
                                                </div>
                                                <div className={styles.skuInnerContainer}>
                                                    <div className={styles.skugeneratorFlex}>

                                                        {hasValidSkuLocationValue() && hasLocation
                                                            &&
                                                            <div className={styles.skuLocationExpress}>
                                                                <p>SKU Location</p>
                                                                <div className={styles.skuLocationExpressInner}>
                                                                    {skuKeys.map((key: any) => (
                                                                        (_.has(config, key) && config[key]?.length) ? (
                                                                            <div
                                                                                key={key}
                                                                                onClick={() => handleSKUitemClick(key)}
                                                                                className={`${styles.generateSkuItem} ${activeItemSKU === key ? styles.active : ''}`}
                                                                            >
                                                                                {config[key]}
                                                                            </div>
                                                                        ) : null
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        }

                                                        {
                                                            hasLocationNumberInCustomSkuFormat &&
                                                            <div className={styles.skuLocationNumberExpress}>
                                                                <p>Location Number </p>
                                                                <input type="text" placeholder="Enter number"
                                                                       onKeyPress={handleKeyPress}
                                                                       value={SKUInputValue}
                                                                       onChange={handleInputChangeSKU}/>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 5
                                                    }
                                                    }>
                                                        {
                                                            editId ?
                                                                <Button modifier={ModifierType.Secondary} handleClick={handleSKUGeneratorClick} disabled={SKUloader || !enabledButtons || savingProgress || !editId}>
                                                                    {SKUloader ? 'Generating SKU' : 'Generate SKU'}
                                                                </Button>
                                                                : <Tippy
                                                                    interactive={true}
                                                                    zIndex={9999}
                                                                    trigger="click"
                                                                    arrow={false}
                                                                    content={
                                                                        <div className={`popover ${styles.popoverContent}`}>
                                                                            <p>
                                                                                Save the listing once to generate a unique ID, which activates the SKU generator
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <button className={styles.GenerateButton}
                                                                            style={SKUloader || !enabledButtons || savingProgress || !editId ? {opacity: 0.7, cursor: 'not-allowed'} : {opacity: 1, cursor: 'pointer'}}
                                                                    >
                                                                        {SKUloader ? 'Generating SKU' : 'Generate SKU'}
                                                                    </button>
                                                                </Tippy>
                                                        }

                                                        <Tippy
                                                            interactive={true}
                                                            zIndex={9999}
                                                            trigger="click"
                                                            arrow={false}
                                                            content={
                                                                <div className={`popover ${styles.popoverContent}`}>
                                                                    <p>
                                                                        Utilize SKU generator to create a sequential
                                                                        customizable
                                                                        SKU
                                                                        system. <a
                                                                        href={"https://help.listperfectly.com/en/articles/9660321 "}
                                                                        target={"_blank"}>Learn more</a>.
                                                                    </p>
                                                                </div>
                                                            }
                                                        >
                                                            <PopoverIcon
                                                                className={styles.popoverIcon}
                                                                color={'#fff'}
                                                            ></PopoverIcon>
                                                        </Tippy>
                                                    </div>


                                                </div>
                                            </div>
                                        }
                                    </div>
                                }

                            </div>
                        }
                        <div className={styles.rightContainer}>
                            <div className={styles.inputsItem}>
                                {
                                    !hasSimple && <div className={styles.inpLeftItem}>
                                        <p className={styles.heading}>
                                            COGS
                                            <Tippy
                                                interactive={true}
                                                zIndex={5}
                                                trigger="click"
                                                arrow={false}
                                                content={
                                                    <div
                                                        className={`popover ${styles.popoverContent}`}
                                                    >
                                                        <ul>
                                                            <li>
                                                                Cost of Goods - What you paid to buy
                                                                or create the listing for sale.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                }
                                            >
                                                <PopoverIcon
                                                    className={styles.popoverIcon}
                                                    color={'#fff'}
                                                ></PopoverIcon>
                                            </Tippy>
                                        </p>
                                        <InputCount
                                            placeholder="COGS"
                                            name="input_847"
                                            type="number"
                                            value={
                                                formInfo.newCogs.changed
                                                    ? bdecode(formInfo.newCogs.text)
                                                    : unescapedRowMetadata[847]
                                                        ? bdecode(unescapedRowMetadata[847])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={500}
                                            height={40}
                                            hideCount={true}
                                        />
                                    </div>
                                }
                                <div className={styles.inpRightItem}>
                                    <p className={styles.heading}>
                                        Price
                                        <Tippy
                                            interactive={true}
                                            zIndex={5}
                                            trigger="
                                                click"
                                            arrow={false}
                                            content={
                                                <div
                                                    className={`popover ${styles.popoverContent}`}
                                                >
                                                    <ul>
                                                        <li>
                                                            Enter number only, without currency
                                                            symbols
                                                        </li>
                                                        <div className={styles.line}></div>
                                                        <p>
                                                            ★ Crossposts to supported 'price'
                                                            fields.
                                                        </p>
                                                    </ul>
                                                </div>
                                            }
                                        >
                                            <PopoverIcon
                                                className={styles.popoverIcon}
                                                color={'#fff'}
                                            ></PopoverIcon>
                                        </Tippy>
                                        {
                                            (hasPro || (hasPro && is_role_allowed)) &&
                                            <div className={styles.researchLensContainer}>
                                                {
                                                    isEnabledPriceTool ? (
                                                        <Button
                                                            modifier={ModifierType.Primary2}
                                                            disabled={!hasNewTitle && !hasMetadataTitle}
                                                            handleClick={() => {
                                                                const screenWidth = window.screen.width;
                                                                const screenHeight = window.screen.height;

                                                                // Set popup size dynamically as a percentage of screen size
                                                                const popupWidth = Math.min(0.9 * screenWidth); // 90% of screen width
                                                                const popupHeight = Math.min(0.75 * screenHeight); // 75% of screen height

                                                                const left = (screenWidth - popupWidth) / 2;
                                                                const top = (screenHeight - popupHeight) / 2;

                                                                window.open(
                                                                    `https://www.ebay.com/sh/research?marketplace=EBAY-US&keywords=${
                                                                        hasNewTitle ? formInfo?.newTitle?.text : unescapedRowMetadata[133]
                                                                    }&dayRange=365&endDate=1729187113959&startDate=1697651113959&categoryId=0&offset=0&limit=50&sorting=-avgsalesprice&tabName=SOLD&tz=Asia%2FTbilisi`,
                                                                    '_blank',
                                                                    `width=${popupWidth},height=${popupHeight},left=${left},top=${top},resizable=yes,scrollbars=yes`
                                                                );
                                                            }}

                                                        >
                                                            <ResearchIcon />
                                                        </Button>
                                                    ) : (
                                                        <Tippy
                                                            interactive={true}
                                                            arrow={false}
                                                            zIndex={9999}
                                                            trigger="click"
                                                            content={
                                                                <div className={`popover ${styles.popoverContent}`}>
                                                                    <p>Add listing title to use research button</p>
                                                                </div>
                                                            }
                                                        >
                                                            <div>
                                                                <Button modifier={ModifierType.Primary2} disabled={true}>
                                                                    <ResearchIcon />
                                                                </Button>
                                                            </div>
                                                        </Tippy>
                                                    )
                                                }
                                            </div>
                                        }
                                    </p>
                                    <InputCount
                                        placeholder="Price"
                                        name="input_22"
                                        type="number"
                                        value={
                                            formInfo.newPrice.changed
                                                ? bdecode(formInfo.newPrice.text)
                                                : unescapedRowMetadata[22]
                                                    ? bdecode(unescapedRowMetadata[22])
                                                    : ''
                                        }
                                        handleChange={handleStandartInputChange}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                    />
                                </div>
                            </div>
                            {
                                !hasSimple && <div className={styles.inputsItem}>
                                    <div className={styles.inpLeftItem}>
                                        <p className={styles.heading}>
                                            MSRP
                                            <Tippy
                                                interactive={true}
                                                zIndex={5}
                                                trigger="click"
                                                arrow={false}
                                                content={
                                                    <div
                                                        className={`popover ${styles.popoverContent}`}
                                                    >
                                                        <ul>
                                                            <li>
                                                                'Manufacturer's Recommended Sale
                                                                Price'
                                                            </li>
                                                            <li>
                                                                Original price as displayed on
                                                                manufacturer tags
                                                            </li>
                                                            <div className={styles.line}></div>
                                                            <p>
                                                                ★ Crossposts to supported 'MSRP'
                                                                fields.
                                                            </p>
                                                        </ul>
                                                    </div>
                                                }
                                            >
                                                <PopoverIcon
                                                    className={styles.popoverIcon}
                                                    color={'#fff'}
                                                ></PopoverIcon>
                                            </Tippy>
                                        </p>
                                        <InputCount
                                            placeholder="MSRP"
                                            name="input_104"
                                            type="number"
                                            value={
                                                formInfo.newMSRP.changed
                                                    ? bdecode(formInfo.newMSRP.text)
                                                    : unescapedRowMetadata[104]
                                                        ? bdecode(unescapedRowMetadata[104])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                    </div>
                                    {
                                        !enableToShowSkuGenerator &&
                                        <div className={styles.inpRightItem}>
                                            <p className={styles.heading}>
                                                SKU
                                                <Tippy
                                                    interactive={true}
                                                    zIndex={5}
                                                    trigger="click"
                                                    arrow={false}
                                                    content={
                                                        <div
                                                            className={`popover ${styles.popoverContent}`}
                                                        >
                                                            <ul>
                                                                <li>'Stock Keeping Unit'</li>
                                                                <li>
                                                                    Used to track inventory movement and
                                                                    locate inventory.
                                                                </li>
                                                                <li>
                                                                    Example: G-Box32 = Located in
                                                                    garage, in box #32.
                                                                </li>
                                                                <div className={styles.line}></div>
                                                                <p>
                                                                    ★ Crossposts to supported 'SKU'
                                                                    fields.
                                                                </p>
                                                            </ul>
                                                        </div>
                                                    }
                                                >
                                                    <PopoverIcon
                                                        className={styles.popoverIcon}
                                                        color={'#fff'}
                                                    ></PopoverIcon>
                                                </Tippy>
                                            </p>
                                            <InputCount
                                                placeholder="SKU"
                                                name="input_1"
                                                value={
                                                    formInfo.newSku.changed
                                                        ? bdecode(formInfo.newSku.text)
                                                        : unescapedRowMetadata[1]
                                                            ? bdecode(unescapedRowMetadata[1])
                                                            : ''
                                                }
                                                handleChange={handleStandartInputChange}
                                                limit={250}
                                                height={40}
                                                hideCount={true}
                                            />
                                        </div>
                                    }
                                    {
                                        enableToShowSkuGenerator &&
                                        <div className={styles.inpRightItem}>
                                            <p className={styles.heading}>
                                                Quantity
                                                <Tippy
                                                    interactive={true}
                                                    zIndex={5}
                                                    trigger="click"
                                                    arrow={false}
                                                    content={
                                                        <div
                                                            className={`popover ${styles.popoverContent}`}
                                                        >
                                                            <ul>
                                                                <li>Enter the quantity.</li>
                                                                <div className={styles.line}></div>
                                                                <p>
                                                                    ★ Crossposts to supported 'Quantity'
                                                                    fields.
                                                                </p>
                                                            </ul>
                                                        </div>
                                                    }
                                                >
                                                    <PopoverIcon
                                                        className={styles.popoverIcon}
                                                        color={'#fff'}
                                                    ></PopoverIcon>
                                                </Tippy>
                                            </p>
                                            <InputCount
                                                placeholder="Quantity"
                                                name="input_851"
                                                type="number"
                                                value={
                                                    formInfo.newQuantity.changed
                                                        ? bdecode(formInfo.newQuantity.text)
                                                        : unescapedRowMetadata[851]
                                                            ? bdecode(unescapedRowMetadata[851])
                                                            : ''
                                                }
                                                handleChange={handleStandartInputChange}
                                                limit={250}
                                                height={40}
                                                hideCount={true}
                                            />
                                        </div>
                                    }

                                </div>
                            }
                            {
                                !hasSimple && <div className={styles.inputsItem}>
                                    <div className={styles.inpLeftItem}>
                                        <p className={styles.heading}>
                                            UPC
                                            <Tippy
                                                interactive={true}
                                                zIndex={5}
                                                trigger="click"
                                                arrow={false}
                                                content={
                                                    <div
                                                        className={`popover ${styles.popoverContent}`}
                                                    >
                                                        <ul>
                                                            <li>'Universal Product Code'</li>
                                                            <li>
                                                                Barcode number located on the
                                                                scannable tag.
                                                            </li>
                                                            <div className={styles.line}></div>
                                                            <p>
                                                                ★ Crossposts to supported 'UPC'
                                                                fields.
                                                            </p>
                                                        </ul>
                                                    </div>
                                                }
                                            >
                                                <PopoverIcon
                                                    className={styles.popoverIcon}
                                                    color={'#fff'}
                                                ></PopoverIcon>
                                            </Tippy>
                                        </p>
                                        <InputCount
                                            placeholder="UPC"
                                            name="input_704"
                                            value={
                                                formInfo.newUpc.changed
                                                    ? bdecode(formInfo.newUpc.text)
                                                    : unescapedRowMetadata[704]
                                                        ? bdecode(unescapedRowMetadata[704])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                    </div>
                                    {
                                        !enableToShowSkuGenerator &&
                                        <div className={styles.inpRightItem}>
                                            <p className={styles.heading}>
                                                Quantity
                                                <Tippy
                                                    interactive={true}
                                                    zIndex={5}
                                                    trigger="click"
                                                    arrow={false}
                                                    content={
                                                        <div
                                                            className={`popover ${styles.popoverContent}`}
                                                        >
                                                            <ul>
                                                                <li>Enter the quantity.</li>
                                                                <div className={styles.line}></div>
                                                                <p>
                                                                    ★ Crossposts to supported 'Quantity'
                                                                    fields.
                                                                </p>
                                                            </ul>
                                                        </div>
                                                    }
                                                >
                                                    <PopoverIcon
                                                        className={styles.popoverIcon}
                                                        color={'#fff'}
                                                    ></PopoverIcon>
                                                </Tippy>
                                            </p>
                                            <InputCount
                                                placeholder="Quantity"
                                                name="input_851"
                                                type="number"
                                                value={
                                                    formInfo.newQuantity.changed
                                                        ? bdecode(formInfo.newQuantity.text)
                                                        : unescapedRowMetadata[851]
                                                            ? bdecode(unescapedRowMetadata[851])
                                                            : ''
                                                }
                                                handleChange={handleStandartInputChange}
                                                limit={250}
                                                height={40}
                                                hideCount={true}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}