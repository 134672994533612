import {ChangeEvent, useState} from 'react';
import _ from 'lodash';
import styles from './Search.module.scss';
import {IFilterBy} from '../../../../interfaces';

import {useLocation} from 'react-router-dom';
import {FaSearch} from "../../../../assets/icons";

interface ISearchProps {
	searchItem: string;
	setSearchItem: (value: string) => void;
	filterBy: Record<string, any>;
	setFilterBy: (value: IFilterBy) => void;
	handleFetchForSearch: (filterValue: string) => void;
}

const Search = (props: ISearchProps) => {
	const {
		searchItem,
		setSearchItem,
		filterBy,
		setFilterBy,
		handleFetchForSearch,
	} = props;

	const [cacher, setCacher] = useState(true);
	const [isFocused, setIsFocused] = useState(false);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
		setIsFocused(false);
		if (!event.target.value) {
			setIsFocused(false);
		}
	};

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		let escapedValue = _.escape(e.target?.value);

		if (escapedValue?.length > 30) {
			return;
		}

		setCacher(false);
		setSearchItem(e.target?.value);

		if (e.target?.value?.trim()?.length >= 2) {
			setFilterBy({ ...filterBy, search_everything: e.target?.value });
			handleFetchForSearch(e.target?.value);
		} else if (!e.target?.value?.length) {
			setFilterBy(_.omit(filterBy, ['search_everything']));
			handleFetchForSearch('');
		}
	};

	const filterByParam = queryParams.get('filter_by');
	let decodedFilterBy;
	if (filterByParam) {
		try {
			let safeParam = filterByParam.replace(/%(?![0-9A-Fa-f]{2})/g, "%25");
			let decodedValue :any = decodeURIComponent(safeParam);
			const tempElement = document.createElement("textarea");
			tempElement.innerHTML = decodedValue;
			decodedValue = tempElement.value;
			decodedFilterBy = JSON.parse(decodedValue) || {};
		}
		catch (e) {console.log(e, 'error')}
	}

	const searchEverythingValue = _.get(decodedFilterBy, 'search_everything');

	return (

	 <div style={{position: 'relative'}}>
		 <div style={{display: 'flex', alignItems: 'center'}}>
			 <input
			  className={(_.escape(searchItem?.length) <= 30) ? styles.myInput : `${styles.myInput} ${styles.myInputDanger}`}
			  type="text"
			  maxLength={30}
			  placeholder={isFocused ? '' : '     Search everything'}
			  value={searchItem || (cacher && searchEverythingValue) || ''}
			  onFocus={handleFocus}
			  onBlur={handleBlur}
			  onChange={handleChangeInput}
			 />
			 {!isFocused && !searchItem && !searchEverythingValue && (
			  <div className="SearchEverythingIcon">
				  <FaSearch/>
			  </div>
			 )}
		 </div>
		 {isFocused && <p
          className={
			  _.escape(searchItem?.length) <= 30 ? styles.characterText : `${styles.characterText} ${styles.characterTextDanger}`}>
          <span> {(_.escape(searchItem || '')?.length === 0 ? _.escape(filterBy?.search_everything || '') : _.escape(searchItem || ''))?.length || 0}</span>/30
			 {
				 _.escape(searchItem?.length) > 30 && <span
               style={{marginLeft: 5}}
              >- Limit exceeded
                  </span>
			 }
         </p>}
	 </div>

	);
};

export default Search;
