import React, {Fragment, useEffect, useState} from 'react';
import {useGlobalUploaderState} from "./state";
import {UploaderMainLoader} from "./components/UploaderMainLoader";
import UploaderService from "./services";
import ImageUploadButton from "./container/ImageUploadButton";
import UploadedImageList from "./container/UploadedImageList";
import slugify from "slugify";
import Compressor from "compressorjs";
import {toast} from "react-toastify";

import {
    getImageFilenameAndExtensionSeparately,
    getUniqueId,
    replaceSuffixInFilename,
    getFilenameFromUrl, sleep, isOldImage
} from "./common";
import ImageUploaderModal from "./container/ImageUploaderModal";
import {BackgroundRemoveStats, UploaderFile} from "./interfaces";
import 'react-rangeslider/lib/index.css'
import 'react-image-crop/dist/ReactCrop.css'
import {bucketUrl, imageUploadLimitCount, isMobile} from "./const/const";
import ImageUploaderBulkActions from "./container/ImageUploaderBulkActions";
import Tippy from "@tippyjs/react";
import {PopoverIcon} from "../../assets/icons";
import SizelyLogo from "@assets/icons/png/size_ly.png" ;

import MobileImageActionsModal from "./container/MobileImageActionsModal";
import './common/drag-drop-touch';
import './common/filters-polyfill';
import {MoonLoader} from "react-spinners";
import UserConfigs from "@services/UserConfigs";
import UseExtensionInstall from "@hooks/UseExtensionInstall";
import UseIsMobile from "@hooks/UseIsMobile";
import UseDetailedExtensionVersion from "@hooks/UseDetailedExtensionVersion";
import Auth from "@services/Auth";
import useRoles from "@hooks/useRoles";
let uploadedImageCounter: number= 0;
let currentUploaderImageCounter: number = 0;
function App({ filesList, lpListingFiles, handleImageEditComplete,isUploaderOpen, displayBulkActions, displayUploaderTitle, handleChangeUploaderInputs,expressListerView, allowListingSave, setImagesFromUploader}: { filesList?: any, isUploaderOpen: Function,handleImageEditComplete: Function, lpListingFiles: Array<any>, handleChangeUploaderInputs:Function,allowListingSave: Function,expressListerView: Boolean, displayBulkActions: Boolean, displayUploaderTitle: Boolean, setImagesFromUploader:any}) {
    const { state,dispatch } = useGlobalUploaderState();
    const [focused, setFocused] = useState(false);
    const [isValidFileAvailable, setValidFileAvailable] =  useState(false);
    const {files,activeFile, imageLoadingThreadsQueue} = state;
    const [mobileImageActions, setMobileImageActionsVisibility] = useState(false);
    const [mobileImageActionsActiveIndex, setMobileImageActionsActiveIndex] = useState(null);
    const [finishedUploadedImages, setFinishedUploadedImages] = useState([]);
    const [showImagesUploadingStatusLoader, setShowImagesUploadingStatusLoader] = useState(false);
    //
    const [extensionSettings,setExtensionSettings] = useState<any>({})
    //
    const token: any = Auth.get_token();
    const { hasSimple, hasPro, is_role_allowed } = useRoles();

    useEffect(() => {
        activeFile ? isUploaderOpen(true) : isUploaderOpen(false);
    }, [activeFile]);

    useEffect(() => {
            if(files.length === 0) return;
            imageLoadingThreadsQueue > 0 ?   allowListingSave(false) :   allowListingSave(true);
    }, [imageLoadingThreadsQueue]);

    useEffect(() => {
        let imagesArray: any = [];
        try {
            files?.slice(0, 6).forEach((item)=>{
                if(item?.fileS3Link){
                    imagesArray.push(item?.fileS3Link);
                }
            })
            setImagesFromUploader(imagesArray);
            const bottomMobileModal = document.querySelector('.mobile-bottom-modal-container');
            const bottomModal = document.querySelector('.mobile-bottom-modal');
            const uploaderInput = document.getElementById('imageUploadInput');
            const body = document.querySelector('body');
            if(files.length >= 30) {
                bottomMobileModal?.classList.add('hideElement');
                uploaderInput?.removeAttribute("capture");
                bottomModal?.classList.add('slide-bottom');
                bottomModal?.classList.remove('slide-top');
                setTimeout(() => {
                    body?.classList.remove('overflow-hidden');
                    bottomMobileModal?.classList.add('hideElement');
                    isUploaderOpen(false);
                }, 300);
            }
        }
        catch (e) {
           console.log(e, 'error')
        }

    },[files])

    useEffect(() => {
        UploaderService.loadRemoveBackgroundStats().then((data: BackgroundRemoveStats) => {
            dispatch({type: "SETBACKGROUNDSTATS", data})
        })
        UploaderService.loadUserConfig().then((result:any) => {
            let {message = false, config} = result;
            const  {remove_bg_colors = null,selected_remove_bg_color} = config || {remove_bg_colors: null}
            if (message && message === 'Config not found.'){
                //config not found
            }
            if (!remove_bg_colors){
                config = {...config, selected_remove_bg_color: "#FFFFFF",remove_bg_colors:  ["#000000", "#FFFFFF", "#36454F", "#00D084", "#8ED1FC", "#0693E3", "#FF6900", "#FCB900", "#F78DA7", "#9900EF"]}
                UploaderService.saveUserConfig(config).then()
            }
            dispatch({type: "SETUSERCONFIG", data: config})
            dispatch({type: "SETBACKGROUNDREMOVECOLORS", data: {selected_remove_bg_color: config.selected_remove_bg_color, remove_bg_colors: config.remove_bg_colors}})
        })
    }, [])

    useEffect(() => {
        if(files.length === 0) return;
        let thumbnailImage: number | null = null
        let listingImages: Array<number> = [];
        let isValidFile = false;
        files.map((file) => {
            const {lp_id, error, placeholder} = file;
            if (lp_id){
                if (!thumbnailImage) thumbnailImage = lp_id
                else listingImages.push(lp_id)
            }
            if (!placeholder && !error) isValidFile = true;
        })
        let thumbnailInput: any = document.querySelector('input[name="input_727"]');
        let imagesInput: any  = document.querySelector('input[name="input_728"]');
        if (thumbnailInput && imagesInput){
            thumbnailInput.value = thumbnailImage
            imagesInput.value = listingImages.join(',')
            handleChangeUploaderInputs(thumbnailImage, listingImages.join(','))
        }
        if (isValidFile !== isValidFileAvailable) setValidFileAvailable(isValidFile)
    }, [files])

   useEffect(() => {
    try{
     if (files.length !== 0 || lpListingFiles.length === 0) {
         currentUploaderImageCounter = 0;
         return
     }
     for (let i = 0; i < lpListingFiles.length; i++) {
         const {id, link} = lpListingFiles[i];
         let filename = getFilenameFromUrl(link);
         filename = replaceSuffixInFilename(filename, '_CI', '_LP')
         let {
             fileExtension,
             filenameWithoutExtension
         } = getImageFilenameAndExtensionSeparately(filename);
         let fileInfo: UploaderFile = {
             file: null,
             index: uploadedImageCounter,
             imagePreviewUrl: link + `?rnd=${getUniqueId()}`,
             imageSmallPreviewUrl: replaceSuffixInFilename(link, '_CI', '_TN') + `?rndp=${getUniqueId()}`,
             loading: false,
             fileS3Link: link,
             isOldImage: isOldImage(link),
             backgroundLoading: false,
             preview: true,
             filename,
             placeholder: false,
             uploaded: false,
             filenameWithoutExtension: filenameWithoutExtension + '_LP',
             fileExtension,
             rotate: 1,
             flipVertical: false,
             flipHorizontal: false,
             brightness: 0,
             contrast: 0,
             lp_id: id,
             filter: null,
             error: false,
             edit: false,
         };
         uploadedImageCounter++;
         currentUploaderImageCounter++;
         dispatch({type: "ADDNEWFILE", data: fileInfo})
     }
    }
    catch (e){
     console.log(e, 'error')
   }
 }, [lpListingFiles]);

    const getExtensionSettins = async() => {
        const type:string = 'chrome_extension'
        try {
            const response:any = await UserConfigs.get(type,token)
            if(response) {
                const { config } = response;
                setExtensionSettings(config)
            }
        }
        catch (e) {
            console.log('error', e)
        }
    }

    useEffect(() => {
        getExtensionSettins();
    }, []);

    const generateImageSmallPreview = async (url: string) => {
        let canvas = document.createElement('canvas');
        let img: any = document.createElement("img");
        let reader = new FileReader();
        img.src = url
        return new Promise<Promise<any>>((resolve) => {
            img.onload = () => {
                //small preview
                let MAX_WIDTH = 640;
                let MAX_HEIGHT = 720;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;

                let ctx: any = canvas.getContext("2d")
                ctx.drawImage(img, 0, 0, width, height);
                // @ts-ignore
                resolve(canvas.toDataURL("image/jpeg"))
            }

        })
    }
    const dragOver = (e:any) => {
        if (!focused) setFocused(true);
        e.preventDefault();
    }

    const dragEnter = (e:any) => {
        e.preventDefault();
        setFocused(true);
    }
    const dragLeave = (e: any) => {
        e.preventDefault();
        setFocused(false);
    }

    const uploadUnsupportedFormatFile = async (file: File, fileExtension: string,filenameWithoutExtension:string) => {
        return new Promise((resolve) => {
            let filename = filenameWithoutExtension + fileExtension;
            let fileInfo: UploaderFile = {
                file: file,
                index: uploadedImageCounter,
                imagePreviewUrl: null,
                imageSmallPreviewUrl: null,
                filename: filename,
                isOldImage: false,
                loading: true,
                fileS3Link: null,
                preview: false,
                backgroundLoading: false,
                placeholder: false,
                uploaded: false,
                filenameWithoutExtension,
                fileExtension,
                rotate: 1,
                flipVertical: false,
                flipHorizontal: false,
                brightness: 0,
                contrast: 0,
                lp_id: null,
                filter: null,
                error: false,
                edit: false,
            };
            dispatch({type: "ADDNEWFILE", data: fileInfo})
            try {
                let uploadedFileIndex = uploadedImageCounter;
                //todo each catch for all
                dispatch({type: "INCREASEIMAGELOADINGQUEUE"})
                UploaderService.getS3Policy(filename).then(async ({fields}: any) => {
                    await UploaderService.uploadFileToS3(fields, file, filename);
                    const {success} = await UploaderService.fileGenerateSet(filename);
                    if (!success)   throw new Error("Error during generate set")
                    try{
                        filename =  filenameWithoutExtension + '.jpeg';
                        //TODO check how works
                        let CIFilename = replaceSuffixInFilename(filename,'_LP', '_CI');
                        let CILink = bucketUrl + CIFilename
                        const fileLPId:number = await UploaderService.getImageIdFromAdminAjax(CIFilename)
                        // @ts-ignore
                        dispatch({type: "SETNEWPROPERTYINFILE", data: { index: uploadedFileIndex, properties: {
                                    lp_id: fileLPId,
                                    fileS3Link: CILink,
                                    filename,
                                    imagePreviewUrl: CILink + `?rnd=${getUniqueId()}`,
                                    imageSmallPreviewUrl: replaceSuffixInFilename(CILink, '_CI', '_TN') + `?rndp=${getUniqueId()}`,
                                    uploaded: true,
                                    loading:false
                                }}})

                        dispatch({type: "DECREASEIMAGELOADINGQUEUE"})
                    }catch (e) {
                        dispatch({type: "DECREASEIMAGELOADINGQUEUE"})
                        console.log("Error during getting LP image ids",99999)
                        // throw new Error("Error during getting LP image ids")
                    }
                })

                uploadedImageCounter++;
                currentUploaderImageCounter++;
                resolve(true)
            } catch (e) {
                console.log(e);
                alert("Error during get s3 policy")
                resolve(true)
            }
        })
    }
    const addUploadedFileToState = async (file: any) => {
        if (currentUploaderImageCounter >= imageUploadLimitCount) {
            return toast.error(`Add up to 30 files. Limit reached.`);
        }
        let filename = file.name.replace('.jpg', '.jpeg')
        let {
            fileExtension,
            filenameWithoutExtension,
            clearFilenameExtension
        } = getImageFilenameAndExtensionSeparately(filename);

        let supportedFormats = ["jpg", "jpeg", "png", "bmp"]
        let uuid = getUniqueId();

        if (filenameWithoutExtension.length === 0) {
            filenameWithoutExtension = slugify(`${uuid}_LP`)
        } else {
            filenameWithoutExtension = slugify(`${filenameWithoutExtension}_${uuid}_LP`);
        }

        if (!supportedFormats.includes(clearFilenameExtension)){
            await uploadUnsupportedFormatFile(file, fileExtension,filenameWithoutExtension)
            return;
        }

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.8,
                maxHeight: 2000,
                maxWidth: 2000,
                mimeType: "image/jpeg",
                convertSize: 0,
                beforeDraw(context:any, canvas:any) {
                    context.fillStyle = '#fff';
                    context.fillRect(0, 0, canvas.width, canvas.height);
                },
                error: (error:any) => {
                    alert(error)
                },
                success: (compressedFile:any) => {
                    filename = filenameWithoutExtension + '.jpeg';
                    let reader: any = new FileReader();
                    reader.onload = async (e: any) => {
                        const smallPreview: string = await generateImageSmallPreview(e.target.result);
                        let fileInfo: UploaderFile = {
                            file: compressedFile,
                            index: uploadedImageCounter,
                            imagePreviewUrl: reader.result,
                            imageSmallPreviewUrl: smallPreview,
                            isOldImage: false,
                            loading: false,
                            fileS3Link: null,
                            preview: false,
                            backgroundLoading: false,
                            filename,
                            placeholder: false,
                            uploaded: false,
                            filenameWithoutExtension,
                            fileExtension,
                            rotate: 1,
                            flipVertical: false,
                            flipHorizontal: false,
                            brightness: 0,
                            contrast: 0,
                            lp_id: null,
                            filter: null,
                            error: false,
                            edit: false,
                        };
                        dispatch({type: "ADDNEWFILE", data: fileInfo})
                        try {
                            let uploadedFileIndex = uploadedImageCounter;
                            //todo each catch for all
                            dispatch({type: "INCREASEIMAGELOADINGQUEUE"})
                            UploaderService.getS3Policy(filename).then(async ({fields}: any) => {
                                await UploaderService.uploadFileToS3(fields, compressedFile, filename);
                                const {success} = await UploaderService.fileGenerateSet(filename);
                                if (!success)   throw new Error("Error during generate set")
                                try{
                                    let CIFilename = replaceSuffixInFilename(filename,'_LP', '_CI');
                                    let CILink = bucketUrl + CIFilename
                                    const fileLPId:number = await UploaderService.getImageIdFromAdminAjax(CIFilename)
                                    // @ts-ignore
                                    dispatch({type: "SETNEWPROPERTYINFILE", data: { index: uploadedFileIndex, properties: {
                                                lp_id: fileLPId,
                                                fileS3Link: CILink,
                                                uploaded: true
                                            }}})
                                    dispatch({type: "DECREASEIMAGELOADINGQUEUE"})
                                }catch (e) {
                                    dispatch({type: "DECREASEIMAGELOADINGQUEUE"})
                                    console.log("Error during getting LP image ids",99999)
                                // throw new Error("Error during getting LP image ids")
                                }
                            })

                            uploadedImageCounter++;
                            currentUploaderImageCounter++;
                            resolve(true)
                        } catch (e) {
                            console.log(e);
                            alert("Error during get s3 policy")
                            resolve(true)
                        }
                    };
                    reader.readAsDataURL(compressedFile);
                }
            });
        })

    }

    const uploadFileToUploader = (files: any, i: number) => {
        addUploadedFileToState(files[i]).then(() => {
            sleep(100).then(() => {
                if (files.length !== i  + 1) {
                    uploadFileToUploader(files, i+ 1)
                }else{
                    //upload done
                    // @ts-ignore
                    const imageUploadInput: any =  document.getElementById('imageUploadInput')
                    if(imageUploadInput) imageUploadInput.value = null;
                    if(isMobile && files.length === 1) updateButtonMobileModalView(false);
                }
            })
        })
    }

    useEffect(() => {
        if(filesList && filesList.length){
            uploadFileToUploader(filesList, 0)
        }
    }, [filesList]);

    const updateButtonMobileModalView = (hide = false) => {
        //for hide bottom tabs
        isUploaderOpen(true);
        const bottomModal = document.querySelector('.mobile-bottom-modal');
        const uploaderInput = document.getElementById('imageUploadInput');
        const body = document.querySelector('body');
        const bottomMobileModal = document.querySelector('.mobile-bottom-modal-container');
        if (!bottomMobileModal || !uploaderInput || !bottomModal || !body) return;
        if (hide) {
            uploaderInput.removeAttribute("capture");
            bottomModal.classList.add('slide-bottom');
            bottomModal.classList.remove('slide-top');
            setTimeout(() => {
                body.classList.remove('overflow-hidden');
                bottomMobileModal.classList.add('hideElement');
                isUploaderOpen(false);
            }, 300);
        } else {
            uploaderInput.setAttribute("capture", "camera");
            body.classList.add('overflow-hidden');
            bottomModal.classList.remove('slide-bottom');
            bottomModal.classList.add('slide-top');
            bottomMobileModal.classList.remove('hideElement');
        }
    }

    useEffect(() => {
        const newFinishedUploadedImages:any = [];
        // if(files?.length){
        //     setShowImagesUploadingStatusLoader(true);
        // }
        files?.map((item:any) => {
            if (item?.fileS3Link !== null) {
                newFinishedUploadedImages.push(item?.fileS3Link);
            }
            return null;
        });

        setFinishedUploadedImages(newFinishedUploadedImages);
    }, [files]);

    useEffect(() => {
        if(finishedUploadedImages?.length === files?.length){
           setTimeout(()=>{
               setShowImagesUploadingStatusLoader(false);
           }, 2000)
        }
    }, [finishedUploadedImages]);

    const {hasExtensionInstall} = UseExtensionInstall();
    const isMobileScreen = UseIsMobile()
    const showSizeLyLogo = UseDetailedExtensionVersion('1.0.53.1') ;


    return (
        <Fragment>
           {/*here is dinamic title need to send images array and uploadFileToUpload*/}
           {/*<UploaderPlaceholderText uploadFileToUpload={uploadFileToUpload} uploaderImages={[]} />*/}
            { mobileImageActions && <MobileImageActionsModal setMobileImageActionsVisibility={setMobileImageActionsVisibility}  setMobileImageActionsActiveIndex={setMobileImageActionsActiveIndex} files={files} showModal={setMobileImageActionsVisibility} imageIndex={mobileImageActionsActiveIndex} />}
            <div style={{position: "relative"}}>
                {displayUploaderTitle && <p className={'uploader-title'}>
                    Upload Images
                    <Tippy
                        interactive={true}
                        zIndex={5}
                        arrow={false}
                        trigger="click"
                        content={
                            <div className={`popover`}>
                                <p>- Add up to 30 files</p>
                                <p>- Max file size: 15MB</p>
                                <p>
                                    - Supported format: jpg, png, tiff, heic, webp, bmp
                                </p>
                            </div>
                        }
                    >
                        <PopoverIcon
                            style={{marginLeft: 6, cursor: "pointer"}}
                            color={'#fff'}
                        ></PopoverIcon>
                    </Tippy>
                </p>}
                {(displayBulkActions && !hasSimple) && <ImageUploaderBulkActions isValidFileAvailable={isValidFileAvailable}/>}

                {
                    showImagesUploadingStatusLoader ? (
                     <div className={"image-uploader-status-title"}>
                         <MoonLoader size={20}/>
                         <div>Images Uploading... <span className="uploaded-images-length"> {finishedUploadedImages?.length} of {files?.length}</span></div>
                     </div>
                    ) : <></>
                }

                <div 
                     onDragOver={dragOver}
                     onDragEnter={dragOver}
                     onDragLeave={dragLeave}
                     onDrop={(e: any) => {
                         if (!e.dataTransfer.files ||  !e.dataTransfer.files.length) return
                         e.preventDefault();
                         uploadFileToUploader(e.dataTransfer.files, 0)
                         setFocused(false)
                     }}
                     className="image-uploader-container">
                    { extensionSettings?.turnOnSizely && !isMobileScreen && hasExtensionInstall
                     && showSizeLyLogo && <div className={'lp_custom_chrome_extension_image_uploader_size_ly_logo'}>
                        <img src={SizelyLogo}/>
                    </div>}
                    <UploaderMainLoader/>
                    <div className={expressListerView && files.length ? "uploadedImageList pd-50": "uploadedImageList" } >
                        <ImageUploaderModal hasSimple={hasSimple} handleImageEditComplete={handleImageEditComplete}/>
                        <UploadedImageList hasPro={hasPro} isRoleAllowed={is_role_allowed} mobileImageActions={mobileImageActions} setMobileImageActionsVisibility={setMobileImageActionsVisibility} setMobileImageActionsActiveIndex={setMobileImageActionsActiveIndex}  onImageRemove={() => currentUploaderImageCounter--}/>
                        <ImageUploadButton expressListerView={expressListerView}  uploadFileToUploader={uploadFileToUploader} setShowImagesUploadingStatusLoader={setShowImagesUploadingStatusLoader}/>
                    </div>
                </div>
            </div>
            <div className="image-uploader-background mobile-bottom-modal-container hideElement">
                <div className="mobile-bottom-modal">
                    <div className="mobile-bottom-modal-header">
                        <div className="bottom-modal-close"></div>
                    </div>
                    <div className="mobile-bottom-modal-body">
                        <div className="success-checkmark"></div>
                        <div className="mobile-bottom-modal-headline">Uploaded</div>

                        <div>
                            <label htmlFor="imageUploadInput" className="btn btn-green">Next Photo</label>
                        </div>
                        <span onClick={() => updateButtonMobileModalView(true)} className="btn btn-white-blue close-bottom-modal">Done</span>
                    </div>

                </div>
            </div>

        </Fragment>
    );
}

export default App;