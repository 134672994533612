import React, {useEffect, useRef, useState} from 'react';
import styles from './TableEditColumn.module.scss';

import Tippy from "@tippyjs/react";
import {objectToString} from '../../../../utils/objectToString';
import {IColumn} from '../../../../interfaces';
import {CircleQuestionGraySVG, SVGArrow} from '../../../../assets/icons';
import formatString from '../../../../utils/formatString';
import Button, {ModifierType} from "../../../../components/UI/Button";

interface IColumnData {
  name: string;
  value: string;
  isChecked: boolean;
}

const columnData: IColumnData[] = [
  { name: 'Total Sold Price', value: 'total_sold_price', isChecked: true },

  { name: 'Date Sold', value: 'date_sold', isChecked: true },
  { name: 'Platforms', value: 'platforms', isChecked: true },
  { name: 'Sold On', value: 'sold_on', isChecked: true },

  { name: 'Shop', value: 'shop_id', isChecked: true },
  { name: 'SKU', value: 'sku', isChecked: true },

  {
    name: 'Shipping Cost Buyer',
    value: 'shipping_cost_buyer',
    isChecked: true,
  },

  {
    name: 'Shipping Cost Seller',
    value: 'shipping_cost_seller',
    isChecked: true,
  },

  { name: 'COGS', value: 'cogs', isChecked: true },
  { name: 'Fees', value: 'fees', isChecked: true },

  { name: 'Shipping Status', value: 'shipping_status', isChecked: true },

  { name: 'Linked', value: 'linked', isChecked: true },
  { name: 'Quantity Sold', value: 'quantity_sold', isChecked: true },
  { name: 'Quantity Available', value: 'quantity_available', isChecked: true },

  { name: 'Sales Tax', value: 'sales_tax', isChecked: true },
  { name: 'Profit', value: 'profit', isChecked: true },
];

interface ITableEditColumn {
  handleChangeModal: () => void;
  columns: IColumn[];
  handleEditColumns: (cols: string[]) => void;
}

const TableEditColumn = (props: ITableEditColumn) => {
  const { handleChangeModal, columns, handleEditColumns } = props;

  const settingsRef = useRef<HTMLDivElement>(null);

  const [editColumns, setEditColumns] = useState<any>([]);


  const [arranges, setArranges] = useState<string[]>([]);

  const [selectedItem, setSelectedItem] = useState<string>('');

  useEffect(() => {
    document.querySelectorAll('.top-headers-wrapper, .site-footer-copyright-area, .lp_custom_help_bubble_button_container')
    .forEach(el => el instanceof HTMLElement && (el.style.display = 'none'));
    document.querySelector('.site-content')?.setAttribute("style", "width: 100%");

    let checkedColumns = columnData.map(item => ({
      ...item,
      isChecked: columns.some(col => col.value === item.value)
    }));
    setEditColumns(checkedColumns);
    const initialColumns = objectToString(columns);
    setArranges(initialColumns);
    setSelectedItem(initialColumns[0] || '');

    return () => {
      document.querySelectorAll('.top-headers-wrapper, .site-footer-copyright-area, .lp_custom_help_bubble_button_container')
      .forEach(el => el instanceof HTMLElement && (el.style.display = 'block'));
      document.querySelector('.site-content')?.setAttribute("style", "width: calc(100% - 40px)");
    };
  }, [columns]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, placeholder } = e.target;

    setEditColumns((prev:any) => prev.map((col:any) => col.name === name ? { ...col, isChecked: checked } : col));
    setArranges((prev:any) => checked ? [...prev, placeholder] : prev.filter((item:any) => item !== placeholder));
    setSelectedItem(checked ? placeholder : '');
  };

  const moveItem = (direction: 'up' | 'down') => {
    const currentIndex = arranges.indexOf(selectedItem);
    if ((direction === 'up' && currentIndex > 0) || (direction === 'down' && currentIndex < arranges.length - 1)) {
      const newOrder = [...arranges];
      const [item] = newOrder.splice(currentIndex, 1);
      newOrder.splice(direction === 'up' ? currentIndex - 1 : currentIndex + 1, 0, item);
      setArranges(newOrder);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
       settingsRef.current &&
       !settingsRef.current.contains(event.target as Node)
      ) {
        handleChangeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [settingsRef, handleChangeModal]);

  const isColumnAllowed = (value: string) => {
    const allowedColumns = ['total_sold_price', 'sold_on','shipping_status', 'shipping_cost_buyer', 'fees', 'quantity_sold', 'date_sold', 'shop_id', 'shipping_cost_seller', 'quantity_available', 'platforms', 'sku', 'cogs', 'linked', 'sales_tax','profit'];
    return allowedColumns.includes(value);
  };


  return (
   <>
     <div className={styles.tableSettings__overlay}>
       <div ref={settingsRef} className={styles.modal}>
         <h1 className={styles.modal__title}>Columns</h1>
         <div className={styles.modal__wrapper}>
           <div className={styles.modal__viewColumns}>
             <h3 className={styles.columns__title}>Available Columns</h3>
             <div className={styles.modal__viewColumns_wrapper}>
               {editColumns?.map((column: IColumnData, index: number) => (
                <>
                  {isColumnAllowed(column?.value) ? (
                   <div className={styles.viewColumnItem}>
                     <input
                      type="checkbox"
                      className={styles.myCheckbox}
                      id={column.value}
                      name={column.name}
                      checked={column?.isChecked || false}
                      onChange={handleChange}
                      placeholder={column.value}
                     />
                     <label htmlFor={column.value}>
                       {column.name === 'Cogs' ? 'COGS' : column.name}
                     </label>
                   </div>
                  ) : (
                   <Tippy
                    interactive={true}
                    zIndex={5}
                    arrow={false}
                    trigger="focus click"
                    content={
                      <p>
                        It's only available for Pro Plus.
                      </p>
                    }
                   >
                     <div className={styles.viewColumnItem}>
                       <input
                        type="checkbox"
                        className={styles.myCheckbox}
                        id={column.value}
                        name={column.name}
                        checked={true}
                        // onChange={handleChange}
                        placeholder={column.value}
                        disabled={true}
                       />
                       <label
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 5,
                          opacity: '0.7',
                        }}
                        htmlFor={column.value}
                       >
                         {column.name === 'Cogs' ? 'COGS' : column.name}
                       </label>
                       <CircleQuestionGraySVG
                        style={{
                          cursor: 'pointer',
                          width: 25,
                          height: 25,
                        }}
                       />
                     </div>
                   </Tippy>
                  )}
                </>
               ))}
             </div>
           </div>
           {arranges.length > 0 && (
            <div className={styles.arrange__block}>
              <h3 className={styles.columns__title}>Arrange</h3>
              <div className={styles.arrange__items}>
                <div className={styles.arrange__wrapper}>
                  {arranges?.map((item, index) => (
                   <span
                    key={index}
                    className={
                      arranges.indexOf(selectedItem) === index
                       ? styles.activeSpan
                       : undefined
                    }
                    onClick={() => setSelectedItem(item)}
                   >
                        {item === 'cogs'
                         ? 'COGS'
                         : item === 'sku'
                          ? 'SKU'
                          : formatString(item)}
                      </span>
                  ))}
                </div>
                <div className={styles.arrange__directions}>
                    <span
                     style={{
                       opacity:
                        arranges.indexOf(selectedItem) === 0
                         ? '0.4'
                         : undefined,
                       cursor:
                        arranges.indexOf(selectedItem) === 0
                         ? 'not-allowed'
                         : undefined,
                     }}
                     onClick={() => moveItem('up')}
                    >
                      <SVGArrow />
                    </span>
                  <span
                   style={{
                     opacity:
                      arranges.indexOf(selectedItem) === arranges.length - 1
                       ? '0.4'
                       : undefined,
                     cursor:
                      arranges.indexOf(selectedItem) === arranges.length - 1
                       ? 'not-allowed'
                       : undefined,
                   }}
                   onClick={() => moveItem('down')}
                  >
                      <SVGArrow />
                    </span>
                </div>
              </div>
            </div>
           )}
         </div>
         <div className={styles.tableSettingFooter}>
           <div className={styles.modal__btn__block}>
             <div>

               <Button handleClick={() => handleEditColumns(arranges)} modifier={ModifierType.Primary}>Save</Button>
               <Button
                style={{
                  border:'1px solid #173593',
                  color:'#173593'
                }}
                handleClick={handleChangeModal} modifier={ModifierType.Transparent}>Cancel</Button>
             </div>
           </div>
         </div>
       </div>
     </div>
     <footer className="site-footer-copyright-area"></footer>
   </>
  );
};

export default TableEditColumn;
