import { ChangeEvent, useState, useEffect } from 'react';
import './columnSearch.css'
import _ from 'lodash';
import styles from './ColumnSearch.module.scss';
import { joinArrayValues } from '../../../../../../utils/joinArrayValues';
import { useLocation } from 'react-router-dom';
import {FaSearch, RangesCalendar, RangesCalendarGreen, CloseRanges,} from "../../../../../../assets/icons";
import 'react-datepicker/dist/react-datepicker.css';
import Tippy from "@tippyjs/react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";
import bdecode from "../../../../../../utils/bdecode";
const titleIcon =
    'https://cdn.listperfectly.com/apptest.listperfectly.com/globals/icons/shop_regular.svg';

const downUpIcon = `https://cdn.listperfectly.com/apptest.listperfectly.com/globals/icons/chevron_down_light.svg`;

interface IColumnSearchProps {
  columnValue: string;
  handleTogglePlatformsModalShow: () => void;
  activePlatforms: string[];
  inputValues: Record<string, string | number>;
  handleInputChange: (col: string, value: string | number) => void;
  filterByArr: any;
  startDateCreated:any;
  endDateCreated:any;
  setStartChangedCreated:any;
  setEndChangedCreated:any;
  setStartDateCreated:any;
  setEndDateCreated:any;
  createdTippy?:any;
  setCreatedCloseClicked?:any;
  handleToggleDateRangeModalShow?:any;
  handleToggleDateRangeModalShowSoldDate?:any;
  dateRangeModalShow?:any;
  startDateSoldDate:any;
  endDateSoldDate:any;
  setStartChangedSoldDate:any;
  setEndChangedSoldDate:any;
  setStartDateSoldDate:any;
  setEndDateSoldDate:any;
  setSoldDateCloseClicked?:any;
  dateRangeModalShowSoldDate?:any;
  selectedCondition?:any
  dropDownChangeCondition?:any;
  optionsCondition?:any;
  newOptions?:any;
  selectedSubCondition?:any
  setSelectedSubCondition?:any;
  preOwnedOptions?:any;
  optionsIntendedFor?:any;
  dropDownChangeIntendedFor?:any;
  selectedIntendedFor?:any;
}

const ColumnSearch = (props: IColumnSearchProps) => {
  const {
    columnValue,
    handleTogglePlatformsModalShow,
    activePlatforms,
    inputValues,
    handleInputChange,
    filterByArr,
    startDateCreated,
    endDateCreated,
    setStartChangedCreated,
    setEndChangedCreated,
    setStartDateCreated,
    setEndDateCreated,
    createdTippy,
    setCreatedCloseClicked,
    handleToggleDateRangeModalShow,
    handleToggleDateRangeModalShowSoldDate,
    dateRangeModalShow,
    startDateSoldDate,
    endDateSoldDate,
    setStartChangedSoldDate,
    setEndChangedSoldDate,
    setStartDateSoldDate,
    setEndDateSoldDate,
    setSoldDateCloseClicked,
    dateRangeModalShowSoldDate,
    selectedCondition,
    dropDownChangeCondition,
    optionsCondition,
    newOptions,
    selectedSubCondition,
    setSelectedSubCondition,
    preOwnedOptions,
    optionsIntendedFor,
    dropDownChangeIntendedFor,
    selectedIntendedFor
  } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isFocused, setIsFocused] = useState(false);
  const [cacher, setCacher] = useState(true);
  const [selector, setSelector] = useState('');
  const isSmallScreenLaptop = UseIsSmallLaptop() ;


  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#fff',
      color: '#1E3166',
      borderColor: '#D5D5D5',
      padding: 1,
      borderRadius: !isSmallScreenLaptop ? '4px' : '3px',
      cursor: 'pointer',
      zIndex: 1,
      minHeight: !isSmallScreenLaptop ? '39px' : '32px',
      height: !isSmallScreenLaptop ? '39px' : '32px',
      width: "100%", // Ensure full width
      minWidth: "50px", // Optional: Prevent collapsing too much
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: !isSmallScreenLaptop ? '39px' : '32px',
      padding: !isSmallScreenLaptop ? '0 6px' : '0 5px'
    }),
    input: (provided: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state:any) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: !isSmallScreenLaptop ? '39px' : '32px',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      minWidth: !isSmallScreenLaptop ? '200px' : '160px',
      border: '1px solid #1E3166',
      borderRadius: !isSmallScreenLaptop ? '8px' : '6px',
      paddingTop: !isSmallScreenLaptop ? '10px' : '8px',
      marginTop: !isSmallScreenLaptop ? '18px' : '14px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      fontWeight: 600,
      fontSize: !isSmallScreenLaptop ? '16px' : '13px',
      textAlign: 'center',
    }),
  };

  const isActive = startDateCreated || endDateCreated;
  const handleChange = ([newStartDate, newEndDate]: any) => {
    if (newStartDate !== startDateCreated ) {
      setStartDateCreated(newStartDate);
      setStartChangedCreated(true);
    }
    if (newEndDate !== endDateCreated) {
      setEndDateCreated(newEndDate);
      setEndChangedCreated(true);
    }
  };

  const isActiveSoldDate = startDateSoldDate || endDateSoldDate;
  const handleChangeSoldDate = ([newStartDate, newEndDate]: any) => {
    if (newStartDate !== startDateSoldDate ) {
      setStartDateSoldDate(newStartDate);
      setStartChangedSoldDate(true);
    }
    if (newEndDate !== endDateSoldDate) {
      setEndDateSoldDate(newEndDate);
      setEndChangedSoldDate(true);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (!event.target.value) {
      setIsFocused(false);
    }
  };

  const handleChangePlatformInput = (
      e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (columnValue === 'platforms') return;
    let value: string;
    if (columnValue === 'condition') {
      value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    } else {
      value = e.target.value;
    }
    handleInputChange(e.target.name, value);
    setCacher(false);
  };

  const handlePlatformClick = () => {
    if (columnValue !== 'platforms') return;
    handleTogglePlatformsModalShow();
  };

  let decodedFilterBy: any;
  const functionGetValueFromUrl = (columnValue: string) => {
    const filterByParam = queryParams.get('filter_by');
    if (filterByParam) {
     try {
       let safeParam = filterByParam.replace(/%(?![0-9A-Fa-f]{2})/g, "%25");
       let decodedValue :any = decodeURIComponent(safeParam);
       const tempElement = document.createElement("textarea");
       tempElement.innerHTML = decodedValue;
       decodedValue = tempElement.value;
       decodedFilterBy = JSON.parse(decodedValue) || {};
     }
     catch (e){console.log(e, 'error')}
    }
    if (columnValue === 'title') {
      return _.get(decodedFilterBy, '133');
    } else if (columnValue === 'SKU') {
      return _.get(decodedFilterBy, '1');
    } else if (columnValue === 'price') {
      return _.get(decodedFilterBy, '22');
    } else if (columnValue === 'quantity') {
      return _.get(decodedFilterBy, '851');
    } else if (columnValue === 'notes') {
      return _.get(decodedFilterBy, '726');
    } else if (columnValue === 'platforms') {
      return _.get(decodedFilterBy, 'platforms');
    } else if (columnValue === 'condition') {
    return _.get(decodedFilterBy, '15');
    } else if (columnValue === 'brand') {
      return _.get(decodedFilterBy, '206');
    }else if (columnValue === 'keywords') {
      return _.get(decodedFilterBy, '453');
    }
    else {
      return '';
    }
  };
  useEffect(() => {
    functionGetValueFromUrl(columnValue);
  }, []);

  useEffect(() => {
    if(dateRangeModalShow) {
      setStartChangedCreated(false);
      setEndChangedCreated(false);
    }
  }, [dateRangeModalShow]);

  useEffect(() => {
    if(dateRangeModalShowSoldDate) {
      setStartChangedSoldDate(false);
      setEndChangedSoldDate(false);
    }
  }, [dateRangeModalShowSoldDate]);

  const handleChangeNewCondition = (selectedOption: any) =>{
      handleInputChange('new_condition', selectedOption?.value);
      setSelectedSubCondition(selectedOption);
  }

  const handleChangePreOwnedCondition = (selectedOption: any) =>{
      handleInputChange('pre_owned_condition', selectedOption?.value);
      setSelectedSubCondition(selectedOption);
  }

  const inputType =
      columnValue === 'price'
          ? 'number'
          : columnValue === 'quantity'
              ? 'number'
              : columnValue === 'sold_price'
                  ? 'number'
                  : columnValue === 'cogs'
                      ? 'number'
                      : columnValue === 'updated'
                          ? 'date'
                          : columnValue === 'created'
                              ? 'date'
                              : 'text';

  return (columnValue === 'condition' || columnValue === 'condition 2') ? (
          <div style={{ width: '100%', padding: '0 10px' }}>
            {
                columnValue === 'condition' &&  <Select
                    styles={customStyles}
                    value={selectedCondition}
                    onChange={dropDownChangeCondition}
                    options={optionsCondition}
                    isSearchable={false}
                    placeholder={null}
                    menuPlacement={'bottom'}
                    menuPosition={'fixed'}
                />
            }
            {
              columnValue === 'condition 2' &&
                <>
                  {selectedCondition?.value === "New" && (
                      <Select
                          styles={customStyles}
                          isSearchable={false}
                          placeholder={null}
                          menuPlacement={'bottom'}
                          menuPosition={'fixed'}
                          options={newOptions}
                          value={selectedSubCondition}
                          onChange={handleChangeNewCondition}
                      />
                  )}
                  {selectedCondition?.value === "Pre-Owned" && (
                      <Select
                          styles={customStyles}
                          isSearchable={false}
                          placeholder={null}
                          menuPlacement={'bottom'}
                          menuPosition={'fixed'}
                          options={preOwnedOptions}
                          value={selectedSubCondition}
                          onChange={handleChangePreOwnedCondition}
                      />
                  )}
                </>
            }
          </div>
      )
     : columnValue === 'intended for' ?
       <div style={{ width: '100%', padding: '0 10px' }}>
          <Select
            styles={customStyles}
            value={selectedIntendedFor}
            onChange={dropDownChangeIntendedFor}
            options={optionsIntendedFor}
            isSearchable={false}
            placeholder={null}
            menuPlacement={'bottom'}
            menuPosition={'fixed'}
          />
        </div>
          : columnValue === 'platforms' ? (
              <div className={styles.platformsInput} onClick={handlePlatformClick}>
                <div className={styles.platformsContainer}>
                  <div className={styles.titleContainer}>
                    <div
                        className={styles.titleIcon}
                        style={{
                          backgroundImage: `url(${titleIcon})`,
                        }}
                    ></div>
                    <div className={styles.title} style={{textTransform:'initial'}}>
                      {activePlatforms?.length
                          ? joinArrayValues(activePlatforms)
                          : 'Platforms'}
                    </div>
                    <div
                        className={styles.downUpIcon}
                        style={{
                          backgroundImage: `url(${downUpIcon})`,
                        }}
                    ></div>
                  </div>
                </div>
              </div>
          ) : columnValue === 'issues' ? (
              <div></div>
          ) : columnValue === 'fees' ? (
              <div></div>
          ) : columnValue === 'date sold' ? (
           <div className={"dateSoldRangesPicker"}>
            <Tippy
                appendTo={createdTippy.current}
                visible={dateRangeModalShowSoldDate}
                interactive={true}
                zIndex={9991}
                arrow={false}
                trigger="focus click"
                content={
                  <div
                      className={styles.rangesModal}
                      onClick={(e) => e.stopPropagation()}
                  >
                    <div className={styles.rangesheader}>
                      <p>Set Date Created Range</p>
                      <div onClick={()=>{
                        handleToggleDateRangeModalShowSoldDate();
                        setSoldDateCloseClicked(true);
                      }}
                           className={styles.closeRangesModal}>
                        <CloseRanges/>
                      </div>
                    </div>

                    <div className={'createdRange'}>
                      <DatePicker
                          selected={startDateSoldDate}
                          onChange={handleChangeSoldDate}
                          selectsRange
                          startDate={startDateSoldDate}
                          endDate={endDateSoldDate}
                          dateFormat="MM/dd/yyyy"
                          isClearable={true}
                          open={true}
                          placeholderText="Start Date - End Date"
                          showDaysMonthYearPicker
                          showYearDropdown
                          showMonthDropdown
                      />
                      <div className={styles.rangesCalendar} >
                        <RangesCalendarGreen />
                      </div>
                    </div>
                  </div>
                }
            >
              <div className={`${styles.calendarIcon} ${isActiveSoldDate ? styles.calendarIconActive : ''}` } onClick={() => {
                if(dateRangeModalShowSoldDate){
                  setSoldDateCloseClicked(true);
                }else{
                  setSoldDateCloseClicked(false);
                }
                handleToggleDateRangeModalShowSoldDate();
              }}>
                <RangesCalendar/>
              </div>
            </Tippy>
          </div>
          )  : columnValue === 'created' ? (
              <div className={"createdRangesPicker"}>
                <Tippy
                    appendTo={createdTippy.current}
                    visible={dateRangeModalShow}
                    interactive={true}
                    zIndex={9991}
                    arrow={false}
                    trigger="focus click"
                    content={
                      <div
                          className={styles.rangesModal}
                          onClick={(e) => e.stopPropagation()}
                      >
                        <div className={styles.rangesheader}>
                          <p>Set Date Created Range</p>
                          <div onClick={()=>{
                            handleToggleDateRangeModalShow();
                            setCreatedCloseClicked(true);
                          }}
                               className={styles.closeRangesModal}>
                            <CloseRanges/>
                          </div>
                        </div>

                        <div className={'createdRange'}>
                          <DatePicker
                              selected={startDateCreated}
                              onChange={handleChange}
                              selectsRange
                              startDate={startDateCreated}
                              endDate={endDateCreated}
                              dateFormat="MM/dd/yyyy"
                              isClearable={true}
                              open={true}
                              placeholderText="Start Date - End Date"
                              showDaysMonthYearPicker
                              showYearDropdown
                              showMonthDropdown
                          />
                          <div className={styles.rangesCalendar} >
                            <RangesCalendarGreen />
                          </div>
                        </div>
                      </div>
                    }
                >
                  <div className={`${styles.calendarIcon} ${isActive ? styles.calendarIconActive : ''}` } onClick={() => {
                    if(dateRangeModalShow){
                      setCreatedCloseClicked(true);
                    }else{
                      setCreatedCloseClicked(false);
                    }
                    handleToggleDateRangeModalShow();
                  }}>
                    <RangesCalendar/>
                  </div>
                </Tippy>
              </div>
          ) :
          (
              <div style={{ position: 'relative', width: '100%' }}>
                <input
                    className="myInputCatalog no-spinner"
                    type={inputType}
                    onChange={handleChangePlatformInput}
                    value={
                       bdecode(inputValues[columnValue] ||
                        (cacher && functionGetValueFromUrl(columnValue)) ||
                        '')
                    }
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    name={columnValue}
                    {...(columnValue === 'title' ? { maxLength: 140 } : {})}
                />
                {!isFocused &&
                    !inputValues[columnValue] &&
                    !functionGetValueFromUrl(columnValue) && (
                        <div className="myInputSearchIcon">
                          <FaSearch/>
                        </div>
                    )}
              </div>
          );
};

export default ColumnSearch;
