import React, {useState} from 'react';
import _ from 'lodash';
import moment from 'moment'

import styles from './ReactDatePicker.module.scss';
import "./ReactDatePicker.scss"

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button, {ModifierType} from "../../../../components/UI/Button";

const ReactDatePicker = (props: any) => {
const { isLabel = '', handleClickFilterApply, dateRangeTableDateFilter, setDateRangeTableDateFilter } = props;

  // TODO revisit
  const [startDate, setStartDate] = useState(isLabel ? '' : dateRangeTableDateFilter.start ? new Date(dateRangeTableDateFilter.start) : '');
  const [endDate, setEndDate] = useState(isLabel ? '' : dateRangeTableDateFilter.end ? new Date(dateRangeTableDateFilter.end) : '');

    const handleStartDateChange = (date: any) => {
        if(!date){
            setStartDate('');
            setDateRangeTableDateFilter({ ..._.omit(dateRangeTableDateFilter, ['start']) });
            return;
        }
        const startOfTheDay = moment(date).startOf('day').toDate();
        setStartDate(startOfTheDay);
        setDateRangeTableDateFilter({...dateRangeTableDateFilter, start: startOfTheDay });
    };
    const handleEndDateChange = (date: any) => {
        if(!date){
            setEndDate('');
            setDateRangeTableDateFilter({ ..._.omit(dateRangeTableDateFilter, ['end']) });
            return;
        }
        const endOfTheDay = moment(date).endOf('day').toDate();
        setEndDate(endOfTheDay);
        setDateRangeTableDateFilter({...dateRangeTableDateFilter, end: endOfTheDay });
    };

  return (
    <div
      className={
        isLabel ? 'datepicker__container' : 'datepickerNOTLABEL__container'
      }>
      <div>
        {isLabel && (
          <label htmlFor="myDatePicker" className={styles.date__label}>
            Dates
          </label>
        )}
        <div id={"salesPageDatepickerBlockWrapper"} className={styles.blockWrapper}>
          <div className="datePicker__wrapper">
            <DatePicker
              placeholderText="MM/DD/YYYY"
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onFocus={(e:any) => e.target.blur()}
            />
            <span>-</span>
                <DatePicker
                 placeholderText="MM/DD/YYYY"
                 selected={endDate}
                 onChange={handleEndDateChange}
                 selectsEnd
                 startDate={startDate}
                 endDate={endDate}
                 minDate={startDate}
                 onFocus={(e:any) => e.target.blur()}

                />
          </div>
          {isLabel && (
                <Button handleClick={handleClickFilterApply} modifier={ModifierType.Primary}>Apply</Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReactDatePicker;
