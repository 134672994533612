import {useEffect, useMemo, useRef, useState} from "react";
import _ from "lodash";
import formatedString from "../../../../../../utils/formatedString";
import {instanceAxios} from "@services/index";
import convertToSlug from "../../../../../../utils/convertToSlug";
import Auth from "@services/Auth";
import Orders from "@services/Orders";
import {tableDataTransform} from "../../../../../../utils/tableDataTransform";
import OrdersService from "@services/Orders";
import AuthService from "@services/Auth";

export const shippingStatusDropdownOptions = [
	{ value: '', label: '-' },
	{ value: 'pending', label: 'Pending' },
	{ value: 'shipped', label: 'Shipped' },
	{ value: 'canceled', label: 'Canceled' },
	{ value: 'returned', label: 'Returned' },
	{ value: 'hold', label: 'Hold' },
	{ value: 'awaiting_shipment', label: 'Awaiting Shipment' },
];

interface IUseMultiItemOrderMobileCardLogicProps {
	order:any;
	tableData:any;
	setTableData:(tableData:any) => void;
	filter_by_url_json?:any;
	metadataForInfiniteScroll:any;
	orderListing?:any;
}

export default function useMultiItemOrderMobileCardLogic (props:IUseMultiItemOrderMobileCardLogicProps){
	const {order,setTableData,filter_by_url_json,metadataForInfiniteScroll, tableData,orderListing
	} = props ;

	let order_listings = _.get(order, 'listings');
	if (!Array.isArray(order_listings)) order_listings = [];

	const unescapedRowMetadata = useMemo(() => {
		const result: any = {};
		for (const key in order?.catalog_metadata) {
			if (order.catalog_metadata.hasOwnProperty(key)) {
				result[key] = _.get(order.catalog_metadata, key);
			}
		}
		return result;
	}, [_.get(order, 'catalog_metadata')]);

	const initialShippingStatus = _.get(order, 'shipping_status')
	 ? {
		 value: formatedString(_.get(order, 'shipping_status')),
		 label: _.get(order, 'shipping_status'),
	 }
	 : { value: '', label: '-' };

	const [selectedOption, setSelectedOption] = useState(initialShippingStatus);

	const [isOpenActionsDropdown,setIsOpenActionsDropdown] = useState(false) ;

	const [isCatalogModalShow, setIsCatalogModalShow] = useState(false);

	const actionsDropdownRef = useRef<HTMLDivElement>(null);
	const actionsBtnRef = useRef<HTMLDivElement>(null);

	const handleToggleActionsDropdown = () => {
		setIsOpenActionsDropdown(!isOpenActionsDropdown)
	}

	const shippingStatusDropdownChange = (selectedOption: any) => {
		setSelectedOption(selectedOption);

		const updateShippingStatus = async () => {
			try {
				await instanceAxios.put('/orders/update', {
					filter_by: { id: order.id },
					payload: {
						shipping_status: selectedOption.value
						 ? convertToSlug(selectedOption.value)
						 : null,
					},
				});
			} catch (error) {
				console.error(error);
			}
		};

		updateShippingStatus();
	};

	const getNewTableData = async () => {

		const localeFilterBy = { ...filter_by_url_json };

		if(localeFilterBy?.listingTitle) {
			delete localeFilterBy.listingTitle
		}
		if( (localeFilterBy?.date_sold_range === 'all-time'  && localeFilterBy?.order_ids) ) {
			delete localeFilterBy.date_sold_range
		}

		if(localeFilterBy?.date_sold_range === 'all-time' ) {
			delete localeFilterBy.date_sold_range
		}

		const options = { include_listings_catalog_metadata: true };
		const token = Auth.get_token();

		const metadata = {offset:0, limit:metadataForInfiniteScroll.offset || 10};

		try {
			const response:any = await Orders.list(localeFilterBy,metadata, options,_, token) ;
			if(response.data && setTableData) {
				setTableData(tableDataTransform(response.data));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleListingUnlinking = async () => {
		try {
			const listingId = orderListing?.id;
			if (!listingId) return;

			// Save to the database
			await OrdersService.listings_update(AuthService.get_token(), { id: listingId }, { catalog_id: null });

			await getNewTableData();
			handleToggleActionsDropdown()

		} catch (error) {
			console.error(error);
		}
	};

	const handleToogleShowCatalogModalShow = () => {
		setIsCatalogModalShow(!isCatalogModalShow);

		if (isCatalogModalShow) {
			getNewTableData()
			document.body.style.overflow = 'auto';
		} else {
			document.body.style.overflow = 'hidden';
		}
	};

	const handleDeleteForOneSale = async () => {
		const previousTableData = [...tableData];
		const filteredTableData = tableData?.filter((tbData:any) => tbData.id !== order.id)
		try {
			const response = await instanceAxios.put('/orders/update', {
				filter_by: {id: order.id},
				payload: {status: 'trash'},
			});

			if(response.status === 200) {
				setTableData(filteredTableData)
			}

		} catch (error) {

			setTableData(previousTableData)

			console.error(error);
		}
	}



	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as Node;
			if (
			 actionsDropdownRef.current &&
			 !actionsDropdownRef.current.contains(target) &&
			 actionsBtnRef.current &&
			 !actionsBtnRef.current.contains(target)
			) {
				setIsOpenActionsDropdown(false)
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);


	return {unescapedRowMetadata,selectedOption,shippingStatusDropdownChange,isOpenActionsDropdown,handleToggleActionsDropdown,actionsDropdownRef,actionsBtnRef,handleListingUnlinking,isCatalogModalShow,handleToogleShowCatalogModalShow,handleDeleteForOneSale}
}