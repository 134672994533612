export const shippingStatusOptions = [
	{ value: '', label: '-' },
	{ value: 'pending', label: 'Pending' },
	{ value: 'shipped', label: 'Shipped' },
	{ value: 'canceled', label: 'Canceled' },
	{ value: 'returned', label: 'Returned' },

	{ value: 'hold', label: 'Hold' },
	{ value: 'awaiting_shipment', label: 'Awaiting Shipment' },
];

export const soldDateRangeOptions = [
	{value: 'last-24-hours', label: 'Last 24 Hours'},
	{value: 'last-7-days', label: 'Last 7 Days'},
	{value: 'last-30-days', label: 'Last 30 Days'},
	{value: 'last-90-days', label: 'Last 90 Days'},
	{value: 'last-365-days', label: 'Last Year (365 days)'},
	{value: 'all-time', label: 'All Time'},
	{value: 'custom', label: 'Custom'},
];