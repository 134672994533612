import React, { useState, useRef, useEffect } from 'react';
// @ts-ignore
import Modal from 'react-modal';
// @ts-ignore
import { Document, Page, pdf, Image } from '@react-pdf/renderer';
// @ts-ignore
import html2canvas from 'html2canvas';
// @ts-ignore
import QRCode from 'qrcode';
import DropdownMenu from './DropdownMenu';
import styles from './ShippingLabelModal.module.scss';
import {XinstanceAxios} from "@services/index";
import {useSelector} from "react-redux";
import {decode} from "he";
import {MoonLoader} from "react-spinners";
import {CloseSvg} from "@assets/icons";

interface ShippingLabelModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    title?:any;
    sku?:any;
}

Modal.setAppElement('#root');

const ShippingLabelModal: React.FC<ShippingLabelModalProps> = ({ isOpen, onRequestClose , title, sku }:any) => {
    const [selectedFormat, setSelectedFormat] = useState("2x1 Shipping Label Setting");
    const [qrCodeData, setQrCodeData] = useState<string | null>(null);
    const [imgSrc, setImgSrc] = useState<string | null>(null);
    const labelRef = useRef<HTMLDivElement | null>(null);
    const pdfModalProductId = useSelector((state: any) => state.navigation.pdfModalProductId);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await XinstanceAxios.post('/listings/list', {
                metadata: { offset: 0, limit: 1 },
                filter_by: { id: pdfModalProductId },
                options: {
                    include_thumbnail: true,
                    include_images: true,
                },
            });
            if (response.status === 200) {
                const qrDataUrl = await QRCode.toDataURL(`${window.location.origin}/listings/view/${pdfModalProductId}`);
                setQrCodeData(qrDataUrl);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        try{
            if (isOpen) {
                fetchData().finally(() => handleGenerateImage(selectedFormat));
            }
        }catch(e){
            console.log(e, 'error')
        }
    }, [isOpen, pdfModalProductId]);

    // Convert Label (with QR) to Image
    const handleGenerateImage = async (localFormat?:any) => {
        try{
            if (labelRef.current) {
                // Dynamically adjust label size to match the selected format
                const { width, height } = getLabelSettings(localFormat);
                // Apply new width and height to the label container before capturing
                labelRef.current.style.width = width;
                labelRef.current.style.height = height;

                // Wait for the DOM to reflect changes
                await new Promise((resolve) => setTimeout(resolve, 100));

                // Capture the label with updated dimensions
                const canvas = await html2canvas(labelRef.current, {
                    scale: 2,  // Higher scale for better resolution
                    useCORS: true,
                });

                const imgData = canvas.toDataURL('image/png');
                setImgSrc(imgData);
                setLoading(false)
            }
        }catch(e){
            console.log(e, 'error')
        }
    };

    // Download Image as PDF (With Exact Page Size)
    const handleDownloadPdf = async () => {
        if (!imgSrc) return;
        try{
            const { pdfWidth, pdfHeight } = getPageSize(selectedFormat);

            const pdfBlob = await pdf(
                <Document>
                    <Page size={{ width: pdfWidth, height: pdfHeight }} style={{ margin: 0 }}>
                        <Image
                            src={imgSrc}
                            style={{
                                objectFit: 'contain',  // Ensures no stretching
                                width: pdfWidth,
                                height: pdfHeight,
                            }}
                        />
                    </Page>
                </Document>
            ).toBlob();

            const link = document.createElement('a');
            link.href = URL.createObjectURL(pdfBlob);
            link.download = 'shipping-label.pdf';
            link.click();
        }catch(e){
            console.log(e, 'error')
        }
    };

    const handleFormatChange = (format: string) => {
        setLoading(true);
        setSelectedFormat(format);
        try{
            handleGenerateImage(format).then(r => setLoading(false));
        }catch(e){
            console.log(e, 'error')
        }
    };

    const { width, height, fontSize, qrSize,  scale } = getLabelSettings(selectedFormat);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Shipping Label Modal"
            className={`${styles.shippingLabelModal} ${styles.responsiveModal}`}
            overlayClassName={styles.overlay}
        >
            <div className={styles.modalContent}>
                <div className={styles.upperContainer}>
                    <DropdownMenu
                        selectedFormat={selectedFormat}
                        onSelectFormat={handleFormatChange}
                    />
                    {
                        loading &&
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            <MoonLoader/></div>
                    }
                    {
                        <div className={styles.labelMainContainer}>
                            <div
                                ref={labelRef}
                                className={styles.labelContainer}
                                style={{
                                    width,
                                    height,
                                    position: loading ? 'absolute' : 'relative',
                                    top: loading ? '-1000px' : 'auto'
                                }}
                            >
                                <div
                                    className={styles.labelContent}
                                    style={{transform: `scale(${scale})`, transformOrigin: 'top left', display: 'flex', paddingRight: '2px', height:`${height}`}}
                                >
                                    {qrCodeData && (
                                        <img
                                            src={qrCodeData}
                                            alt="QR Code"
                                            className={styles.qrCode}
                                            style={{width: qrSize, height: qrSize}}
                                        />
                                    )}
                                    {
                                        (title || sku) &&
                                        <div className={styles.content} style={{marginTop: '1%'}}>
                                            {Boolean(sku) &&
                                                <h2 style={{fontSize}}>SKU:{decode(sku)}</h2>}
                                            {Boolean(title) &&
                                                <h2 style={{
                                                    fontSize,
                                                    fontWeight: 500
                                                }}>{decode(title)}</h2>}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div onClick={onRequestClose} className={styles.closeButton}><CloseSvg/></div>
                        </div>
                    }
                </div>
                <div className={styles.buttonGroup}>
                    <button className={styles.downloadButton} onClick={handleDownloadPdf}>Download PDF</button>
                </div>
            </div>
        </Modal>
    );
};

// Label Dimensions and Scaling
const getLabelSettings = (format: string) => {
    switch (format) {
        case "2x1 Shipping Label Setting":
            return { width: '2in', height: '1in', qrSize: 70, fontSize: '12px', scale: 1 };
        case "1x2 Shipping Label Setting":
            return { width: '1in', height: '2in', qrSize: 30, fontSize: '8px', scale: 1 };
        case "2x2 Shipping Label Setting":
            return { width: '2in', height: '2in', qrSize: 60, fontSize: '14px', scale: 1 };
        case "2x3 Shipping Label Setting":
            return { width: '2in', height: '3in', qrSize: 60, fontSize: '13px', scale: 1 };
        case "3x2 Shipping Label Setting":
            return { width: '3in', height: '2in', qrSize: 60, fontSize: '14px', scale: 1 };
        case "2.5x4 Shipping Label Setting":
            return { width: '2.5in', height: '4in', qrSize: 70, fontSize: '15px', scale: 1 };
        case "4x2.5 Shipping Label Setting":
            return { width: '4in', height: '2.5in', qrSize: 80, fontSize: '15px', scale: 1 };
        case "3x5 Shipping Label":
            return { width: '3in', height: '5in', qrSize: 90, fontSize: '16px', scale: 1 };
        case "5x3 Shipping Label":
            return { width: '5in', height: '3in', qrSize: 100, fontSize: '16px', scale: 1 };
        case "4x6 Shipping Label":
        default:
            return { width: '4in', height: '6in', qrSize: 120, fontSize: '16px', scale: 1 };
    }
};


// Convert Label Size to PDF Page Size
const getPageSize = (format: string) => {
    const {width, height} = getLabelSettings(format);
    return {
        pdfWidth: parseFloat(width) * 72, // Convert inches to PDF points (72 points = 1 inch)
        pdfHeight: parseFloat(height) * 72,
    };
};

export default ShippingLabelModal;