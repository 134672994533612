import styles from "../MultiItemOrderMobileCard.module.scss" ;

import accounting from "accounting";
import {useNavigate} from "react-router-dom";

import {FaImage, LinkedSVG, MobileViewIcon, NotLinkedSvg, ThreeDots} from "@assets/icons";
import config from "@config";
import useBasicFuncs from "@hooks/useBasisFuncs";
import useMultiItemOrderMobileCardLogic from "../useMultiItemOrderMobileCardLogic";
import CatalogInModal from "../../../../CatalogInModal";
import {setListingViewId, setShowListingViewModal} from "../../../../../../../redux/slices/NavigationSlice";
import {useDispatch} from "react-redux";

interface IOrderListingCardProps {
	orderListing:any;
	total_price:number;
	order:any;
	filter_by_url_json:any;
	metadataForInfiniteScroll:any;
	tableData:any;
	setTableData:(tableData:any) => void;

}
const OrderListingCard = (props:IOrderListingCardProps) => {
	const {orderListing,total_price,order,filter_by_url_json,metadataForInfiniteScroll,setTableData,tableData} = props ;

	const {isOpenActionsDropdown, handleToggleActionsDropdown, actionsBtnRef,actionsDropdownRef,handleListingUnlinking,isCatalogModalShow,handleToogleShowCatalogModalShow} = useMultiItemOrderMobileCardLogic({
		order,
		filter_by_url_json,
		metadataForInfiniteScroll,
		tableData,
		setTableData,
		orderListing
	})

	const {thumbnail,title,sku} = orderListing ;

	const {bdecode} = useBasicFuncs() ;
	const dispatch = useDispatch();
	const navigate = useNavigate() ;


	return (
	 <>
		 <div className={styles['orderListingCard']}>
			 <div className={styles['orderListingCard__leftSide']}>
				 <div className={styles['orderListingCard__image']}>
					 {
						 thumbnail ?
						  <img src={`${config.orders_image_prefix}/${thumbnail}`} alt={"order_listing_card_thumbnail"}/>
						  : <FaImage/>
					 }
				 </div>
				 <div className={styles['orderListingCard__titleBlock']}>
					 <div className={styles['orderListingCard__title']}>
						 {bdecode(title) || "-"}
					 </div>
					 <div className={styles['orderListingCard__details']}>
						 <div className={styles['orderListingCard__price']}>
							 {total_price ? accounting.formatMoney(total_price, '$', 2)
							  : "-"}
						 </div>
					 </div>
					 <div className={styles['orderListingCard__sku']}>
						 <div className={styles['orderListingCard__sku-label']}>SKU</div>
						 <div className={styles['orderListingCard__sku-value']}>{sku || "-"}</div>
					 </div>

				 </div>
			 </div>

			 <div className={styles['orderListingCard__actionsBtnWrapper']}>
				 <div ref={actionsBtnRef} className={styles['orderListingCard__actionsBtn']}
					  onClick={handleToggleActionsDropdown}>
					 <ThreeDots/>
				 </div>
				 {isOpenActionsDropdown ?
				  <div ref={actionsDropdownRef} className={styles['orderListingCard__actionsDropdown']}>
					  {
						 orderListing.catalog_id ? <div
						   className={`${styles['orderListingCard__actionsDropdownItem']} ${styles['orderListingCard__actionsDropdownItem--view']}`}
						   onClick={(e: any) => {
							   e.preventDefault();
							   if (orderListing.catalog_id) {
								   const location = window.location;
								   const searchParams = new URLSearchParams(location.search);
								   searchParams.set('modal-listing-id', `${orderListing.catalog_id}`);
								   searchParams.set('modal', 'listing-view');
								   navigate(`?${searchParams.toString()}`);
								   dispatch(setShowListingViewModal(true));
								   dispatch(setListingViewId(Number(orderListing.catalog_id)));
							   }
						   }}
						  >
							  <MobileViewIcon/>
							  <span>View</span>
						  </div> : null
					  }
					  {!orderListing.catalog_id ? (
					   <div
						className={`${styles['orderListingCard__actionsDropdownItem']} ${styles['orderListingCard__actionsDropdownItem--link']}`}
						onClick={handleToogleShowCatalogModalShow}
					   >
						   <LinkedSVG/>
						   <span>Link</span>
					   </div>
					  ) : (
					   <div onClick={handleListingUnlinking}
							className={`${styles['orderListingCard__actionsDropdownItem']} ${styles['orderListingCard__actionsDropdownItem--unlink']}`}>
						   <NotLinkedSvg/>
						   <span>Unlink</span>
					   </div>
					  )}
				  </div> : null}
			 </div>
		 </div>
		 {isCatalogModalShow ? <CatalogInModal handleToogleShowCatalogModalShow={handleToogleShowCatalogModalShow}
											   handleListingUnlinking={handleListingUnlinking}
											   selectedListing={orderListing} orderId={order.id}/> : null}
	 </>
	)
}

export default OrderListingCard;