import { useState, useEffect } from "react";
import _ from "lodash";
import UserConfigs from "@services/UserConfigs"; // Adjust the import path
import Auth from "@services/Auth"; // Assuming `token` comes from an auth hook

const useShopifyInfo = () => {
    const [shopifyName, setShopifyName] = useState<string>("");
    const [shopifyURLFormat, setShopifyURLFormat] = useState<any>("");
    const token: any = Auth.get_token();

    useEffect(() => {
        const fetchShopifyInfo = async () => {
            try {
                const response: any = await UserConfigs.get("chrome_extension", token);

                if (response) {
                    const { config } = response;
                    const name = _.get(config, "shopifyShopId1", "");
                    const urlFormat = _.get(config, "shopifyURLFormat", "");
                    if (name?.length) {
                        setShopifyName(name);
                        setShopifyURLFormat(urlFormat);
                    }
                }
            } catch (e) {
                console.error("Error fetching Shopify info", e);
            }
        };

        fetchShopifyInfo();
    }, [token]); // Runs when `token` changes

    return { shopifyName, shopifyURLFormat };
};

export default useShopifyInfo;
