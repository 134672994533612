// useSingleItemOrderMobileCardLogic.ts
import {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import _ from 'lodash';
import formatedString from '../../../../../../utils/formatedString';
import { instanceAxios } from '@services/index';
import convertToSlug from '../../../../../../utils/convertToSlug';
import AuthService from "@services/Auth";
import OrdersService from "@services/Orders";
import {tableDataTransform} from "../../../../../../utils/tableDataTransform";
import Orders from "@services/Orders";
import Auth from "@services/Auth";

export const shippingStatusDropdownOptions = [
	{ value: '', label: '-' },
	{ value: 'pending', label: 'Pending' },
	{ value: 'shipped', label: 'Shipped' },
	{ value: 'canceled', label: 'Canceled' },
	{ value: 'returned', label: 'Returned' },
	{ value: 'hold', label: 'Hold' },
	{ value: 'awaiting_shipment', label: 'Awaiting Shipment' },
];

export default function useSingleItemOrderMobileCardLogic(props: any) {
	const {order,tableData,setTableData,filter_by_url_json,metadataForInfiniteScroll} = props ;
	const unescapedRowMetadata = useMemo(() => {
		const result: any = {};
		for (const key in order?.catalog_metadata) {
			if (order.catalog_metadata.hasOwnProperty(key)) {
				result[key] = _.get(order.catalog_metadata, key);
			}
		}
		return result;
	}, [_.get(order, 'catalog_metadata')]);

	let order_listings = _.get(order, 'listings');
	if (!Array.isArray(order_listings)) order_listings = [];
	const is_linked = !!(_.get(order, 'listings[0][catalog_id]') || _.get(order, 'catalog_id')); // * catalog_id

	const initialShippingStatus = _.get(order, 'shipping_status')
	 ? {
		 value: formatedString(_.get(order, 'shipping_status')),
		 label: _.get(order, 'shipping_status'),
	 }
	 : { value: '', label: '-' };

	const [selectedOption, setSelectedOption] = useState(initialShippingStatus);

	const [isOpenActionsDropdown,setIsOpenActionsDropdown] = useState(false) ;

	const [loadingDimensions, setLoadingDimensions] = useState(true);
	const [dimensions, setDimensions] = useState<string | null>(null);

	const [isCatalogModalShow, setIsCatalogModalShow] = useState(false);
	const [selectedListing, setSelectedListing] = useState<any>(null);


	const actionsDropdownRef = useRef<HTMLDivElement>(null);
	const actionsBtnRef = useRef<HTMLDivElement>(null);
	const shippingStatusDropdownChange = (selectedOption: any) => {
		setSelectedOption(selectedOption);

		const updateShippingStatus = async () => {
			try {
				await instanceAxios.put('/orders/update', {
					filter_by: { id: order.id },
					payload: {
						shipping_status: selectedOption.value
						 ? convertToSlug(selectedOption.value)
						 : null,
					},
				});
			} catch (error) {
				console.error(error);
			}
		};

		updateShippingStatus();
	};

	const handleToggleActionsDropdown = () => {
		setIsOpenActionsDropdown(!isOpenActionsDropdown)
	}

	useLayoutEffect(() => {
		setLoadingDimensions(true);

		const getImageDimensions = (src: string | undefined) => {
			try {
				if (src) {
					const img = new Image();
					img.src = src;

					img.onload = () => {
						if (img.width >= img.height) {
							setDimensions('width');
						} else {
							setDimensions('height');
						}

						// Set loader to false when image is loaded
						setLoadingDimensions(false);
					};
				}
			} catch (e) {
				console.error(e);
				setLoadingDimensions(false); // Handle errors and set loader to false
			}
		};

		getImageDimensions(order.thumbnail);
	}, [order.thumbnail]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as Node;
			if (
			 actionsDropdownRef.current &&
			 !actionsDropdownRef.current.contains(target) &&
			 actionsBtnRef.current &&
			 !actionsBtnRef.current.contains(target)
			) {
				setIsOpenActionsDropdown(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleDeleteForOneSale = async () => {
		const previousTableData = [...tableData];
		const filteredTableData = tableData?.filter((tbData:any) => tbData.id !== order.id)
		try {
			const response = await instanceAxios.put('/orders/update', {
				filter_by: {id: order.id},
				payload: {status: 'trash'},
			});

			if(response.status === 200) {
				setTableData(filteredTableData)
			}

		} catch (error) {

			setTableData(previousTableData)

			console.error(error);
		}
	}
	const getNewTableData = async () => {

		const localeFilterBy = { ...filter_by_url_json };

		if(localeFilterBy?.listingTitle) {
			delete localeFilterBy.listingTitle
		}
		if( (localeFilterBy?.date_sold_range === 'all-time'  && localeFilterBy?.order_ids) ) {
			delete localeFilterBy.date_sold_range
		}

		if(localeFilterBy?.date_sold_range === 'all-time' ) {
			delete localeFilterBy.date_sold_range
		}

		const options = { include_listings_catalog_metadata: true };
		const token = Auth.get_token();


		const metadata = {offset:0, limit:metadataForInfiniteScroll.offset || 10};

		try {
			const response:any = await Orders.list(localeFilterBy,metadata, options,_, token) ;
			if(response.data) {
				setTableData(tableDataTransform(response.data));
			}
		} catch (error) {
			console.error(error);
		}
	};


	const handleListingUnlinking = async () => {
		try {
			const listingId = order_listings[0]?.id;
			if (!listingId) return;

			// Save to the database
			await OrdersService.listings_update(AuthService.get_token(), { id: listingId }, { catalog_id: null });
			await getNewTableData();
			handleToggleActionsDropdown()
		} catch (error) {
			console.error(error);
		}
	};


	const handleToogleShowCatalogModalShow = () => {
		setIsCatalogModalShow(!isCatalogModalShow);
		if(order_listings.length === 1) {
			setSelectedListing(order_listings[0])
		}

		if (isCatalogModalShow) {
			 getNewTableData()
			document.body.style.overflow = 'auto';
		} else {
			document.body.style.overflow = 'hidden';
		}
	};


	return { unescapedRowMetadata, selectedOption, shippingStatusDropdownChange,isOpenActionsDropdown,handleToggleActionsDropdown,loadingDimensions,dimensions,actionsDropdownRef,actionsBtnRef,is_linked,handleDeleteForOneSale,handleListingUnlinking,isCatalogModalShow, handleToogleShowCatalogModalShow,selectedListing };
}
